import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchHashtags } from '../../redux/actions/post';

const HashtagSuggestions = ({ query, onSelect }) => {
  const dispatch = useDispatch();
  const { loading, hashtags } = useSelector((state) => state.hashtags);

  useEffect(() => {
    if (query) {
      dispatch(fetchHashtags(query));
    }
  }, [query, dispatch]);

  return (
    <div className="hashtag-suggestions-component-container">
      {loading ? (
        <div className="hashtag-suggestions-component-loading">Loading...</div>
      ) : (
        hashtags.map((hashtag) => (
          <div
            key={hashtag.tag}
            className="hashtag-suggestions-component-item"
            onClick={() => onSelect(hashtag.tag)}
          >
            <span className="hashtag-suggestions-component-text">
              #{hashtag.tag} ({hashtag.count})
            </span>
          </div>
        ))
      )}
    </div>
  );
};

export default HashtagSuggestions;

