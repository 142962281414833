import React from 'react';
import { useSelector } from 'react-redux';

const AboutUser = () => {
  const { user } = useSelector((state) => state.user);

  if (!user) {
    return <div>Loading user data...</div>;
  }

  return (
    <div className="about-user-container">
      <div className="about-user-header">
        <h3 className="about-user-title">About {user.name}</h3>
        <p className="about-user-subtitle">Personal details and information.</p>
      </div>
      <div className="about-user-content">
        <dl className="about-user-details">
          <div className="about-user-row">
            <dt className="about-user-label">Full name</dt>
            <dd className="about-user-value">{user.name}</dd>
          </div>
          <div className="about-user-row">
            <dt className="about-user-label">Email address</dt>
            <dd className="about-user-value">{user.email}</dd>
          </div>
         
        </dl>
      </div>
    </div>
  );
};

export default AboutUser;

