import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ProductCard from "../Route/ProductCard/ProductCard";

const SuggestedProduct = ({ data }) => {
  const { allProducts } = useSelector((state) => state.products);
  const [productData, setProductData] = useState();

  useEffect(() => {
    const d = allProducts && allProducts.filter((i) => i.category === data.category);
    setProductData(d);
  }, [allProducts, data.category]); 

  return (
    <div>
      {data ? (
        <div className="suggested-product-container">
          <h2 className="suggested-product-heading">
            Related Products
          </h2>
          <div className="suggested-product-grid">
            {productData && productData.map((product) => (
              <ProductCard data={product} key={product._id} />
            ))}
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default SuggestedProduct;


