import React, { useState, useEffect } from 'react';
import Header from '../components/Layout/Header';
import Sidebar from '../components/Layout/Sidebar';
import AdvertWidget from '../widgets/AdvertWidget';
import { useSelector } from "react-redux";
import MyPostWidget from '../widgets/MyPostWidget';
import MainFeedWidget from '../widgets/MainFeedWidget';
import SponsoredProducts from '../components/Sponsored/SponsoredProducts';
import NotificationPopup from '../components/Notifications/notificationspopup'

const LandingPage = () => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const picturePath = user?.picturePath;
  const currentProfile = useSelector((state) => state.user.currentProfile);
  const { seller } = useSelector((state) => state.seller);
  const userId= user?._id;
  const sellerId = seller?._id;
  const id = currentProfile === 'User' ? userId : sellerId;

  return (
    <div className="profile-page bg-black">
      <Header />
      <Sidebar />
      <NotificationPopup />
      <div className="mx-auto flex w-full flex-col lg:flex-row items-start gap-x-8 px-4 py-10 sm:px-6 lg:px-24">
        
        {/* Explicitly hide this <aside> on screens smaller than 'lg' */}
        <aside className="lg:sticky top-24 hidden lg:block w-full lg:w-69 shrink-0 bg-[#f5f5f5] p-2 rounded-lg">
          <AdvertWidget />
        </aside>
        
        {/* Main content area, always visible */}
        <main className="flex-grow p-2 w-full">
          <MyPostWidget picturePath={picturePath} />
          <MainFeedWidget ownerId={id} profileType={currentProfile} isProfile={false} />
        </main>

        {/* Explicitly hide this <aside> on screens smaller than 'lg' */}
        <aside className="lg:sticky top-24 hidden lg:block w-full lg:w-69 shrink-0 bg-[#f5f5f5] p-4 rounded-lg">
          <SponsoredProducts/>
        </aside>
      </div>
    </div>
  );
};


export default LandingPage;