import React, { useState } from "react";
import {
  ChatBubbleOutlineOutlined,
  ChatBubble,
  FavoriteBorderOutlined,
  FavoriteOutlined,
  ShareOutlined,
} from "@mui/icons-material";
import { Box, Divider, IconButton, Typography } from "@mui/material";
import FlexBetween from "../components/FlexBetween";
import Friend from "../components/Friend";
import WidgetWrapper from "../components/WidgetWrapper";
import { useDispatch, useSelector } from "react-redux";
import { setPost } from "../state";
import Cookies from 'js-cookie';
import axios from 'axios';
import { useEffect } from "react";
import { Popover, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { Fragment } from 'react'
import {
  EllipsisHorizontalIcon,
} from '@heroicons/react/24/outline';
import { format } from 'date-fns';
import { server } from "../server";
import ProfileSwitchButton from "../components/ProfileSwitchButton";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { EDIT_POST_SUCCESS } from "../redux/actions/post";




const PostWidget = ({
  postId,
  postUserId,
  owner,
  name,
  description,
  profileType,
  location,
  mediaPath,
  avatar,
  likes: initialLikes,
  comments,
  createdAt,
  onShareSuccess,
  resharedBy,
  reshareDescription,
  reshareAvatar,
  onPostDelete,
  onPostEdit
}) => {
  const [isComments, setIsComments] = useState(false);
  const dispatch = useDispatch();
  const token = Cookies.get('token');
  const { user } = useSelector((state) => state.user);
  const { seller } = useSelector((state) => state.seller);
  const loggedInUserId = user?._id;
  const loggedInSellerId = seller?._id;
  const [scrollPosition, setScrollPosition] = useState(0);
  const currentProfile = useSelector(state => state.user.currentProfile);
  const userProfile = useSelector(state => state.user);
  const sellerProfile = useSelector(state => state.seller); 
  const [showReshareInput, setShowReshareInput] = useState(false);
  const [reshareInput, setReshareInput] = useState('');
  const [isReshare, setIsReshare] = useState(!!resharedBy);
  const [isEditMode, setIsEditMode] = useState(false);
  const [editedDescription, setEditedDescription] = useState(description);
  const [editedReshareDescription, setEditedReshareDescription] = useState(reshareDescription);
  const [commentUpdateTrigger, setCommentUpdateTrigger] = useState(0);
  const formattedDate = format(new Date(createdAt), "PPP");
  const [currentPage, setCurrentPage] = useState(1);
  const [allCommentsLoaded, setAllCommentsLoaded] = useState(false);


  const toggleEditMode = () => {
    // When entering edit mode, save the current scroll position
    if (!isEditMode) {
        setScrollPosition(window.scrollY);
    } else {
        // Restore the scroll position when exiting edit mode
        window.scrollTo(0, scrollPosition);
    }

    setIsEditMode(!isEditMode);

    if (isEditMode) {
        setEditedDescription(description);
    }
};

  const currentProfileType = useSelector(state => state.user.currentProfile); // Assuming this holds either 'User' or 'Shop'
  const currentProfileId = currentProfileType === 'User' ? loggedInUserId : loggedInSellerId;
  const [isLiked, setIsLiked] = useState(initialLikes[currentProfileId] === true);
  const [likeCount, setLikeCount] = useState(initialLikes ? Object.keys(initialLikes).length : 0);
  const [commentText, setCommentText] = useState('');
  const [fetchedComments, setFetchedComments] = useState([]);
  const [editingCommentId, setEditingCommentId] = useState(null);
  const [newCommentText, setNewCommentText] = useState('');
  const currentProfileName = sessionStorage.getItem('currentProfile');

 

// Function to like or unlike a post
const likeOrUnlikePost = async () => {
  const profileType = currentProfile
  const likeData = { profileType };

  try {
    const response = await axios.patch(`${server}/posts/like-post/${postId}`, likeData, {
      withCredentials: true,
      headers: {
        Authorization: `Bearer ${token}`,
        'current-profile': currentProfileName || 'defaultProfile',
      },
    });

    if (response.data && response.data.likes) {
      const updatedPost = response.data;
      const newLikeCount = Object.keys(updatedPost.likes).length;

      // Determine if the current user or shop has liked the post
      const isLikedNow = updatedPost.likes[currentProfileId] === true;

      setIsLiked(isLikedNow);
      setLikeCount(newLikeCount);

      dispatch(setPost({ post: updatedPost }));
    } else {
      console.error("Invalid response format or missing 'likes' property");
    }
  } catch (error) {
    console.error("Error occurred during like or unlike post request:", error);
  }
};



  const handleCommentSubmit = async (e) => {
    e.preventDefault(); // Prevent default form submission behavior
    await postComment(); // Call your existing postComment function
  };

  // Function to fetch comments for a post
const fetchComments = async (page = 1) => {
  if (!postId) {
    console.error("Post ID is undefined.");
    return;
  }

  try {
    const response = await axios.get(`${server}/posts/get-comments/${postId}?page=${page}&limit=10`, {
      withCredentials: true,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    // Use a more efficient way to concatenate and deduplicate comments
    setFetchedComments(prevComments => {
      const newComments = response.data.comments;
      const combinedComments = [...prevComments, ...newComments];
      // Create a map to eliminate duplicates based on comment _id
      const commentMap = new Map(combinedComments.map(comment => [comment._id, comment]));
      return Array.from(commentMap.values());
    });
    // Check if all comments have been loaded
    if (response.data.currentPage >= response.data.totalPages) {
      setAllCommentsLoaded(true);
    }
  } catch (error) {
    console.error("Error fetching the comments:", error);
  }
};



  // useEffect to fetch comments when the component mounts or postId changes
  useEffect(() => {
    fetchComments();
  }, [postId, commentUpdateTrigger]); // Dependency array includes postId

  const loadMoreComments = () => {
    const nextPage = currentPage + 1;
    fetchComments(nextPage);
    setCurrentPage(nextPage);
  };
  

  //Function to post comment
const postComment = async () => {
  try {
    const commentData = {
      commentText,
      profileType: currentProfile,
    };

    const response = await axios.post(`${server}/posts/post-comment/${postId}`, commentData, {
      withCredentials: true,
      headers: {
          Authorization: `Bearer ${token}`,
          'current-profile': currentProfileName || 'defaultProfile',
      },
    });

    if (response.data) {
      const newComment = response.data; // Use the response data directly

      console.log("New comment object:", newComment);

      setFetchedComments(prevComments => [...prevComments, newComment]);
      setCommentText(''); // Clear the input field
      setCommentUpdateTrigger(prev => prev + 1); // Trigger any re-fetch or update
    }
  } catch (error) {
      console.error("Error posting comment:", error);
  }
};


  

//Function to edit comment 

const submitEditComment = async (postId, commentId) => {
  try {
    const response = await axios.patch(`${server}/posts/edit-comment/${postId}/${commentId}`, 
    { commentText: newCommentText }, 
    {
      withCredentials: true,
      headers: {
        Authorization: `Bearer ${token}`,
        'current-profile': currentProfileName || 'defaultProfile',
      },
    });

    if (response.status === 200) {
      console.log("Comment updated successfully");
      setEditingCommentId(null);
      setNewCommentText('');
      // Optionally, re-fetch comments to update the UI
      fetchComments();
    }
  } catch (error) {
    console.error("Error occurred during comment update:", error);
  }
};

//Function to delete comments
const deleteComment = async (postId, commentId) => {
  try {
    const response = await axios.delete(`${server}/posts/delete-comment/${postId}/${commentId}`, {
      withCredentials: true,
      headers: {
        Authorization: `Bearer ${token}`,
        'current-profile': currentProfileName || 'defaultProfile',
      },
    });

    if (response.status === 200) {
      console.log("Comment deleted successfully");
      // Optionally, fetch comments again to update the UI
      fetchComments();
    }
  } catch (error) {
    console.error("Error occurred during comment deletion:", error);
  }
};



//Function to delete posts

const deletePost = async (profileType = 'User') => {
  try {
    const response = await axios.delete(`${server}/posts/delete-post/${postId}`, {
      withCredentials: true,
      headers: {
        Authorization: `Bearer ${token}`,
        'Profile-Type': profileType,
        'current-profile': currentProfileName || 'defaultProfile',
      },
    });

    if (response.status === 200) {
      console.log("Post deleted successfully");
      onPostDelete(postId); // Call the passed-down callback
    }
  } catch (error) {
    console.error("Error occurred during post deletion:", error);
  }
};

// Adjusted submitEdit function
const submitEdit = async () => {
  try {
    const response = await axios.patch(`${server}/posts/edit-post/${postId}`, {
      description: editedDescription,
    }, {
      withCredentials: true,
      headers: {
        Authorization: `Bearer ${token}`,
        'Profile-Type': profileType,
        'current-profile': currentProfileName || 'defaultProfile',
      },
    });

    if (response.status === 200) {
      console.log("Post edited successfully");
      setIsEditMode(false); // Exit edit mode
      dispatch(EDIT_POST_SUCCESS(response.data));
      toast.success('Post edited successfully!', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  } catch (error) {
    console.error("Error occurred during post edit:", error);
    toast.error('Failed to edit post. Please try again.', {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
};


const handleEditSubmit = async (e) => {
  e.preventDefault();
  if (isReshare) {
    await submitReshareEdit();
  } else {
    await submitEdit();
  }
};

const handleCancelEdit = () => {
  setEditedDescription(description); // Reset to original description
  setEditedReshareDescription(reshareDescription); // Reset to original reshare description
  setIsEditMode(false); // Exit edit mode
};


//Function to edit reshared posts
const submitReshareEdit = async () => {
  try {
    const updateData = {
      reshareDescription: editedReshareDescription
    };

    const response = await axios.patch(`${server}/posts/edit-post/${postId}`, updateData, {
      withCredentials: true,
      headers: {
        Authorization: `Bearer ${token}`,
        'Profile-Type': profileType,
        'current-profile': currentProfileName || 'defaultProfile',
      },
    });

    if (response.status === 200) {
      console.log("Reshare post edited successfully");
      setIsEditMode(false); // Exit edit mode
      setEditedReshareDescription(editedReshareDescription); // Update the reshare description in state
      // Optionally update global state or perform other actions
    }
  } catch (error) {
    console.error("Error occurred during reshare post edit:", error);
  }
};

const renderEditForm = () => (
  <form onSubmit={handleEditSubmit} className="absolute top-0 left-0 w-full p-2 bg-black rounded shadow-md">
    <textarea
      className="w-full resize-none border rounded-lg bg-gray-700 border-gray-300 px-3 py-2 text-gray-700 placeholder-gray-400 focus:border-green-500 focus:ring-1 focus:ring-green-500 focus:outline-none transition duration-200 ease-in-out"
      value={isReshare ? editedReshareDescription : editedDescription}
      onChange={(e) => isReshare ? setEditedReshareDescription(e.target.value) : setEditedDescription(e.target.value)}
      rows={3}
    />
    <div className="flex justify-end space-x-2 mt-3">
      <button type="submit" className="inline-flex items-center justify-center rounded-md bg-green-600 px-4 py-2 text-sm font-semibold text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 transition duration-200 ease-in-out">
        Save Changes
      </button>
      <button type="button" onClick={handleCancelEdit} className="inline-flex items-center justify-center rounded-md bg-gray-300 px-4 py-2 text-sm font-semibold text-gray-700 hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 transition duration-200 ease-in-out">
        Cancel
      </button>
    </div>
  </form>
);


const handleReshareEditSubmit = async (e) => {
  e.preventDefault();
  await submitEdit();
};

const handleCancelReshare = () => {
  setShowReshareInput(false); // This will hide the reshare input field
  setReshareInput(''); // Optional: Clear the input field
};

  // Function to handle share
  const handleSharePost = async () => {
    try {
      const shareData = {
        profileType: currentProfile,
        reshareDescription: reshareInput,
      };
  
      const response = await axios.post(`${server}/posts/share-post/${postId}`, shareData, {
        withCredentials: true,
        headers: {
          Authorization: `Bearer ${token}`,
          'current-profile': currentProfileName || 'defaultProfile',
        },
      });
  
      if (response.status === 201) {
        onShareSuccess();
        alert('Post shared successfully');
        // Handle post share success (like refetching the posts list)
      } else {
        console.error('Failed to share post:', response);
      }
      setShowReshareInput(false);
    } catch (error) {
      console.error('Error sharing post:', error);
      // Optionally display a more user-friendly error message
      if (error.response && error.response.data && error.response.data.message) {
        alert(`Error sharing post: ${error.response.data.message}`);
      } else {
        alert('Error sharing post. Please try again.');
      }
    }
  };
  
  const handleShowReshareInput = () => {
    setReshareInput('');
    setShowReshareInput(true);
  };
  
  const isVideo = (path) => {
    return path && (path.endsWith(".mp4") || path.endsWith(".webm")); // Add other video formats if needed
  };

  useEffect(() => {
    setEditedDescription(description);
  }, [description]); // Add other dependencies as needed
  


return (
  <>
  <ToastContainer />
  <WidgetWrapper m="2rem 0" className="relative">
    {/* Conditional rendering of Edit and Delete buttons */}
    {(loggedInUserId === owner || loggedInSellerId === owner) && (
  <Popover className="relative inline-block">
    <Popover.Button className="inline-flex items-center text-sm font-semibold text-gray-900">
      <EllipsisHorizontalIcon className="h-10 w-10" aria-hidden="true" />
    </Popover.Button>

    <Transition
      as={Fragment}
      enter="transition ease-out duration-200"
      enterFrom="opacity-0 translate-y-1"
      enterTo="opacity-100 translate-y-0"
      leave="transition ease-in duration-150"
      leaveFrom="opacity-100 translate-y-0"
      leaveTo="opacity-0 translate-y-1"
    >
      <Popover.Panel className="absolute z-10 mt-2 shadow-lg ring-1 ring-black">
        <div className="py-1">
          <a
            href="#"
            onClick={toggleEditMode}
            className="block px-4 py-2 text-sm text-gray-700"
          >
            Edit
          </a>
          <a
            href="#"
            onClick={deletePost}
            className="block px-4 py-2 text-sm text-gray-700"
          >
            Delete
          </a>
        </div>
      </Popover.Panel>
    </Transition>
  </Popover>
)}
      

{/* Reshare section */}
{resharedBy && (
  <div style={{ borderBottom: '1px solid #ccc', paddingBottom: '10px', marginBottom: '10px' }}>
    <div className="reshare-header" style={{ display: 'flex', alignItems: 'left' }}>
      <img 
        src={reshareAvatar || 'default-avatar-url'} 
        alt={resharedBy}
        style={{ width: '30px', height: '30px', borderRadius: '50%', marginRight: '10px' }} 
      />
      <span style={{ fontWeight: 'bold' }}>Reshared by {resharedBy}</span>
    </div>
    {reshareDescription && (
    <div className="reshare-description" style={{ marginLeft: '40px', marginTop: '5px' }}>
      {isEditMode && isReshare ? renderEditForm() : reshareDescription}
    </div>
    )}
   </div>
  )}

      {/* Reshare input field */}
    {showReshareInput && (
      <div className="reshare-container">
        <textarea
          className="reshare-textarea"
          value={reshareInput}
          onChange={(e) => setReshareInput(e.target.value)}
          placeholder="Add a description to your reshare..."
        />
        <button className="reshare-button" onClick={handleSharePost}>
          Share
        </button>
        <button className="reshare-button cancel-button" onClick={handleCancelReshare}>
          Cancel
        </button>
      </div>
    )}
        <Friend
          friendId={owner}
          friendType={profileType}
          name={name}
          subtitle={location}
          avatar={avatar}
        />
        {/* Date Display at the bottom right */}
        <div style={{ fontSize: '10px' }} className="px-20 text-gray-500">
          {formattedDate}
        </div>
{/* Post Description or Edit Form */}
<div>
      {isEditMode && !isReshare ? renderEditForm() : (
        <Typography color="#1f9c29" sx={{ mt: "1rem" }}>{description}</Typography>
      )}
    </div>

        {/* Media Rendering: Image or Video */}
      {mediaPath && (
        isVideo(mediaPath) ? (
          <video width="100%" controls>
            <source src={mediaPath} type="video/mp4" /> 
            Your browser does not support the video tag.
          </video>
        ) : (
          <img
            width="100%"
            height="auto"
            alt="post media"
            style={{ borderRadius: "0.75rem", marginTop: "0.75rem" }}
            src={mediaPath}
          />
        )
      )}
        <FlexBetween mt="0.25rem">
          <FlexBetween gap="1rem">
            <FlexBetween gap="0.3rem">
              <IconButton onClick={likeOrUnlikePost}>
                {isLiked ? (
                  <img src="https://res.cloudinary.com/dzlopmfj8/image/upload/v1702746849/thumbs-up-active_biz9jp.svg" alt="Liked" style={{ width: '24px', height: '24px' }} />
                  ) : (
                    <img src="https://res.cloudinary.com/dzlopmfj8/image/upload/v1702746849/thumbs-up_pqr2o9.svg" alt="Not Liked" style={{ width: '24px', height: '24px' }} />
                  )}
              </IconButton>
              <Typography>{likeCount}</Typography>
            </FlexBetween>

            <FlexBetween gap="0.3rem">
            <IconButton onClick={() => setIsComments(!isComments)}>
              {fetchedComments.length > 0 ? (
                <ChatBubble /> 
              ) : (
                <ChatBubbleOutlineOutlined />
              )}
            </IconButton>
              <Typography>{fetchedComments.length}</Typography>
            </FlexBetween>
          </FlexBetween>

          <IconButton onClick={handleShowReshareInput} title="Share Post">
          <ShareOutlined />
        </IconButton>
        </FlexBetween>

    {/* Comments Section */}
    {isComments && (
      <div className="mt-4 space-y-4">
        {fetchedComments.map((commentObj) =>  (
          
          <div key={commentObj._id} className="flex items-start space-x-4">

            {/* Commenter Avatar */}
            <div className="flex-shrink-0">
            <img
                className="h-10 w-10 rounded-full"
                src={commentObj.profileType === 'Shop' 
                     ? (commentObj.shopId?.avatar?.url || 'default-shop-avatar-url') 
                     : (commentObj.userId?.avatar?.url || 'default-user-avatar-url')}
                alt={commentObj.profileType === 'Shop' 
                     ? commentObj.shopId?.name || 'Shop Name' 
                     : commentObj.userId?.name || 'User Name'}
            />
            </div>
            <div className="min-w-0 flex-1">
              {/* Comment Text and Dropdown for Edit/Delete */}
              <span className="block text-sm font-medium text-white pb-1">
              {commentObj.profileType === 'Shop' && commentObj.shopId ? commentObj.shopId.name : commentObj.userId && commentObj.userId.name}
              </span>
              <div style={{ backgroundColor: 'rgba(255, 255, 255, 0.2)' }} className="p-3 rounded-lg shadow">
                {editingCommentId === commentObj._id ? (
                  <input type="text" style={{
                    backgroundColor: '#424242', 
                    color: 'white',             
                    border: '1px solid #333',   
                  }} value={newCommentText} onChange={(e) => setNewCommentText(e.target.value)} />
                ) : (
                  <span className="block text-sm text-white">{commentObj.commentText}</span>
                )}
              </div>

            {/* Dropdown Menu for Edit and Delete */}
            {((commentObj.profileType === 'User' && commentObj.userId && loggedInUserId === commentObj.userId._id) ||
          (commentObj.profileType === 'Shop' && commentObj.shopId && loggedInSellerId === commentObj.shopId._id)) && (
              <Popover className="relative inline-block">
                <Popover.Button>
                  <EllipsisHorizontalIcon className="h-5 w-5" aria-hidden="true" />
                </Popover.Button>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-200"
                  enterFrom="opacity-0 translate-y-1"
                  enterTo="opacity-100 translate-y-0"
                  leave="transition ease-in duration-150"
                  leaveFrom="opacity-100 translate-y-0"
                  leaveTo="opacity-0 translate-y-1"
                >
                  <Popover.Panel className="z-10 mt-2 bg-white shadow-lg ring-1 ring-black ring-opacity-5">
                    <div className="py-1">
                      <button
                        onClick={() => {
                          setEditingCommentId(commentObj._id);
                          setNewCommentText(commentObj.commentText);
                        }}
                        className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                      >
                        Edit
                      </button>
                      <button
                        onClick={() => deleteComment(postId, commentObj._id)}
                        className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                      >
                        Delete
                      </button>
                    </div>
                  </Popover.Panel>
                </Transition>
              </Popover>
            )}

            {/* Save Button for Edited Comment */}
            {editingCommentId === commentObj._id && (
              <button onClick={() => submitEditComment(postId, commentObj._id)} className="text-green-500 mt-1">
                Save
              </button>
            )}
          </div>
        </div>
        ))}
        {/* Load More Comments Button */}
        { !allCommentsLoaded && (
    <div className="flex justify-center">
      <button 
        onClick={loadMoreComments} 
        className="load-more-comments-btn mt-2 px-4 py-2 bg-green-700 text-white rounded-lg hover:bg-green-500 shadow-md transition duration-300 ease-in-out"
      >
        Load More Comments
      </button>
    </div>
  )}
        </div>
      )}



{/* Comment Input Section */}
<div className="mt-6">
<div className="flex items-start space-x-4">
<div className="flex-shrink-0">
      <ProfileSwitchButton />
    </div>
  <div className="min-w-0 flex-1">
    <form className="relative" onSubmit={handleCommentSubmit}>
      <textarea
        value={commentText}
        onChange={(e) => setCommentText(e.target.value)}
        rows={3}
        className="block w-full resize-none border-0 bg-transparent px-1.5 py-1.5 text-white placeholder:text-white focus:border-green-500 focus:ring-0 sm:text-sm"
        placeholder="Add your comment..."
      />
      <div className="absolute bottom-0 right-0 flex justify-end py-2 pr-2">
        <button
          type="submit"
          className="inline-flex items-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500"
        >
          Post
        </button>
      </div>
    </form>
  </div>
</div>
</div>
  </WidgetWrapper>
  </>
);
 }
export default PostWidget;