import React from 'react';
import { useCookies } from 'react-cookie';


const CookieConsent = () => {
  const [cookies, setCookie] = useCookies(["cookieConsent"]);
  const giveCookieConsent = () => {
    setCookie("cookieConsent", true, { path: "/" });
  };

  return (
    <div className="cookie-consent-component-wrapper">
      <div className="cookie-consent-component-container">
        <p className="cookie-consent-component-text">
          We use cookies to enhance your user experience.
          <br />
          By using our website, you agree to our use of cookies.
          <a href="/privacy-policy" className="cookie-consent-component-link">
            Learn more <span aria-hidden="true">→</span>
          </a>
        </p>
        <div className="cookie-consent-component-button-container">
          <button
            onClick={giveCookieConsent}
            className="cookie-consent-component-button"
          >
            Accept
          </button>
        </div>
      </div>
    </div>
  );
};

export default CookieConsent;

