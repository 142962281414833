export default function TempPage() {
  return (
      <div className="relative min-h-screen bg-cover bg-center" style={{ backgroundImage: "url('https://res.cloudinary.com/dzlopmfj8/image/upload/v1716384099/buzzvibe2_zxqrw9.jpg')" }}>
          <div className="flex flex-col items-center justify-center min-h-screen bg-black bg-opacity-50">
              <img
                  className="h-42 mb-6"
                  src="https://res.cloudinary.com/dzlopmfj8/image/upload/v1706549678/Asset-1_yjnmot.png"
                  alt="logo"
              />
              <h1 className="text-4xl font-bold tracking-tight text-white sm:text-6xl">
                  Coming soon!
              </h1>
              <p className="mt-6 text-lg leading-8 text-gray-300">
                  Get ready for something amazing...
              </p>
          </div>
      </div>
  );
}
