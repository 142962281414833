import React, { useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom'; 
import { server } from "../../server";

const ForgotPasswordResetPassword = () => {
  const { token } = useParams(); 
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const { data } = await axios.post(`${server}/user/reset-password/${token}`, { password });
      setMessage(data.message);
      setError('');
    } catch (err) {
      setError(err.response?.data?.message || 'An error occurred. Please try again.');
      setMessage('');
    }
  };

  return (
    <div className="forgot-password-reset-container">
      <h2 className="forgot-password-reset-title">Enter New Password</h2>
      <form onSubmit={handleSubmit}>
        <div className="forgot-password-reset-form-group">
          <label htmlFor="password" className="forgot-password-reset-label">New Password</label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="forgot-password-reset-input"
            required
          />
        </div>
        <div className="forgot-password-reset-button-container">
          <button
            type="submit"
            className="forgot-password-reset-button"
          >
            Update Password
          </button>
        </div>
        {message && <div className="forgot-password-reset-message success">{message}</div>}
        {error && <div className="forgot-password-reset-message error">{error}</div>}
      </form>
    </div>
  );
};

export default ForgotPasswordResetPassword;

