import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { server } from "../../server";

function MemeGallery() {
  const [memes, setMemes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const fetchMemes = async () => {
      try {
        const response = await axios.get(`${server}/meme/user-memes`, { withCredentials: true });
        setMemes(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching memes:', error);
        setError('Failed to fetch memes. Please try again.');
        setLoading(false);
      }
    };

    fetchMemes();
  }, []);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  if (loading) {
    return <div>Loading memes...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="meme-gallery">
      {memes.length === 0 ? (
        <p>No memes created yet.</p>
      ) : (
        <div className="meme-gallery-item" onClick={handleOpenModal}>
          <img src={memes[0].imageUrl} alt="Meme" className="meme-gallery-image" />
          <div className="meme-gallery-overlay-text">{memes[0].overlayText}</div>
        </div>
      )}

      {isModalOpen && (
        <div className="meme-gallery-modal">
          <div className="meme-gallery-modal-content">
            <span className="meme-gallery-close" onClick={handleCloseModal}>
              &times;
            </span>
            <div className="meme-gallery-grid">
              {memes.map((meme) => (
                <div key={meme._id} className="meme-gallery-item">
                  <img src={meme.imageUrl} alt="Meme" className="meme-gallery-image" />
                  <div className="meme-gallery-overlay-text">{meme.overlayText}</div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default MemeGallery;
