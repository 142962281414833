import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { server } from "../server";
import Sidebar from '../components/Layout/Sidebar';
import Header from '../components/Layout/Header';
import ProductCard from '../components/Route/ProductCard/ProductCard';

const ShopByBrandPage = () => {
    const { brandName } = useParams();
    const [brand, setBrand] = useState(null);
    const [products, setProducts] = useState([]);

    useEffect(() => {
        const fetchBrand = async () => {
            try {
                const { data } = await axios.get(`${server}/brand/brands/${encodeURIComponent(brandName)}`);
                setBrand(data.brand);
            } catch (error) {
                console.error('Error fetching brand details:', error);
            }
        };

        const fetchProducts = async () => {
            try {
                const { data } = await axios.get(`${server}/product/products/branded/${encodeURIComponent(brandName)}`);
                setProducts(data.products);
            } catch (error) {
                console.error('Error fetching products for brand:', error);
            }
        };

        fetchBrand();
        fetchProducts();
    }, [brandName]);

    return (
        <div>
            <Header activeHeading={3} />
            <Sidebar />
            {brand && (
                <div>
                    <div style={{ position: 'relative', textAlign: 'center' }}>
                        <img src={brand.coverImage.url} alt="Cover" style={{ width: '100%', height: '300px', objectFit: 'cover' }} />
                        <img src={brand.avatarImage.url} alt="Avatar" style={{ width: '100px', height: '100px', borderRadius: '50%', objectFit: 'cover', position: 'absolute', bottom: '-50px', left: '20px' }} />
                    </div>
                    <div style={{ padding: '20px', marginTop: '50px' }}>
                        <h1>{brand.name}</h1>
                        <p>{brand.description}</p>
                    </div>
                </div>
            )}

            <div className= "px-24">
            <h2 style={{ textAlign: 'center', color: 'green', fontSize: '24px' }}>Products by {brand?.name}</h2>
            <div className="grid grid-cols-1 gap-[20px] md:grid-cols-2 md:gap-[25px] lg:grid-cols-4 lg:gap-[25px] xl:grid-cols-5 xl:gap-[30px] mb-12">
                    {products && products.length > 0 ? (
                        products.map((product, index) => <ProductCard data={product} key={index} />)

                    ) : (
                        <h1 className="text-center w-full pb-[100px] text-[20px]">No products found!</h1>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ShopByBrandPage;

