import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { fetchOwnedBrands, setSelectedBrand } from '../../redux/actions/brand';

const BrandSelection = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { seller } = useSelector((state) => state.seller);
  const { ownedBrands } = useSelector((state) => state.brand);

  useEffect(() => {
    if (seller) {
      dispatch(fetchOwnedBrands());
    }
  }, [seller, dispatch]);

  const handleBrandSelect = (brand) => {
    dispatch(setSelectedBrand(brand));
    navigate(`/dashboard/brands/${brand._id}`);
  };

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-4 p-4">
      {ownedBrands.map((brand) => (
        <div key={brand._id} className="relative cursor-pointer overflow-hidden rounded-lg shadow-lg" onClick={() => handleBrandSelect(brand)}>
          <img src={brand.avatarImage.url} alt="Avatar" className="w-full h-full object-cover"/>
          <div className="absolute bottom-0 left-0 right-0 bg-gradient-to-t from-black to-transparent h-1/3 flex justify-center items-end pb-2">
            <h3 className="text-white text-xl font-semibold">{brand.name}</h3>
          </div>
        </div>
      ))}
    </div>
  );
};

export default BrandSelection;
