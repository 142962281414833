import React, { useState } from "react";
import {
  EditOutlined,
  DeleteOutlined,
  AttachFileOutlined,
  GifBoxOutlined,
  ImageOutlined,
  MicOutlined,
  MoreHorizOutlined,
} from "@mui/icons-material";
import {
  Box,
  Divider,
  Typography,
  InputBase,
  Button,
  IconButton,
  useMediaQuery,
} from "@mui/material";
import FlexBetween from "../components/FlexBetween";
import Dropzone from "react-dropzone";
import WidgetWrapper from "../components/WidgetWrapper";
import axios from 'axios';
import Cookie from "js-cookie";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import styles from "../stylesheets/Posts.module.css"
import ProfileSwitchButton from "../components/ProfileSwitchButton";
import { server } from "../server";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CircularProgress } from '@mui/material';


const MyPostWidget = ({ mediaPath }) => {
  const [isImage, setIsImage] = useState(false);
  const [isVideo, setIsVideo] = useState(false);
  const [image, setImage] = useState(null);
  const [video, setVideo] = useState(null);
  const [post, setPost] = useState("");
  const userId = useSelector((state) => state.user);
  const isNonMobileScreens = useMediaQuery("(min-width: 1000px)");
  const greyColor = "#808080";
  const currentProfile = useSelector((state) => state.user.currentProfile);
  const userProfile = useSelector((state) => state.user);
  const sellerProfile = useSelector((state) => state.seller);
  const [imagePreview, setImagePreview] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [posts, setPosts] = useState([]);

  // Event handler to toggle image upload
  const toggleImageUpload = () => {
    setIsImage(!isImage);
    if (isVideo) setIsVideo(false); // Close video upload if it's open
  };

  // Event handler to toggle video upload
  const toggleVideoUpload = () => {
    setIsVideo(!isVideo);
    if (isImage) setIsImage(false); // Close image upload if it's open
  };

  const uploadImageToCloudinary = async (image) => {
    const formData = new FormData();
    formData.append("file", image);
    formData.append("upload_preset", "qfdl8km9");
    const response = await axios.post("https://api.cloudinary.com/v1_1/dzlopmfj8/image/upload", formData);
    return response.data.secure_url;
  };

  const uploadVideoToCloudinary = async (video) => {
    const formData = new FormData();
    formData.append("file", video);
    formData.append("upload_preset", "qfdl8km9");
    const response = await axios.post("https://api.cloudinary.com/v1_1/dzlopmfj8/video/upload", formData);
    return response.data.secure_url;
  };

  const createPost = async () => {
    setIsLoading(true);
    const token = Cookie.get('token');
    let mediaUrl = null;
  
    if (image) {
      mediaUrl = await uploadImageToCloudinary(image);
    } else if (video) {
      mediaUrl = await uploadVideoToCloudinary(video);
    }
  
    const postData = {
      profileType: currentProfile === 'User' ? 'User' : 'Shop',
      description: post,
      mediaPath: mediaUrl,
      currentProfile: currentProfile,
    };
  
    if (currentProfile === 'User') {
      postData.userId = userProfile.id;
    } else if (currentProfile === 'Shop') {
      postData.userId = sellerProfile.id;
    }
  
    // No need for an optimistic ID since we're going to immediately prepend the new post
    const optimisticPost = { ...postData, id: 'optimistic-id', createdAt: new Date().toISOString() };
    setPosts([optimisticPost, ...posts]);
  
    setImage(null);
    setVideo(null);
    setPost("");
  
    try {
      const response = await axios.post(`${server}/posts/create-post`, postData, {
        withCredentials: true,
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
  
      if (response.data) {
        // Prepend the actual post from the server response at the top of the list
        // This also removes the optimistic post by not copying it into the new state
        setPosts([response.data, ...posts.filter(post => post.id !== 'optimistic-id')]);
      } else {
        console.error("Error posting:", response.data);
        // Optionally handle the error state, such as showing a message to the user
        // Ensure the optimistic post is removed if the post creation failed
        setPosts(posts.filter(post => post.id !== 'optimistic-id'));
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      // Remove the optimistic post if the request fails
      setPosts(posts.filter(post => post.id !== 'optimistic-id'));
      const message = error.response?.data?.message || "An unexpected error occurred.";
      toast.error(message);
    }
  };
  


  useEffect(() => {
    if (image) {
      const previewUrl = URL.createObjectURL(image);
      setImagePreview(previewUrl);
    } else {
      setImagePreview(null); 
    }
  }, [image]);

  return (
    <>
    <ToastContainer />
    
    <WidgetWrapper>
      <FlexBetween gap="1.5rem">
        <ProfileSwitchButton />
        <InputBase
          placeholder="What's on your mind..."
          onChange={(e) => setPost(e.target.value)}
          value={post}
          sx={{
            width: "100%",
            backgroundColor: "rgba(250, 250, 250, 0.1)",
            borderRadius: "2rem",
            padding: "1rem 2rem",
          }}
        />
      </FlexBetween>

      {isImage && (
        <Box
          border={`1px solid ${greyColor}`}
          borderRadius="5px"
          mt="1rem"
          p="1rem"
        >
          <Dropzone
            acceptedFiles=".jpg,.jpeg,.png"
            multiple={false}
            onDrop={(acceptedFiles) => setImage(acceptedFiles[0])}
          >
            {({ getRootProps, getInputProps }) => (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Box
                  {...getRootProps()}
                  border={`2px dashed #1bab27`}
                  p="1rem"
                  width="100%"
                  sx={{ "&:hover": { cursor: "pointer" } }}
                >
                  <input {...getInputProps()} />
                  {!image ? (
                    <p>Add Image Here</p>
                  ) : (
                    <FlexBetween>
                      <Typography>{image.name}</Typography>
                      <EditOutlined />
                    </FlexBetween>
                  )}
                </Box>
                {image && (
                  <IconButton
                    onClick={() => setImage(null)}
                    sx={{ width: "15%" }}
                  >
                    <DeleteOutlined />
                  </IconButton>
                )}
              </div>
            )}
          </Dropzone>
          {imagePreview && (
            <Box mt="1rem">
              <img src={imagePreview} alt="Preview" style={{ width: '100%', borderRadius: '5px' }} />
            </Box>
          )}
        </Box>
      )}

      {isVideo && (
        <Box
          border={`1px solid ${greyColor}`}
          borderRadius="5px"
          mt="1rem"
          p="1rem"
        >
          <Dropzone
            acceptedFiles="video/*"
            multiple={false}
            onDrop={(acceptedFiles) => setVideo(acceptedFiles[0])}
          >
            {({ getRootProps, getInputProps }) => (
              <FlexBetween>
                <Box
                  {...getRootProps()}
                  border={`2px dashed #1bab27`}
                  p="1rem"
                  width="100%"
                  sx={{ "&:hover": { cursor: "pointer" } }}
                >
                  <input {...getInputProps()} />
                  {!video ? (
                    <p>Add Video Here</p>
                  ) : (
                    <FlexBetween>
                      <Typography>{video.name}</Typography>
                      <EditOutlined />
                    </FlexBetween>
                  )}
                </Box>
                {video && (
                  <IconButton
                    onClick={() => setVideo(null)}
                    sx={{ width: "15%" }}
                  >
                    <DeleteOutlined />
                  </IconButton>
                )}
              </FlexBetween>
            )}
          </Dropzone>
          {video && (
            <Box mt="1rem">
              <video width="100%" controls>
                <source src={URL.createObjectURL(video)} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </Box>
          )}
        </Box>
      )}

      <Divider sx={{ margin: "1.25rem 0" }} />
      <FlexBetween>
      <FlexBetween gap="0.25rem" onClick={toggleImageUpload}>
          <ImageOutlined sx={{ color: greyColor }} />
          <Typography
            color={greyColor}
            sx={{ "&:hover": { cursor: "pointer", color: greyColor } }}
          >
            Image
          </Typography>
        </FlexBetween>

        <FlexBetween gap="0.25rem" onClick={toggleVideoUpload}>
          <GifBoxOutlined sx={{ color: greyColor }} />
          <Typography
            color={greyColor}
            sx={{ "&:hover": { cursor: "pointer", color: greyColor } }}
          >
            Video
          </Typography>
        </FlexBetween>

        <Button className={styles['css-cda2fy']} disabled={!post || isLoading} onClick={createPost}>
  {isLoading ? <CircularProgress size={24} /> : "POST"}
</Button>

      </FlexBetween>
    </WidgetWrapper>
    </>
  );
};

export default MyPostWidget;
