import React, { useState, useCallback } from "react";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { FaCheckCircle, FaTimesCircle } from "react-icons/fa";
import { Link } from "react-router-dom";
import { RxAvatar } from "react-icons/rx";
import axios from "axios";
import { server } from "../../server";
import { toast } from "react-toastify";
import _ from "lodash";

const Signup = () => {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [visible, setVisible] = useState(false);
  const [avatar, setAvatar] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [userBanner, setUserBanner] = useState(null);
  const [handle, setHandle] = useState("");
  const [handleAvailable, setHandleAvailable] = useState(true);
  const [checkingHandle, setCheckingHandle] = useState(false);

  const handleFileInputChange = (e) => {
    const reader = new FileReader();

    reader.onload = () => {
      if (reader.readyState === 2) {
        setAvatar(reader.result);
      }
    };

    reader.readAsDataURL(e.target.files[0]);
  };

  const handleBannerInputChange = (e) => {
    const reader = new FileReader();

    reader.onload = () => {
      if (reader.readyState === 2) {
        setUserBanner(reader.result);
      }
    };

    reader.readAsDataURL(e.target.files[0]);
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validateHandle = (handle) => {
    const regex = /^[a-zA-Z0-9]*$/;
    return regex.test(handle) && !/\s/.test(handle) && handle.length <= 30;
  };

  const checkHandleAvailability = async (value) => {
    if (validateHandle(value)) {
      setHandle(value);
      setCheckingHandle(true);
      try {
        const response = await axios.post(`${server}/user/check-handle`, { handle: value });
        setHandleAvailable(true);
      } catch (error) {
        setHandleAvailable(false);
      } finally {
        setCheckingHandle(false);
      }
    } else {
      setHandleAvailable(false);
      toast.error("Handle should not contain spaces and must be alphanumeric.");
    }
  };

  const debouncedCheckHandleAvailability = useCallback(_.debounce(checkHandleAvailability, 1000), []);

  const handleChangeHandle = (e) => {
    let value = e.target.value.toLowerCase();
    value = value.replace(/\s+/g, ""); // Remove spaces
    setHandle(value);
    if (value) {
      debouncedCheckHandleAvailability(value);
    } else {
      setHandleAvailable(null);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateEmail(email)) {
      toast.error("Please enter a valid email address.");
      return;
    }

    if (!validateHandle(handle)) {
      setHandleAvailable(false);
      return;
    }

    if (!handleAvailable) {
      return;
    }

    axios
      .post(`${server}/user/create-user`, { name, handle, email, password, avatar, userBanner })
      .then((res) => {
        toast.success(res.data.message);
        setName("");
        setHandle("");
        setEmail("");
        setPassword("");
        setAvatar(null);
        setUserBanner(null);
        setPhoneNumber("");
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };

  return (
    <div className="user-sign-up-container">
      <div className="user-sign-up-header">
        <h2 className="user-sign-up-title">Register as a new user</h2>
      </div>
      <div className="user-sign-up-form-container">
        <div className="user-sign-up-form">
          <form className="user-sign-up-form-elements" onSubmit={handleSubmit}>
            <div>
              <label htmlFor="name" className="user-sign-up-label">Full Name</label>
              <div className="user-sign-up-input-container">
                <input
                  type="text"
                  name="name"
                  autoComplete="name"
                  required
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  className="user-sign-up-input"
                />
              </div>
            </div>

            <div>
              <label htmlFor="handle" className="user-sign-up-label">Handle</label>
              <div className="user-sign-up-input-container">
                <input
                  type="text"
                  name="handle"
                  required
                  maxLength="30"
                  value={handle}
                  onChange={handleChangeHandle}
                  className="user-sign-up-input"
                />
                {handle && handleAvailable === false && !checkingHandle && (
                  <p className="text-red-500 text-sm mt-1 flex items-center">
                    <FaTimesCircle className="mr-1" /> This handle is already taken. Please choose another.
                  </p>
                )}
                {handle && handleAvailable === true && !checkingHandle && (
                  <p className="text-green-500 text-sm mt-1 flex items-center">
                    <FaCheckCircle className="mr-1" /> This handle is available.
                  </p>
                )}
              </div>
            </div>

            <div>
              <label htmlFor="email" className="user-sign-up-label">Email address</label>
              <div className="user-sign-up-input-container">
                <input
                  type="email"
                  name="email"
                  autoComplete="email"
                  required
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="user-sign-up-input"
                />
              </div>
            </div>

            <div>
              <label htmlFor="password" className="user-sign-up-label">Password</label>
              <div className="user-sign-up-input-container user-sign-up-relative">
                <input
                  type={visible ? "text" : "password"}
                  name="password"
                  autoComplete="current-password"
                  required
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="user-sign-up-input"
                />
                {visible ? (
                  <AiOutlineEye
                    className="user-sign-up-icon"
                    size={25}
                    onClick={() => setVisible(false)}
                  />
                ) : (
                  <AiOutlineEyeInvisible
                    className="user-sign-up-icon"
                    size={25}
                    onClick={() => setVisible(true)}
                  />
                )}
              </div>
            </div>

            <div>
              <label htmlFor="phoneNumber" className="user-sign-up-label">Phone Number</label>
              <div className="user-sign-up-input-container">
                <input
                  type="tel"
                  name="phoneNumber"
                  required
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  className="user-sign-up-input"
                />
              </div>
            </div>

            <div>
              <label htmlFor="avatar" className="user-sign-up-label">Avatar</label>
              <div className="user-sign-up-file-container">
                <span className="user-sign-up-avatar-preview">
                  {avatar ? (
                    <img
                      src={avatar}
                      alt="avatar"
                      className="user-sign-up-avatar"
                    />
                  ) : (
                    <RxAvatar className="user-sign-up-avatar-icon" />
                  )}
                </span>
                <label
                  htmlFor="file-input"
                  className="user-sign-up-file-label"
                >
                  <span>Upload a file</span>
                  <input
                    type="file"
                    name="avatar"
                    id="file-input"
                    accept=".jpg,.jpeg,.png"
                    onChange={handleFileInputChange}
                    className="user-sign-up-file-input"
                  />
                </label>
              </div>
            </div>

            <div>
              <label htmlFor="banner" className="user-sign-up-label">Banner</label>
              <div className="user-sign-up-file-container">
                <span className="user-sign-up-banner-preview">
                  {userBanner ? (
                    <img src={userBanner} alt="Banner Preview" className="user-sign-up-avatar" />
                  ) : (
                    <div className="user-sign-up-banner-placeholder"></div>
                  )}
                </span>
                <label htmlFor="banner-input" className="user-sign-up-file-label">
                  Upload a Banner
                  <input
                    type="file"
                    name="banner"
                    id="banner-input"
                    accept=".jpg,.jpeg,.png"
                    onChange={handleBannerInputChange}
                    className="user-sign-up-file-input"
                  />
                </label>
              </div>
            </div>

            <div>
              <button
                type="submit"
                className="user-sign-up-submit-button"
              >
                Submit
              </button>
            </div>
            <div className="user-sign-up-footer">
              <h4>Already have an account?</h4>
              <Link to="/login" className="user-sign-up-link">
                Sign In
              </Link>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Signup;

