import React, { useState } from 'react';
import { parse } from 'papaparse';
import { toast } from 'react-toastify';

const CSVUploadComponent = ({ onProductsParsed }) => {
  const [file, setFile] = useState(null);
  const [parsedProducts, setParsedProducts] = useState([]);
  const [validationErrors, setValidationErrors] = useState({});

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const validateProductData = (products) => {
    const errors = {};
    const validatedProducts = products.map((product, index) => {
      let hasError = false;

      // Required fields 
      const requiredFields = [
        'name', 'description', 'category', 'tags', 'originalPrice',
        'discountPrice', 'productType', 'stock', 'image_public_id',
        'image_url', 'shopId', 'handle', 'ratings', 'sold_out'
      ];
      for (let field of requiredFields) {
        if (!product[field]) {
          errors[`product_${index}_${field}`] = `${field.charAt(0).toUpperCase() + field.slice(1)} is required`;
          hasError = true;
        }
      }

      if (product.originalPrice && isNaN(parseFloat(product.originalPrice))) {
        errors[`product_${index}_originalPrice`] = 'Original Price must be a number';
        hasError = true;
      }

      if (product.discountPrice && isNaN(parseFloat(product.discountPrice))) {
        errors[`product_${index}_discountPrice`] = 'Discount Price must be a number';
        hasError = true;
      }

      if (product.stock && isNaN(parseInt(product.stock))) {
        errors[`product_${index}_stock`] = 'Stock must be a number';
        hasError = true;
      }

      if (product.ratings && (isNaN(parseFloat(product.ratings)) || product.ratings < 0 || product.ratings > 5)) {
        errors[`product_${index}_ratings`] = 'Ratings must be a number between 0 and 5';
        hasError = true;
      }

      if (product.sold_out && isNaN(parseInt(product.sold_out))) {
        errors[`product_${index}_sold_out`] = 'Sold Out must be a number';
        hasError = true;
      }

      // Check if image URL is valid (basic check)
      if (product.image_url && !product.image_url.startsWith('http')) {
        errors[`product_${index}_image_url`] = 'Image URL is invalid';
        hasError = true;
      }

      // Add other specific validations as needed

      return { ...product, hasError };
    });

    setParsedProducts(validatedProducts);
    setValidationErrors(errors);
  };

  const handleUpload = () => {
    if (!file) {
      toast.error("Please select a file to upload.");
      return;
    }

    parse(file, {
      complete: (result) => {
        validateProductData(result.data);
      },
      header: true,
      skipEmptyLines: true,
    });
  };

  const handleProductChange = (index, field, value) => {
    const updatedProducts = [...parsedProducts];
    updatedProducts[index][field] = value;
    setParsedProducts(updatedProducts);

    // Clear validation error if the field is now correct
    if (validationErrors[`product_${index}_${field}`] && value) {
      const updatedErrors = { ...validationErrors };
      delete updatedErrors[`product_${index}_${field}`];
      setValidationErrors(updatedErrors);
    }
  };

  const handleSubmitProducts = () => {
    if (Object.keys(validationErrors).length > 0) {
      toast.error("Please correct the errors before submitting.");
      return;
    }

    // Pass the validated and potentially edited products to the parent component
    onProductsParsed(parsedProducts);
  };

  const renderInputField = (product, index, field) => {
    return (
      <div key={`${field}-${index}`} className="CSV-upload-component-input-group">
        <label>{field.charAt(0).toUpperCase() + field.slice(1)}:</label>
        <input 
          type="text" 
          value={product[field] || ''} 
          onChange={(e) => handleProductChange(index, field, e.target.value)}
          className="CSV-upload-component-input"
        />
        {validationErrors[`product_${index}_${field}`] && (
          <span className="CSV-upload-component-error">{validationErrors[`product_${index}_${field}`]}</span>
        )}
      </div>
    );
  };

  return (
    <div className="CSV-upload-component-container">
      <input 
        type="file" 
        accept=".csv" 
        onChange={handleFileChange} 
        className="CSV-upload-component-file-input"
      />

      <button 
        onClick={handleUpload} 
        className="CSV-upload-component-upload-button"
      >
        Upload CSV
      </button>

      {parsedProducts.map((product, index) => (
        <div key={index} className="CSV-upload-component-product">
          {[
            'name', 'description', 'category', 'tags', 'originalPrice', 
            'discountPrice', 'productType', 'stock', 'image_public_id', 
            'image_url', 'shopId', 'handle', 'ratings', 'sold_out'
          ].map(field => renderInputField(product, index, field))}
        </div>
      ))}

      <button 
        onClick={handleSubmitProducts} 
        className="CSV-upload-component-submit-button"
      >
        Submit Products
      </button>
    </div>
  );
};

export default CSVUploadComponent;
