import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { server } from "../server";

const SellerActivationPage = () => {
  const { activation_token } = useParams();
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showSetupButton, setShowSetupButton] = useState(false);

  useEffect(() => {
    console.log("Activation token from URL:", activation_token);

    if (activation_token) {
      activateShop();
    }
  }, [activation_token]);

  const activateShop = async () => {
    try {
      const response = await axios.post(`${server}/shop/activation`, {
        activation_token,
      });
      setShowSetupButton(true);
    } catch (err) {
      const message = err.response?.data?.message || "An error occurred during activation";
      console.error("Activation error:", message); 
      setErrorMessage(message);

      if (err.response?.data?.message === "Shop already exists") {
        setShowSetupButton(true);
      } else {
        setError(true);
      }
    }
  };

  return (
    <div style={{
      width: "100%",
      height: "100vh",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    }}>
      {error ? (
        <p>{errorMessage}</p>
      ) : (
        <div>
          <p>Your account has been activated successfully! Please complete your payment setup to start receiving payments.</p>
          {showSetupButton && (
            <button onClick={() => window.location.href = "https://gamechip.ai/dashboard-payment-setup"} style={{ padding: "10px 20px", fontSize: "16px", cursor: "pointer", backgroundColor: "blue", color: "white", borderRadius: "5px" }}>
              Complete Payment Setup
            </button>
          )}
        </div>
      )}
    </div>
  );
};

export default SellerActivationPage;


