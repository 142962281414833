import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { server } from "../../server";

const QuadProductAdCarouselProps = ({ ads }) => { 
    const [currentIndex, setCurrentIndex] = useState(0);
    const adRefs = useRef(new Map());

    // Group ads into quads
    const groupAdsInQuads = (ads) => {
        const quads = [];
        for (let i = 0; i < ads.length; i += 4) {
            const quad = ads.slice(i, i + 4); // Group ads in groups of 4
            quads.push(quad);
        }
        return quads;
    };

    const adQuads = groupAdsInQuads(ads); // Group ads into quads

    const logViewedImpression = async (adId) => {
        try {
            await axios.post(`${server}/sponsored/viewed-impression/${adId}`, {}, {
                withCredentials: true,
                headers: {
                    'current-profile': sessionStorage.getItem('currentProfile') || 'defaultProfile',
                }
            });
            console.log(`Viewed impression logged for ad: ${adId}`);
        } catch (error) {
            console.error(`Error logging viewed impression for ad: ${adId}`, error);
        }
    };

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    const adId = entry.target.dataset.adId;
                    if (entry.isIntersecting) {
                        const timer = setTimeout(() => {
                            logViewedImpression(adId);
                        }, 1000);
                        entry.target._timer = timer;
                    } else {
                        clearTimeout(entry.target._timer);
                    }
                });
            },
            { threshold: 0.5 }
        );

        const currentAdQuad = adQuads[currentIndex] || [];
        currentAdQuad.forEach((ad) => {
            const adElement = adRefs.current.get(ad._id);
            if (adElement) {
                observer.observe(adElement);
            }
        });

        return () => {
            observer.disconnect();
        };
    }, [adQuads, currentIndex]);

    const logAdClick = async (ad_id) => {
        try {
            await axios.post(`${server}/sponsored/click/${ad_id}`, {}, {
                withCredentials: true,
                headers: {
                    'current-profile': sessionStorage.getItem('currentProfile') || 'defaultProfile',
                }
            });
            console.log('Click logged for ad:', ad_id);
        } catch (error) {
            console.error('Error logging click:', error);
        }
    };

    const nextSlide = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % adQuads.length);
    };

    const prevSlide = () => {
        setCurrentIndex((prevIndex) => (prevIndex - 1 + adQuads.length) % adQuads.length);
    };

    if (adQuads.length === 0) {
        return <div className="quad-product-ad-carousel-loading">Loading...</div>;
    }

    const currentAdQuad = adQuads[currentIndex]; // Get the current quad of ads

    return (
        <div className="quad-product-ad-carousel-container">
            {adQuads.length > 0 && (
                <>
                    {/* Quad wrapper with translateX transition */}
                    <div
                        className="quad-product-ad-carousel-wrapper"
                        style={{
                            display: 'flex',
                            width: '100%',
                            transform: `translateX(-${currentIndex * 100}%)`,
                            transition: 'transform 0.5s ease-in-out',
                        }}
                    >
                        {adQuads.map((quad, index) => (
                            <div key={index} className="quad-product-ad-carousel-quad" style={{ flex: '1 0 100%', display: 'flex' }}>
                                {quad.map((ad, idx) => (
                                    ad.product && ad.product._id ? (
                                        <Item key={ad._id} ad={ad} logAdClick={() => logAdClick(ad._id)} adRefs={adRefs} />
                                    ) : (
                                        <div key={idx} className="quad-product-ad-carousel-error">Error: Missing product data.</div>
                                    )
                                ))}
                            </div>
                        ))}
                    </div>
                    {adQuads.length > 1 && (
                        <>
                            <button className="sponsored-carousel-nav-button sponsored-carousel-prev" onClick={prevSlide}>‹</button>
                            <button className="sponsored-carousel-nav-button sponsored-carousel-next" onClick={nextSlide}>›</button>
                        </>
                    )}
                </>
            )}
        </div>
    );
};

const Item = ({ ad, logAdClick, adRefs }) => {
    const { images, positions } = ad.adSet;
    const imageUrl = images && images["Quad Product Carousel"];
    const position = positions && positions["Quad Product Carousel"] || { x: 0, y: 0 };

    return (
        <div
            className="quad-product-ad-carousel-item"
            data-ad-id={ad._id} // To track the ad in IntersectionObserver
            ref={(el) => el && adRefs.current.set(ad._id, el)} // Save the ref
        >
            <div className="quad-product-ad-carousel-image-wrapper">
                <img
                    src={imageUrl}
                    alt={ad.product.name}
                    className="quad-product-ad-carousel-image"
                    style={{
                        transform: `translate(${position.x}px, ${position.y}px)`,
                    }}
                />
            </div>
            <div className="quad-product-ad-carousel-content">
                <h2 className="quad-product-ad-carousel-title">{ad.product.name}</h2>
                <p className="quad-product-ad-carousel-price">{`$${ad.product.discountPrice || ad.product.originalPrice}`}</p>
                <Link
                    to={`/product/${ad.product._id}`}
                    onClick={logAdClick}
                    className="quad-product-ad-carousel-button"
                >
                    Buy now
                </Link>
            </div>
        </div>
    );
};

export default QuadProductAdCarouselProps;
