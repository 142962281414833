import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { toast } from 'react-toastify';
import { loadStripe } from '@stripe/stripe-js';
import { EmbeddedCheckoutProvider, EmbeddedCheckout } from '@stripe/react-stripe-js';
import { server } from "../../server";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const SubscriptionCheckout = () => {
    const navigate = useNavigate();
    const { user } = useSelector(state => state.user);
    const { priceId } = useParams();
    const [isLoading, setIsLoading] = useState(false);
    const [email, setEmail] = useState(user?.email || '');
    const [fullName, setFullName] = useState(user?.name || '');
    const [priceDetails, setPriceDetails] = useState(null);
    const [clientSecret, setClientSecret] = useState('');
    const [couponCode, setCouponCode] = useState('');
    const [discountedPrice, setDiscountedPrice] = useState(null); 

    useEffect(() => {
        // Fetch price details from your backend
        const fetchPriceDetails = async () => {
            try {
                const response = await axios.get(`${server}/subscriptions/price-details/${priceId}`);
                setPriceDetails(response.data);
            } catch (error) {
                console.error('Failed to fetch price details', error);
                toast.error('Failed to load pricing information.');
            }
        };

        fetchPriceDetails();
    }, [priceId]);

    const handleApplyCoupon = async () => {
        if (!couponCode) {
            toast.error('Please enter a coupon code.');
            return;
        }

        try {
            const response = await axios.post(`${server}/subscriptioncouponcode/apply-subscription-coupon`, {
                code: couponCode,
                priceId
            });

            if (response.data.success) {
                toast.success('Coupon applied successfully!');
                setDiscountedPrice(response.data.finalPrice); 
            }
        } catch (error) {
            toast.error(error.response?.data?.message || 'Failed to apply coupon.');
        }
    };

    const handlePaymentSubmission = async (event) => {
        event.preventDefault();
        setIsLoading(true);

        try {
            const { data } = await axios.post(`${server}/payment/create-subscription-checkout-session`, {
                priceId,
                discountedPrice: discountedPrice || null
            }, {
                withCredentials: true,
                headers: {
                    "Content-Type": "application/json",
                    'current-profile': sessionStorage.getItem('currentProfile') || 'defaultProfile',
                }
            });

            setClientSecret(data.clientSecret);
        } catch (error) {
            console.error('Checkout session creation failed:', error);
            toast.error('Failed to create checkout session. Please try again.');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="subscription-checkout-container">
            <div className="subscription-checkout-form-container">
                <form onSubmit={handlePaymentSubmission} className="subscription-checkout-form">
                    <label className="subscription-checkout-label">Name On Card</label>
                    <input 
                        type="text" 
                        className="subscription-checkout-input" 
                        placeholder="Full Name" 
                        value={fullName} 
                        onChange={e => setFullName(e.target.value)} 
                        required 
                    />
                    <label className="subscription-checkout-label">Email Address</label>
                    <input 
                        type="email" 
                        className="subscription-checkout-input" 
                        placeholder="Email Address" 
                        value={email} 
                        onChange={e => setEmail(e.target.value)} 
                        required 
                    />

                    <label className="subscription-checkout-label">Coupon Code</label>
                    <input 
                        type="text" 
                        className="subscription-checkout-input" 
                        placeholder="Enter Coupon Code" 
                        value={couponCode} 
                        onChange={e => setCouponCode(e.target.value)} 
                    />
                    <button 
                        type="button" 
                        className="subscription-checkout-coupon-button" 
                        onClick={handleApplyCoupon}
                    >
                        Apply Coupon
                    </button>

                    <button 
                        type="submit" 
                        className="subscription-checkout-button" 
                        disabled={isLoading}
                    >
                        {isLoading ? 'Processing...' : 'Confirm Subscription'}
                    </button>
                </form>
            </div>

            <div className="subscription-checkout-details-container">
                {priceDetails && (
                    <div className="subscription-checkout-details">
                        <h2>{`Level up with your new: ${priceDetails.product.name} subscription`}</h2>
                        <p>{priceDetails.product.description}</p>
                        <p>Price: ${(discountedPrice !== null ? discountedPrice / 100 : priceDetails.unit_amount / 100).toFixed(2)} {priceDetails.currency.toUpperCase()}</p>
                    </div>
                )}
            </div>

            {clientSecret && (
                <EmbeddedCheckoutProvider stripe={stripePromise} options={{ clientSecret }}>
                    <EmbeddedCheckout />
                </EmbeddedCheckoutProvider>
            )}
        </div>
    );
};

export default SubscriptionCheckout;






