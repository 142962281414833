import React from 'react';
import { FaMapMarkerAlt, FaClock, FaCalendarAlt } from 'react-icons/fa'; 
import GoogleCalendarButton from './GoogleCalendarButton';
import OutlookCalendarButton from './OutlookCalendarButton';
import DownloadICSButton from './DownloadICSButton';

const MyEventCard = ({ event, onCancelRSVP }) => {
    const eventDate = new Date(event.date);
    const formattedDate = eventDate.toLocaleDateString();
    const formattedTime = eventDate.toLocaleTimeString();

    return (
        <div className="my-event-card-component-card">
            <img src={event.image} alt={event.title} className="my-event-card-component-image" />
            <div className="my-event-card-component-info">
                <h3 className="my-event-card-component-title">{event.title}</h3>
                <p className="my-event-card-component-description">{event.description}</p>
                <div className="my-event-card-component-datetime">
                    <p className="my-event-card-component-date">
                        <FaCalendarAlt className="my-event-card-icon" /> {formattedDate}
                    </p>
                    <p className="my-event-card-component-time">
                        <FaClock className="my-event-card-icon" /> {formattedTime}
                    </p>
                </div>
                <p className="my-event-card-component-location">
                    <FaMapMarkerAlt className="my-event-card-icon" /> {event.location.address}
                </p>
                <button
                    onClick={() => onCancelRSVP(event._id)}
                    className="my-event-card-component-button"
                >
                    Going...
                </button>
                <div className="my-event-card-component-calendar-buttons">
                    <GoogleCalendarButton event={event} />
                    <OutlookCalendarButton event={event} />
                    <DownloadICSButton event={event} />
                </div>
            </div>
        </div>
    );
};

export default MyEventCard;



