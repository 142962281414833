import React, { useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { server } from '../../server'; 

const USPSRateCalculator = ({ shopId }) => {
  const [destinationZip, setDestinationZip] = useState('');
  const [weightInOunces, setWeightInOunces] = useState('');
  const [dimensions, setDimensions] = useState({ length: '', width: '', height: '' });
  const [rate, setRate] = useState(null);
  const currentProfileName = sessionStorage.getItem('currentProfile');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${server}/usps/get-usps-rates`, 
        {
          shopId,
          destinationZip,
          weightInOunces,
          dimensions,
        },
        {
          headers: {
            'current-profile': currentProfileName || 'defaultProfile',
          },
          withCredentials: true
        }
      );
      setRate(response.data.rate);
      toast.success('Shipping rate fetched successfully!');
    } catch (error) {
      toast.error('Failed to fetch shipping rate');
      console.error(error);
    }
  };


  return (
    <div className="USPS-rate-calculator-container">
      <h2>USPS Rate Calculator</h2>
      <form onSubmit={handleSubmit}>
        <div className="USPS-rate-calculator-form-group">
          <label>Destination Zip Code</label>
          <input
            type="text"
            value={destinationZip}
            onChange={(e) => setDestinationZip(e.target.value)}
            required
          />
        </div>
        <div className="USPS-rate-calculator-form-group">
          <label>Weight (in ounces)</label>
          <input
            type="number"
            value={weightInOunces}
            onChange={(e) => setWeightInOunces(e.target.value)}
            required
          />
        </div>
        <div className="USPS-rate-calculator-form-group">
          <label>Dimensions (in inches)</label>
          <input
            type="number"
            placeholder="Length"
            value={dimensions.length}
            onChange={(e) => setDimensions({ ...dimensions, length: e.target.value })}
            required
          />
          <input
            type="number"
            placeholder="Width"
            value={dimensions.width}
            onChange={(e) => setDimensions({ ...dimensions, width: e.target.value })}
            required
          />
          <input
            type="number"
            placeholder="Height"
            value={dimensions.height}
            onChange={(e) => setDimensions({ ...dimensions, height: e.target.value })}
            required
          />
        </div>
        <button className="USPS-rate-calculator-button" type="submit">Get Rate</button>
      </form>
      {rate && (
        <div className="USPS-rate-calculator-rate-result">
          <h3>Shipping Rate</h3>
          <p>${rate}</p>
        </div>
      )}
    </div>
  );
};

export default USPSRateCalculator;

