import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { fetchUserAndSubscription } from '../redux/actions/user';
import Loader from '../components/Layout/Loader';


const SubscriptionProtectedRoute = ({ children, requiredTiers }) => {
  const dispatch = useDispatch();
  const { isAuthenticated, user, loading, error } = useSelector((state) => state.user);
  const subscriptions = useSelector((state) => state.user.subscriptions);
  const [initialLoading, setInitialLoading] = useState(true); 

  useEffect(() => {
    if (!user || !subscriptions ) {
      dispatch(fetchUserAndSubscription());
    } else {
      setInitialLoading(false); 
    }
  }, [dispatch, user?.id, subscriptions]); 

 
  if (loading || initialLoading) {
    return <Loader />; 
  }

  if (error) {
    console.error('Error fetching user or subscriptions:', error);
    return <div>Error: {error}</div>;  
  }

  if (!subscriptions || subscriptions.length === 0) {
    console.log('No subscriptions data available');
    return <div>No Subscription Data</div>;
  }

  const hasRequiredTier = subscriptions.some(sub => requiredTiers.includes(sub.tier) && sub.status === 'active');
  
  if (!isAuthenticated || !hasRequiredTier || subscriptions.length === 0) {
    console.log('Redirecting due to unmet conditions', { isAuthenticated, hasRequiredTier });
    return <Navigate to="/subscriptions" replace />;
  }

  return children; 
};

export default SubscriptionProtectedRoute;






