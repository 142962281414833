import React from "react";
import ShopSettings from "../../components/Shop/ShopSettings";
import DashboardHeader from "../../components/Shop/Layout/DashboardHeader";
import DashboardSideBar from "../../components/Shop/Layout/DashboardSideBar";
import { Link } from "react-router-dom";

const ShopSettingsPage = () => {
  return (
    <div className="shop-settings-page-container">
      <DashboardHeader />
      <div className="shop-settings-page-content">
        <div className="shop-settings-page-sidebar">
          <DashboardSideBar active={12} />
        </div>
        <div className="shop-settings-page-main">
          <ShopSettings />
          <div className="shop-settings-page-manage-payment">
            <Link to="/payment-method" className="shop-settings-page-manage-payment-btn">
              Manage Payment Method
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShopSettingsPage;

