import React from "react";
import { useNavigate } from "react-router-dom";
import { brandingData, categoriesData } from "../../../static/data";


const Categories = () => {
  const navigate = useNavigate();

  return (
    <>
      <div className="homepage-category-component-section hidden sm:block">
        <div className="homepage-category-component-branding">
          {brandingData &&
            brandingData.map((i, index) => (
              <div className="homepage-category-component-branding-item" key={index}>
                {i.icon}
                <div className="homepage-category-component-branding-text">
                  <h3 className="homepage-category-component-branding-title">{i.title}</h3>
                  <p className="homepage-category-component-branding-description">{i.Description}</p>
                </div>
              </div>
            ))}
        </div>
      </div>

      <div className="homepage-category-component-categories-section" id="categories">
        <div className="homepage-category-component-categories-grid">
          {categoriesData &&
            categoriesData.map((i) => {
              const handleSubmit = (i) => {
                navigate(`/products/${i.title}`);
              };
              return (
                <div
                  className="homepage-category-component-category-item"
                  key={i.id}
                  onClick={() => handleSubmit(i)}
                >
                  <h5 className="homepage-category-component-category-title">{i.title}</h5>
                  <img
                    src={i.image_Url}
                    className="homepage-category-component-category-image"
                    alt={i.title}
                  />
                </div>
              );
            })}
        </div>
      </div>
    </>
  );
};

export default Categories;

