import React, { useEffect, useState } from "react";
import {
  AiFillHeart,
  AiOutlineHeart,
  AiOutlineMessage,
  AiOutlineShoppingCart,
} from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { getAllProductsShop } from "../../redux/actions/product";
import { server } from "../../server";
import styles from "../../styles/styles";
import {
  addToWishlist,
  removeFromWishlist,
} from "../../redux/actions/wishlist";
import { addTocart } from "../../redux/actions/cart";
import { toast } from "react-toastify";
import Ratings from "./Ratings";
import axios from "axios";

const ProductDetails = ({ data }) => {
  const { wishlist } = useSelector((state) => state.wishlist);
  const { cart } = useSelector((state) => state.cart);
  const { user, isAuthenticated } = useSelector((state) => state.user);
  const { seller } = useSelector((state) => state.seller);
  const { products } = useSelector((state) => state.products);
  const [count, setCount] = useState(1);
  const [click, setClick] = useState(false);
  const [select, setSelect] = useState(0);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllProductsShop(data && data?.shop._id));
    if (wishlist && wishlist.find((i) => i._id === data?._id)) {
      setClick(true);
    } else {
      setClick(false);
    }
    
  }, [data, wishlist]);

  const incrementCount = () => {
    setCount(count + 1);
  };

  const decrementCount = () => {
    if (count > 1) {
      setCount(count - 1);
    }
  };

  const removeFromWishlistHandler = (data) => {
    setClick(!click);
    dispatch(removeFromWishlist(data));
  };

  const addToWishlistHandler = (data) => {
    setClick(!click);
    dispatch(addToWishlist(data));
  };

  const addToCartHandler = (id) => {
    const isItemExists = cart && cart.find((i) => i._id === id);
    if (isItemExists) {
      toast.error("Item already in cart!");
    } else {
      if (data.stock < 1) {
        toast.error("Product stock limited!");
      } else {
        const cartData = { ...data, qty: count };
        dispatch(addTocart(cartData));
        toast.success("Item added to cart successfully!");
      }
    }
  };

  const totalReviewsLength =
    products &&
    products.reduce((acc, product) => acc + product.reviews.length, 0);

  const totalRatings =
    products &&
    products.reduce(
      (acc, product) =>
        acc + product.reviews.reduce((sum, review) => sum + review.rating, 0),
      0
    );

  const avg =  totalRatings / totalReviewsLength || 0;

  const averageRating = avg.toFixed(2);


  const handleMessageSubmit = async () => {
    if (isAuthenticated) {
      const groupTitle = data._id + user._id;
      const userId = user._id;
      const sellerId = data.shop._id;
      await axios
        .post(`${server}/conversation/create-new-conversation`, {
          groupTitle,
          userId,
          sellerId,
        })
        .then((res) => {
          navigate(`/inbox?${res.data.conversation._id}`);
        })
        .catch((error) => {
          toast.error(error.response.data.message);
        });
    } else {
      toast.error("Please login to create a conversation");
    }
  };

  return (
    <div className="product-details-component__content-bg">
      {data ? (
        <div className={`${styles.section} product-details-component__section`}>
          <div className="product-details-component__py-5">
            <div className="product-details-component__flex-container">
              <div className="product-details-component__flex-left">
                {select < data.images.length ? (
                  <img
                    src={`${data.images[select]?.url}`}
                    alt=""
                    className="product-details-component__main-image"
                  />
                ) : (
                  <video
                    src={`${data.videos[select - data.images.length]?.url}`}
                    className="product-details-component__main-image"
                    controls
                  />
                )}
                <div className="product-details-component__image-selection">
                  {data.images.map((image, index) => (
                    <img
                      key={index}
                      src={image.url}
                      alt={`Image ${index + 1}`}
                      className={`product-details-component__thumbnail ${select === index ? "product-details-component__thumbnail--selected" : ""}`}
                      onClick={() => setSelect(index)}
                    />
                  ))}
                  {data.videos.map((video, index) => (
                    <video
                      key={index}
                      src={video.url}
                      className={`product-details-component__thumbnail ${select === data.images.length + index ? "product-details-component__thumbnail--selected" : ""}`}
                      onClick={() => setSelect(data.images.length + index)}
                    />
                  ))}
                </div>
              </div>
              <div className="product-details-component__flex-right">
                {data.brand && (
                  <div className="product-details-component__brand-container">
                    <span className="product-details-component__brand-label">
                      Brand:
                    </span>
                    <span className="product-details-component__brand-value">{data.brand}</span>
                    {data.brandTypeSelection === 'branded' && (
                      <img
                        src='https://res.cloudinary.com/dzlopmfj8/image/upload/v1710252613/feat_morfbr.png'
                        alt=""
                        className="product-details-component__brand-image"
                      />
                    )}
                  </div>
                )}
                <h1 className="product-details-component__product-title">{data.name}</h1>
                <p className="product-details-component__product-description">{data.description}</p>
                <div className="product-details-component__price-container">
                  <h4 className="product-details-component__discount-price">
                    ${data.discountPrice}
                  </h4>
                  <h3 className="product-details-component__original-price">
                    {"$" + data.originalPrice ? data.originalPrice : null}
                  </h3>
                </div>
                <div className="product-details-component__availability-container">
                  {data.availableForDelivery && (
                    <span className="product-details-component__delivery-badge">
                      Available for Delivery
                    </span>
                  )}
                  {data.availableForPickup && (
                    <span className="product-details-component__pickup-badge">
                      Available for Pickup
                    </span>
                  )}
                </div>

                <div className="product-details-component__quantity-wishlist-container">
                  <div className="product-details-component__quantity-container">
                    <button
                      className="product-details-component__quantity-button"
                      onClick={decrementCount}
                    >
                      -
                    </button>
                    <span className="product-details-component__quantity-value">
                      {count}
                    </span>
                    <button
                      className="product-details-component__quantity-button"
                      onClick={incrementCount}
                    >
                      +
                    </button>
                  </div>
                  <div>
                    {click ? (
                      <AiFillHeart
                        size={30}
                        className="product-details-component__wishlist-icon"
                        onClick={() => removeFromWishlistHandler(data)}
                        color={click ? "red" : "#333"}
                        title="Remove from wishlist"
                      />
                    ) : (
                      <AiOutlineHeart
                        size={30}
                        className="product-details-component__wishlist-icon"
                        onClick={() => addToWishlistHandler(data)}
                        color={click ? "red" : "#333"}
                        title="Add to Wishlist"
                      />
                    )}
                  </div>
                </div>
                <div
                  className="product-details-component__add-to-cart-button"
                  onClick={() => addToCartHandler(data._id)}
                >
                  <span className="product-details-component__add-to-cart-text">
                    Add to Cart <AiOutlineShoppingCart className="product-details-component__cart-icon" />
                  </span>
                </div>
                <div className="product-details-component__shop-info">
                  <Link to={`/shop/${data?.shop.handle}`}>
                    <img
                      src={`${data?.shop?.avatar?.url}`}
                      alt=""
                      className="product-details-component__shop-avatar"
                    />
                  </Link>
                  <div className="product-details-component__shop-details">
                    <Link to={`/shop/${data?.shop.handle}`}>
                      <h3 className="product-details-component__shop-name">
                        {data.shop.name}
                      </h3>
                    </Link>
                    <h5 className="product-details-component__shop-ratings">
                      ({averageRating}/5) Ratings
                    </h5>
                  </div>
                  <div
                    className="product-details-component__send-message-button"
                    onClick={handleMessageSubmit}
                  >
                    <span className="product-details-component__send-message-text">
                      Send Message <AiOutlineMessage className="product-details-component__message-icon" />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ProductDetailsInfo
            data={data}
            products={products}
            totalReviewsLength={totalReviewsLength}
            averageRating={averageRating}
          />
          <br />
          <br />
        </div>
      ) : null}
    </div>
  );
};

const ProductDetailsInfo = ({
  data,
  products,
  totalReviewsLength,
  averageRating,
}) => {
  const [active, setActive] = useState(1);

  return (
    <div className="product-details-component__info-container">
      <div className="product-details-component__info-tabs">
        <div className="product-details-component__info-tab" onClick={() => setActive(1)}>
          <h4 className="product-details-component__info-tab-title">
            Product Details
          </h4>
          {active === 1 && <div className="product-details-component__active-indicator" />}
        </div>
        <div className="product-details-component__info-tab" onClick={() => setActive(2)}>
          <h4 className="product-details-component__info-tab-title">
            Product Reviews
          </h4>
          {active === 2 && <div className="product-details-component__active-indicator" />}
        </div>
        <div className="product-details-component__info-tab" onClick={() => setActive(3)}>
          <h4 className="product-details-component__info-tab-title">
            Seller Information
          </h4>
          {active === 3 && <div className="product-details-component__active-indicator" />}
        </div>
      </div>
      {active === 1 && (
        <>
          <p className="product-details-component__product-description-text">
            {data.description}
          </p>
          <div className="product-details-component__metrics-container">

            {data.testResults && data.testResults.length > 0 && (
              <div className="product-details-component__test-results">
                <h3 className="product-details-component__metrics-title">Test Results:</h3>
                <img 
                  src={data.testResults[0].url} 
                  alt="Test Results" 
                  className="product-details-component__test-results-image"
                />
              </div>
            )}
          </div>

          <div className="product-details-component__barcode-container">
            {data.barcode && data.barcode.length > 0 && (
              <div className="product-details-component__barcode">
                <h3 className="product-details-component__metrics-title">Product Barcode:</h3>
                <img 
                  src={data.barcode[0].url} 
                  alt="Product Barcode" 
                  className="product-details-component__barcode-image"
                />
              </div>
            )}
            {data.barcodeString && (
              <div className="product-details-component__barcode-string">
                <h3 className="product-details-component__metrics-title">Barcode Number:</h3>
                <p className="product-details-component__barcode-value">{data.barcodeString}</p>
              </div>
            )}
          </div>

          {data.nutritionInfoImage && data.nutritionInfoImage.length > 0 && (
            <div className="product-details-component__nutrition-info">
              <h3 className="product-details-component__metrics-title">Nutrition Information:</h3>
              <img 
                src={data.nutritionInfoImage[0].url} 
                alt="Nutrition Info" 
                className="product-details-component__nutrition-info-image"
              />
            </div>
          )}
        </>
      )}

      {active === 2 && (
        <div className="product-details-component__reviews-container">
          {data &&
            data.reviews.map((item, index) => (
              <div className="product-details-component__review" key={index}>
                <img
                  src={`${item.user.avatar?.url}`}
                  alt=""
                  className="product-details-component__review-avatar"
                />
                <div className="product-details-component__review-content">
                  <div className="product-details-component__review-header">
                    <h1 className="product-details-component__reviewer-name">{item.user.name}</h1>
                    <Ratings rating={data?.ratings} />
                  </div>
                  <p className="product-details-component__review-comment">{item.comment}</p>
                </div>
              </div>
            ))}

          {data && data.reviews.length === 0 && (
            <h5 className="product-details-component__no-reviews">No Reviews for this product.</h5>
          )}
        </div>
      )}

      {active === 3 && (
        <div className="product-details-component__seller-info">
          <div className="product-details-component__seller-section">
            <Link to={`/shop/${data.shop.handle}`} className="product-details-component__shop-link">
              <div className="product-details-component__shop-container">
                <img
                  src={`${data?.shop?.avatar?.url}`}
                  className="product-details-component__shop-avatar-large"
                  alt={`${data.shop.name} avatar`}
                />
                <div className="product-details-component__shop-details-large">
                  <h3 className="product-details-component__shop-name-large">
                    {data.shop.name}
                  </h3>
                  <p className="product-details-component__shop-rating">
                    ({averageRating}/5) Ratings
                  </p>
                </div>
              </div>
            </Link>
            <p className="product-details-component__shop-description">{data.shop.description}</p>
          </div>

          <div className="product-details-component__shop-metrics">
            <div className="product-details-component__shop-metric">
              <h4 className="product-details-component__shop-metric-title">Joined on</h4>
              <p className="product-details-component__shop-metric-value">
                {data.shop?.createdAt?.slice(0, 10)}
              </p>
            </div>

            <div className="product-details-component__shop-metric">
              <h4 className="product-details-component__shop-metric-title">Total Products</h4>
              <p className="product-details-component__shop-metric-value">
                {products && products.length}
              </p>
            </div>

            <div className="product-details-component__shop-metric">
              <h4 className="product-details-component__shop-metric-title">Total Reviews</h4>
              <p className="product-details-component__shop-metric-value">
                {totalReviewsLength}
              </p>
            </div>
          </div>
        </div>
      )}

      <div className="product-details-component__visit-shop">
        <Link to={`/shop/${data?.shop.handle}`}>
          <div className="product-details-component__visit-shop-button">
            <h4 className="product-details-component__visit-shop-text">Visit Shop</h4>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default ProductDetails;