import React, { useState, useEffect } from "react";
import axios from "axios";
import { server } from "../../server";

const TaxCodeSelector = ({ selectedTaxCode, updateTaxCode }) => {
  const [taxCodes, setTaxCodes] = useState([]);
  const [filteredTaxCodes, setFilteredTaxCodes] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchTaxCodes = async () => {
        setIsLoading(true);
        try {
            const response = await axios.get(`${server}/tax/tax-codes`);
            console.log("Fetched tax codes:", response.data.data);  
            setTaxCodes(response.data.data);
            setFilteredTaxCodes(response.data.data);
        } catch (error) {
            console.error('Failed to fetch tax codes:', error);
        }
        setIsLoading(false);
    };

    fetchTaxCodes();
  }, []);

  useEffect(() => {
    if (inputValue) {
      setFilteredTaxCodes(
        taxCodes.filter((code) =>
          code.description.toLowerCase().includes(inputValue.toLowerCase())
        )
      );
    } else {
      setFilteredTaxCodes(taxCodes);
    }
  }, [inputValue, taxCodes]);

  useEffect(() => {
    if (selectedTaxCode) {
      const selected = taxCodes.find(code => code.id === selectedTaxCode);
      if (selected) {
        setInputValue(selected.description);
      }
    }
  }, [selectedTaxCode, taxCodes]);

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleSelectCode = (code) => {
    console.log("Selected tax code object:", code);  
    updateTaxCode({
        id: code.id,
        description: code.description,
    });
    setInputValue(code.description);
    setShowDropdown(false);
  };

  return (
    <div className="tax-code-selector-container">
      <label htmlFor="taxCode" className="tax-code-selector-label">Tax Code <span className="tax-code-selector-required">*</span></label>
      <input
        type="text"
        placeholder="Search tax codes..."
        value={inputValue}
        onChange={handleInputChange}
        onFocus={() => setShowDropdown(true)}
        onBlur={() => setTimeout(() => setShowDropdown(false), 300)}
        className="tax-code-selector-input"
      />
      {showDropdown && (
        <div className="tax-code-selector-dropdown">
          {isLoading ? (
            <div className="tax-code-selector-loading">Loading...</div>
          ) : (
            filteredTaxCodes.map((code) => (
              <div key={code.id} onClick={() => handleSelectCode(code)} className="tax-code-selector-item">
                {code.description}
              </div>
            ))
          )}
        </div>
      )}
    </div>
  );
};

export default TaxCodeSelector;

