import React, { useState, useEffect } from 'react';
import LocationTracker from '../components/Location/Locator';
import EventsPage from './EventsPage';

const EventsPageContainer = () => {
    const [userLocation, setUserLocation] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const savedLocation = sessionStorage.getItem('userLocation');
        if (savedLocation) {
            setUserLocation(JSON.parse(savedLocation));
            setLoading(false);
        }
    }, []);

    const handleLocationRetrieved = (location) => {
        setUserLocation(location);
        setLoading(false);
    };

    if (loading) {
        return (
            <div>
                <LocationTracker onLocationRetrieved={handleLocationRetrieved} />
                Loading location information...
            </div>
        );
    }

    return (
        <EventsPage userLocation={userLocation} />
    );
};

export default EventsPageContainer;
