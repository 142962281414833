import React from 'react';

const MembershipStart = ({ nextStep }) => {
    return (
        <div className="membership-start-container">
            <div className="membership-start-overlay">
                <div className="membership-start-content">
                    <img
                        className="membership-start-logo"
                        src="https://res.cloudinary.com/dzlopmfj8/image/upload/v1706549678/Asset-1_yjnmot.png"
                        alt="Your Company"
                    />
                    <button onClick={nextStep} className="membership-start-button">
                        Get Started
                    </button>
                </div>
            </div>
        </div>
    );
};

export default MembershipStart;
