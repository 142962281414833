import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  mode: "light",
  user: null,
  token: null,
  posts: [],
  currentProfile: 'User',
  userProfile: {
    avatar: null, 
  },
  sellerProfile: {
    avatar: null,
  },
};


export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setMode: (state) => {
      state.mode = state.mode === "light" ? "dark" : "light";
    },
    setLogin: (state, action) => {
        state.user = action.payload.user;
        state.token = action.payload.token;
      },
      setLogout: (state) => {
        state.user = null;
        state.token = null;
      },

      
      
    setPosts: (state, action) => {
      state.posts = action.payload.posts;
    },
    setPost: (state, action) => {
      const updatedPosts = state.posts.map((post) => {
        if (post._id === action.payload.post._id) return action.payload.post;
        return post;
      });
      state.posts = updatedPosts;
    },
    SwitchProfile: (state, action) => {
      state.currentProfile = action.payload;
    },
    setSwitchProfile: (state) => {
      state.currentProfile = state.currentProfile === 'User' ? 'Shop' : 'User';
    },
    switchToUser: (state) => {
      state.currentProfile = 'User';
    },
    switchToSeller: (state) => {
      state.currentProfile = 'Shop';
    },
  },
});

export const { setMode, setPosts, setPost, switchToUser, switchToSeller, setSwitchProfile, SwitchProfile} =
  authSlice.actions;
export default authSlice.reducer;


