import React from 'react';

const CheckoutSteps = ({ active }) => {
  console.log(active);
  return (
    <div className="checkout-steps-component-wrapper">
      <div className="checkout-steps-component-content">
        <div className="checkout-steps-component-step">
          <div className="checkout-steps-component-flex">
            <div className="checkout-steps-component-cart-button">
              <span className="checkout-steps-component-cart-button-text">1. Shipping</span>
            </div>
            <div className={`checkout-steps-component-line ${active > 1 ? 'active' : ''}`} />
          </div>
        </div>
        <div className="checkout-steps-component-step">
          <div className="checkout-steps-component-flex">
            <div className={`checkout-steps-component-cart-button ${active > 1 ? 'active' : 'inactive'}`}>
              <span className={`checkout-steps-component-cart-button-text ${active > 1 ? '' : 'inactive-text'}`}>2. Payment</span>
            </div>
          </div>
        </div>
        <div className="checkout-steps-component-step">
          <div className="checkout-steps-component-flex">
            <div className={`checkout-steps-component-line ${active > 3 ? 'active' : ''}`} />
            <div className={`checkout-steps-component-cart-button ${active > 2 ? 'active' : 'inactive'}`}>
              <span className={`checkout-steps-component-cart-button-text ${active > 2 ? '' : 'inactive-text'}`}>3. Success</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CheckoutSteps;
