export default function SplitImagePromo() {
    return (
      <>
        <div className="grid h-600 grid-cols-1 grid-rows-2 lg:grid-cols-2 lg:grid-rows-1">
          <div className="relative flex">
            <img
              src="https://res.cloudinary.com/dzlopmfj8/image/upload/v1716393233/art-category-buzzvibe_cfepbq.jpg"
              alt=""
              className="absolute inset-0 h-full w-full object-cover object-center"
            />
            <div className="relative flex w-full flex-col items-start justify-end bg-black bg-opacity-40 p-8 sm:p-12">
              <h2 className="text-lg font-medium text-white text-opacity-75">Vintage</h2>
              <p className="mt-1 text-2xl font-medium text-white">Nothing like the old school</p>
              <a
                href="#"
                className="mt-4 rounded-md bg-white px-4 py-2.5 text-sm font-medium text-gray-900 hover:bg-gray-50"
              >
                Shop now
              </a>
            </div>
          </div>
          <div className="relative flex">
            <img
              src="https://res.cloudinary.com/dzlopmfj8/image/upload/v1702599501/b5_lpnl4j.jpg"
              alt=""
              className="absolute inset-0 h-full w-full object-cover object-center"
            />
            <div className="relative flex w-full flex-col items-start justify-end bg-black bg-opacity-40 p-8 sm:p-12">
              <h2 className="text-lg font-medium text-white text-opacity-75">Art</h2>
              <p className="mt-1 text-2xl font-medium text-white">Discover Inspiration</p>
              <a
                href="#"
                className="mt-4 rounded-md bg-white px-4 py-2.5 text-sm font-medium text-gray-900 hover:bg-gray-50"
              >
                Shop now
              </a>
            </div>
          </div>
        </div>
      </>
    )
  }
  