import React from 'react';
import DashboardHeader from '../../components/Shop/Layout/DashboardHeader';
import DashboardSideBar from '../../components/Shop/Layout/DashboardSideBar';
import AllProducts from "../../components/Shop/AllProducts";

const ShopAllProducts = () => {
  return (
    <div className="shop-all-products-page-container">
        <DashboardHeader />
        <div className="shop-all-products-page-content">
            <div className="shop-all-products-page-sidebar">
              <DashboardSideBar active={3} />
            </div>
            <div className="shop-all-products-page-main">
                <AllProducts />
            </div>
        </div>
    </div>
  );
};

export default ShopAllProducts;
