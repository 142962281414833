import React, { useState, useEffect } from 'react';
import axios from 'axios';
import EventCard from '../components/Events/EventCard'; 
import EventCreationForm from '../components/Events/EventCreationForm';
import { server } from "../server";
import Sidebar from '../components/Layout/Sidebar';
import Header from '../components/Layout/Header';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MyEvents from '../components/Events/MyEvents';
import { useSelector } from 'react-redux'; 


const EventsPage = ({ userLocation }) => {
    const [events, setEvents] = useState([]);
    const [filteredEvents, setFilteredEvents] = useState([]);
    const [filterDistance, setFilterDistance] = useState(50);
    const [filterTime, setFilterTime] = useState(168);
    const [open, setOpen] = useState(false);

    useEffect(() => {
        fetchEvents();
    }, [userLocation, filterDistance, filterTime]); 

    const fetchEvents = async () => {
        try {
            const response = await axios.get(`${server}/event/events`);
            const now = new Date();
            const fetchedEvents = response.data.map(event => ({
                ...event,
                distance: userLocation ? getDistanceFromLatLonInKm(
                    userLocation.latitude, 
                    userLocation.longitude, 
                    event.location.lat, 
                    event.location.lng
                ) : undefined,
                timeToEvent: Math.abs(new Date(event.date) - now) / 36e5 // Convert milliseconds to hours
            }));

            const filtered = fetchedEvents.filter(event =>
                (filterDistance === 'all' || event.distance <= filterDistance) &&
                (filterTime === 'all' || event.timeToEvent <= filterTime)
            ).sort((a, b) => a.distance - b.distance);

            setFilteredEvents(filtered);
            setEvents(fetchedEvents);
        } catch (error) {
            console.error('Failed to fetch events:', error);
            toast.error('Failed to load events.');
        }
    };

    const handleRSVP = (eventId) => {
        const updatedEvents = events.map(event => 
            event._id === eventId ? { ...event, isGoing: true } : event
        );
        setEvents(updatedEvents);
        setFilteredEvents(updatedEvents);
        toast.success('RSVP successful!');
    };

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    function getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2) {
        const R = 6371;
        const dLat = deg2rad(lat2-lat1);
        const dLon = deg2rad(lat2-lat1);
        const a = Math.sin(dLat/2) * Math.sin(dLat/2) +
                  Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) *
                  Math.sin(dLon/2) * Math.sin(dLon/2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
        const distance = R * c;
        return distance;
    }

    function deg2rad(deg) {
        return deg * (Math.PI/180);
    }
    const handleFilterChange = (e, type) => {
        const value = e.target.value;
        if (type === 'distance') {
            setFilterDistance(value === 'all' ? 'all' : Number(value));
        } else if (type === 'time') {
            setFilterTime(value === 'all' ? 'all' : Number(value));
        }
    };

    return (
        <div >
            <Header />
            <div className="events-page-container">
                <Sidebar className="events-page-sidebar" />
                <div className="events-page-main-content">
                    <div className="events-page-filters">
                        <select value={filterDistance} onChange={(e) => handleFilterChange(e, 'distance')} className="events-page-select">
                            <option value="all">All Distances</option>
                            <option value="25">25 km</option>
                            <option value="50">50 km</option>
                            <option value="75">75 km</option>
                            <option value="100">100 km</option>
                            <option value="125">125 km</option>
                            <option value="150">150 km</option>
                        </select>
                        <select value={filterTime} onChange={(e) => handleFilterChange(e, 'time')} className="events-page-select">
                            <option value="all">All Times</option>
                            <option value="24">Next 24 hours</option>
                            <option value="48">Next 48 hours</option>
                            <option value="72">Next 72 hours</option>
                            <option value="168">Next 7 days</option>
                        </select>
                    </div>
                    <div className="events-page-events-list">
                        {filteredEvents.map(event => (
                            <EventCard key={event.id} event={event} onRSVP={handleRSVP} />
                        ))}
                    </div>
                </div>
                <aside className="events-page-aside">
                    <div className="events-page-create-event-button-container">
                        <button onClick={handleOpen} className="events-page-create-event-button">
                            Create Event
                        </button>
                    </div>
                    <MyEvents />
                </aside>
            </div>
            {open && (
                <div className="events-page-modal-overlay" onClick={handleClose}>
                    <div className="events-page-modal-box" onClick={(e) => e.stopPropagation()}>
                    <h2 className="events-page-modal-title">Create a New Event</h2>
                        <EventCreationForm onClose={handleClose} />
                    </div>
                </div>
            )}
            <ToastContainer position="top-right" autoClose={5000} />
        </div>
    );
};

export default EventsPage;



