import React from 'react';
import './ShareToSocialMedia.css'; // Import the stylesheet

const ShareToSocialMedia = ({ url, message }) => {
  const encodedMessage = encodeURIComponent(message);
  const encodedUrl = encodeURIComponent(url);

  const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodedUrl}`;
  const twitterUrl = `https://twitter.com/intent/tweet?text=${encodedMessage}&url=${encodedUrl}`;
  const linkedinUrl = `https://www.linkedin.com/shareArticle?mini=true&url=${encodedUrl}&title=${encodedMessage}`;

  return (
    <div className="share-buttons">
      <a href={facebookUrl} target="_blank" rel="noopener noreferrer" className="share-button facebook">
        <i className="fa fa-facebook"></i> 
      </a>
      <a href={twitterUrl} target="_blank" rel="noopener noreferrer" className="share-button twitter">
        <i className="fa fa-twitter"></i> 
      </a>
      <a href={linkedinUrl} target="_blank" rel="noopener noreferrer" className="share-button linkedin">
        <i className="fa fa-linkedin"></i> 
      </a>
    </div>
  );
};

export default ShareToSocialMedia;

