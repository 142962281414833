import React from 'react';
import OwnedBrand from '../components/Brands/OwnedBrand';
import BrandCatalog from '../components/Brands/BrandCatalog';
import DashboardHeader from '../components/Shop/Layout/DashboardHeader';
import DashboardSideBar from '../components/Shop/Layout/DashboardSideBar';
import ProfileSwitchButtonShop from '../components/Shop/ShopSwitch';



const BrandManagementPage = () => {
  return (
    <div>
      <DashboardHeader />
      <div className="flex justify-between w-full">
        <div className="w-[80px] md:w-[330px]">
          <DashboardSideBar active={13} />
        </div>
        <div className="flex flex-col flex-grow">
          <OwnedBrand />
          <BrandCatalog />
        </div>
      </div>
    </div>
  );
};

export default BrandManagementPage;