import axios from 'axios';
import { server } from "../../server";


export const loadNotifications = (profileType, profileId) => async (dispatch) => {
  try {
    dispatch({ type: "LoadNotificationsRequest" });

    const { data } = await axios.get(`${server}/notifications/get-notifications/${profileType}/${profileId}`, {
      withCredentials: true,
    });

    dispatch({
      type: "LoadNotificationsSuccess",
      payload: {
        notifications: data.notifications, 
        unseenCount: data.unseenCount,
      },
    });
  } catch (error) {
    dispatch({
      type: "LoadNotificationsFail",
      payload: error.response?.data?.message || error.message,
    });
  }
};

// resetUnseenNotifications action
export const resetUnseenNotifications = (profileId, profileType) => async (dispatch) => {
  try {
    await axios.put(`${server}/notifications/reset-unseen-count`, { profileId, profileType, withCredentials: true,});

    dispatch({
      type: 'ResetUnseenNotifications',
    });
  } catch (error) {
    console.error('Error resetting unseen notifications:', error);
    
  }
};




