import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { server } from "../server";


const ActivationPageOauth = () => {
  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const email = searchParams.get('email');

    if (email) {
      activateAccount(email);
    }
  }, [location]);

  const activateAccount = async (email) => {
    try {
      // Replace with your actual backend endpoint for account activation
      const response = await axios.post(`${server}/oauth/activation`, { email });
      alert(response.data.message); // Show a success message or redirect the user
    } catch (error) {
      alert('Activation failed. Please try again.'); // Handle errors appropriately
    }
  };

  return (
    <div>
      <h1>Activating your account...</h1>
      {/* You can have a loader here or any content you deem appropriate */}
    </div>
  );
};

export default ActivationPageOauth;
