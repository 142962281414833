import { createReducer } from "@reduxjs/toolkit";
import {
  FETCH_HASHTAGS_REQUEST,
  FETCH_HASHTAGS_SUCCESS,
  FETCH_HASHTAGS_FAILURE,
  FETCH_POSTS_BY_HASHTAG_REQUEST,
  FETCH_POSTS_BY_HASHTAG_SUCCESS,
  FETCH_POSTS_BY_HASHTAG_FAILURE,
} from '../actions/post'; 

const initialState = {
  hashtagposts: [],
  hashtags: [],
  loading: false,
  error: null,
};

export const hashtagsReducer = createReducer(initialState, {

  [FETCH_HASHTAGS_REQUEST]: (state) => {
    state.loading = true;
    state.error = null;
  },
  [FETCH_HASHTAGS_SUCCESS]: (state, action) => {
    state.loading = false;
    state.hashtags = action.payload;
    state.error = null;
  },
  [FETCH_HASHTAGS_FAILURE]: (state, action) => {
    state.loading = false;
    state.error = action.payload;
  },
  [FETCH_POSTS_BY_HASHTAG_REQUEST]: (state) => {
    state.loading = true;
    state.error = null;
  },
  [FETCH_POSTS_BY_HASHTAG_SUCCESS]: (state, action) => {
    state.loading = false;
    state.hashtagposts = action.payload;
    state.error = null;
  },
  [FETCH_POSTS_BY_HASHTAG_FAILURE]: (state, action) => {
    state.loading = false;
    state.error = action.payload;
  },
});