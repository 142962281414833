import { createReducer } from "@reduxjs/toolkit";
import {
  GET_CURRENT_USER_FOLLOWERS_REQUEST,
  GET_CURRENT_USER_FOLLOWERS_SUCCESS,
  GET_CURRENT_USER_FOLLOWERS_FAIL,
  GET_OTHER_USER_FOLLOWERS_REQUEST,
  GET_OTHER_USER_FOLLOWERS_SUCCESS,
  GET_OTHER_USER_FOLLOWERS_FAIL
} from "../actions/followers";

const initialState = {
  isLoading: false,
  currentUserFollowers: [],
  currentUserFollowing: [],
  otherUserFollowers: [],
  otherUserFollowing: [],
  error: null
};

export const followersReducer = createReducer(initialState, {
  [GET_CURRENT_USER_FOLLOWERS_REQUEST]: (state) => {
    state.isLoading = true;
  },
  [GET_CURRENT_USER_FOLLOWERS_SUCCESS]: (state, action) => {
    state.isLoading = false;
    state.currentUserFollowers = action.payload.followers;
    state.currentUserFollowing = action.payload.following;
  },
  [GET_CURRENT_USER_FOLLOWERS_FAIL]: (state, action) => {
    state.isLoading = false;
    state.error = action.payload;
  },
  [GET_OTHER_USER_FOLLOWERS_REQUEST]: (state) => {
    state.isLoading = true;
  },
  [GET_OTHER_USER_FOLLOWERS_SUCCESS]: (state, action) => {
    state.isLoading = false;
    state.otherUserFollowers = action.payload.followers;
    state.otherUserFollowing = action.payload.following;
  },
  [GET_OTHER_USER_FOLLOWERS_FAIL]: (state, action) => {
    state.isLoading = false;
    state.error = action.payload;
  }
});

export default followersReducer;

