import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { server } from "../../server";

const SponsoredProductCarousel = () => {
    const [products, setProducts] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(0);
    const adRefs = useRef(new Map()); // To store product refs

    const logAdClick = async (adId) => {
        try {
            await axios.post(`${server}/sponsored/click/${adId}`, { withCredentials: true, 
                headers: {
                    'current-profile': sessionStorage.getItem('currentProfile') || 'defaultProfile',
                }});
            console.log('Click logged for ad:', adId);
        } catch (error) {
            console.error('Error logging click:', error);
        }
    };

    const logImpression = async (adId) => {
        try {
            await axios.post(`${server}/sponsored/impression/${adId}`, {}, { withCredentials: true, 
                headers: {
                    'current-profile': sessionStorage.getItem('currentProfile') || 'defaultProfile',
                }});
            console.log('Impression logged for ad:', adId);
        } catch (error) {
            console.error('Error logging impression:', error);
        }
    };

    const logViewedImpression = async (adId) => {
        try {
            await axios.post(`${server}/sponsored/viewed-impression/${adId}`, {}, { withCredentials: true, 
                headers: {
                    'current-profile': sessionStorage.getItem('currentProfile') || 'defaultProfile',
                }});
            console.log('Viewed impression logged for ad:', adId);
        } catch (error) {
            console.error('Error logging viewed impression:', error);
        }
    };

    useEffect(() => {
        const fetchProducts = async () => {
            try {
                const response = await axios.get(`${server}/sponsored/sponsored-products`, { withCredentials: true });
                const activeProducts = response.data.filter(ad => ad.isActive && ad.product);
                setProducts(activeProducts);
            } catch (error) {
                console.error('Error fetching products:', error);
            }
        };

        fetchProducts();
    }, []);

    useEffect(() => {
        if (products.length > 0) {
            products.forEach(product => {
                logImpression(product._id); // Log impressions for all products
            });
        }
    }, [products]);

    useEffect(() => {
        if (products.length > 0) {
            const observer = new IntersectionObserver(
                (entries) => {
                    entries.forEach((entry) => {
                        if (entry.isIntersecting) {
                            const adId = entry.target.dataset.adId;

                            // Start a timer for 1 second to log viewed impression
                            const timer = setTimeout(() => {
                                logViewedImpression(adId);
                            }, 1000);

                            // Store the timer in the DOM element
                            entry.target._timer = timer;
                        } else {
                            clearTimeout(entry.target._timer); // Clear the timer if the element goes out of view
                        }
                    });
                },
                { threshold: 0.5 } // 50% of the ad must be visible
            );

            // Observe the current product in view
            const currentProduct = products[currentIndex];
            const currentProductElement = adRefs.current.get(currentProduct?._id);
            if (currentProductElement) {
                observer.observe(currentProductElement);
            }

            return () => {
                observer.disconnect();
            };
        }
    }, [currentIndex, products]);

    const nextSlide = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % products.length);
    };

    const prevSlide = () => {
        setCurrentIndex((prevIndex) => (prevIndex - 1 + products.length) % products.length);
    };

    if (products.length === 0) {
        return null;
    }

    const currentProduct = products[currentIndex];
    const imageUrl = currentProduct.adSet.images["Sponsored Product Carousel"];
    const position = currentProduct.adSet.positions["Sponsored Product Carousel"] || { x: 0, y: 0 };

    return (
        <div className="sponsored-product-carousel">
            <div
                className="sponsored-product-carousel-paper"
                data-ad-id={currentProduct._id}
                ref={(el) => el && adRefs.current.set(currentProduct._id, el)} // Save ref for IntersectionObserver
            >
                <div className="sponsored-product-carousel-image-wrapper">
                    <img
                        src={imageUrl}
                        alt={currentProduct.product.name}
                        className="sponsored-product-carousel-image"
                        style={{
                            transform: `translate(${position.x}px, ${position.y}px)`,
                        }}
                    />
                </div>
                <div className="sponsored-product-carousel-content">
                    <h2 className="sponsored-product-carousel-title">{currentProduct.product.name}</h2>
                    <p className="sponsored-product-carousel-price">{`$${currentProduct.product.discountPrice || currentProduct.product.originalPrice}`}</p>
                    <Link
                        to={`/product/${currentProduct.product._id}`}
                        onClick={() => logAdClick(currentProduct._id)}
                        className="sponsored-product-carousel-button"
                    >
                        Buy now
                    </Link>
                </div>
            </div>
            {products.length > 1 && (
                <>
                    <button className="sponsored-carousel-nav-button sponsored-carousel-prev" onClick={prevSlide}>‹</button>
                    <button className="sponsored-carousel-nav-button sponsored-carousel-next" onClick={nextSlide}>›</button>
                </>
            )}
        </div>
    );
};

export default SponsoredProductCarousel;






