import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { fetchPostsByHashtag } from '../../redux/actions/post';
import PostWidgetRedux from '../../widgets/PostWidgetRedux';
import Header from '../Layout/Header';
import Sidebar from '../Layout/Sidebar';
import SponsoredProducts from '../Sponsored/SponsoredProducts';

const HashtagPosts = () => {
  const { hashtag } = useParams(); 
  const dispatch = useDispatch();
  const hashtagposts = useSelector(state => state.hashtags.hashtagposts);
  const { loading, error } = useSelector(state => state.hashtags);

  useEffect(() => {
    dispatch(fetchPostsByHashtag(hashtag));
  }, [dispatch, hashtag]);

  useEffect(() => {
    console.log("Hashtag posts:", hashtagposts);
  }, [hashtagposts]);

  if (loading) return <div>Loading posts...</div>;
  if (error) return <div>Error fetching posts: {error}</div>;

  return (
    <div className="hashtag-posts-page">
      <Header />
      <Sidebar />

      <div className="hashtag-posts-container">
        
        <aside className="hashtag-posts-aside">
          <SponsoredProducts />
        </aside>
        
        <main className="hashtag-posts-main">
          <div>
            <div className="hashtag-posts-header">
              <h1 className="hashtag-posts-title">Posts with #{hashtag}</h1>
            </div>
            {hashtagposts && hashtagposts.map(post => (
              <PostWidgetRedux
                key={post._id}
                postId={post._id}
                owner={post.owner}
                postUserId={post.userId}
                profileType={post.profileType}
                name={post.name}
                description={post.description}
                location={post.location}
                mediaPath={post.mediaPath}
                images={post.images}        
                videos={post.videos}
                link={post.link}
                avatar={post.avatar}
                likes={post.likes}
                comments={post.comments}
                createdAt={post.createdAt}
                onShareSuccess={() => {}}
                resharedBy={post.resharedBy}
                reshareDescription={post.reshareDescription}
                reshareAvatar={post.reshareAvatar}
                isLikedByCurrentUser={post.isLikedByCurrentUser}
                likesCount={post.likesCount}
                totalComments={post.totalComments}
              />
            ))}
          </div>
        </main>

        <aside className="hashtag-posts-aside">
          <SponsoredProducts />
        </aside>
      </div>
    </div>
  );
};

export default HashtagPosts;


