import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { server } from '../../server'; 

const Rooms = () => {
  const [rooms, setRooms] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  const currentProfile = useSelector(state => state.user.currentProfile);

  useEffect(() => {
    const fetchRooms = async () => {
      try {
        const response = await axios.get(`${server}/room/rooms?profile=${currentProfile}`, {
          withCredentials: true
        });
        setRooms(response.data.rooms);
        setLoading(false);
      } catch (err) {
        setError('Failed to fetch rooms');
        setLoading(false);
      }
    };

    fetchRooms();
  }, [currentProfile]);

  if (loading) return <p>Loading rooms...</p>;
  if (error) return <p>{error}</p>;

  return (
    <div className="rooms-container">
      <h2>My Rooms</h2>
      {rooms.length > 0 ? (
        <ul className="rooms-list">
          {rooms.map(room => (
            <Link to={`/rooms/${room.handle}`} key={room._id} className="rooms-list-item-link">
              <li 
                className="rooms-list-item" 
                style={{
                  backgroundImage: `url(${room.banner?.url || ''})`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                }}>
                <div className="room-name">{room.name}</div>
                <div className="room-description">{room.description}</div>
              </li>
            </Link>
          ))}
        </ul>
      ) : (
        <p>No rooms created yet.</p>
      )}
    </div>
  );
};

export default Rooms;



