import React, { useState } from 'react';
import DashboardHeader from '../../components/Shop/Layout/DashboardHeader';
import DashboardSideBar from '../../components/Shop/Layout/DashboardSideBar';
import ShippingRules from '../../components/Shipping/ShippingRules';
import RegionsList from '../../components/Region/RegionsList';
import PackingSlipForm from '../../components/Shipping/PackingSlipForm';
import USPSRateCalculator from '../../components/Shipping/USPSRateCalculator';

const ShopShipping = () => {
    const [showPackingSlipModal, setShowPackingSlipModal] = useState(false);

    const handleOpenModal = () => {
        setShowPackingSlipModal(true);
    };

    const handleCloseModal = () => {
        setShowPackingSlipModal(false);
    };

    return (
        <div>
            <DashboardHeader />
            <div className="shop-shipping-page-container">
                <div className="shop-shipping-page-sidebar">
                    <DashboardSideBar active={14} />
                </div>
                <div className="shop-shipping-page-main">
                    <USPSRateCalculator />
                    <RegionsList />
                    <div className="shop-shipping-page-shipping-rules">
                        <ShippingRules />
                    </div>
                    <button onClick={handleOpenModal} className="shop-shipping-page-button-open-modal">
                        Generate Packing Slip
                    </button>
                </div>
            </div>
            {showPackingSlipModal && <PackingSlipForm onClose={handleCloseModal} />}
        </div>
    );
};

export default ShopShipping;

