import React, { useEffect, useState } from 'react';
import Lottie from 'lottie-react';
import animationData from '../../Assests/animations/107043-success.json';


const MembershipSuccess = () => {
    const [showAnimation, setShowAnimation] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            setShowAnimation(false);
        }, 4000);

        return () => clearTimeout(timer);
    }, []);

    return (
        <div className="membership-success-container">
            <div className="membership-success-content">
                {showAnimation && (
                    <div className="membership-success-animation">
                        <Lottie animationData={animationData} loop={false} />
                    </div>
                )}
                <h2 className="membership-success-title">Membership Created Successfully!</h2>
                <p className="membership-success-message">
                    Congratulations! Your membership has been successfully created.
                </p>
                <p className="membership-success-email">
                    Please check your email for your QR sign-in code.
                </p>
                <p className="membership-success-footer">
                    Thank you for joining the BuzzVibe community!
                </p>
            </div>
        </div>
    );
};

export default MembershipSuccess;
