import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { server } from '../../server';


const EditProduct = ({ productId }) => {
  const [productData, setProductData] = useState({
    name: '',
    description: '',
    category: '',
    tags: '',
    originalPrice: '',
    discountPrice: '',
    productType: '',
    stock: '',
    availableForPickup: false,
    availableForDelivery: false,
    handle: '',
    brand: '',
    sold_out: 0,
  });
  const [images, setImages] = useState([]);
  const [newImages, setNewImages] = useState([]);
  const [barcodeImage, setBarcodeImage] = useState(null);
  const [nutritionInfoImage, setNutritionInfoImage] = useState(null);
  const [loading, setLoading] = useState(true);
  const currentProfile = sessionStorage.getItem('currentProfile');

  useEffect(() => {
    axios.get(`${server}/product/get-product/${productId}`)
      .then(response => {
        setProductData(response.data.product);
        setImages(response.data.product.images || []);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching product data:', error);
        setLoading(false);
      });
  }, [productId]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setProductData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setProductData(prevState => ({
      ...prevState,
      [name]: checked
    }));
  };

  const handleProductTypeChange = (e) => {
    const value = e.target.value;
    setProductData(prevState => {
      const updatedProductType = prevState.productType.includes(value)
        ? prevState.productType.filter(type => type !== value)
        : [...prevState.productType, value];
      return { ...prevState, productType: updatedProductType };
    });
  };

  const handleImageChange = (event, field) => {
    const files = Array.from(event.target.files);
    if (field === 'images') {
        setNewImages(files);
    } else if (field === 'barcode') {
        setBarcodeImage(files[0]);
    } else if (field === 'NutritionInfo') {
        setNutritionInfoImage(files[0]);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData();

    // Append all product data fields to formData
    for (const key in productData) {
      if (key !== 'reviews') {
        if (Array.isArray(productData[key])) {
          formData.append(key, JSON.stringify(productData[key]));
        } else {
          formData.append(key, productData[key]);
        }
      }
    }

    // Append images to formData
    newImages.forEach((image) => {
      formData.append('images', image);
    });

    // Append barcode and NutritionInfo images
    if (barcodeImage) formData.append('barcode', barcodeImage);
    if (nutritionInfoImage) formData.append('NutritionInfo', nutritionInfoImage);

    axios.put(`${server}/product/edit-product/${productId}`, formData, {
      withCredentials: true,
      headers: {
        'Current-Profile': currentProfile,
        'Content-Type': 'multipart/form-data'
      }
    })
    .then(response => {
      console.log('Product updated:', response.data);
      // Handle successful update
    })
    .catch(error => {
      console.error('Error updating product:', error);
      // Handle errors
    });
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!productData) {
    return <div>Product not found</div>;
  }

  return (
    <form className="edit-product-component-form" onSubmit={handleSubmit}>
      <div className="edit-product-component-section">
        <h2 className="edit-product-component-title">Edit Product</h2>
        <p className="edit-product-component-subtitle">Adjust your product details below.</p>

        <div className="edit-product-component-grid">
          <div className="edit-product-component-col-span-4">
            <label htmlFor="name" className="edit-product-component-label">Name</label>
            <input
              type="text"
              name="name"
              id="name"
              value={productData.name}
              onChange={handleInputChange}
              className="edit-product-component-input"
            />
          </div>

          <div className="edit-product-component-col-span-6">
            <label htmlFor="description" className="edit-product-component-label">Description</label>
            <textarea
              id="description"
              name="description"
              rows={3}
              value={productData.description}
              onChange={handleInputChange}
              className="edit-product-component-textarea"
            />
          </div>

          <div className="edit-product-component-col-span-3">
            <label htmlFor="category" className="edit-product-component-label">Category</label>
            <input
              type="text"
              name="category"
              id="category"
              value={productData.category}
              onChange={handleInputChange}
              className="edit-product-component-input"
            />
          </div>

          <div className="edit-product-component-col-span-3">
            <label htmlFor="tags" className="edit-product-component-label">Tags</label>
            <input
              type="text"
              name="tags"
              id="tags"
              value={productData.tags}
              onChange={handleInputChange}
              className="edit-product-component-input"
            />
          </div>

          <div className="edit-product-component-col-span-3">
            <label htmlFor="originalPrice" className="edit-product-component-label">Original Price</label>
            <input
              type="number"
              name="originalPrice"
              id="originalPrice"
              value={productData.originalPrice}
              onChange={handleInputChange}
              className="edit-product-component-input"
            />
          </div>

          <div className="edit-product-component-col-span-3">
            <label htmlFor="discountPrice" className="edit-product-component-label">Discount Price</label>
            <input
              type="number"
              name="discountPrice"
              id="discountPrice"
              value={productData.discountPrice}
              onChange={handleInputChange}
              className="edit-product-component-input"
            />
          </div>

          <div className="edit-product-component-col-span-6">
            <span className="edit-product-component-label">Product Type</span>
            <div className="edit-product-component-checkbox-container">
              <label>
                <input
                  type="checkbox"
                  name="productType"
                  value="retail"
                  checked={productData.productType.includes('retail')}
                  onChange={handleProductTypeChange}
                  className="edit-product-component-checkbox"
                />
                <span className="ml-2">Retail</span>
              </label>
              <label>
                <input
                  type="checkbox"
                  name="productType"
                  value="wholesale"
                  checked={productData.productType.includes('wholesale')}
                  onChange={handleProductTypeChange}
                  className="edit-product-component-checkbox"
                />
                <span className="ml-2">Wholesale</span>
              </label>
            </div>
          </div>

          <div className="edit-product-component-col-span-3">
            <label htmlFor="stock" className="edit-product-component-label">Stock</label>
            <input
              type="number"
              name="stock"
              id="stock"
              value={productData.stock}
              onChange={handleInputChange}
              className="edit-product-component-input"
            />
          </div>

          <div className="edit-product-component-col-span-3">
            <label htmlFor="availableForPickup" className="edit-product-component-label">
              <input
                type="checkbox"
                name="availableForPickup"
                id="availableForPickup"
                checked={productData.availableForPickup}
                onChange={handleCheckboxChange}
                className="edit-product-component-checkbox"
              />
              <span className="ml-2">Available for Pickup</span>
            </label>
          </div>

          <div className="edit-product-component-col-span-3">
            <label htmlFor="availableForDelivery" className="edit-product-component-label">
              <input
                type="checkbox"
                name="availableForDelivery"
                id="availableForDelivery"
                checked={productData.availableForDelivery}
                onChange={handleCheckboxChange}
                className="edit-product-component-checkbox"
              />
              <span className="ml-2">Available for Delivery</span>
            </label>
          </div>

          <div className="edit-product-component-col-span-4">
            <label htmlFor="handle" className="edit-product-component-label">Handle</label>
            <input
              type="text"
              name="handle"
              id="handle"
              value={productData.handle}
              onChange={handleInputChange}
              className="edit-product-component-input"
            />
          </div>

          <div className="edit-product-component-col-span-4">
            <label htmlFor="brand" className="edit-product-component-label">Brand</label>
            <input
              type="text"
              name="brand"
              id="brand"
              value={productData.brand}
              onChange={handleInputChange}
              className="edit-product-component-input"
            />
          </div>

          <div className="edit-product-component-col-span-6">
            <label htmlFor="barcode" className="edit-product-component-label">Barcode Image</label>
            <input
              type="file"
              name="barcode"
              id="barcode"
              onChange={(e) => handleImageChange(e, 'barcode')}
              className="edit-product-component-file-input"
            />
          </div>

          <div className="edit-product-component-col-span-6">
            <label htmlFor="nutritionInfo" className="edit-product-component-label">Nutrition Info Image</label>
            <input
              type="file"
              name="nutritionInfo"
              id="nutritionInfo"
              onChange={(e) => handleImageChange(e, 'NutritionInfo')}
              className="edit-product-component-file-input"
            />
          </div>

          <div className="edit-product-component-col-span-6 pt-8">
            <button
              type="submit"
              className="edit-product-component-submit-button"
            >
              Update Product
            </button>
          </div>

          <div className="edit-product-component-col-span-6">
            <label className="edit-product-component-label">Current Images</label>
            <div className="edit-product-component-image-previews">
              {images.map((img, index) => (
                <img key={index} src={img.url} alt={`Product ${index}`} className="edit-product-component-product-image" />
              ))}
            </div>
          </div>

          <div className="edit-product-component-col-span-6">
            <label className="edit-product-component-label">New Images</label>
            <div className="edit-product-component-image-previews">
              {newImages.map((img, index) => (
                <img key={index} src={URL.createObjectURL(img)} alt={`New ${index}`} className="edit-product-component-product-image" />
              ))}
            </div>
          </div>
        </div>
      </div>
    </form>
  );
}
export default EditProduct;
