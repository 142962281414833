import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { server } from "../../server";
import { AiOutlineCamera } from "react-icons/ai";
import axios from "axios";
import { loadSeller } from "../../redux/actions/user";
import { toast } from "react-toastify";

const ShopSettings = () => {
  const { seller } = useSelector((state) => state.seller);
  const [avatar, setAvatar] = useState();
  const [banner, setBanner] = useState();
  const [name, setName] = useState(seller && seller.name);
  const [description, setDescription] = useState(
    seller && seller.description ? seller.description : ""
  );
  const [address, setAddress] = useState(seller && seller.address);
  const [phoneNumber, setPhoneNumber] = useState(seller && seller.phoneNumber);
  const [zipCode, setZipcode] = useState(seller && seller.zipCode);
  const [openingHours, setOpeningHours] = useState(seller && seller.openingHours);
  const currentProfile = sessionStorage.getItem('currentProfile');

  const dispatch = useDispatch();

  const handleImage = async (e) => {
    const reader = new FileReader();

    reader.onload = () => {
      if (reader.readyState === 2) {
        setAvatar(reader.result);
        axios
          .put(
            `${server}/shop/update-shop-avatar`,
            { avatar: reader.result },
            { withCredentials: true, headers: {
              'Current-Profile': currentProfile, 
            } }
          )
          .then((res) => {
            dispatch(loadSeller());
            toast.success("Avatar updated successfully!");
          })
          .catch((error) => {
            toast.error(error.response.data.message);
          });
      }
    };

    reader.readAsDataURL(e.target.files[0]);
  };

  const handleBanner = async (e) => {
    const reader = new FileReader();

    reader.onload = () => {
      if (reader.readyState === 2) {
        setBanner(reader.result);
        axios.put(
            `${server}/shop/update-shop-banner`, 
            { banner: reader.result },
            { withCredentials: true, headers: {
              'Current-Profile': currentProfile, 
            } }
          )
          .then((res) => {
            dispatch(loadSeller());
            toast.success("Banner updated successfully!");
          })
          .catch((error) => {
            toast.error(error.response.data.message);
          });
      }
    };

    reader.readAsDataURL(e.target.files[0]);
  };

  const updateHandler = async (e) => {
    e.preventDefault();

    await axios
      .put(
        `${server}/update-seller-info`,
        {
          name,
          address,
          zipCode,
          phoneNumber,
          description,
          openingHours,
        },
        { withCredentials: true }
      )
      .then((res) => {
        toast.success("Shop info updated successfully!");
        dispatch(loadSeller());
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };

  const handleOpeningHoursChange = (day, openOrClose, value) => {
    setOpeningHours(prev => ({
      ...prev,
      [day]: { ...prev[day], [openOrClose]: value }
    }));
  };

  return (
    <div className="shop-settings-component-container">
      <div className="shop-settings-component-content">
        <div className="shop-settings-component-banner">
          <div className="shop-settings-component-banner">
            <img
              src={banner ? banner : `${seller?.banner?.url}`} 
              alt="Shop Banner"
            />
            <div className="shop-settings-component-camera-icon-container">
              <input
                type="file"
                id="banner"
                className="shop-settings-component-hidden"
                onChange={handleBanner}
              />
              <label htmlFor="banner">
                <AiOutlineCamera />
              </label>
            </div>
          </div>
        </div>
        <div className="shop-settings-component-avatar">
          <img
            src={avatar ? avatar : `${seller?.avatar?.url}`}
            alt=""
          />
          <div className="shop-settings-component-camera-icon-container">
            <input
              type="file"
              id="image"
              className="shop-settings-component-hidden"
              onChange={handleImage}
            />
            <label htmlFor="image">
              <AiOutlineCamera />
            </label>
          </div>
        </div>

        {/* shop info */}
        <form
          aria-aria-required={true}
          className="shop-settings-component-form"
          onSubmit={updateHandler}
        >
          <div className="shop-settings-component-form-group">
            <label>Shop Name</label>
            <input
              type="name"
              placeholder={`${seller?.name}`}
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="shop-settings-component-input"
              required
            />
          </div>
          <div className="shop-settings-component-form-group">
            <label>Shop description</label>
            <input
              type="name"
              placeholder={`${
                seller?.description
                  ? seller.description
                  : "Enter your shop description"
              }`}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              className="shop-settings-component-input"
            />
          </div>
          <div className="shop-settings-component-form-group">
            <label>Shop Address</label>
            <input
              type="name"
              placeholder={seller?.address}
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              className="shop-settings-component-input"
              required
            />
          </div>

          <div className="shop-settings-component-form-group">
            <label>Shop Phone Number</label>
            <input
              type="number"
              placeholder={seller?.phoneNumber}
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              className="shop-settings-component-input"
              required
            />
          </div>

          <div className="shop-settings-component-form-group">
            <label>Shop Zip Code</label>
            <input
              type="number"
              placeholder={seller?.zipCode}
              value={zipCode}
              onChange={(e) => setZipcode(e.target.value)}
              className="shop-settings-component-input"
              required
            />
          </div>

          <div className="shop-settings-component-opening-hours">
            <label>Opening Hours:</label>
            {Object.keys(openingHours).map((day) => (
              <div key={day} className="shop-settings-component-opening-hours-item">
                <span>{day.charAt(0).toUpperCase() + day.slice(1)}:</span>
                <div className="shop-settings-component-opening-hours-inputs">
                  <input
                    type="time"
                    value={openingHours[day].open}
                    onChange={(e) => handleOpeningHoursChange(day, 'open', e.target.value)}
                    className="shop-settings-component-opening-hours-input"
                  />
                  <span>to</span>
                  <input
                    type="time"
                    value={openingHours[day].close}
                    onChange={(e) => handleOpeningHoursChange(day, 'close', e.target.value)}
                    className="shop-settings-component-opening-hours-input"
                  />
                </div>
              </div>
            ))}
          </div>

          <div className="shop-settings-component-form-group">
            <input
              type="submit"
              value="Update Shop"
              className="shop-settings-component-submit-button"
              required
              readOnly
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default ShopSettings;

