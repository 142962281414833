import React, { useState } from 'react';
import axios from 'axios';


const NewsletterSignup = () => {
  const [email, setEmail] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = {
      email_address: email,
      status: 'subscribed',
    };

    try {
      await axios.post(`https://us21.api.mailchimp.com/3.0/lists/${process.env.REACT_APP_MAILCHIMP_LIST_ID}/members/`, data, {
        headers: {
          'Authorization': `apikey ${process.env.REACT_APP_MAILCHIMP_API_KEY}`,
          'Content-Type': 'application/json',
        }
      });
      alert('Thank you for subscribing!');
     
    } catch (error) {
      console.error('There was an error subscribing:', error.response.data);
     
    }
  };

  return (
    <div className="newsletter-signup-container">
      <h1 className="newsletter-signup-title">
        <span className="newsletter-signup-highlight">Subscribe</span> for updates, <br />
        events and offers
      </h1>
      <div>
        <form onSubmit={handleSubmit} className="newsletter-signup-form">
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            placeholder="Enter your email..."
            className="newsletter-signup-input"
          />
          <button type="submit" className="newsletter-signup-button">
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default NewsletterSignup;

