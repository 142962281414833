import React from 'react';

const CampaignTotals = ({ campaignAds }) => {
    const totalAds = campaignAds.length;
    const totalDuration = campaignAds.reduce((acc, ad) => acc + ad.details.adDuration, 0);
    const totalAdSpend = campaignAds.reduce((acc, ad) => acc + ad.details.adSpend, 0);

    const stats = [
        { name: 'Total Ads', value: totalAds },
        { name: 'Total Duration', value: `${totalDuration} days` },
        { name: 'Total Ad Spend', value: `$${totalAdSpend.toFixed(2)}` }
    ];

    return (
        <div className="campaign-totals-container">
            <h3 className="campaign-totals-header">Campaign Totals</h3>
            <dl className="campaign-totals-list">
                {stats.map((stat) => (
                    <div key={stat.name} className="campaign-totals-item">
                        <dt className="campaign-totals-item-title">{stat.name}</dt>
                        <dd className="campaign-totals-item-value">{stat.value}</dd>
                    </div>
                ))}
            </dl>
        </div>
    );
};

export default CampaignTotals;
