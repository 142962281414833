import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ShopLogin from "../components/Shop/ShopLogin";

const ShopLoginPage = () => {
  const navigate = useNavigate();
  const { isSeller, isLoading, seller } = useSelector((state) => state.seller);

  useEffect(() => {
    if (isSeller === true && seller) {
      navigate(`/dashboard/${seller.handle}`);
    }
  }, [isLoading, isSeller, seller, navigate]);

  return (
    <div>
      <ShopLogin />
    </div>
  );
}

export default ShopLoginPage;
