import React from 'react';

const ShopMarker = ({ shop }) => {
  const averageRating = shop.averageRating ? shop.averageRating.toFixed(1) : 'N/A';
  
  // Function to render opening hours
  const renderOpeningHours = () => {
    if (!shop.openingHours || shop.openingHours.length === 0) {
      return <div style={{color: 'white', fontSize: '12px'}}>Opening hours not available</div>;
    }

    return shop.openingHours.map((hour, index) => (
      <div key={index} style={{color: 'white', fontSize: '12px'}}>
        {`${hour.day}: ${hour.openTime} - ${hour.closeTime}`}
      </div>
    ));
  };

  return (
    <a href={`/shop/${shop.handle}`} style={{ textDecoration: 'none', display: 'block' }}>
      <div style={{
        backgroundColor: 'black',
        width: '180px',
        height: 'auto', // Adjusted for variable content height
        padding: '10px',
        borderRadius: '8px',
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.5)',
        textAlign: 'center',
        transition: 'transform 0.3s ease',
      }}>
        {shop.banner && shop.banner.url && (
          <img
            src={shop.banner.url}
            alt={`${shop.name} banner`}
            style={{
              width: '160px', // Adjusted to fit within the container
              borderRadius: '4px',
              margin: '0 auto 5px', // Center the banner and add some space below
            }}
          />
        )}
        <img 
          src={shop.avatar.url} 
          alt={shop.name} 
          style={{
            width: '69px',
            height: '69px',
            borderRadius: '50%',
            border: '2px solid white',
            boxShadow: '0 1px 2px rgba(0, 0, 0, 0.5)',
            marginBottom: '5px',
          }} 
        />
        <p style={{
          color: 'white',
          fontSize: '12px',
          fontWeight: 'bold',
          margin: '0',
        }}>
          {shop.name}
        </p>
        <div style={{ color: 'white', fontSize: '12px', marginTop: '5px' }}>
          {`Rating: ${averageRating}/5`}
        </div>
        <div style={{ marginTop: '10px' }}>
          {renderOpeningHours()}
        </div>
      </div>
    </a>
  );
};

export default ShopMarker;





