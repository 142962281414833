import { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon, ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline';
import { useSelector, useDispatch } from 'react-redux';
import { setWalletSidebarOpen } from '../state/uiSlice';
import CryptoWalletBuySidebar from './CryptoWalletBuy';
import CryptoWalletSellSidebar from './CryptoWalletSell';
import CryptoWalletPaySidebar from './CryptoWalletPay';

export default function CryptoWalletSidebar() {
  const isWalletSidebarOpen = useSelector((state) => state.ui.isWalletSidebarOpen);
  const walletSidebarType = useSelector((state) => state.ui.walletSidebarType);
  const dispatch = useDispatch();

  const userProfile = useSelector((state) => state.user.user);
  const profileName = userProfile?.name || 'David Harrison';
  const avatarUrl = userProfile?.avatar?.url || 'https://res.cloudinary.com/dzlopmfj8/image/upload/v1722588106/posts/wjqwxnil1hnh1ofqcfy5.webp';

  const handleClose = () => {
    dispatch(setWalletSidebarOpen({ isOpen: false, type: null }));
  };

  const handleOpenWalletPanel = () => {
    dispatch(setWalletSidebarOpen({ isOpen: true, type: null }));
  };

  const handleOpenBuySidebar = () => {
    dispatch(setWalletSidebarOpen({ isOpen: true, type: 'buy' }));
  };

  const handleOpenSellSidebar = () => {
    dispatch(setWalletSidebarOpen({ isOpen: true, type: 'sell' }));
  };
  const handleOpenPaySidebar = () => {
    dispatch(setWalletSidebarOpen({ isOpen: true, type: 'pay' }));
};

  return (
    <Transition.Root show={isWalletSidebarOpen} as={Fragment}>
      <Dialog as="div" className="crypto-wallet-sidebar-dialog" onClose={handleClose}>
        <div className="crypto-wallet-sidebar-overlay" />

        <div className="crypto-wallet-sidebar-container">
          <Transition.Child
            as={Fragment}
            enter="crypto-wallet-sidebar-enter"
            enterFrom="crypto-wallet-sidebar-enterFrom"
            enterTo="crypto-wallet-sidebar-enterTo"
            leave="crypto-wallet-sidebar-leave"
            leaveFrom="crypto-wallet-sidebar-leaveFrom"
            leaveTo="crypto-wallet-sidebar-leaveTo"
          >
            <Dialog.Panel className="crypto-wallet-sidebar-panel">
              <div className="crypto-wallet-sidebar-inner">
                
                {/* Cover Image with Avatar and Titles */}
                <div className="crypto-wallet-cover-container">
                  <img
                    src="https://res.cloudinary.com/dzlopmfj8/image/upload/v1722588106/posts/wjqwxnil1hnh1ofqcfy5.webp"
                    alt="Cover"
                    className="crypto-wallet-cover-image"
                  />
                  <div className="crypto-wallet-avatar-container">
                    <img
                      src={avatarUrl || 'default-avatar-url'}
                      alt={`${profileName} Avatar`}
                      className="crypto-wallet-avatar"
                    />
                  </div>
                  <div className="crypto-wallet-avatar-info">
                    <h2 className="crypto-wallet-title">{profileName}</h2>
                    <p className="crypto-wallet-subtitle">User</p>
                  </div>
                </div>

                {/* Buttons Section */}
                <div className="crypto-wallet-buttons-container">
                  <ChevronLeftIcon className="crypto-wallet-chevron-icon" aria-hidden="true" />
                  <div className="crypto-wallet-button-wrapper">
                    <button className="crypto-wallet-button wallet crypto-wallet-action-button-active" onClick={handleOpenWalletPanel}>
                      <img
                        src="https://res.cloudinary.com/dzlopmfj8/image/upload/v1723455786/wallet_logog_npmln6.png"
                        alt="Wallet"
                        className="crypto-wallet-button-icon"
                      />
                    </button>
                    <span className="crypto-wallet-button-title">Wallet</span>
                  </div>
                  <div className="crypto-wallet-button-wrapper">
                    <button className="crypto-wallet-button buy" onClick={handleOpenBuySidebar}>
                      <img
                        src="https://res.cloudinary.com/dzlopmfj8/image/upload/v1723455786/buy_logo_nlfx4t.png"
                        alt="Buy"
                        className="crypto-wallet-button-icon"
                      />
                    </button>
                    <span className="crypto-wallet-button-title">Buy</span>
                  </div>
                  <div className="crypto-wallet-button-wrapper">
                    <button className="crypto-wallet-button sell" onClick={handleOpenSellSidebar}>
                      <img
                        src="https://res.cloudinary.com/dzlopmfj8/image/upload/v1723455786/sell_logo_nb404k.png"
                        alt="Sell"
                        className="crypto-wallet-button-icon"
                      />
                    </button>
                    <span className="crypto-wallet-button-title">Sell</span>
                  </div>
                  <div className="crypto-wallet-button-wrapper">
                    <button className="crypto-wallet-button pay" onClick={handleOpenPaySidebar}>
                      <img
                        src="https://res.cloudinary.com/dzlopmfj8/image/upload/v1723455786/Pay_logo_a74bco.png"
                        alt="Pay"
                        className="crypto-wallet-button-icon"
                      />
                    </button>
                    <span className="crypto-wallet-button-title">Pay</span>
                  </div>
                  <div className="crypto-wallet-button-wrapper">
                    <button className="crypto-wallet-button trade">
                      <img
                        src="https://res.cloudinary.com/dzlopmfj8/image/upload/v1723455786/trade_logo_zfh39o.png"
                        alt="Trade"
                        className="crypto-wallet-button-icon"
                      />
                    </button>
                    <span className="crypto-wallet-button-title">Trade</span>
                  </div>
                  <ChevronRightIcon className="crypto-wallet-chevron-icon" aria-hidden="true" />
                </div>

                {/* Wallet Section */}
                <div className="crypto-wallet-section">
                  <div className="crypto-wallet-section-title">
                    <div className="crypto-wallet-section-icon">
                      <img src="https://res.cloudinary.com/dzlopmfj8/image/upload/v1723455786/wallet_logog_npmln6.png" alt="Wallet Icon" className="crypto-wallet-section-icon-img" />
                    </div>
                    <span className="crypto-wallet-section-text">WALLET</span>
                  </div>
                  
                  {/* Balance Container */}
                  <div className="crypto-wallet-balance-container">
                  <div className="crypto-wallet-balance-inner-container">
                    <div className="crypto-wallet-balance-item">
                      <span className="crypto-wallet-balance-title">Game Tokens</span>
                      <span className="crypto-wallet-balance-value">10,442,100</span>
                    </div>
                    <div className="crypto-wallet-balance-item">
                      <span className="crypto-wallet-balance-title">Available</span>
                      <span className="crypto-wallet-balance-value">10,442,100</span>
                    </div>
                    <div className="crypto-wallet-balance-item">
                      <span className="crypto-wallet-balance-title">Pending</span>
                      <span className="crypto-wallet-balance-value">0</span>
                    </div>
                    <div className="crypto-wallet-balance-item">
                      <span className="crypto-wallet-balance-title">Value</span>
                      <span className="crypto-wallet-balance-value">$10,442,100</span>
                    </div>
                    </div>
                  </div>
                </div>

                {/* Pay Portal Section */}
                <div className="crypto-wallet-section">
                <div className="crypto-wallet-section-title">
                    <span className="crypto-wallet-section-text">PAY PORTAL</span>
                </div>
                <div className="crypto-wallet-pay-portal-container">
                    <ChevronLeftIcon className="crypto-wallet-chevron-icon" aria-hidden="true" />
                    <div className="crypto-wallet-pay-portal">
                    <button className="crypto-wallet-pay-portal-button ap">AP</button>
                    <button className="crypto-wallet-pay-portal-button ch">Ch</button>
                    <button className="crypto-wallet-pay-portal-button ca">CA</button>
                    <button className="crypto-wallet-pay-portal-button pp">PP</button>
                    <button className="crypto-wallet-pay-portal-button x">X</button>
                    <button className="crypto-wallet-pay-portal-button boa">BoA</button>
                    </div>
                    <ChevronRightIcon className="crypto-wallet-chevron-icon" aria-hidden="true" />
                </div>
                </div>


               {/* Dashboard Section */}
                <div className="crypto-wallet-section">
                <div className="crypto-wallet-section-title">
                    <span className="crypto-wallet-section-text">DASHBOARD</span>
                </div>
                <div className="crypto-wallet-dashboard-container">
                    <div className="crypto-wallet-dashboard-item">
                    <span className="crypto-wallet-dashboard-sub-title">REWARDS</span>
                    <img src="https://res.cloudinary.com/dzlopmfj8/image/upload/v1723460741/rewards_logo_mqgfa8.png" alt="Rewards" className="crypto-wallet-dashboard-image rewards-image" />
                    <hr className="crypto-wallet-divider" />
                    <span className="crypto-wallet-dashboard-value">
                        <span className="crypto-wallet-dashboard-value-bold-part">YOU EARNED...</span> 1500 TOKENS
                    </span>
                    </div>
                    <div className="crypto-wallet-dashboard-item">
                    <span className="crypto-wallet-dashboard-sub-title">CONVERT</span>
                    <img src="https://res.cloudinary.com/dzlopmfj8/image/upload/v1723460874/convert_full_logo_fyn8w6.png" alt="Convert" className="crypto-wallet-dashboard-image convert-image" />
                    <hr className="crypto-wallet-divider" />
                    <span className="crypto-wallet-dashboard-value">
                         USD 2000 
                    </span>
                    <span className="crypto-wallet-dashboard-value">
                         TOKENS 1500
                    </span>
                    </div>
                    <div className="crypto-wallet-dashboard-item">
                    <span className="crypto-wallet-dashboard-sub-title">TRADING</span>
                    <img src="https://res.cloudinary.com/dzlopmfj8/image/upload/v1723460759/trading_logo_y4x06b.png" alt="Trading" className="crypto-wallet-dashboard-image trading-image" />
                    <hr className="crypto-wallet-divider" />
                    <span className="crypto-wallet-dashboard-value">
                        <span className="crypto-wallet-dashboard-value-bold-part">INVESTMENTS</span> MINING: 010,442,100
                    </span>
                    </div>
                </div>
                </div>

               {/* Offers Section */}
                <div className="crypto-wallet-section">
                <div className="crypto-wallet-section-title">
                    <span className="crypto-wallet-section-text">OFFERS</span>
                </div>
                <div className="crypto-wallet-offers-container">
                    <div className="crypto-wallet-offer-item">
                    <span className="crypto-wallet-offer-title">OFFER</span>
                    <hr className="crypto-wallet-offer-divider" />
                    <button className="crypto-wallet-offer-accept-button">ACCEPT</button>
                    </div>
                    <div className="crypto-wallet-offer-item">
                    <span className="crypto-wallet-offer-title">OFFER</span>
                    <hr className="crypto-wallet-offer-divider" />
                    <button className="crypto-wallet-offer-accept-button">ACCEPT</button>
                    </div>
                    <div className="crypto-wallet-offer-item">
                    <span className="crypto-wallet-offer-title">OFFER</span>
                    <hr className="crypto-wallet-offer-divider" />
                    <button className="crypto-wallet-offer-accept-button">ACCEPT</button>
                    </div>
                </div>
                </div>


              </div>

              <>
      {walletSidebarType === 'buy' && <CryptoWalletBuySidebar/>}
      {walletSidebarType === 'sell' && <CryptoWalletSellSidebar />}
      {walletSidebarType === 'pay' && <CryptoWalletPaySidebar />}
    </>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
    
  );
}


