import React, { useState } from 'react';
import MembershipStart from './MembershipStart';
import MembershipPersonalData from './MembershipPersonalData';
import MembershipAgreement from './MembershipAgreement';
import MembershipTOS from './MembershipTOS';
import WaiverOfLiability from './MembershipLiability'; 
import MembershipCheckout from './MembershipCheckout';
import ProgressIndicator from './ProgressIndicator';

const MembershipProcess = () => {
    const [step, setStep] = useState(1);
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        dob: '',
        photo: '',
        signature1: '',
        signature2: '',
        signature3: '',
    });

    const nextStep = () => setStep(step + 1);
    const prevStep = () => setStep(step - 1);

    const handleChange = input => e => {
        const value = e.target ? e.target.value : e;
        setFormData({ ...formData, [input]: value });
    };

    return (
        <div>
            {step === 1 && <MembershipStart nextStep={nextStep} />}
            {step === 2 && (
                <MembershipPersonalData
                    nextStep={nextStep}
                    handleChange={handleChange}
                    formData={formData}
                />
            )}
            {step === 3 && (
                <MembershipAgreement
                    nextStep={nextStep}
                    prevStep={prevStep}
                    handleChange={handleChange}
                    formData={formData}
                />
            )}
            {step === 4 && (
                <MembershipTOS
                    nextStep={nextStep}
                    prevStep={prevStep}
                    handleChange={handleChange}
                    formData={formData}
                />
            )}
            {step === 5 && (
                <WaiverOfLiability
                    nextStep={nextStep}
                    prevStep={prevStep}
                    handleChange={handleChange}
                    formData={formData}
                />
            )}
            {step === 6 && (
                <MembershipCheckout
                    prevStep={prevStep}
                    formData={formData}
                />
            )}
            <ProgressIndicator currentStep={step} totalSteps={6} />
        </div>
    );
};

export default MembershipProcess;



