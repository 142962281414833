import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { server } from '../../server';
import { Line } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import BoostAnimation from './BoostAnimation';
import { FiPlayCircle, FiPauseCircle,  FiZap, FiSlash, FiTrash } from 'react-icons/fi';
import { FiLayers, FiPackage, FiShoppingCart, FiTag } from 'react-icons/fi';
import { FiCalendar, FiClock } from 'react-icons/fi';


ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const DualSponsoredDashboard = () => {
    const [sponsoredAds, setSponsoredAds] = useState([]); 
    const [selectedFilter, setSelectedFilter] = useState('both'); 
    const [selectedAd, setSelectedAd] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [timeResolution, setTimeResolution] = useState('daily');
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [cancelAdId, setCancelAdId] = useState(null);
    const [isBoostModalVisible, setIsBoostModalVisible] = useState(false);
    const [boostAdId, setBoostAdId] = useState(null);
    const [boostAction, setBoostAction] = useState(null);
    const [showBoostAnimation, setShowBoostAnimation] = useState(false);



    const [chartData, setChartData] = useState({
        labels: [],
        datasets: [
            {
                label: 'Clicks',
                data: [],
                borderColor: 'rgb(75, 192, 192)',
                backgroundColor: 'rgba(75, 192, 192, 0.5)',
            },
            {
                label: 'Impressions',
                data: [],
                borderColor: 'rgb(53, 162, 235)',
                backgroundColor: 'rgba(53, 162, 235, 0.5)',
            },
            {
                label: 'Viewed Impressions',
                data: [],
                borderColor: 'rgb(255, 159, 64)', 
                backgroundColor: 'rgba(255, 159, 64, 0.5)', 
            },
        ],
    });

    useEffect(() => {
        const fetchSponsoredAds = async () => {
            setIsLoading(true);
            try {
                const token = localStorage.getItem('token');
                const response = await axios.get(`${server}/sponsored/sponsored-ads`, {
                    withCredentials: true,
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'current-profile': sessionStorage.getItem('currentProfile') || 'defaultProfile',
                    },
                });
                const sortedAds = response.data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)); 
                setSponsoredAds(sortedAds);
                processChartData(sortedAds, selectedFilter);
            } catch (error) {
                console.error('Error fetching sponsored ads:', error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchSponsoredAds();
    }, []);

    useEffect(() => {
        processChartData(sponsoredAds, selectedFilter);
    }, [sponsoredAds, selectedFilter, timeResolution, selectedAd]); 

    const processChartData = (ads, filter) => {
        const adsToProcess = selectedAd ? [selectedAd] : ads.filter(ad => {
            if (filter === 'both') return true;
            if (filter === 'product') return ad.product;
            if (filter === 'shop') return ad.shop && !ad.product && !ad.brand;
            if (filter === 'brand') return ad.brand;
            return false;
        });
    
        const aggregationMap = {};
    
        adsToProcess.forEach(ad => {
            // Process clicks
            if (ad.clickTimestamps && Array.isArray(ad.clickTimestamps)) {
                ad.clickTimestamps.forEach(timestamp => {
                    const date = new Date(timestamp);
                    const key = timeResolution === 'daily'
                        ? date.toISOString().split('T')[0]  // For daily, use date only
                        : `${date.toISOString().split('T')[0]} ${date.getHours()}:00`;  // For hourly, use date and hour
    
                    if (!aggregationMap[key]) {
                        aggregationMap[key] = { clicks: 0, impressions: 0, viewedImpressions: 0 };
                    }
                    aggregationMap[key].clicks += 1;
                });
            }
    
            // Process impressions
            if (ad.impressionTimestamps && Array.isArray(ad.impressionTimestamps)) {
                ad.impressionTimestamps.forEach(timestamp => {
                    const date = new Date(timestamp);
                    const key = timeResolution === 'daily'
                        ? date.toISOString().split('T')[0] 
                        : `${date.toISOString().split('T')[0]} ${date.getHours()}:00`;
    
                    if (!aggregationMap[key]) {
                        aggregationMap[key] = { clicks: 0, impressions: 0, viewedImpressions: 0 };
                    }
                    aggregationMap[key].impressions += 1;
                });
            }
    
            // Process viewed impressions
            if (ad.viewedImpressionTimestamps && Array.isArray(ad.viewedImpressionTimestamps)) {
                ad.viewedImpressionTimestamps.forEach(timestamp => {
                    const date = new Date(timestamp);
                    const key = timeResolution === 'daily'
                        ? date.toISOString().split('T')[0] 
                        : `${date.toISOString().split('T')[0]} ${date.getHours()}:00`;
    
                    if (!aggregationMap[key]) {
                        aggregationMap[key] = { clicks: 0, impressions: 0, viewedImpressions: 0 };
                    }
                    aggregationMap[key].viewedImpressions += 1;
                });
            }
        });
    
        // Sort the labels by actual date and hour
        const labels = Object.keys(aggregationMap).sort((a, b) => new Date(a) - new Date(b));
        const clicksData = labels.map(label => aggregationMap[label].clicks);
        const impressionsData = labels.map(label => aggregationMap[label].impressions);
        const viewedImpressionsData = labels.map(label => aggregationMap[label].viewedImpressions);
    
        // Update the chart data state
        setChartData({
            labels,
            datasets: [
                {
                    label: 'Clicks',
                    data: clicksData,
                    borderColor: 'rgb(75, 192, 192)',
                    backgroundColor: 'rgba(75, 192, 192, 0.5)',
                },
                {
                    label: 'Impressions',
                    data: impressionsData,
                    borderColor: 'rgb(53, 162, 235)',
                    backgroundColor: 'rgba(53, 162, 235, 0.5)',
                },
                {
                    label: 'Viewed Impressions',
                    data: viewedImpressionsData,
                    borderColor: 'rgb(255, 159, 64)',
                    backgroundColor: 'rgba(255, 159, 64, 0.5)',
                },
            ],
        });
    };
    
    
    

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        interaction: {
            mode: 'index',
            intersect: false,
        },
        plugins: {
            title: {
                display: true,
                text: selectedAd ? `${selectedAd.product ? 'Product' : 'Shop'}: ${selectedAd.product ? selectedAd.product.name : selectedAd.shop.name}` : 'Sponsored Ads Performance',
                font: {
                    size: 20,
                },
                color: '#fff',
            },
            tooltip: {
                callbacks: {
                    label: (context) => `${context.dataset.label}: ${context.parsed.y}`,
                },
            },
            legend: {
                labels: {
                    color: '#fff', 
                },
            },
        },
        
        scales: {
            x: {
                title: {
                    display: true,
                    text: 'Date',
                    color: '#fff',
                },
                ticks: {
                    color: '#fff', 
                },
            },
            y: {
                title: {
                    display: true,
                    text: 'Count',
                    color: '#fff',
                },
                ticks: {
                    color: '#fff', 
                },
                beginAtZero: true,
            },
        },
        elements: {
            line: {
                tension: 0.4, 
            },
            point: {
                hoverRadius: 7,
                radius: 5,
            },
        },
    };

    const toggleAdActiveStatus = async (adId, isActive) => {
        try {
            await axios.post(`${server}/sponsored/toggle-status`, { adId, isActive }, {
                headers: {'current-profile': sessionStorage.getItem('currentProfile') || 'defaultProfile', },
                withCredentials: true
            });
            setSponsoredAds(prevAds =>
                prevAds.map(ad => ad._id === adId ? { ...ad, isActive: !ad.isActive } : ad)
            );
        } catch (error) {
            console.error('Error toggling ad status:', error);
        }
    };
    
    
    const handleFilterChange = (newFilter) => {
        setSelectedFilter(newFilter);
    };

    const handleAdSelection = (ad) => {
        setSelectedAd(ad);
    };

    const showCancelModal = (adId) => {
        setCancelAdId(adId);
        setIsModalVisible(true);
    };

    const cancelAd = async (adId) => {
        try {
            await axios.post(`${server}/sponsored/sponsored-products/cancel`, { adId }, {
                withCredentials: true,
                headers: {
                    'current-profile': sessionStorage.getItem('currentProfile') || 'defaultProfile',
                }
            });
            setSponsoredAds(prevAds => prevAds.filter(ad => ad._id !== adId));
            setIsModalVisible(false);
            console.log('Ad cancelled successfully');
        } catch (error) {
            console.error('Error cancelling ad:', error);
        }
    };


    const activateAdBoost = async (adId) => {
        try {
            const token = localStorage.getItem('token');
            await axios.post(`${server}/sponsored/boost-ad/${adId}`, {}, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'current-profile': sessionStorage.getItem('currentProfile') || 'defaultProfile',
                },
                withCredentials: true
            });
            setSponsoredAds(prevAds =>
                prevAds.map(ad => ad._id === adId ? { ...ad, boostActive: true, boostStartTime: new Date() } : ad)
            );
            console.log('Boost activated successfully');
            setShowBoostAnimation(true);  
            setTimeout(() => {
                setShowBoostAnimation(false);  
            }, 4200);
        } catch (error) {
            console.error('Error activating boost:', error);
        }
    };
    
    
    const deactivateAdBoost = async (adId) => {
        try {
            const token = localStorage.getItem('token');
            await axios.post(`${server}/sponsored/deboost-ad/${adId}`, {}, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'current-profile': sessionStorage.getItem('currentProfile') || 'defaultProfile',
                },
                withCredentials: true
            });
            setSponsoredAds(prevAds =>
                prevAds.map(ad => ad._id === adId ? { ...ad, boostActive: false, boostStartTime: null } : ad)
            );
            console.log('Boost deactivated successfully');
        } catch (error) {
            console.error('Error deactivating boost:', error);
        }
    };

    const showBoostConfirmation = (adId, action) => {
        setBoostAdId(adId);
        setBoostAction(action);
        setIsBoostModalVisible(true);
    };
    
    const handleBoostConfirmation = async () => {
        if (boostAction === 'activate') {
            await activateAdBoost(boostAdId);
        } else {
            await deactivateAdBoost(boostAdId);
        }
        setIsBoostModalVisible(false);
    };
    
    
    

    return (
        <div className="sponsored-dashboard">
            
            {isLoading ? (
                <p>Loading...</p>
            ) : (
                <>
                {showBoostAnimation && <BoostAnimation />}
                <div className="sponsored-dashboard-container">
                <div className="sponsored-dashboard-filter-time-row">
                <div className="sponsored-dashboard-buttons-row">

                <div className="sponsored-dashboard-filter-row">
                    <div
                        className={`sponsored-dashboard-filter-item ${selectedFilter === 'both' ? 'active' : ''}`}
                        onClick={() => handleFilterChange('both')}
                    >
                        <FiLayers className="sponsored-dashboard-filter-icon" />
                        <span>All</span>
                    </div>
                    <div
                        className={`sponsored-dashboard-filter-item ${selectedFilter === 'product' ? 'active' : ''}`}
                        onClick={() => handleFilterChange('product')}
                    >
                        <FiPackage className="sponsored-dashboard-filter-icon" />
                        <span>Products</span>
                    </div>
                    <div
                        className={`sponsored-dashboard-filter-item ${selectedFilter === 'shop' ? 'active' : ''}`}
                        onClick={() => handleFilterChange('shop')}
                    >
                        <FiShoppingCart className="sponsored-dashboard-filter-icon" />
                        <span>Shops</span>
                    </div>
                    <div
                        className={`sponsored-dashboard-filter-item ${selectedFilter === 'brand' ? 'active' : ''}`}
                        onClick={() => handleFilterChange('brand')}
                    >
                        <FiTag className="sponsored-dashboard-filter-icon" />
                        <span>Brands</span>
                    </div>
                    </div>

                </div>
                <div className="sponsored-dashboard-time-buttons-row">
                    <div
                        className={`sponsored-dashboard-time-filter-item ${timeResolution === 'daily' ? 'active' : ''}`}
                        onClick={() => setTimeResolution('daily')}
                    >
                        <FiCalendar className="sponsored-dashboard-time-filter-icon" />
                        <span>Daily View</span>
                    </div>
                    <div
                        className={`sponsored-dashboard-time-filter-item ${timeResolution === 'hourly' ? 'active' : ''}`}
                        onClick={() => setTimeResolution('hourly')}
                    >
                        <FiClock className="sponsored-dashboard-time-filter-icon" />
                        <span>Hourly View</span>
                    </div>
                    </div>
                    </div>
                    </div>
                    <div style={{ height: '500px', width: '100%' }}>
                        <Line data={chartData} options={options} />
                    </div>
                    {/* Updated Table Section */}
                    <table className="sponsored-table">
                        <thead>
                            <tr>
                                <th>Type</th>
                                <th>Name</th>
                                <th>Image</th>
                                <th>Total Ad Spend</th>
                                <th>Ad Duration (days)</th>
                                <th>Spent So Far</th>
                                <th>AD Status</th>
                                <th>Click Count</th>
                                <th>Impressions</th>
                                <th>Cost Per Click (CPC)</th>
                                <th>Cost Per Impression (CPI)</th>
                                <th>Toggle Status</th>
                                <th>Boost</th>
                                <th>Cancel</th>
                            </tr>
                        </thead>
                        <tbody>
                            {sponsoredAds.filter(ad => {
                                if (selectedFilter === 'both') return true;
                                if (selectedFilter === 'product') return ad.product;
                                if (selectedFilter === 'shop') return ad.shop && !ad.product && !ad.brand;;
                                if (selectedFilter === 'brand') return ad.brand;
                                return false;
                            }).map(ad => {
                                const costPerClick = ad.clicks > 0 ? (ad.spentSoFar / ad.clicks).toFixed(2) : 0;
                                const costPerImpression = ad.impressions > 0 ? (ad.spentSoFar / ad.impressions).toFixed(2) : 0;
                                return (
                                    <tr key={ad._id} onClick={() => handleAdSelection(ad)}>
                                        <td>{ad.product ? 'Product' : ad.brand ? 'Brand' : ad.shop ? 'Shop' : 'Unknown'}</td>
                                        <td>{ad.product ? ad.product.name : ad.brand ? ad.brand.name : ad.shop ? ad.shop.name : 'N/A'}</td>
                                        <td>
                                            {ad.product && ad.product.images && ad.product.images[0] ? (
                                                <img src={ad.product.images[0].url} alt="product" style={{ width: '50px', height: '50px', objectFit: 'cover', borderRadius: '5px' }} />
                                            ) : ad.shop ? (
                                                <img src={ad.shop.avatar.url} alt="shop" style={{ width: '50px', height: '50px', objectFit: 'cover', borderRadius: '5px' }} />
                                            ) : ad.brand && (
                                                <img src={ad.brand.avatarImage.url} alt="brand" style={{ width: '50px', height: '50px', objectFit: 'cover', borderRadius: '5px' }} />
                                            )}
                                        </td>
                                        <td>${ad.adSpend.toFixed(2)}</td>
                                        <td>{ad.adDuration}</td>
                                        <td>${ad.spentSoFar.toFixed(2)}</td>
                                        <td>{ad.status}</td>
                                        <td>{ad.clicks}</td>
                                        <td>{ad.impressions}</td>
                                        <td>${costPerClick}</td>
                                        <td>${costPerImpression}</td>
                                        <td>
                                        <button 
                                            onClick={() => ad.status === 'active' || ad.status === 'paused' ? toggleAdActiveStatus(ad._id, ad.status !== 'active') : null}
                                            style={{
                                                backgroundColor: 'transparent',
                                                color: ad.status === 'active' ? '#f44336' : ad.status === 'paused' ? '#4CAF50' : '#9E9E9E',
                                                border: 'none', 
                                                padding: '10px',
                                                cursor: ad.status === 'active' || ad.status === 'paused' ? 'pointer' : 'not-allowed',
                                                fontSize: '24px',
                                            }}
                                            disabled={ad.status !== 'active' && ad.status !== 'paused'} 
                                        >
                                            {ad.status === 'paused' ? <FiPlayCircle /> : ad.status === 'active' ? <FiPauseCircle /> : null}
                                        </button>
                                    </td>

                                    <td>
                                        <button 
                                            onClick={(e) => {
                                                e.stopPropagation(); 
                                                showBoostConfirmation(ad._id, ad.boostActive ? 'deactivate' : 'activate');
                                            }}
                                            style={{
                                                backgroundColor: 'transparent',
                                                color: ad.boostActive ? '#f44336' : '#4CAF50',
                                                border: 'none',
                                                padding: '10px',
                                                cursor: 'pointer',
                                                fontSize: '24px',
                                            }}
                                        >
                                            {ad.boostActive ? <FiSlash /> : <FiZap />}
                                        </button>
                                    </td>
                                    <td>
                                        <Button
                                            variant="contained"
                                            color="warning"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                showCancelModal(ad._id);
                                            }}
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                padding: '10px',
                                                minWidth: 'auto', 
                                            }}
                                        >
                                            <FiTrash size={20} />
                                        </Button>
                                    </td>

                                    <td>
                                    <Dialog open={isModalVisible} onClose={() => setIsModalVisible(false)} 
                                    PaperProps={{
                                        style: {
                                            backgroundColor: '#333',  
                                            color: 'white'           
                                        }
                                    }}>
                                        <DialogTitle>{"Cancel Ad"}</DialogTitle>
                                        <DialogContent>
                                            <DialogContentText>
                                                Are you sure you want to cancel this ad campaign?
                                            </DialogContentText>
                                        </DialogContent>
                                        <DialogActions>
                                            <Button onClick={() => setIsModalVisible(false)}>No</Button>
                                            <Button variant="contained" color="warning" onClick={(e) => {
                                                e.stopPropagation();  
                                                cancelAd(ad._id); 
                                            }}>
                                                Cancel
                                            </Button>
                                        </DialogActions>
                                    </Dialog>
                                    </td>
                                    <td>
                                    <Dialog
                                        open={isBoostModalVisible}
                                        onClose={() => setIsBoostModalVisible(false)}
                                        aria-labelledby="alert-dialog-title"
                                        aria-describedby="alert-dialog-description"
                                        PaperProps={{
                                            style: {
                                                backgroundColor: '#333',  
                                                color: 'white'            
                                            }
                                        }}
                                    >
                                        <DialogTitle id="alert-dialog-title">{"Confirm Boost Action"}</DialogTitle>
                                        <DialogContent>
                                            <DialogContentText id="alert-dialog-description">
                                                Are you sure you want to {boostAction === 'activate' ? 'activate' : 'deactivate'} the boost for this ad?
                                            </DialogContentText>
                                        </DialogContent>
                                        <DialogActions>
                                            <Button onClick={() => setIsBoostModalVisible(false)} color="primary">
                                                No
                                            </Button>
                                            <Button onClick={handleBoostConfirmation} color="primary" autoFocus>
                                                Yes
                                            </Button>
                                        </DialogActions>
                                    </Dialog>

                                    </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                
                </>
            )}
        </div>
    );
    };
    
    export default DualSponsoredDashboard;
    

