import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { toast } from 'react-toastify';
import { loadStripe } from '@stripe/stripe-js';
import { EmbeddedCheckoutProvider, EmbeddedCheckout } from '@stripe/react-stripe-js';
import { server } from "../../server";
import styles from "../../styles/styles";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const ShopSubscriptionCheckout = () => {
    const navigate = useNavigate();
    const { seller } = useSelector(state => state.seller);
    const { priceId } = useParams();
    const [isLoading, setIsLoading] = useState(false);
    const [email, setEmail] = useState(seller?.email || '');
    const [shopName, setShopName] = useState(seller?.name || '');
    const [priceDetails, setPriceDetails] = useState(null);
    const [clientSecret, setClientSecret] = useState('');

    useEffect(() => {
        const fetchPriceDetails = async () => {
            try {
                const response = await axios.get(`${server}/subscriptions/price-details/${priceId}`);
                setPriceDetails(response.data);
            } catch (error) {
                console.error('Failed to fetch price details', error);
                toast.error('Failed to load pricing information.');
            }
        };

        fetchPriceDetails();
    }, [priceId]);

    const handlePaymentSubmission = async (event) => {
        event.preventDefault();
        setIsLoading(true);

        try {
            const { data } = await axios.post(`${server}/payment/create-shop-subscription-checkout-session`, {
                priceId
            }, {
                withCredentials: true,
                headers: {
                    "Content-Type": "application/json",
                    'current-profile': sessionStorage.getItem('currentProfile') || 'defaultProfile',
                }
            });

            setClientSecret(data.clientSecret);
        } catch (error) {
            console.error('Checkout session creation failed:', error);
            toast.error('Failed to create checkout session. Please try again.');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 max-w-4xl mx-auto">
            <div className="order-2 lg:order-1 bg-black">
                <form onSubmit={handlePaymentSubmission} className={styles.form}>
                    <label className="block pb-2">Shop Name</label>
                    <input type="text" className={styles.input} placeholder="Shop Name" value={shopName} onChange={e => setShopName(e.target.value)} required />
                    <label className="block pb-2">Email Address</label>
                    <input type="email" className={styles.input} placeholder="Email Address" value={email} onChange={e => setEmail(e.target.value)} required />
                    <button type="submit" className={styles.button} disabled={isLoading}>
                        {isLoading ? 'Processing...' : 'Confirm Subscription'}
                    </button>
                </form>
            </div>
            <div className="order-1 lg:order-2">
                {priceDetails && (
                    <div className="subscription-details">
                        <h2>{`Upgrade your shop with the ${priceDetails.product.name} subscription`}</h2>
                        <p>{priceDetails.product.description}</p>
                        <p>Price: ${(priceDetails.unit_amount / 100).toFixed(2)} {priceDetails.currency.toUpperCase()}</p>
                    </div>
                )}
            </div>
            {clientSecret && (
                <EmbeddedCheckoutProvider stripe={stripePromise} options={{ clientSecret }}>
                    <EmbeddedCheckout />
                </EmbeddedCheckoutProvider>
            )}
        </div>
    );
};

export default ShopSubscriptionCheckout;

