import { createReducer } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  notifications: [],
  unseenCount: 0, // Added to track the count of unseen notifications
  error: null,
};

export const notificationsReducer = createReducer(initialState, {
  LoadNotificationsRequest: (state) => {
    state.loading = true;
    state.error = null; 
  },
  LoadNotificationsSuccess: (state, action) => {
    state.loading = false;
    state.notifications = action.payload.notifications; // Updated to handle the notifications array
    state.unseenCount = action.payload.unseenCount; // Updated to handle the unseen count
  },
  LoadNotificationsFail: (state, action) => {
    state.loading = false;
    state.error = action.payload;
  },
  ClearNotificationsError: (state) => {
    state.error = null;
  },
  ResetUnseenNotifications: (state) => {
    state.unseenCount = 0;
  },
});


  