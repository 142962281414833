import { configureStore } from "@reduxjs/toolkit";
import { userReducer } from "./reducers/user";
import { sellerReducer } from "./reducers/seller";
import { productReducer } from "./reducers/product";
import { eventReducer } from "./reducers/event";
import { cartReducer } from "./reducers/cart";
import { wishlistReducer } from "./reducers/wishlist";
import { orderReducer } from "./reducers/order";
import  uiReducer  from '../state/uiSlice';
import { friendsReducer } from "./reducers/friends";
import { notificationsReducer } from "./reducers/notifications";
import { postsReducer } from "./reducers/post";
import { brandReducer } from './reducers/brand';
import { followersReducer } from "./reducers/followers";
import { hashtagsReducer } from "./reducers/hashtags";
import { connectionsReducer } from "./reducers/connections";



const Store = configureStore({
  reducer: {
    user: userReducer,
    seller: sellerReducer,
    products: productReducer,
    events: eventReducer,
    cart: cartReducer,
    wishlist: wishlistReducer,
    order: orderReducer,
    ui: uiReducer,
    friends: friendsReducer,
    notifications: notificationsReducer,
    posts: postsReducer,
    brand: brandReducer,
    followers: followersReducer,
    hashtags: hashtagsReducer,
    connections: connectionsReducer
    
  },
});

export default Store;
