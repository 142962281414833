import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { server } from "../../server";
import { toast } from 'react-toastify'; 
import ChangeSubscription from './ChangeSubscription';
import { useNavigate } from 'react-router-dom';

const UserSubscriptions = () => {
    const [subscriptions, setSubscriptions] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [selectedSubscription, setSelectedSubscription] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        fetchSubscriptions();
    }, []);

    const fetchSubscriptions = async () => {
        try {
            const response = await axios.get(`${server}/subscriptions/my-subscriptions`, {
                withCredentials: true,
                headers: {
                    "Content-Type": "application/json",
                    'current-profile': sessionStorage.getItem('currentProfile') || 'defaultProfile',
                }
            });
            setSubscriptions(response.data);
            setLoading(false);
        } catch (error) {
            setError('Failed to fetch subscriptions');
            setLoading(false);
            console.error(error);
        }
    };

    const confirmCancelSubscription = (subscriptionId) => {
        setShowModal(true);
        setSelectedSubscription(subscriptionId);
    };

    const cancelSubscription = async () => {
        try {
            await axios.post(`${server}/subscriptions/cancel-subscription`, { subscriptionId: selectedSubscription }, {
                withCredentials: true,
                headers: {
                    "Content-Type": "application/json",
                    'current-profile': sessionStorage.getItem('currentProfile') || 'defaultProfile',
                }
            });
            fetchSubscriptions(); 
            setShowModal(false);
            toast.success('Subscription cancelled successfully!');
        } catch (error) {
            console.error('Failed to cancel subscription', error);
            toast.error('Failed to cancel subscription');
            setShowModal(false);
        }
    };

    if (loading) return <div className="user-subscriptions-loading">Loading...</div>;
    if (error) return <div className="user-subscriptions-error">Error: {error}</div>;

    return (
        <div className="user-subscriptions-overview">
            <h2 className="user-subscriptions-title">My Subscriptions</h2>
            {showModal && (
                <div className="user-subscriptions-modal-overlay">
                    <div className="user-subscriptions-modal">
                        <h4>Are you sure you want to cancel this subscription?</h4>
                        <button onClick={cancelSubscription} className="user-subscriptions-confirm-btn">Yes</button>
                        <button onClick={() => setShowModal(false)} className="user-subscriptions-cancel-btn">No</button>
                    </div>
                </div>
            )}

            {subscriptions.length > 0 ? (
                <ul className="user-subscriptions-list">
                    {subscriptions.map(subscription => (
                        <li key={subscription._id} className={`user-subscriptions-item ${subscription.status === 'active' ? 'user-subscriptions-item-active' : ''}`}>
                            <p className="user-subscriptions-detail">Tier: {subscription.tier}</p>
                            <p className="user-subscriptions-detail">Status: {subscription.status}</p>
                            <p className="user-subscriptions-detail">Next Billing Date: {new Date(subscription.currentPeriodEnd).toLocaleDateString()}</p>
                            {subscription.status === 'active' && (
                                <div className="user-subscriptions-controls">
                                    <ChangeSubscription currentSubscriptionId={subscription._id} />
                                    <button onClick={() => confirmCancelSubscription(subscription._id)} className="user-subscriptions-cancel-btn">
                                        Cancel Subscription
                                    </button>
                                </div>
                            )}
                        </li>
                    ))}
                </ul>
            ) : (
                <div className="user-subscriptions-empty">
                    <p>No subscriptions found.</p>
                    <button onClick={() => navigate('/subscriptions')} className="user-subscriptions-view-plans-btn">View Subscription Plans</button>
                </div>
            )}
        </div>
    );
};

export default UserSubscriptions;



