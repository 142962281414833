import React, { useState, useEffect } from 'react';
import { FaMapMarkerAlt, FaClock, FaCalendarAlt } from 'react-icons/fa';
import axios from 'axios';
import { server } from "../../server";
import GoogleCalendarButton from './GoogleCalendarButton';
import OutlookCalendarButton from './OutlookCalendarButton';
import DownloadICSButton from './DownloadICSButton'; 
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const EventCard = ({ event, onRSVP, onCancelRSVP }) => {
    const [isGoing, setIsGoing] = useState(false); // State to determine if the user is going
    const currentProfileType = useSelector((state) => state.user.currentProfile);
    const navigate = useNavigate();  

    useEffect(() => {
        checkIfGoing();
    }, []);

    const checkIfGoing = async () => {
        try {
            const response = await axios.get(`${server}/event/events/my`, { withCredentials: true });
            const myEvents = response.data;
            const goingToEvent = myEvents.some((myEvent) => myEvent._id === event._id);
            setIsGoing(goingToEvent);
        } catch (error) {
            console.error('Error checking if going to event:', error);
        }
    };

    const goToEventDetail = () => {
        navigate(`/events/${event._id}`); 
    };
    
    const handleRSVP = async () => {
        try {
            const data = {
                currentProfile: currentProfileType, 
            };
            await axios.post(`${server}/event/rsvp/${event._id}`, data, {
                withCredentials: true
            });
            setIsGoing(true);
            onRSVP(event._id);
        } catch (error) {
            console.error('Error RSVPing to event:', error);
            alert('Failed to RSVP: ' + (error.response?.data?.message || 'Error occurred'));
        }
    };

    const handleCancelRSVP = async () => {
        try {
            await axios.delete(`${server}/event/rsvp/${event._id}`, { withCredentials: true });
            setIsGoing(false);
            onCancelRSVP(event._id);
        } catch (error) {
            console.error('Failed to cancel RSVP:', error);
            alert('Failed to cancel RSVP: ' + (error.response?.data?.message || 'Error occurred'));
        }
    };

    const eventDate = new Date(event.date);
    const formattedDate = eventDate.toLocaleDateString();
    const formattedTime = eventDate.toLocaleTimeString();

    return (
        <div className="event-card-component-card" onClick={goToEventDetail}>
            <img src={event.image} alt={event.title} className="event-card-component-image" />
            <div className="event-card-component-info">
                <h3 className="event-card-component-title">{event.title}</h3>
                <p className="event-card-component-description">{event.description}</p>
                <div className="event-card-component-datetime">
                    <p className="event-card-component-date">
                        <FaCalendarAlt className="event-card-icon" /> {formattedDate}
                    </p>
                    <p className="event-card-component-time">
                        <FaClock className="event-card-icon" /> {formattedTime}
                    </p>
                </div>
                <p className="event-card-component-location">
                    <FaMapMarkerAlt className="event-card-icon" /> {event.location.address}
                </p>
                <div className="event-card-component-buttons">
                    <button
                        className="event-card-component-button"
                        onClick={(e) => {
                            e.stopPropagation(); 
                            if (!isGoing) {
                                handleRSVP();
                            }
                        }}
                    >
                        {isGoing ? 'Going' : 'Attend'}
                    </button>
                    {isGoing && (
                        <button
                            className="event-card-component-cancel-button"
                            onClick={(e) => {
                                e.stopPropagation(); 
                                handleCancelRSVP();
                            }}
                        >
                            Cancel RSVP
                        </button>
                    )}
                </div>
                <div className="event-card-component-calendar-buttons">
                    <GoogleCalendarButton event={event} />
                    <OutlookCalendarButton event={event} />
                    <DownloadICSButton event={event} /> 
                </div>
            </div>
        </div>
    );
};

export default EventCard;





