import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom"; // Import useParams
import Footer from "../components/Layout/Footer";
import Header from "../components/Layout/Header";
import Loader from "../components/Layout/Loader";
import ProductCard from "../components/Route/ProductCard/ProductCard";
import styles from "../styles/styles";
import Sidebar from '../components/Layout/Sidebar';
import HeroAllProducts from "../components/Route/Hero/HeroAllProducts";
import SuggestedProduct from "../components/Products/SuggestedProduct";
import { Disclosure } from '@headlessui/react';
import { ChevronDownIcon, XMarkIcon } from '@heroicons/react/20/solid'; 
import { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import TrendingProductsCarosel from "../components/Products/TrendingProductsCarosel";

const CategoryProductsPage = () => {
  const { category } = useParams();
  const { allProducts, isLoading } = useSelector((state) => state.products);
  const [data, setData] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState('');
  const [minPrice, setMinPrice] = useState(0);
  const [maxPrice, setMaxPrice] = useState(1000); 
  const [brands, setBrands] = useState([]);
  const [activeFilters, setActiveFilters] = useState([]);
  const [openPriceRange, setOpenPriceRange] = useState(false);
  const [availableForPickup, setAvailableForPickup] = useState(false);
  const [availableForDelivery, setAvailableForDelivery] = useState(false);
  const [minRating, setMinRating] = useState(0); 
  const [sortOption, setSortOption] = useState('');

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
  }

  useEffect(() => {
    if (allProducts) {
      const uniqueBrands = new Set(allProducts.map(product => product.brand));
      setBrands([...uniqueBrands]);
  
      let filteredProducts = [...allProducts];
  
      const newActiveFilters = [];

      // Sort products based on the selected sort option
    if (sortOption === 'priceLowHigh') {
      filteredProducts.sort((a, b) => a.discountPrice - b.discountPrice);
    } 
    if (sortOption === 'priceHighToLow') {
      filteredProducts.sort((a, b) => b.discountPrice - a.discountPrice);
    } 
    if (sortOption === 'newest') {
      filteredProducts.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
    }
      if (category) {
        filteredProducts = filteredProducts.filter(product => product.category === category);
        // Assuming you want to add a category filter to active filters as well
        newActiveFilters.push({ id: 'category', name: 'Category', value: category });
      }
      if (selectedBrand) {
        filteredProducts = filteredProducts.filter(product => product.brand === selectedBrand);
        newActiveFilters.push({ id: 'brand', name: 'Brand', value: selectedBrand });
      }
      // Rating filter
    if (minRating > 0) {
      filteredProducts = filteredProducts.filter(product => product.ratings >= minRating);
      newActiveFilters.push({ id: 'rating', name: 'Rating', value: `${minRating}+ stars` });
    }
      if (availableForPickup) {
        filteredProducts = filteredProducts.filter(product => product.availableForPickup);
        newActiveFilters.push({ id: 'availability', name: 'Availability', value: 'Pickup' });
      } else if (availableForDelivery) {
        filteredProducts = filteredProducts.filter(product => product.availableForDelivery);
        newActiveFilters.push({ id: 'availability', name: 'Availability', value: 'Delivery' });
      }
  
      filteredProducts = filteredProducts.filter(product => product.discountPrice >= minPrice && product.discountPrice <= maxPrice);
      newActiveFilters.push({ id: 'priceRange', name: 'Price Range', value: `$${minPrice} - $${maxPrice}` });
  
      setData(filteredProducts);
      setActiveFilters(newActiveFilters);
    } else {
      setData([]);
      setBrands([]);
    }
  }, [allProducts, sortOption, category, selectedBrand, minPrice, maxPrice, availableForPickup, availableForDelivery, minRating]);
  

  const removeFilter = (filterId) => {
    setActiveFilters(activeFilters.filter(filter => filter.id !== filterId));
    if (filterId === 'brand') {
      setSelectedBrand('');
    } else if (filterId === 'availability') {
      setAvailableForPickup(false);
      setAvailableForDelivery(false);
    } else if (filterId === 'priceRange') {
      setMinPrice(0);
      setMaxPrice(1000);
    } else if (filterId === 'rating') {
      setMinRating(0); 
    }
  };
  

  const renderProductCards = (products) => {
    return products.map((product, index) => <ProductCard data={product} key={index} />);
  };

  const renderActiveFilters = () => {
    return activeFilters.map((filter) => (
      <span key={filter.id} className="category-products-page__active-filter">
        {filter.name}: {filter.value}
        <button onClick={() => removeFilter(filter.id)} className="category-products-page__remove-filter">
          <XMarkIcon className="category-products-page__remove-icon" aria-hidden="true" />
        </button>
      </span>
    ));
  };

  const renderFilters = () => {
    return (
      <div className="category-products-page__filters-container">
        <div className="category-products-page__filters-wrapper">
          <Disclosure as="section" className="category-products-page__disclosure-section">
            {({ open }) => (
              <>
                <h2 id="filter-heading" className="category-products-page__heading">
                  {category ? category : "All Products"}
                </h2>
                <Disclosure.Button className="category-products-page__disclosure-button">
                  <span className="category-products-page__disclosure-title">Filters</span>
                  <ChevronDownIcon className={`category-products-page__chevron ${open ? 'rotate-180' : ''}`} />
                </Disclosure.Button>
                <Disclosure.Panel className="category-products-page__disclosure-panel">
                  <div className="category-products-page__filter-options">
                    <Menu as="div" className="category-products-page__menu">
                      <Menu.Button className="category-products-page__menu-button">
                        {selectedBrand || 'Select Brand'}
                        <ChevronDownIcon className="category-products-page__chevron" />
                      </Menu.Button>
                      <Transition
                        as={Fragment}
                        enter="category-products-page__transition-enter"
                        enterFrom="category-products-page__transition-enter-from"
                        enterTo="category-products-page__transition-enter-to"
                        leave="category-products-page__transition-leave"
                        leaveFrom="category-products-page__transition-leave-from"
                        leaveTo="category-products-page__transition-leave-to"
                      >
                        <Menu.Items className="category-products-page__menu-items">
                          <div className="category-products-page__menu-content">
                            <Menu.Item>
                              {({ active }) => (
                                <button
                                  className={`category-products-page__menu-item ${active ? 'category-products-page__menu-item--active' : ''}`}
                                  onClick={() => setSelectedBrand('')}
                                >
                                  All Brands
                                </button>
                              )}
                            </Menu.Item>
                            {brands.map((brand, index) => (
                              <Menu.Item key={brand}>
                                {({ active }) => (
                                  <button
                                    className={`category-products-page__menu-item ${active ? 'category-products-page__menu-item--active' : ''}`}
                                    onClick={() => setSelectedBrand(brand)}
                                  >
                                    {brand}
                                  </button>
                                )}
                              </Menu.Item>
                            ))}
                          </div>
                        </Menu.Items>
                      </Transition>
                    </Menu>

                    <Menu as="div" className="category-products-page__menu">
                      <Menu.Button className="category-products-page__menu-button">
                        Availability
                        <ChevronDownIcon className="category-products-page__chevron" />
                      </Menu.Button>
                      <Transition
                        as={Fragment}
                        enter="category-products-page__transition-enter"
                        enterFrom="category-products-page__transition-enter-from"
                        enterTo="category-products-page__transition-enter-to"
                        leave="category-products-page__transition-leave"
                        leaveFrom="category-products-page__transition-leave-from"
                        leaveTo="category-products-page__transition-leave-to"
                      >
                        <Menu.Items className="category-products-page__menu-items">
                          <div className="category-products-page__menu-content">
                            <Menu.Item>
                              {({ active }) => (
                                <button
                                  className={`category-products-page__menu-item ${active ? 'category-products-page__menu-item--active' : ''}`}
                                  onClick={() => {
                                    setAvailableForPickup(!availableForPickup);
                                    setAvailableForDelivery(false);
                                  }}
                                >
                                  Pickup
                                </button>
                              )}
                            </Menu.Item>
                            <Menu.Item>
                              {({ active }) => (
                                <button
                                  className={`category-products-page__menu-item ${active ? 'category-products-page__menu-item--active' : ''}`}
                                  onClick={() => {
                                    setAvailableForDelivery(!availableForDelivery);
                                    setAvailableForPickup(false);
                                  }}
                                >
                                  Delivery
                                </button>
                              )}
                            </Menu.Item>
                          </div>
                        </Menu.Items>
                      </Transition>
                    </Menu>

                    <Menu as="div" className="category-products-page__menu">
                      <Menu.Button className="category-products-page__menu-button">
                        Rating: {minRating}+
                        <ChevronDownIcon className="category-products-page__chevron" />
                      </Menu.Button>
                      <Transition
                        as={Fragment}
                        enter="category-products-page__transition-enter"
                        enterFrom="category-products-page__transition-enter-from"
                        enterTo="category-products-page__transition-enter-to"
                        leave="category-products-page__transition-leave"
                        leaveFrom="category-products-page__transition-leave-from"
                        leaveTo="category-products-page__transition-leave-to"
                      >
                        <Menu.Items className="category-products-page__menu-items">
                          <div className="category-products-page__menu-content">
                            {[0, 1, 2, 3, 4, 5].map((rating) => (
                              <Menu.Item key={rating}>
                                {({ active }) => (
                                  <button
                                    className={`category-products-page__menu-item ${active ? 'category-products-page__menu-item--active' : ''}`}
                                    onClick={() => setMinRating(rating)}
                                  >
                                    {rating}+ Stars
                                  </button>
                                )}
                              </Menu.Item>
                            ))}
                          </div>
                        </Menu.Items>
                      </Transition>
                    </Menu>
  
                    <div className="category-products-page__price-range">
                      <button
                        type="button"
                        className="category-products-page__menu-button"
                        onClick={() => setOpenPriceRange(!openPriceRange)}
                      >
                        Price Range
                        <ChevronDownIcon className="category-products-page__chevron" />
                      </button>
                      {openPriceRange && (
                        <div className="category-products-page__price-range-panel">
                          <div className="category-products-page__price-range-content">
                            <label htmlFor="minPrice" className="category-products-page__price-label">
                              Minimum Price
                            </label>
                            <input
                              type="range"
                              id="minPrice"
                              name="minPrice"
                              min="0"
                              max={maxPrice}
                              value={minPrice}
                              onChange={(e) => setMinPrice(Number(e.target.value))}
                              className="category-products-page__price-range-input"
                            />
                            <span className="category-products-page__price-value">Value: ${minPrice}</span>
  
                            <label htmlFor="maxPrice" className="category-products-page__price-label">
                              Maximum Price
                            </label>
                            <input
                              type="range"
                              id="maxPrice"
                              name="maxPrice"
                              min={minPrice}
                              max="1000" // Adjust based on your data
                              value={maxPrice}
                              onChange={(e) => setMaxPrice(Number(e.target.value))}
                              className="category-products-page__price-range-input"
                            />
                            <span className="category-products-page__price-value">Value: ${maxPrice}</span>
                          </div>
                        </div>
                      )}
                    </div>
  
                    <div className="category-products-page__sort-dropdown">
                      <label htmlFor="sortOptions" className="category-products-page__sort-label">
                        Sort by:
                      </label>
                      <div className="category-products-page__sort-wrapper">
                        <select
                          id="sortOptions"
                          name="sortOptions"
                          value={sortOption}
                          onChange={(e) => setSortOption(e.target.value)}
                          className="category-products-page__sort-select"
                        >
                          <option value="">Select</option>
                          <option value="newest">Newest to Oldest</option>
                          <option value="priceHighToLow">Price: High to Low</option>
                          <option value="priceLowToHigh">Price: Low to High</option>
                        </select>
                      </div>
                    </div>
  
                  </div>
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
        </div>
      </div>
    );
  };
  
  return (
    <div className="category-products-page__container">
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <Header activeHeading={3} />
          <Sidebar />
          <HeroAllProducts />
          <div className="category-products-page__content">
            <div className="category-products-page__filters">
              {renderFilters()}
            </div>
          </div>
         <div className="category-products-page__active-filters-bar">
            <div className="category-products-page__active-filters">{renderActiveFilters()}</div>
          </div>
          <div className="category-products-page__products">
            <h2 className="category-products-page__sr-only">Products</h2>
            {data && data.length > 0 ? (
              <div className="category-products-page__product-grid">
                {renderProductCards(data)}
              </div>
            ) : (
              <div className="category-products-page__no-products">
                <p>No products under this category. Check out these instead.</p>
                <TrendingProductsCarosel className="category-products-page__trending-products" />
              </div>
            )}
          </div>
        </>
      )}
      <Footer />
    </div>
  );
};

export default CategoryProductsPage;
