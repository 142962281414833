import { Fragment, useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import NotificationFeed from './NotificationFeed';
import { useSelector, useDispatch } from "react-redux";
import { loadSeller } from "../redux/actions/user";
import { setNotificationSidebarOpen } from "../state/uiSlice";

export default function NotificationSidebar() {
  const isNotificationSidebarOpen = useSelector((state) => state.ui.isNotificationSidebarOpen);
  const currentProfile = useSelector((state) => state.user.currentProfile);
  const { user } = useSelector((state) => state.user);
  const seller = useSelector((state) => state.seller.seller);
  const userId= user?._id;
  const sellerId = seller?._id;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadSeller());
  }, [dispatch]);

  // Function to map currentProfile to "User" or "Shop"
  const getCurrentProfileType = () => {
    return currentProfile === 'User' ? 'User' : 'Shop';
  };

  const currentProfileType = getCurrentProfileType();
  // Determine the profile ID based on the current profile type
  const profileId = currentProfile === 'User' ? userId : sellerId;

  const handleClose = () => {
    dispatch(setNotificationSidebarOpen(false));
  };

  return (
    <Transition.Root show={isNotificationSidebarOpen} as={Fragment}>
      <Dialog as="div" className="notification-sidebar-dialog" onClose={handleClose}>
        <div className="notification-sidebar-overlay" />

        <div className="notification-sidebar-container">
          <div className="notification-sidebar-inner-container">
            <div className="notification-sidebar-panel-container">
              <Transition.Child
                as={Fragment}
                enter="notification-sidebar-enter"
                enterFrom="notification-sidebar-enterFrom"
                enterTo="notification-sidebar-enterTo"
                leave="notification-sidebar-leave"
                leaveFrom="notification-sidebar-leaveFrom"
                leaveTo="notification-sidebar-leaveTo"
              >
                <Dialog.Panel className="notification-sidebar-panel">
                  <div className="notification-sidebar-panel-content">
                    <div className="notification-sidebar-header">
                      <div className="notification-sidebar-header-inner">
                        <Dialog.Title className="notification-sidebar-title">
                          Notifications
                        </Dialog.Title>
                        <div className="notification-sidebar-close-container">
                          <button
                            type="button"
                            className="notification-sidebar-close-button"
                            onClick={handleClose}
                          >
                            <span className="notification-sidebar-close-overlay" />
                            <span className="sr-only">Close panel</span>
                            <XMarkIcon className="notification-sidebar-close-icon" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                      <div className="notification-sidebar-header-spacer">
                      </div>
                    </div>
                    <div className="notification-sidebar-body">
                        <NotificationFeed profileId={profileId} profileType={currentProfileType} />
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}



