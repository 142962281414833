import React, { useEffect, useState } from "react";
import { AiOutlineDelete, AiOutlineEye } from "react-icons/ai";
import { Link } from "react-router-dom";
import axios from "axios";
import { server } from "../../server";
import { toast } from "react-toastify";
import { Bar, Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
} from 'chart.js';
import 'chartjs-adapter-date-fns';
import { format, parseISO } from 'date-fns';

ChartJS.register(CategoryScale, LinearScale, BarElement, LineElement, Title, Tooltip, Legend, TimeScale);

const AllProducts = () => {
  const [data, setData] = useState([]);
  const [categoryData, setCategoryData] = useState({
    labels: [],
    datasets: [
      {
        label: 'Number of Products',
        data: [],
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
      },
    ],
  });
  const [productsAddedData, setProductsAddedData] = useState({
    labels: [],
    datasets: [
      {
        label: 'Products Added',
        data: [],
        borderColor: 'rgba(255, 99, 132, 1)',
        backgroundColor: 'rgba(255, 99, 132, 0.2)',
      },
    ],
  });

  useEffect(() => {
    axios.get(`${server}/product/admin-all-products`, { withCredentials: true })
      .then((res) => {
        setData(res.data.products);
        processCategoryData(res.data.products);
        processProductsAddedData(res.data.products);
      })
      .catch((error) => {
        console.error("Error fetching products:", error);
        toast.error("Failed to fetch products.");
      });
  }, []);

  const processCategoryData = (products) => {
    const categoryCounts = products.reduce((acc, product) => {
      acc[product.category] = (acc[product.category] || 0) + 1;
      return acc;
    }, {});

    setCategoryData({
      labels: Object.keys(categoryCounts),
      datasets: [
        {
          label: 'Number of Products',
          data: Object.values(categoryCounts),
          backgroundColor: Object.keys(categoryCounts).map(() => 'rgba(53, 162, 235, 0.5)'),
        },
      ],
    });
  };

  const processProductsAddedData = (products) => {
    const timeframe = 'daily';

    const sortedProducts = products.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
    const productsAddedCounts = {};
    
    sortedProducts.forEach(product => {
      const date = parseISO(product.createdAt);
      const label = timeframe === 'daily' ? format(date, 'MM/dd/yyyy') : format(date, 'MM/yyyy');

      if (!productsAddedCounts[label]) {
        productsAddedCounts[label] = 0;
      }
      productsAddedCounts[label]++;
    });

    setProductsAddedData({
      labels: Object.keys(productsAddedCounts),
      datasets: [{
        label: 'Products Added',
        data: Object.values(productsAddedCounts),
        borderColor: 'rgba(255, 99, 132, 1)',
        backgroundColor: 'rgba(255, 99, 132, 0.2)',
      }],
    });
  };

  const handleDelete = async (id) => {
    if (window.confirm('Are you sure you want to delete this product?')) {
      try {
        await axios.delete(`${server}/product/admin-delete/${id}`, { withCredentials: true });
        toast.success('Product deleted successfully');
        setData(data.filter((product) => product._id !== id));
      } catch (error) {
        toast.error('Failed to delete product');
        console.error('Error deleting product:', error);
      }
    }
  };

  return (
    <div className="admin-all-products-component">
      <h1>All Products</h1>

      <div className="admin-all-products-charts-container">
        <div className="admin-all-products-chart">
          <Bar
            data={categoryData}
            options={{
              maintainAspectRatio: false, 
              plugins: {
                legend: {
                  position: 'top',
                },
                title: {
                  display: true,
                  text: 'Number of Products by Category',
                },
              },
            }}
          />
        </div>

        <div className="admin-all-products-chart">
          <Line
            data={productsAddedData}
            options={{
              maintainAspectRatio: false,
              plugins: {
                legend: {
                  position: 'top',
                },
                title: {
                  display: true,
                  text: 'Products Added Over Time',
                },
              },
              scales: {
                x: {
                  type: 'time',
                  time: {
                    unit: 'day',
                  },
                },
              },
            }}
          />
        </div>
      </div>

      <div className="admin-all-products-data-grid">
        <table className="admin-all-products-data-table">
          <thead>
            <tr>
              <th>Image</th>
              <th>Product Id</th>
              <th>Name</th>
              <th>Product Type</th>
              <th>Shop Name</th>
              <th>Price</th>
              <th>Stock</th>
              <th>SKU</th>
              <th>Category</th>
              <th>Sold Out</th>
              <th>Preview</th>
              <th>Delete</th>
            </tr>
          </thead>
          <tbody>
            {data.map((item) => (
              <tr key={item._id}>
                <td><img src={item?.images[0]?.url || 'defaultProductImageUrl'} alt={item.name} style={{ width: '125px', height: 'auto', borderRadius: '8px' }} /></td>
                <td>{item._id}</td>
                <td>{item.name}</td>
                <td>{Array.isArray(item.productType) ? item.productType.join(', ') : item.productType}</td>
                <td>{item.handle}</td>
                <td>{"US$ " + item.discountPrice}</td>
                <td>{item.stock}</td>
                <td>{item.SKU}</td>
                <td>{item.category}</td>
                <td>{item.sold_out}</td>
                <td>
                  <Link to={`/product/${item._id}`}>
                    <button className="admin-all-products-icon-button">
                      <AiOutlineEye size={20} />
                    </button>
                  </Link>
                </td>
                <td>
                  <button className="admin-all-products-icon-button" onClick={() => handleDelete(item._id)}>
                    <AiOutlineDelete size={20} />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AllProducts;


