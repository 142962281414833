import React, { useState, useEffect } from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import DraggableImage from './DraggableImage';
import AdFormatIconRow from './AdFormatIconRow';  
import { FaUpload } from 'react-icons/fa';

const AdSetModalForBrands = ({ isOpen, onClose, onSave, adFormats, brandImage, brandName }) => {
    const [adImages, setAdImages] = useState({});
    const [adPositions, setAdPositions] = useState({});
    const [adImageNames, setAdImageNames] = useState({});
    const [isUploaded, setIsUploaded] = useState({});
    const [editMode, setEditMode] = useState({});
    const [selectedFormat, setSelectedFormat] = useState(adFormats[0]);  

    useEffect(() => {
        if (isOpen) {
            const initialAdImages = {};
            const initialAdPositions = {};
            const initialAdImageNames = {};
            const initialIsUploaded = {};

            adFormats.forEach((format) => {
                initialAdImages[format] = brandImage;
                initialAdPositions[format] = { x: 0, y: 0 };
                initialAdImageNames[format] = '';
                initialIsUploaded[format] = false;
            });

            setAdImages(initialAdImages);
            setAdPositions(initialAdPositions);
            setAdImageNames(initialAdImageNames);
            setIsUploaded(initialIsUploaded);

            setEditMode(adFormats.reduce((acc, format) => {
                acc[format] = false;
                return acc;
            }, {}));
            setSelectedFormat(adFormats[0]); 
        }
    }, [isOpen, adFormats, brandImage]);

    const handlePositionChange = (format, position) => {
        setAdPositions((prev) => ({ ...prev, [format]: position }));
    };

    const toggleEditMode = (format) => {
        setEditMode((prev) => ({ ...prev, [format]: !prev[format] }));
    };

    const handleImageChange = (format, event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setAdImages((prev) => ({
                    ...prev,
                    [format]: reader.result, 
                }));
                setAdImageNames((prev) => ({
                    ...prev,
                    [format]: file.name, 
                }));
                setIsUploaded((prev) => ({
                    ...prev,
                    [format]: true, 
                }));
            };
            reader.readAsDataURL(file);
        }
    };

    const handleSave = () => {
        onSave({ images: adImages, positions: adPositions });
        onClose();
    };

    if (!isOpen) return null;

    // Define the format details for the icon row
    const formatDetails = {
        'Sponsored Brands': {
            label: 'Sponsored Brands',
            icon: 'https://res.cloudinary.com/dzlopmfj8/image/upload/v1724325662/sidebar-ad_jdcpmo.png',
        },
        'Sponsored Brands Carousel': {
            label: 'Brands Carousel',
            icon: 'https://res.cloudinary.com/dzlopmfj8/image/upload/v1724327318/single_carousel_ad_mnubsr.svg',
        },
        // Add more formats as necessary...
    };

    return (
        <div className="ad-set-modal-overlay">
            <div className="ad-set-modal">
                <button onClick={onClose} className="close-modal-button">
                    <AiOutlineClose />
                </button>
                <h2>Customize Brand Ad Set</h2>

                {/* Ad Format Icon Row */}
                <AdFormatIconRow
                    formats={adFormats.map((format) => ({
                        name: format,
                        label: formatDetails[format]?.label || format,
                        icon: formatDetails[format]?.icon || 'default_icon_url',
                    }))}
                    selectedFormat={selectedFormat}
                    onFormatSelect={setSelectedFormat}  
                />

                {/* Content based on selected format */}
                <div className="ad-format-section">
                    <h3>{selectedFormat}</h3>
                    <div className="ad-format-controls">
                        <button onClick={() => toggleEditMode(selectedFormat)} className="ad-set-modal-edit-button">
                            {editMode[selectedFormat] ? 'Done' : 'Edit'}
                        </button>
                    </div>
                    <div className="ad-format-preview">
                        <div className="ad-format-content">
                            <div className="ad-preview-upload-icon-wrapper" onClick={() => document.getElementById(`fileInput-${selectedFormat}`).click()}>
                                <FaUpload
                                    className={`ad-preview-upload-icon ${isUploaded[selectedFormat] ? 'uploaded' : ''}`}
                                />
                                <p className="ad-preview-upload-text">Uploaded Image: {adImageNames[selectedFormat]}</p>
                                <input
                                    id={`fileInput-${selectedFormat}`}
                                    type="file"
                                    accept="image/*"
                                    onChange={(event) => handleImageChange(selectedFormat, event)}
                                    className="ad-preview-upload-input"
                                />
                            </div>

                            {/* Display content based on the selected format */}
                            {selectedFormat === 'Sponsored Brands' && (
                                <div className="ad-preview-sponsored-brands-container">
                                    <div className="ad-preview-sponsored-brands-image-container">
                                        {editMode[selectedFormat] ? (
                                            <DraggableImage
                                                src={adImages[selectedFormat]}
                                                initialPosition={adPositions[selectedFormat]}
                                                onPositionChange={(position) => handlePositionChange(selectedFormat, position)}
                                            />
                                        ) : (
                                            <img
                                                src={adImages[selectedFormat]}
                                                alt="Brand"
                                                className="ad-preview-sponsored-brands-image"
                                                style={{
                                                    left: `${adPositions[selectedFormat]?.x || 0}px`,
                                                    top: `${adPositions[selectedFormat]?.y || 0}px`,
                                                }}
                                            />
                                        )}
                                    </div>
                                    <div className="ad-preview-sponsored-brands-content">
                                        <strong className="ad-preview-sponsored-brands-link">{brandName}</strong>
                                        <span className="ad-preview-sponsored-brands-link"> – Discover Now &rarr;</span>
                                    </div>
                                </div>
                            )}

                            {selectedFormat === 'Sponsored Brands Carousel' && (
                                <div className="ad-preview-sponsored-brands-carousel-container">
                                    <div className="ad-preview-sponsored-brands-carousel-wrapper">
                                        <div className="ad-preview-sponsored-brands-carousel-item empty-box"></div>
                                        <div className="ad-preview-sponsored-brands-carousel-item">
                                            <div className="ad-preview-sponsored-brands-carousel-image-container">
                                                {editMode[selectedFormat] ? (
                                                    <DraggableImage
                                                        src={adImages[selectedFormat]}
                                                        initialPosition={adPositions[selectedFormat]}
                                                        onPositionChange={(position) => handlePositionChange(selectedFormat, position)}
                                                    />
                                                ) : (
                                                    <img
                                                        src={adImages[selectedFormat]}
                                                        alt="Brand"
                                                        className="ad-preview-sponsored-brands-carousel-image"
                                                        style={{
                                                            left: `${adPositions[selectedFormat]?.x || 0}px`,
                                                            top: `${adPositions[selectedFormat]?.y || 0}px`,
                                                            objectFit: 'cover',
                                                        }}
                                                    />
                                                )}
                                            </div>
                                            <div className="ad-preview-sponsored-brands-carousel-content">
                                                <h2 className="ad-preview-sponsored-brands-carousel-title">{brandName}</h2>
                                                <span className="ad-preview-sponsored-brands-carousel-button">Learn More</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>

                <button onClick={handleSave} className="save-ad-set-button">
                    Save
                </button>
            </div>
        </div>
    );
};

export default AdSetModalForBrands;
