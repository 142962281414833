import React from 'react'
import Header from "../../components/Layout/Header"
import Sidebar from '../../components/Layout/Sidebar';
import ShopSubscriptionCheckout from '../../components/Checkout/ShopSubscriptionCheckout';

const ShopSubscriptionCheckoutPage = () => {
  
  return (
    <div>
        <Header />
        <Sidebar />
        <div className="m-24">
        <ShopSubscriptionCheckout />
        </div>
    </div>
  )
}

export default ShopSubscriptionCheckoutPage ;