import { createReducer } from "@reduxjs/toolkit";
import {
  CREATE_BRAND_REQUEST,
  CREATE_BRAND_SUCCESS,
  CREATE_BRAND_FAIL,
  FETCH_BRANDS_REQUEST,
  FETCH_BRANDS_SUCCESS,
  FETCH_BRANDS_FAIL,
  FETCH_OWNED_BRANDS_REQUEST,
  FETCH_OWNED_BRANDS_SUCCESS,
  FETCH_OWNED_BRANDS_FAIL,
  CREATE_BRAND_CATALOG_ITEM_REQUEST,
  CREATE_BRAND_CATALOG_ITEM_SUCCESS,
  CREATE_BRAND_CATALOG_ITEM_FAIL,
  UPDATE_BRAND_REQUEST,
  UPDATE_BRAND_SUCCESS,
  UPDATE_BRAND_FAIL,
  FETCH_BRAND_CATALOG_ITEMS_REQUEST,
  FETCH_BRAND_CATALOG_ITEMS_SUCCESS,
  FETCH_BRAND_CATALOG_ITEMS_FAIL,
  SET_SELECTED_BRAND,
  CLEAR_SELECTED_BRAND,

} from "../actions/brand";

const initialState = {
  isLoading: false,
  brands: [],
  selectedBrand: null,
  ownedBrands: [],
  error: null,
  newBrand: null,
  newCatalogItem: null,
  catalogItems: [],
  updatedBrand: null,
};

export const brandReducer = createReducer(initialState, {
  [CREATE_BRAND_REQUEST]: (state) => {
    state.isLoading = true;
  },
  [CREATE_BRAND_SUCCESS]: (state, action) => {
    state.isLoading = false;
    state.newBrand = action.payload;
  },
  [CREATE_BRAND_FAIL]: (state, action) => {
    state.isLoading = false;
    state.error = action.payload;
  },
  [FETCH_BRANDS_REQUEST]: (state) => {
    state.isLoading = true;
  },
  [FETCH_BRANDS_SUCCESS]: (state, action) => {
    state.isLoading = false;
    state.brands = action.payload;
  },
  [FETCH_BRANDS_FAIL]: (state, action) => {
    state.isLoading = false;
    state.error = action.payload;
  },
  [FETCH_OWNED_BRANDS_REQUEST]: (state) => {
    state.isLoading = true;
  },
  [FETCH_OWNED_BRANDS_SUCCESS]: (state, action) => {
    state.isLoading = false;
    state.ownedBrands = action.payload;
  },
  [FETCH_OWNED_BRANDS_FAIL]: (state, action) => {
    state.isLoading = false;
    state.error = action.payload;
  },
  [CREATE_BRAND_CATALOG_ITEM_REQUEST]: (state) => {
    state.isLoading = true;
  },
  [CREATE_BRAND_CATALOG_ITEM_SUCCESS]: (state, action) => {
    state.isLoading = false;
    state.newCatalogItem = action.payload;
  },
  [CREATE_BRAND_CATALOG_ITEM_FAIL]: (state, action) => {
    state.isLoading = false;
    state.error = action.payload;
  },
  [UPDATE_BRAND_REQUEST]: (state) => {
    state.isLoading = true;
  },
  [UPDATE_BRAND_SUCCESS]: (state, action) => {
    state.isLoading = false;
    state.updatedBrand = action.payload;
    const index = state.ownedBrands.findIndex(brand => brand._id === action.payload._id);
    if (index !== -1) {
      state.ownedBrands[index] = action.payload;
    }
  },
  [UPDATE_BRAND_FAIL]: (state, action) => {
    state.isLoading = false;
    state.error = action.payload;
  },
  [SET_SELECTED_BRAND]: (state, action) => {
    state.selectedBrand = action.payload; 
  },
  [CLEAR_SELECTED_BRAND]: (state) => {
    state.selectedBrand = null; 
  },
  [FETCH_BRAND_CATALOG_ITEMS_REQUEST]: (state) => {
    state.isLoading = true;
  },
  [FETCH_BRAND_CATALOG_ITEMS_SUCCESS]: (state, action) => {
    state.isLoading = false;
    state.catalogItems = action.payload;
  },
  [FETCH_BRAND_CATALOG_ITEMS_FAIL]: (state, action) => {
    state.isLoading = false;
    state.error = action.payload;
  },
  clearErrors: (state) => {
    state.error = null;
  },
});
