import React from "react";
import { useLottie } from "lottie-react";
import animationData from "../../Assests/animations/Animation - 1715606377061.json";

const BoostAnimation = () => {
  const options = {
    animationData: animationData,
    loop: false,
    width: 300,
    height: 300,
  };

  const { View } = useLottie(options);

  return (
    <div className="boost-animation-container">
      {View}
    </div>
  );
};

export default BoostAnimation;
