import { React, useState, useCallback } from "react";
import { useSelector } from "react-redux";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { FaCheckCircle, FaTimesCircle } from "react-icons/fa";
import styles from "../../styles/styles";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { server } from "../../server";
import { toast } from "react-toastify";
import { RxAvatar } from "react-icons/rx";
import _ from "lodash";

const ShopCreate = () => {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState();
  const [address, setAddress] = useState("");
  const [zipCode, setZipCode] = useState();
  const [avatar, setAvatar] = useState();
  const [password, setPassword] = useState("");
  const [visible, setVisible] = useState(false);
  const [banner, setBanner] = useState();
  const [handle, setHandle] = useState("");
  const [handleAvailable, setHandleAvailable] = useState(true);
  const [checkingHandle, setCheckingHandle] = useState(false);
  const user = useSelector((state) => state.user.user);

  const userId = user?._id;

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateHandle(handle)) {
      setHandleAvailable(false);
      return;
    }

    if (!handleAvailable) {
      return;
    }

    axios
      .post(
        `${server}/shop/create-shop`,
        {
          name,
          handle,
          email,
          password,
          avatar,
          banner,
          zipCode,
          address,
          phoneNumber,
          owner: userId,
        },
        { withCredentials: true }
      )
      .then((res) => {
        toast.success(res.data.message);
        setName("");
        setHandle("");
        setEmail("");
        setPassword("");
        setAvatar();
        setBanner();
        setZipCode();
        setAddress("");
        setPhoneNumber();
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };

  const handleAvatarInputChange = (e) => {
    const reader = new FileReader();

    reader.onload = () => {
      if (reader.readyState === 2) {
        setAvatar(reader.result);
      }
    };

    reader.readAsDataURL(e.target.files[0]);
  };

  const handleBannerInputChange = (e) => {
    const reader = new FileReader();

    reader.onload = () => {
      if (reader.readyState === 2) {
        setBanner(reader.result);
      }
    };

    reader.readAsDataURL(e.target.files[0]);
  };

  const validateHandle = (handle) => {
    const regex = /^[a-zA-Z0-9]*$/;
    return regex.test(handle) && !/\s/.test(handle) && handle.length <= 30;
  };

  const checkHandleAvailability = async (value) => {
    if (validateHandle(value)) {
      setHandle(value);
      setCheckingHandle(true);
      try {
        const response = await axios.post(`${server}/shop/check-handle`, {
          handle: value,
        });
        setHandleAvailable(true);
      } catch (error) {
        setHandleAvailable(false);
      } finally {
        setCheckingHandle(false);
      }
    } else {
      setHandleAvailable(false);
      toast.error(
        "Shop handle should not contain spaces and must be alphanumeric."
      );
    }
  };

  const debouncedCheckHandleAvailability = useCallback(
    _.debounce(checkHandleAvailability, 1000),
    []
  );

  const handleChangeHandle = (e) => {
    let value = e.target.value.toLowerCase();
    value = value.replace(/\s+/g, ""); // Remove spaces
    setHandle(value);
    if (value) {
      debouncedCheckHandleAvailability(value);
    } else {
      setHandleAvailable(null);
    }
  };

  return (
    <div className="shop-create-component-container">
      <div className="shop-create-component-header">
        <h2>Register as a seller</h2>
      </div>
      <div className="shop-create-component-form-container">
        <div className="shop-create-component-form">
          <form className="shop-create-component-form-elements" onSubmit={handleSubmit}>
            <div className="shop-create-component-form-group">
              <label htmlFor="name">Shop Name</label>
              <input
                type="text"
                name="name"
                required
                value={name}
                onChange={(e) => setName(e.target.value)}
                className="shop-create-component-input"
              />
            </div>
            <div className="shop-create-component-form-group">
              <label htmlFor="handle">Shop Handle</label>
              <input
                type="text"
                name="handle"
                required
                maxLength="30"
                value={handle}
                onChange={handleChangeHandle}
                className="shop-create-component-input"
              />
              {handle && handleAvailable === false && !checkingHandle && (
                <p className="shop-create-component-handle-error">
                  <FaTimesCircle className="shop-create-component-icon" /> This
                  handle is already taken. Please choose another.
                </p>
              )}
              {handle && handleAvailable === true && !checkingHandle && (
                <p className="shop-create-component-handle-success">
                  <FaCheckCircle className="shop-create-component-icon" /> This
                  handle is available.
                </p>
              )}
            </div>

            <div className="shop-create-component-form-group">
              <label htmlFor="phone-number">Phone Number</label>
              <input
                type="number"
                name="phone-number"
                required
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                className="shop-create-component-input"
              />
            </div>

            <div className="shop-create-component-form-group">
              <label htmlFor="email">Email address</label>
              <input
                type="email"
                name="email"
                autoComplete="email"
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="shop-create-component-input"
              />
            </div>

            <div className="shop-create-component-form-group">
              <label htmlFor="address">Address</label>
              <input
                type="text"
                name="address"
                required
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                className="shop-create-component-input"
              />
            </div>

            <div className="shop-create-component-form-group">
              <label htmlFor="zipcode">Zip Code</label>
              <input
                type="number"
                name="zipcode"
                required
                value={zipCode}
                onChange={(e) => setZipCode(e.target.value)}
                className="shop-create-component-input"
              />
            </div>

            <div className="shop-create-component-form-group">
              <label htmlFor="password">Password</label>
              <div className="shop-create-component-password-container">
                <input
                  type={visible ? "text" : "password"}
                  name="password"
                  autoComplete="current-password"
                  required
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="shop-create-component-input"
                />
                {visible ? (
                  <AiOutlineEye
                    className="shop-create-component-eye-icon"
                    size={25}
                    onClick={() => setVisible(false)}
                  />
                ) : (
                  <AiOutlineEyeInvisible
                    className="shop-create-component-eye-icon"
                    size={25}
                    onClick={() => setVisible(true)}
                  />
                )}
              </div>
            </div>

            <div className="shop-create-component-form-group">
              <label htmlFor="avatar">Avatar</label>
              <div className="shop-create-component-avatar-container">
                <span className="shop-create-component-avatar-preview">
                  {avatar ? (
                    <img
                      src={avatar}
                      alt="avatar"
                      className="shop-create-component-avatar-image"
                    />
                  ) : (
                    <RxAvatar className="shop-create-component-avatar-icon" />
                  )}
                </span>
                <label
                  htmlFor="file-input"
                  className="shop-create-component-file-label"
                >
                  <span>Upload an avatar</span>
                  <input
                    type="file"
                    name="avatar"
                    id="file-input"
                    onChange={handleAvatarInputChange}
                    className="shop-create-component-file-input"
                  />
                </label>
              </div>
            </div>

            <div className="shop-create-component-form-group">
              <label htmlFor="banner">Shop Banner</label>
              <div className="shop-create-component-avatar-container">
                <span className="shop-create-component-avatar-preview">
                  {banner ? (
                    <img
                      src={banner}
                      alt="banner"
                      className="shop-create-component-avatar-image"
                    />
                  ) : (
                    <RxAvatar className="shop-create-component-avatar-icon" />
                  )}
                </span>
                <label
                  htmlFor="banner-input"
                  className="shop-create-component-file-label"
                >
                  <span>Upload a banner</span>
                  <input
                    type="file"
                    name="banner"
                    id="banner-input"
                    onChange={handleBannerInputChange}
                    className="shop-create-component-file-input"
                  />
                </label>
              </div>
            </div>

            <div className="shop-create-component-form-group">
              <button type="submit" className="shop-create-component-submit">
                Submit
              </button>
            </div>

            <div className={`${styles.noramlFlex} shop-create-component-signin`}>
              <h4>Already have an account?</h4>
              <Link to="/shop-login" className="shop-create-component-link">
                Sign in
              </Link>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ShopCreate;

