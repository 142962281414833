import React, { useState } from 'react';

const CustomCalendar = ({ selectedDate, onDateChange }) => {
    const [currentMonth, setCurrentMonth] = useState(selectedDate || new Date());

    const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const months = [
        'January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December'
    ];

    const startOfMonth = new Date(currentMonth.getFullYear(), currentMonth.getMonth(), 1);
    const endOfMonth = new Date(currentMonth.getFullYear(), currentMonth.getMonth() + 1, 0);
    const startDate = startOfMonth.getDay();
    const daysInMonth = endOfMonth.getDate();

    const handleMonthChange = (e) => {
        const newMonth = parseInt(e.target.value, 10);
        setCurrentMonth(new Date(currentMonth.getFullYear(), newMonth, 1));
    };

    const handleYearChange = (e) => {
        const newYear = parseInt(e.target.value, 10);
        setCurrentMonth(new Date(newYear, currentMonth.getMonth(), 1));
    };

    const selectDate = (day) => {
        const newDate = new Date(currentMonth.getFullYear(), currentMonth.getMonth(), day);
        onDateChange(newDate);
    };

    const renderDays = () => {
        const days = [];
        for (let i = 0; i < startDate; i++) {
            days.push(<div key={`empty-${i}`} className="calendar-empty"></div>);
        }
        for (let day = 1; day <= daysInMonth; day++) {
            days.push(
                <div
                    key={day}
                    className={`calendar-day ${selectedDate?.getDate() === day && selectedDate?.getMonth() === currentMonth.getMonth() ? 'selected' : ''}`}
                    onClick={() => selectDate(day)}
                >
                    {day}
                </div>
            );
        }
        return days;
    };

    return (
        <div className="custom-calendar">
            <div className="calendar-header">
                <select value={currentMonth.getMonth()} onChange={handleMonthChange} className="calendar-select">
                    {months.map((month, index) => (
                        <option key={month} value={index}>{month}</option>
                    ))}
                </select>
                <input
                    type="number"
                    value={currentMonth.getFullYear()}
                    onChange={handleYearChange}
                    className="calendar-year-input"
                />
            </div>
            <div className="calendar-grid">
                {daysOfWeek.map(day => (
                    <div key={day} className="calendar-day-header">{day}</div>
                ))}
                {renderDays()}
            </div>
        </div>
    );
};

export default CustomCalendar;
