import { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon, ChevronDownIcon, ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline';
import { useSelector, useDispatch } from 'react-redux';
import { setWalletSidebarOpen } from '../state/uiSlice';

export default function CryptoWalletPaySidebar() {
  const isWalletSidebarOpen = useSelector((state) => state.ui.isWalletSidebarOpen);
  const dispatch = useDispatch();

  const userProfile = useSelector((state) => state.user.user);
  const currentProfile = useSelector((state) => state.user.currentProfile);

  const avatarUrl = userProfile?.avatar?.url || 'https://res.cloudinary.com/dzlopmfj8/image/upload/v1722588106/posts/wjqwxnil1hnh1ofqcfy5.webp';
  const profileName = userProfile?.name || 'David Harrison';

  const handleClose = () => {
    dispatch(setWalletSidebarOpen({ isOpen: false }));
  };

  const handleOpenWalletPanel = () => {
    dispatch(setWalletSidebarOpen({ isOpen: true, type: null }));
  };

  const handleOpenBuySidebar = () => {
    dispatch(setWalletSidebarOpen({ isOpen: true, type: 'buy' }));
  };

  const handleOpenSellSidebar = () => {
    dispatch(setWalletSidebarOpen({ isOpen: true, type: 'sell' }));
  };

  const handleOpenPaySidebar = () => {
    dispatch(setWalletSidebarOpen({ isOpen: true, type: 'pay' }));
  };

  return (
    <Transition.Root show={isWalletSidebarOpen} as={Fragment}>
      <Dialog as="div" className="crypto-wallet-sidebar-dialog" onClose={handleClose}>
        <div className="crypto-wallet-sidebar-overlay" />

        <div className="crypto-wallet-sidebar-container">
          <Transition.Child
            as={Fragment}
            enter="crypto-wallet-sidebar-enter"
            enterFrom="crypto-wallet-sidebar-enterFrom"
            enterTo="crypto-wallet-sidebar-enterTo"
            leave="crypto-wallet-sidebar-leave"
            leaveFrom="crypto-wallet-sidebar-leaveFrom"
            leaveTo="crypto-wallet-sidebar-leaveTo"
          >
            <Dialog.Panel className="crypto-wallet-sidebar-panel">
              <div className="crypto-wallet-sidebar-inner">

                {/* Cover Image with Avatar and Titles */}
                <div className="crypto-wallet-cover-container">
                  <img
                    src="https://res.cloudinary.com/dzlopmfj8/image/upload/v1722588106/posts/wjqwxnil1hnh1ofqcfy5.webp"
                    alt="Cover"
                    className="crypto-wallet-cover-image"
                  />
                  <div className="crypto-wallet-avatar-container">
                    <img
                      src={avatarUrl}
                      alt={`${profileName} Avatar`}
                      className="crypto-wallet-avatar"
                    />
                  </div>
                  <div className="crypto-wallet-avatar-info">
                    <h2 className="crypto-wallet-title">{profileName}</h2>
                    <p className="crypto-wallet-subtitle">User</p>
                  </div>
                </div>


                {/* Buttons Section */}
                <div className="crypto-wallet-buttons-container">
                  <ChevronLeftIcon className="crypto-wallet-chevron-icon" aria-hidden="true" />
                  <div className="crypto-wallet-button-wrapper">
                    <button className="crypto-wallet-button wallet" onClick={handleOpenWalletPanel}>
                      <img
                        src="https://res.cloudinary.com/dzlopmfj8/image/upload/v1723455786/wallet_logog_npmln6.png"
                        alt="Wallet"
                        className="crypto-wallet-button-icon"
                      />
                    </button>
                    <span className="crypto-wallet-button-title">Wallet</span>
                  </div>
                  <div className="crypto-wallet-button-wrapper">
                    <button className="crypto-wallet-button buy" onClick={handleOpenBuySidebar}>
                      <img
                        src="https://res.cloudinary.com/dzlopmfj8/image/upload/v1723455786/buy_logo_nlfx4t.png"
                        alt="Buy"
                        className="crypto-wallet-button-icon"
                      />
                    </button>
                    <span className="crypto-wallet-button-title">Buy</span>
                  </div>
                  <div className="crypto-wallet-button-wrapper">
                    <button className="crypto-wallet-button sell" onClick={handleOpenSellSidebar}>
                      <img
                        src="https://res.cloudinary.com/dzlopmfj8/image/upload/v1723455786/sell_logo_nb404k.png"
                        alt="Sell"
                        className="crypto-wallet-button-icon"
                      />
                    </button>
                    <span className="crypto-wallet-button-title">Cash Out</span>
                  </div>
                  <div className="crypto-wallet-button-wrapper">
                    <button className="crypto-wallet-button pay crypto-wallet-action-button-active" onClick={handleOpenPaySidebar}>
                      <img
                        src="https://res.cloudinary.com/dzlopmfj8/image/upload/v1723455786/Pay_logo_a74bco.png"
                        alt="Pay"
                        className="crypto-wallet-button-icon"
                      />
                    </button>
                    <span className="crypto-wallet-button-title">Pay</span>
                  </div>
                  <div className="crypto-wallet-button-wrapper">
                    <button className="crypto-wallet-button trade">
                      <img
                        src="https://res.cloudinary.com/dzlopmfj8/image/upload/v1723455786/trade_logo_zfh39o.png"
                        alt="Trade"
                        className="crypto-wallet-button-icon"
                      />
                    </button>
                    <span className="crypto-wallet-button-title">Trade</span>
                  </div>
                  <ChevronRightIcon className="crypto-wallet-chevron-icon" aria-hidden="true" />
                </div>

                {/* Section Title with Icon */}
                <div className="crypto-wallet-section-title">
                  <div className="crypto-wallet-section-icon-sell">
                    <img
                      src="https://res.cloudinary.com/dzlopmfj8/image/upload/v1723455786/Pay_logo_a74bco.png"
                      alt="Pay"
                      className="crypto-wallet-section-icon-img"
                    />
                  </div>
                  <span className="crypto-wallet-section-text">PAY</span>
                </div>

                {/* Payment Options Buttons */}
                <div className="crypto-wallet-payment-options">
                  <button className="crypto-wallet-option-button">Transfer</button>
                  <button className="crypto-wallet-option-button">Pay bills</button>
                  <button className="crypto-wallet-option-button">Pay a friend</button>
                  <button className="crypto-wallet-option-button">Pay Invoice</button>
                </div>


                {/* Form */}
                <div className="crypto-wallet-form-container">
                    {/* Wire Transfer Buttons */}
                <div className="crypto-wallet-transfer-buttons">
                  <button className="crypto-wallet-transfer-button">WIRE IN</button>
                  <button className="crypto-wallet-transfer-button">PICKUP</button>
                  <button className="crypto-wallet-transfer-button">WIRE OUT</button>
                </div>
                  <form className="crypto-wallet-form">
                    <div className="crypto-wallet-input-group crypto-wallet-input-group-amount">
                      <label className="crypto-wallet-label" htmlFor="amount">Amount</label>
                      <div className="crypto-wallet-input-wrapper">
                        <input
                          type="text"
                          id="amount"
                          placeholder=""
                          className="crypto-wallet-input"
                        />
                        <span className="crypto-wallet-currency">GMC</span>
                      </div>
                    </div>
                    <div className="crypto-wallet-input-group-date-time-wrapper">
                      <div className="crypto-wallet-input-group crypto-wallet-input-group-date-time">
                        <label className="crypto-wallet-label" htmlFor="date">Date</label>
                        <div className="crypto-wallet-input-wrapper">
                          <input
                            type="text"
                            id="date"
                            placeholder=""
                            className="crypto-wallet-input"
                          />
                          <ChevronDownIcon className="crypto-wallet-chevron-icon" aria-hidden="true" />
                        </div>
                      </div>
                      <div className="crypto-wallet-input-group crypto-wallet-input-group-date-time">
                        <label className="crypto-wallet-label" htmlFor="time">Time</label>
                        <div className="crypto-wallet-input-wrapper">
                          <input
                            type="text"
                            id="time"
                            placeholder=""
                            className="crypto-wallet-input"
                          />
                          <ChevronDownIcon className="crypto-wallet-chevron-icon" aria-hidden="true" />
                        </div>
                      </div>
                    </div>
                    <div className="crypto-wallet-input-group">
                      <label className="crypto-wallet-label" htmlFor="memo">Memo</label>
                      <input
                        type="text"
                        id="memo"
                        placeholder=""
                        className="crypto-wallet-input crypto-wallet-input-memo"
                      />
                    </div>
                    <div className="crypto-wallet-input-group">
                      <label className="crypto-wallet-label" htmlFor="upload">Upload</label>
                      <input
                        type="text"
                        id="upload"
                        placeholder="https://www.web.com/docs/images/source-of-funds.jpg"
                        className="crypto-wallet-input crypto-wallet-input-upload"
                      />
                      <small className="crypto-wallet-upload-note">Upload Documents Providing Source of Funds</small>
                    </div>
                  </form>
                </div>

                {/* Action Buttons */}
                <div className="crypto-wallet-actions">
                  <button className="crypto-wallet-action-button cancel">Cancel</button>
                  <button className="crypto-wallet-action-button buy">Wire in</button>
                </div>

                {/* Wallet Section at the Bottom */}
                <div className="crypto-wallet-section-title crypto-wallet-bottom-bar">
                  <div className="crypto-wallet-section-icon crypto-wallet-bottom-icon">
                    <img
                      src="https://res.cloudinary.com/dzlopmfj8/image/upload/v1723455786/wallet_logog_npmln6.png"
                      alt="Wallet"
                      className="crypto-wallet-section-icon-img"
                    />
                  </div>
                  <span className="crypto-wallet-section-text">WALLET</span>
                </div>

              </div>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

