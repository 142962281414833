import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { XMarkIcon } from '@heroicons/react/20/solid';
import { server } from "../../server";

const TopBannerAd = () => {
    const [ad, setAd] = useState(null);
    const adRef = useRef(null); 

    const logAdClick = async (adId) => {
        try {
            await axios.post(`${server}/sponsored/click/${adId}`, { withCredentials: true, 
                headers: {
                    'current-profile': sessionStorage.getItem('currentProfile') || 'defaultProfile',
                }});
            console.log('Click logged for ad:', adId);
        } catch (error) {
            console.error('Error logging click:', error);
        }
    };

    const logImpression = async (adId) => {
        try {
            await axios.post(`${server}/sponsored/impression/${adId}`, {}, { withCredentials: true, 
                headers: {
                    'current-profile': sessionStorage.getItem('currentProfile') || 'defaultProfile',
                }});
            console.log('Impression logged for ad:', adId);
        } catch (error) {
            console.error('Error logging impression:', error);
        }
    };

    const logViewedImpression = async (adId) => {
        try {
            await axios.post(`${server}/sponsored/viewed-impression/${adId}`, {}, { withCredentials: true, 
                headers: {
                    'current-profile': sessionStorage.getItem('currentProfile') || 'defaultProfile',
                }});
            console.log('Viewed impression logged for ad:', adId);
        } catch (error) {
            console.error('Error logging viewed impression:', error);
        }
    };

    useEffect(() => {
        const fetchAd = async () => {
            try {
                const response = await axios.get(`${server}/sponsored/sponsored-products`, { withCredentials: true });
                const activeAds = response.data.filter(ad => ad.isActive && ad.product && ad.adSet.images["Top Banner Ad"]);
                if (activeAds.length > 0) {
                    setAd(activeAds[0]);
                    logImpression(activeAds[0]._id);
                }
            } catch (error) {
                console.error('Error fetching ad:', error);
            }
        };

        fetchAd();
    }, []);

    useEffect(() => {
        if (ad && adRef.current) {
            const observer = new IntersectionObserver(
                (entries) => {
                    entries.forEach((entry) => {
                        if (entry.isIntersecting) {
                            const adId = ad._id;

                            // Start a timer for 1 second to log viewed impression
                            const timer = setTimeout(() => {
                                logViewedImpression(adId);
                            }, 1000);

                            // Store the timer in the DOM element
                            entry.target._timer = timer;
                        } else {
                            clearTimeout(entry.target._timer); // Clear the timer if the element goes out of view
                        }
                    });
                },
                { threshold: 0.5 } // 50% of the ad must be visible
            );

            if (adRef.current) {
                observer.observe(adRef.current);
            }

            return () => {
                if (adRef.current) observer.unobserve(adRef.current);
            };
        }
    }, [ad]);

    if (!ad) return null;

    const imageUrl = ad.adSet.images["Top Banner Ad"];
    const position = ad.adSet.positions["Top Banner Ad"] || { x: 0, y: 0 };

    return (
        <div className="top-banner-ad-container">
            <div
                className="top-banner-ad-content"
                style={{ 
                    backgroundImage: `url(${imageUrl})`,
                    transform: `translate(${position.x}px, ${position.y}px)`,
                }}
                ref={adRef} // Assigning the adRef for viewed impression tracking
            >
                <Link to={`/product/${ad.product._id}`} 
                      onClick={() => logAdClick(ad._id)} 
                      className="top-banner-ad-link">
                    <strong>{ad.product.name}</strong>
                    <span> – Explore Now &rarr;</span>
                </Link>
                <button onClick={() => setAd(null)} className="top-banner-ad-dismiss">
                    <XMarkIcon className="top-banner-ad-icon" aria-hidden="true" />
                    <span className="sr-only">Dismiss</span>
                </button>
            </div>
        </div>
    );
};

export default TopBannerAd;


