import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { server } from '../../server';
import axios from 'axios';

const frequencies = [
    { value: 'monthly', label: 'Monthly', priceSuffix: '/month' },
    { value: 'annually', label: 'Annually', priceSuffix: '/year' },
];

const tiers = [
    {
        name: 'No G',
        id: 'tier-freelancer',
        description: 'The essentials to start your vibe',
        features: ['Social media access'],
        mostPopular: false,
        price: { monthly: 'Free', annually: 'Free' },
        priceId: { monthly: 'price_1POJqdP4OgHr2xe6gmdWszMF', annually: 'price_1POJqdP4OgHr2xe6gmdWszMF' },
    },
    {
        name: 'OG',
        id: 'tier-startup',
        description: 'A boost to create a Buzz',
        features: [
            'Events',
            'Exclusive deals',
            'Advanced analytics',
            '24-hour support response time',
            'Marketing automations',
        ],
        mostPopular: true,
        price: { monthly: '$20', annually: '$200' },
        priceId: { monthly: 'price_1POJWvP4OgHr2xe6Nz9XxDhz', annually: 'price_1POJXbP4OgHr2xe6c3Tw4HzX' },
    },
    {
        name: 'Super OG',
        id: 'tier-enterprise',
        description: 'V.I.P. Access',
        features: [
            'Unlimited products',
            'Unlimited subscribers',
            'Advanced analytics',
            '1-hour, dedicated support response time',
            'Marketing automations',
            'Custom reporting tools',
        ],
        mostPopular: false,
        price: { monthly: '$100', annually: '$1000' },
        priceId: { monthly: 'price_1POJaOP4OgHr2xe6YQYvrKD5', annually: 'price_1POJaeP4OgHr2xe6nTz9J8el' },
    },
];

const ChangeSubscriptionModal = ({ currentSubscriptionId }) => {
    const [showModal, setShowModal] = useState(false);
    const [currentSubscription, setCurrentSubscription] = useState(null);
    const [frequency, setFrequency] = useState(frequencies[0].value);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [selectedPriceId, setSelectedPriceId] = useState(null);

    useEffect(() => {
        const fetchCurrentSubscription = async () => {
            try {
                const response = await axios.get(`${server}/subscriptions/subscriptions/${currentSubscriptionId}`, {
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json',
                        'current-profile': sessionStorage.getItem('currentProfile') || 'defaultProfile',
                    },
                });
                setCurrentSubscription(response.data);
            } catch (error) {
                console.error('Error fetching current subscription:', error);
            }
        };
        fetchCurrentSubscription();
    }, [currentSubscriptionId]);

    const confirmSubscriptionChange = (newPriceId) => {
        setSelectedPriceId(newPriceId);
        setShowConfirmationModal(true);
    };

    const handleSubscriptionChange = async () => {
        const newPriceId = selectedPriceId;
        if (newPriceId === currentSubscription.priceId) {
            toast.info("You're already on this plan.");
            return;
        }
        try {
            const response = await axios.post(`${server}/subscriptions/change-tier`, {
                subscriptionId: currentSubscriptionId,
                newPriceId,
            }, {
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                    'current-profile': sessionStorage.getItem('currentProfile') || 'defaultProfile',
                },
            });
            toast.success('Subscription updated successfully!');
            setCurrentSubscription(response.data.newSubscription);
            setShowModal(false);
            setShowConfirmationModal(false);
        } catch (error) {
            console.error('Error changing subscription:', error);
            toast.error('Failed to update subscription.');
        }
    };

    const currentFrequencySuffix = frequencies.find(f => f.value === frequency)?.priceSuffix;

    return (
        <div>
            <button className="upgrade-plan-btn" onClick={() => setShowModal(true)}>Upgrade Plan</button>
            {showModal && (
                <div className="change-subscription-modal">
                    <div className="change-subscription-modal-content">
                        <span className="change-subscription-close-btn" onClick={() => setShowModal(false)}>&times;</span>
                        <h2 className="change-subscription-title">Choose your new plan</h2>

                        {/* Frequency Selection */}
                        <div className="change-subscription-frequency">
                            <div className="change-subscription-frequency-group">
                                {frequencies.map((option) => (
                                    <div
                                        key={option.value}
                                        className={`change-subscription-frequency-option ${frequency === option.value ? 'active' : ''}`}
                                        onClick={() => setFrequency(option.value)}
                                    >
                                        {option.label}
                                    </div>
                                ))}
                            </div>
                        </div>

                        {/* Tier Selection */}
                        <div className="change-subscription-tier-group">
                            {tiers.map((tier) => (
                                <div
                                    key={tier.priceId[frequency]}
                                    className={`change-subscription-tier-option ${currentSubscription && currentSubscription.priceId === tier.priceId[frequency] ? 'active' : ''}`}
                                    onClick={() => confirmSubscriptionChange(tier.priceId[frequency])}
                                >
                                    <div className="change-subscription-tier-content">
                                        <span className="change-subscription-tier-name">{tier.name}</span>
                                        <span className="change-subscription-tier-description">{tier.description}</span>
                                        <div className="change-subscription-tier-features">
                                            {tier.features.map((feature, index) => (
                                                <span key={index}>{feature}</span>
                                            ))}
                                        </div>
                                        <span className="change-subscription-tier-price">
                                            {tier.price[frequency]}
                                            <span className="change-subscription-price-suffix">{currentFrequencySuffix}</span>
                                        </span>
                                    </div>
                                    {currentSubscription && currentSubscription.priceId === tier.priceId[frequency] && (
                                        <span className="change-subscription-tier-check-icon">✔</span>
                                    )}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            )}

            {showConfirmationModal && (
                <div className="change-subscription-confirmation-modal">
                    <div className="change-subscription-confirmation-content">
                        <h2 className="change-subscription-confirmation-title">Confirm Subscription Change</h2>
                        <p className="change-subscription-confirmation-text">Are you sure you want to change your subscription?</p>
                        <div className="change-subscription-confirmation-actions">
                            <button
                                className="change-subscription-confirmation-cancel"
                                onClick={() => setShowConfirmationModal(false)}
                            >
                                Cancel
                            </button>
                            <button
                                className="change-subscription-confirmation-confirm"
                                onClick={handleSubscriptionChange}
                            >
                                Confirm
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ChangeSubscriptionModal;



