import React from 'react'
import Header from "../../components/Layout/Header"
import Sidebar from '../../components/Layout/Sidebar';
import ShopSubscriptionPricing from '../../components/Subscription/ShopSubscriptionPricing';

const ShopSubscriptionPage = () => {
  
  return (
    <div>
        <Header />
        <Sidebar />
        <ShopSubscriptionPricing />
    </div>
  )
}

export default ShopSubscriptionPage;