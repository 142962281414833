import React from 'react';
import { FaDownload } from 'react-icons/fa'; 

const generateICS = (event) => {
    const formatDate = (date) => {
        return new Date(date).toISOString().replace(/-|:|\.\d{3}/g, '').slice(0, 15) + 'Z';
    };

    const start = formatDate(event.date);
    const end = formatDate(new Date(new Date(event.date).getTime() + 2 * 60 * 60 * 1000)); // Assuming a 2-hour event

    return [
        'BEGIN:VCALENDAR',
        'VERSION:2.0',
        'BEGIN:VEVENT',
        `URL:${document.location.href}`,
        `DTSTART:${start}`,
        `DTEND:${end}`,
        `SUMMARY:${event.title}`,
        `DESCRIPTION:${event.description.replace(/(\r\n|\n|\r)/gm, " ")}`, 
        `LOCATION:${event.location.address}`,
        'END:VEVENT',
        'END:VCALENDAR'
    ].join('\r\n');
};

const DownloadICSButton = ({ event }) => {
    const downloadICSFile = () => {
        const icsData = generateICS(event);
        const blob = new Blob([icsData], { type: 'text/calendar;charset=utf-8' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${event.title}.ics`); 
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <button onClick={downloadICSFile} className="download-ICS-button">
            <FaDownload className="download-ICS-icon" />
        </button>
    );
};

export default DownloadICSButton;


