import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchReportedPosts } from '../../redux/actions/post';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { format } from 'date-fns';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import axios from 'axios';
import { server } from '../../server';
import AdminHeader from '../Layout/AdminHeader';
import AdminSideBar from '../Admin/Layout/AdminSideBar';


const ReportedPosts = () => {
  const dispatch = useDispatch();
  const { reportedPosts, loading, error } = useSelector((state) => state.posts);

  const [selectedPost, setSelectedPost] = useState(null);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    dispatch(fetchReportedPosts());
  }, [dispatch]);

  useEffect(() => {
    if (error) {
      toast.error(error);
    }
  }, [error]);

  const handlePostClick = (postId) => {
    const post = reportedPosts.find((report) => report.postId === postId);
    setSelectedPost(post);
  };

  const handleCloseModal = () => {
    setSelectedPost(null);
  };

  const handleOpenConfirmModal = () => {
    setShowModal(true);
  };

  const handleCloseConfirmModal = () => {
    setShowModal(false);
  };

  const handleRemovePost = async () => {
    try {
      const response = await axios.post(`${server}/report/remove-post`, { postId: selectedPost.postId }, {
        withCredentials: true
    });
      if (response.data.success) {
        toast.success(response.data.message);
        setSelectedPost(null);
        dispatch(fetchReportedPosts());
      } else {
        toast.error('Failed to remove the post');
      }
    } catch (error) {
      toast.error('An error occurred while removing the post');
    } finally {
      setShowModal(false);
    }
  };

  return (
    <div>
      <AdminHeader />
      <div className="reported-posts-admin-container">
        <div className="reported-posts-admin-sidebar">
          <AdminSideBar active={2} />
        </div>
        <div className="reported-posts-admin-content">
          <ToastContainer />
          <h1 className="reported-posts-admin-title">Reported Posts</h1>
          {loading ? (
            <div className="reported-posts-admin-loader">Loading...</div>
          ) : (
            <div className="reported-posts-admin-table-container">
              <table className="reported-posts-admin-table">
                <thead className="reported-posts-admin-table-head">
                  <tr className="reported-posts-admin-table-row">
                    <th className="reported-posts-admin-table-cell">Post ID</th>
                    <th className="reported-posts-admin-table-cell">Post Owner</th>
                    <th className="reported-posts-admin-table-cell">Description</th>
                    <th className="reported-posts-admin-table-cell">Reason</th>
                    <th className="reported-posts-admin-table-cell">Report Description</th>
                    <th className="reported-posts-admin-table-cell">Reported By</th>
                    <th className="reported-posts-admin-table-cell">Reported At</th>
                  </tr>
                </thead>
                <tbody className="reported-posts-admin-table-body">
                  {reportedPosts.map((report) => (
                    <tr
                      key={report.reportId}
                      onClick={() => handlePostClick(report.postId)}
                      className={`reported-posts-admin-table-body-row ${report.isRemoved ? 'reported-posts-admin-table-body-row-removed' : ''}`}
                    >
                      <td className="reported-posts-admin-table-body-cell">{report.postId}</td>
                      <td className="reported-posts-admin-table-body-cell">{report.postOwner}</td>
                      <td className="reported-posts-admin-table-body-cell">{report.description}</td>
                      <td className="reported-posts-admin-table-body-cell">{report.reason}</td>
                      <td className="reported-posts-admin-table-body-cell">{report.reportDescription}</td>
                      <td className="reported-posts-admin-table-body-cell">{report.reportedBy.name} ({report.reportedBy.email})</td>
                      <td className="reported-posts-admin-table-body-cell">{new Date(report.createdAt).toLocaleString()}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}

          {selectedPost && (
            <div className="reported-posts-admin-modal">
              <div className="reported-posts-admin-modal-box">
                <div className="reported-posts-admin-modal-content">
                  <h2 className="reported-posts-admin-modal-title">Post by {selectedPost.postOwner}</h2>
                  <p className="reported-posts-admin-modal-date">{format(new Date(selectedPost.createdAt), "PPP")}</p>
                  <div className="reported-posts-admin-modal-description">
                    <p>{selectedPost.description}</p>
                    {selectedPost.images && selectedPost.images.length > 0 && (
                      <div className="reported-posts-admin-modal-images">
                        {selectedPost.images.map((image, index) => (
                          <LazyLoadImage
                            key={index}
                            width="100%"
                            height="auto"
                            alt={`post image ${index + 1}`}
                            src={image.url}
                            placeholderSrc="path/to/placeholder/image.jpg"
                          />
                        ))}
                      </div>
                    )}
                    {selectedPost.videos && selectedPost.videos.length > 0 && (
                      <div className="reported-posts-admin-modal-videos">
                        {selectedPost.videos.map((video, index) => (
                          <video key={index} width="100%" controls>
                            <source src={video.url} type="video/mp4" />
                            Your browser does not support the video tag.
                          </video>
                        ))}
                      </div>
                    )}
                  </div>
                  <div className="reported-posts-admin-modal-comments">
                    <p>{selectedPost.comments} comments</p>
                  </div>
                  <div>
                    <button className="reported-posts-admin-view-profile-button" onClick={handleOpenConfirmModal}>Remove Post</button>
                  </div>
                </div>
              </div>
            </div>
          )}

          {showModal && (
            <div className="reported-posts-admin-cancel-modal-overlay">
              <div className="reported-posts-admin-cancel-modal">
                <p>Are you sure you want to remove this post?</p>
                <button className="reported-posts-admin-cancel-confirm-button" onClick={handleRemovePost}>Yes</button>
                <button className="reported-posts-admin-cancel-close-button" onClick={handleCloseConfirmModal}>No</button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ReportedPosts;







