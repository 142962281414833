import React, { useState } from 'react';
import Friend from '../../components/Friend';

const OtherFollowers = ({ followers, isLoading, error }) => {
  const [searchTerm, setSearchTerm] = useState('');

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value.toLowerCase());
  };

  const filteredFollowers = searchTerm
    ? followers.filter((follower) => follower.name.toLowerCase().includes(searchTerm))
    : followers;

  return (
    <div className="follower-list-widget-wrapper">
      <h5 className="follower-list-widget-title">Followers</h5>
      <input
        type="text"
        className="follower-list-widget-search"
        placeholder="Search followers"
        onChange={handleSearchChange}
      />
      <div className="follower-list-widget-content">
        {isLoading && <p>Loading...</p>}
        {error && <p>{error}</p>}
        {!isLoading && !error && filteredFollowers.length > 0 ? (
          filteredFollowers.map((follower) => (
            <Friend key={follower._id} friendId={follower._id} {...follower} friendType={follower.type} />
          ))
        ) : (
          <p>{error || 'No followers found.'}</p>
        )}
      </div>
    </div>
  );
};

export default OtherFollowers;
