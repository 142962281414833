import React, { useEffect, useState } from "react";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { server } from "../../server";
import { toast } from "react-toastify";
import { loadSellers } from "../../redux/actions/user";


const ShopLogin = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [shopHandle, setShopHandle] = useState("");
  const [password, setPassword] = useState("");
  const [visible, setVisible] = useState(false);
  const sellerProfiles = useSelector((state) => state.seller.sellers);

  useEffect(() => {
    dispatch(loadSellers()); 
  }, [dispatch]);

  useEffect(() => {
    const currentProfileName = sessionStorage.getItem('currentProfile');
    if (currentProfileName) {
      const foundSeller = sellerProfiles.find(seller => seller.name === currentProfileName);
      if (foundSeller) {
        setShopHandle(foundSeller.handle); 
      }
    }
  }, [sellerProfiles]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!shopHandle || !password) {
      toast.error("Please provide both shop handle and password");
      return;
    }

    await axios.post(`${server}/shop/login-shop`, { shopHandle, password }, { withCredentials: true })
      .then((res) => {
        toast.success("Login Successful!");
        navigate(`/dashboard/${shopHandle}`);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  return (
    <div className="shop-login-component-container">
      <div className="shop-login-component-header">
        <h2>Login to your shop</h2>
      </div>
      <div className="shop-login-component-form-container">
        <div className="shop-login-component-form">
          <form className="shop-login-component-form-elements" onSubmit={handleSubmit}>
            <div className="shop-login-component-form-group">
              <label htmlFor="shopHandle">Shop Handle</label>
              <input
                type="text"
                name="shopHandle"
                required
                value={shopHandle}
                onChange={(e) => setShopHandle(e.target.value)}
                className="shop-login-component-input"
              />
            </div>
            <div className="shop-login-component-form-group shop-login-component-password-container">
              <label htmlFor="password">Password</label>
              <input
                type={visible ? "text" : "password"}
                name="password"
                required
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="shop-login-component-input"
              />
              {visible ? (
                <AiOutlineEye
                  className="shop-login-component-eye-icon"
                  onClick={() => setVisible(false)}
                />
              ) : (
                <AiOutlineEyeInvisible
                  className="shop-login-component-eye-icon"
                  onClick={() => setVisible(true)}
                />
              )}
            </div>
            <div>
              <button type="submit" className="shop-login-component-button">
                Login
              </button>
            </div>
            <div className="shop-login-component-footer">
              <h4>Don’t have an account?</h4>
              <Link to="/shop-create">Sign Up</Link>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ShopLogin;

