import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getAllUsers } from "../../redux/actions/user";
import { AiOutlineDelete } from "react-icons/ai";
import { RxCross1 } from "react-icons/rx";
import axios from "axios";
import { server } from "../../server";
import { toast } from "react-toastify";
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';


ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const AllUsers = () => {
  const dispatch = useDispatch();
  const { users } = useSelector((state) => state.user);
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [
      {
        label: 'User Sign-ups',
        data: [],
        borderColor: 'rgb(75, 192, 192)',
        backgroundColor: 'rgba(75, 192, 192, 0.5)',
      }
    ]
  });
  const [open, setOpen] = useState(false);
  const [userId, setUserId] = useState("");
  const [timeFrame, setTimeFrame] = useState('daily'); 

  useEffect(() => {
    dispatch(getAllUsers());
  }, [dispatch, timeFrame]);

  useEffect(() => {
    if (users && users.length > 0) {
      processChartData(users, timeFrame);
    }
  }, [users, timeFrame]);

  const processChartData = (users, timeFrame) => {
    const sortedUsers = [...users].sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
    const chartLabels = [];
    const signUpCounts = [];
    let currentUserCount = 0;

    sortedUsers.forEach(user => {
      const date = new Date(user.createdAt);
      let label;
      if (timeFrame === 'daily') {
        label = date.toLocaleDateString();
      } else {
        label = `${date.getMonth() + 1}-${date.getFullYear()}`;
      }

      if (!chartLabels.includes(label)) {
        chartLabels.push(label);
        signUpCounts.push(currentUserCount);
        currentUserCount = 0;
      }
      currentUserCount++;
    });
    if (currentUserCount > 0) {
      signUpCounts.push(currentUserCount);
    }

    setChartData({
      labels: chartLabels,
      datasets: [{
        label: 'User Sign-ups',
        data: signUpCounts,
        borderColor: 'rgb(75, 192, 192)',
        backgroundColor: 'rgba(75, 192, 192, 0.5)',
      }]
    });
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    interaction: {
      mode: 'index',
      intersect: false,
    },
    plugins: {
      title: {
        display: true,
        text: 'User Sign-ups Over Time',
        font: {
          size: 24,
        },
        color: '#ffffff',
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            let label = context.dataset.label || '';
            if (label) {
              label += ': ';
            }
            label += context.parsed.y;
            return label;
          },
        },
      },
      legend: {
        onClick: (e, legendItem, legend) => {
          const index = legendItem.datasetIndex;
          const chart = legend.chart;
          const meta = chart.getDatasetMeta(index);
          meta.hidden = meta.hidden === null ? !chart.data.datasets[index].hidden : null;
          chart.update();
        },
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: 'Date',
        },
      },
      y: {
        title: {
          display: true,
          text: 'Count',
        },
        beginAtZero: true,
      },
    },
    elements: {
      line: {
        tension: 0.4,
      },
      point: {
        hoverRadius: 7,
        radius: 5,
      },
    },
  };

  const handleDelete = async (id) => {
    await axios
    .delete(`${server}/user/delete-user/${id}`, { withCredentials: true })
    .then((res) => {
      toast.success(res.data.message);
    });

  dispatch(getAllUsers());
  };

  const columns = [
    { field: "id", headerName: "User ID", minWidth: 150, flex: 0.7 },
    {
      field: "avatar",
      headerName: "Avatar",
      renderCell: (params) => (
        <img
          src={params.value}
          alt="avatar"
          className="admin-all-users-avatar"
        />
      ),
      minWidth: 70,
      flex: 0.5,
      sortable: false,
    },

    {
      field: "name",
      headerName: "Name",
      minWidth: 130,
      flex: 0.7,
    },
    {
      field: "email",
      headerName: "Email",
      type: "text",
      minWidth: 130,
      flex: 0.7,
    },
    {
      field: "role",
      headerName: "User Role",
      type: "text",
      minWidth: 130,
      flex: 0.7,
    },

    {
      field: "joinedAt",
      headerName: "Joined At",
      type: "text",
      minWidth: 130,
      flex: 0.8,
    },
    {
      field: "viewProfile",
      headerName: "View Profile",
      sortable: false,
      renderCell: (params) => {
        return (
          <Link to={`/profile/${params.id}`} className="admin-all-users-view-profile-button">
            View Profile
          </Link>
        );
      },
      minWidth: 150,
      flex: 0.8,
    },

    {
      field: " ",
      flex: 1,
      minWidth: 150,
      headerName: "Delete User",
      type: "number",
      sortable: false,
      renderCell: (params) => {
        return (
          <button onClick={() => setUserId(params.id) || setOpen(true)} className="admin-all-users-delete-button">
            <AiOutlineDelete size={20} />
          </button>
        );
      },
    },
  ];

  const row = [];
  users &&
    users.forEach((item) => {
      row.push({
        id: item._id,
        name: item.name,
        email: item.email,
        role: item.role,
        joinedAt: item.createdAt.slice(0, 10),
        avatar: item.avatar.url,
      });
    });

    return (
      <div className="admin-all-users-container"> 
        <div className="admin-all-users-chart-section"> 
          <h3 className="admin-all-users-title">User Sign-ups Chart</h3>
          <div>
            <button
              onClick={() => setTimeFrame('daily')}
              className="admin-all-users-timeframe-button admin-all-users-timeframe-button-daily"
            >
              Daily
            </button>
            <button
              onClick={() => setTimeFrame('monthly')}
              className="admin-all-users-timeframe-button admin-all-users-timeframe-button-monthly"
            >
              Monthly
            </button>
          </div>
          <div className="admin-all-users-chart-container">
            <Line data={chartData} options={options} />
          </div>
        </div>
        <div className="admin-all-users-grid-section">
          <h3 className="admin-all-users-title">All Users</h3>
          <div className="admin-all-users-grid-container"> 
            <table className="admin-all-users-table">
              <thead>
                <tr>
                  {columns.map((column) => (
                    <th key={column.field} style={{ minWidth: column.minWidth, flex: column.flex }}>
                      {column.headerName}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {row.map((user) => (
                  <tr key={user.id}>
                    {columns.map((column) => (
                      <td key={column.field}>
                        {column.renderCell ? column.renderCell({ value: user[column.field], id: user.id }) : user[column.field]}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        {open && (
          <div className="admin-all-users-modal">
            <div className="admin-all-users-modal-content">
              <div className="admin-all-users-modal-close" onClick={() => setOpen(false)}>
                <RxCross1 size={25} />
              </div>
              <h3 className="admin-all-users-modal-title">
                Are you sure you want to delete this user?
              </h3>
              <div className="admin-all-users-modal-actions">
                <button className="admin-all-users-modal-button" onClick={() => setOpen(false)}>
                  Cancel
                </button>
                <button className="admin-all-users-modal-button" onClick={() => setOpen(false) || handleDelete(userId)}>
                  Confirm
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    );
};

export default AllUsers;

