import React, { useState } from 'react';
import axios from 'axios';
import { server } from "../../server";
import { useSelector } from "react-redux";
import CustomCalendar from './CustomCalendar';


const EventCreationForm = () => {
    const currentProfile = useSelector((state) => state.user.currentProfile);
    const [formData, setFormData] = useState({
        title: '',
        description: '',
        image: null,
        date: new Date(),
        endDate: new Date(),
        location: '',
        startTime: '',
        endTime: ''
    });

    const handleChange = (e) => {
        const { name, value, files } = e.target;
        if (name === "image") {
            setFormData(prev => ({ ...prev, image: files[0] }));
        } else {
            setFormData(prev => ({ ...prev, [name]: value }));
        }
    };

    const handleDateChange = (date) => {
        setFormData(prev => ({ ...prev, date }));
    };

    const handleEndDateChange = (endDate) => {
        setFormData(prev => ({ ...prev, endDate }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const data = new FormData();
        for (const key in formData) {
            if (formData[key] != null) { // Include all fields, file or otherwise
                data.append(key, formData[key]);
            }
        }
        data.append('currentProfile', currentProfile); // Use Redux state directly

        try {
            const response = await axios.post(`${server}/event/create`, data, {
                withCredentials: true,
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            
            alert('Event created successfully!');
        } catch (error) {
            console.error('Error creating event:', error);
            alert(`Failed to create event: ${error.response?.data?.message || 'Unknown error'}`);
        }
    };

    return (
        <form onSubmit={handleSubmit} className="event-creation-form-form">
            <div>
                <label htmlFor="title" className="event-creation-form-label">Event Title</label>
                <input type="text" name="title" id="title" value={formData.title} onChange={handleChange} placeholder="Event Title" required
                    className="event-creation-form-input" />
            </div>

            <div>
                <label htmlFor="description" className="event-creation-form-label">Event Description</label>
                <textarea name="description" id="description" value={formData.description} onChange={handleChange} placeholder="Event Description" required
                    className="event-creation-form-textarea" />
            </div>

            <div>
                <label htmlFor="image" className="event-creation-form-label">Event Image</label>
                <input type="file" name="image" id="image" onChange={handleChange} required
                    className="event-creation-form-file-input" />
            </div>

            <div>
                <label htmlFor="location" className="event-creation-form-label">Event Location</label>
                <input type="text" name="location" id="location" value={formData.location} onChange={handleChange} placeholder="Event Location" required
                    className="event-creation-form-input" />
            </div>

            <div className="event-creation-form-date-time-row">
                <div>
                    <label htmlFor="date" className="event-creation-form-label">Event Start Date</label>
                    <CustomCalendar selectedDate={formData.date} onDateChange={handleDateChange} />
                    <label htmlFor="startTime" className="event-creation-form-label">Event Start Time</label>
                    <input type="time" name="startTime" id="startTime" value={formData.startTime} onChange={handleChange} required
                        className="event-creation-form-input" />
                </div>
                <div>
                    <label htmlFor="endDate" className="event-creation-form-label">Event End Date</label>
                    <CustomCalendar selectedDate={formData.endDate} onDateChange={handleEndDateChange} />
                    <label htmlFor="endTime" className="event-creation-form-label">Event End Time</label>
                    <input type="time" name="endTime" id="endTime" value={formData.endTime} onChange={handleChange}
                        className="event-creation-form-input" />
                </div>
            </div>

            <button type="submit" className="event-creation-form-button">
                Create Event
            </button>
        </form>
    );
};

export default EventCreationForm;


