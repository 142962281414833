import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { switchProfile, setActiveSeller, loadSellers } from '../../redux/actions/user';
import { CheckCircleIcon, UserCircleIcon } from '@heroicons/react/24/outline';
import { Link, useNavigate } from "react-router-dom";
import { RxCross1 } from "react-icons/rx";
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';

const ProfileSwitchButtonShop = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [openModal, setOpenModal] = useState(false);
    const currentProfile = useSelector((state) => state.user.currentProfile);
    const userProfile = useSelector((state) => state.user.user);
    const sellerProfiles = useSelector((state) => state.seller.sellers);
    const { seller, isSeller } = useSelector((state) => state.seller);

    useEffect(() => {
        if (!sellerProfiles.length) {
            console.log('Loading sellers...');
            dispatch(loadSellers());
        }
    }, [dispatch, sellerProfiles.length]);

    let currentProfileAvatarUrl = '';
    if (isSeller && seller) {
        currentProfileAvatarUrl = seller?.avatar?.url;
    } else if (currentProfile === 'User' && userProfile) {
        currentProfileAvatarUrl = userProfile?.avatar?.url;
    }

    const handleSwitchProfile = (index) => {
        if (index === undefined) {
            dispatch(switchProfile());
            sessionStorage.setItem('currentProfile', 'User');
            navigate(`/dashboard/${userProfile.handle}`);
        } else {
            const selectedSellerProfile = sellerProfiles[index];
            dispatch(setActiveSeller(selectedSellerProfile));
            sessionStorage.setItem('currentProfile', selectedSellerProfile?.name);
            navigate(`/dashboard/${selectedSellerProfile?.handle}`); 
        }
        setOpenModal(false);
        window.location.reload();
    };

    const hasBothProfiles = userProfile && sellerProfiles.length > 0;

    return (
        <div className="switcheroo-shop-component">
            {userProfile ? (
                <>
                    <button onClick={() => setOpenModal(true)} className="switcheroo-shop-component-avatar-button">
                        <img
                            src={currentProfileAvatarUrl}
                            alt={`${currentProfile} Avatar`}
                            className="switcheroo-shop-component-avatar"
                        />
                        {hasBothProfiles && <SwapHorizIcon className="switcheroo-shop-component-swap-icon" />}
                    </button>

                    {openModal && (
                        <div className="switcheroo-shop-component-modal-overlay">
                            <div className="switcheroo-shop-component-modal">
                                <button
                                    aria-label="close"
                                    onClick={() => setOpenModal(false)}
                                    className="switcheroo-shop-component-close-btn"
                                >
                                    <RxCross1 size={25} />
                                </button>
                                <h2 className="switcheroo-shop-component-modal-title">Switch Profile</h2>
                                {sellerProfiles.map((seller, index) => (
                                    <div key={index} className="switcheroo-shop-component-modal-item" onClick={() => handleSwitchProfile(index)}>
                                        <img src={seller.avatar.url} alt={`${seller.name} Avatar`} className="switcheroo-shop-component-modal-item-image" />
                                        {seller.name}
                                        {currentProfile === seller.name && <CheckCircleIcon className="switcheroo-shop-component-check-icon" />}
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                </>
            ) : (
                <Link to="/login">
                    <UserCircleIcon className="h-12 w-12 text-gray-400" aria-hidden="true" />
                </Link>
            )}
        </div>
    );
};

export default ProfileSwitchButtonShop;

