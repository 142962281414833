import React from 'react';
import Carousel from 'react-material-ui-carousel';

const Carousel4Slides = () => {
    const items = [
        {
            name: "Vintage Fashion",
            description: "Nothing like the old-school!",
            backgroundImage: "https://res.cloudinary.com/dzlopmfj8/image/upload/v1716393249/vintage-fashion-category-buzzvibe_l77p4f.jpg",
            buttonLink: "http://link_to_some_destination.com",
            buttonText: "Learn More"
        },
        {
            name: "Collectibles",
            description: "The New Collection",
            backgroundImage: "https://res.cloudinary.com/dzlopmfj8/image/upload/v1716393583/collectibles-category-buzzvibe_xeeuv2.jpg",
            buttonLink: "https://res.cloudinary.com/dzlopmfj8/image/upload/v1707304820/ipqauu9pcdoalabtqrmk.jpg",
            buttonText: "Discover"
        },
        {
            name: "What's the Buzz?",
            description: "Our Favourites",
            backgroundImage: "https://res.cloudinary.com/dzlopmfj8/image/upload/v1716393233/art-category-buzzvibe_cfepbq.jpg",
            buttonLink: "http://link_to_some_destination.com",
            buttonText: "Explore"
        },
        {
            name: "Sustainabilty Matters",
            description: "Sustainable Fashion",
            backgroundImage: "https://res.cloudinary.com/dzlopmfj8/image/upload/v1716393239/couture-category-buzzvibe_qimgdb.jpg",
            buttonLink: "http://link_to_some_destination.com",
            buttonText: "Sign Up"
        }
    ];

    return (
        <Carousel>
            {
                items.map((item, i) => <Item key={i} item={item} />)
            }
        </Carousel>
    );
}

const Item = ({ item }) => {
    return (
        <div className="carousel-4-slides-component-paper" style={{ backgroundImage: `url(${item.backgroundImage})` }}>
            <div className="carousel-4-slides-component-content">
            <h2 className="carousel-4-slides-component-title">{item.name}</h2>
                <p className="carousel-4-slides-component-description">{item.description}</p>
                <a href={item.buttonLink} className="carousel-4-slides-component-button">
                    {item.buttonText}
                </a>
            </div>
        </div>
    )
}

export default Carousel4Slides;

