import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { server } from "../../server";
import Loader from "../Layout/Loader";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const SponsoredBrands = () => {
    const [sponsoredBrands, setSponsoredBrands] = useState([]);
    const brandRefs = useRef(new Map()); 

    const logAdClick = async (adId) => {
        try {
            await axios.post(`${server}/sponsored/click/${adId}`);
            console.log('Click logged for brand ad:', adId);
        } catch (error) {
            console.error('Error logging click:', error);
            toast.error('Failed to log click for brand ad.');
        }
    };

    const logImpression = async (adId) => {
        try {
            await axios.post(`${server}/sponsored/impression/${adId}`, {}, { withCredentials: true });
            console.log('Impression logged for brand ad:', adId);
        } catch (error) {
            console.error('Error logging impression:', error);
            toast.error('Failed to log impression for brand ad.');
        }
    };

    const logViewedImpression = async (adId) => {
        try {
            await axios.post(`${server}/sponsored/viewed-impression/${adId}`, {}, { withCredentials: true });
            console.log('Viewed impression logged for brand ad:', adId);
        } catch (error) {
            console.error('Error logging viewed impression:', error);
            toast.error('Failed to log viewed impression for brand ad.');
        }
    };

    useEffect(() => {
        const fetchSponsoredBrands = async () => {
            try {
                const response = await axios.get(`${server}/sponsored/sponsored-brands`, { withCredentials: true });
                const activeSponsoredBrands = response.data.filter(ad => ad.isActive);
                setSponsoredBrands(activeSponsoredBrands);
            } catch (error) {
                console.error('Error fetching sponsored brands:', error);
                toast.error('Failed to fetch sponsored brands.');
            }
        };
        fetchSponsoredBrands();
    }, []);

    useEffect(() => {
        sponsoredBrands.forEach(({ _id: adId }) => {
            logImpression(adId); // Log impression on load
        });
    }, [sponsoredBrands]);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        const adId = entry.target.dataset.adId;

                        // Start a timer for 1 second to log viewed impression
                        const timer = setTimeout(() => {
                            logViewedImpression(adId);
                        }, 1000);

                        // Store the timer in the DOM element
                        entry.target._timer = timer;
                    } else {
                        // Clear the timer if the element goes out of view before 1 second
                        clearTimeout(entry.target._timer);
                    }
                });
            },
            { threshold: 0.5 } // 50% of the ad must be visible
        );

        // Observe each brand in the sponsoredBrands
        sponsoredBrands.forEach((brand) => {
            const brandElement = brandRefs.current.get(brand._id);
            if (brandElement) {
                observer.observe(brandElement);
            }
        });

        return () => {
            observer.disconnect(); // Clean up the observer on component unmount
        };
    }, [sponsoredBrands]);

    return (
        <div className="sponsored-brands-widget-container">
            <ToastContainer />
            <h2 className="sponsored-brands-widget-title">Sponsored Brands</h2>
            <div className="sponsored-brands-widget-list">
                {sponsoredBrands.map(({ _id: adId, brand }) => (
                    <div
                        key={adId}
                        className="sponsored-brands-widget-item"
                        data-ad-id={adId}
                        ref={(el) => el && brandRefs.current.set(adId, el)} // Save ref for IntersectionObserver
                    >
                        <Link to={`/brand/${brand.name}`} onClick={() => logAdClick(adId)} className="sponsored-brands-widget-link">
                            <div className="sponsored-brands-widget-images">
                                {brand.avatarImage && (
                                    <img src={brand.avatarImage.url} alt={brand.name} className="sponsored-brands-widget-avatar" />
                                )}
                                {brand.coverImage && (
                                    <img src={brand.coverImage.url} alt={brand.name} className="sponsored-brands-widget-cover" />
                                )}
                            </div>
                            <div className="sponsored-brands-widget-details">
                                <h3 className="sponsored-brands-widget-name">{brand.name}</h3>
                                <button className="sponsored-brands-widget-button">Visit Brand</button>
                            </div>
                        </Link>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default SponsoredBrands;

