import React, { useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { fetchConnections } from '../../redux/actions/connections';

const FollowersDisplay = ({ userId, profileType }) => {
  const maxDisplay = 5;
  const dispatch = useDispatch();

  // Select the followers from the followers state
  const { followers, isLoading, error } = useSelector(state => state.connections);

  useEffect(() => {
    if (userId && profileType) {
      // Fetch followers of the profile being viewed
      dispatch(fetchConnections(userId, profileType, 'followers')); // Fetch followers
    }
  }, [userId, profileType, dispatch]);

  return (
    <div className="followers-avatar-display-container">
      {isLoading && <div>Loading followers...</div>}
      {error && <div>Error: {error}</div>}
      {!isLoading && !error && followers && followers.length > 0 ? (
        <>
          <div className="followers-avatar-display-images">
            {followers.slice(0, maxDisplay).map((follower, index) => (
              follower && follower._id ? (
                <img
                  key={index}
                  className="followers-avatar-display-image"
                  src={follower.avatar}
                  alt={follower.name}
                />
              ) : (
                <div key={index} className="follower-placeholder">No Data</div>
              )
            ))}
          </div>
          <div className="followers-avatar-display-count">
            <span>{followers.length} Followers</span>
          </div>
        </>
      ) : (
        <div>No followers to display.</div>
      )}
    </div>
  );
};

export default FollowersDisplay;




