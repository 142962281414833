import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Loader from "../Layout/Loader";
import { getAllPurchasesOfShop } from "../../redux/actions/order";
import { AiOutlineArrowRight } from "react-icons/ai";

const AllPurchases = () => {
  const { shopPurchases, isLoading } = useSelector((state) => state.order);
  const { seller } = useSelector((state) => state.seller);

  const dispatch = useDispatch();

  useEffect(() => {
    if (seller && seller._id) {
      dispatch(getAllPurchasesOfShop(seller._id));
    }
  }, [dispatch, seller]);

  const rows = shopPurchases
    ?.filter((item) => item.paymentInfo.status === 'Paid')
    .map((item) => ({
      id: item._id,
      itemsQty: item.cart.length,
      total: "US$ " + item.totalPrice,
      status: item.status,
    })) || [];

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="shop-purchases-container">
          <div className="shop-purchases-header">
            <h1 className="shop-purchases-title">All Purchases</h1>
          </div>
          <table className="shop-purchases-table">
            <thead>
              <tr>
                <th>Order ID</th>
                <th>Status</th>
                <th>Items Qty</th>
                <th>Total</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {rows.map((row) => (
                <tr key={row.id}>
                  <td>{row.id}</td>
                  <td className={row.status === "Delivered" ? "shop-purchases-status-delivered" : "shop-purchases-status-pending"}>{row.status}</td>
                  <td>{row.itemsQty}</td>
                  <td>{row.total}</td>
                  <td>
                    <Link to={`/purchase/${row.id}`}>
                      <button className="shop-purchases-button">
                        <AiOutlineArrowRight size={20} />
                      </button>
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </>
  );
};

export default AllPurchases;



