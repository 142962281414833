import axios from 'axios';
import { server } from '../../server';


export const UPDATE_BRAND_REQUEST = 'UPDATE_BRAND_REQUEST';
export const UPDATE_BRAND_SUCCESS = 'UPDATE_BRAND_SUCCESS';
export const UPDATE_BRAND_FAIL = 'UPDATE_BRAND_FAIL';

export const CREATE_BRAND_REQUEST = 'CREATE_BRAND_REQUEST';
export const CREATE_BRAND_SUCCESS = 'CREATE_BRAND_SUCCESS';
export const CREATE_BRAND_FAIL = 'CREATE_BRAND_FAIL';

export const FETCH_BRANDS_REQUEST = 'FETCH_BRANDS_REQUEST';
export const FETCH_BRANDS_SUCCESS = 'FETCH_BRANDS_SUCCESS';
export const FETCH_BRANDS_FAIL = 'FETCH_BRANDS_FAIL';

export const FETCH_OWNED_BRANDS_REQUEST = 'FETCH_OWNED_BRANDS_REQUEST';
export const FETCH_OWNED_BRANDS_SUCCESS = 'FETCH_OWNED_BRANDS_SUCCESS';
export const FETCH_OWNED_BRANDS_FAIL = 'FETCH_OWNED_BRANDS_FAIL';

export const CREATE_BRAND_CATALOG_ITEM_REQUEST = 'CREATE_BRAND_CATALOG_ITEM_REQUEST';
export const CREATE_BRAND_CATALOG_ITEM_SUCCESS = 'CREATE_BRAND_CATALOG_ITEM_SUCCESS';
export const CREATE_BRAND_CATALOG_ITEM_FAIL = 'CREATE_BRAND_CATALOG_ITEM_FAIL';

export const FETCH_BRAND_CATALOG_ITEMS_REQUEST = 'FETCH_BRAND_CATALOG_ITEMS_REQUEST';
export const FETCH_BRAND_CATALOG_ITEMS_SUCCESS = 'FETCH_BRAND_CATALOG_ITEMS_SUCCESS';
export const FETCH_BRAND_CATALOG_ITEMS_FAIL = 'FETCH_BRAND_CATALOG_ITEMS_FAIL';

export const SET_SELECTED_BRAND = 'SET_SELECTED_BRAND';
export const CLEAR_SELECTED_BRAND = 'CLEAR_SELECTED_BRAND';


// Action Creators
// Create Brand
export const createBrand = (brandData) => async (dispatch) => {
  try {
    dispatch({ type: CREATE_BRAND_REQUEST });
    const { data } = await axios.post(`${server}/brand/create-brand`, brandData, {
        headers: {
            'current-profile': sessionStorage.getItem('currentProfile') || 'defaultProfile',
          },
      withCredentials: true,
    });
    dispatch({ type: CREATE_BRAND_SUCCESS, payload: data.brand });
  } catch (error) {
    dispatch({ type: CREATE_BRAND_FAIL, payload: error.response.data.message });
  }
};

// Fetch All Brands
export const fetchBrands = () => async (dispatch) => {
  try {
    dispatch({ type: FETCH_BRANDS_REQUEST });
    const { data } = await axios.get(`${server}/brand/brands`, {
        headers: {
            'current-profile': sessionStorage.getItem('currentProfile') || 'defaultProfile',
          },
      withCredentials: true,
    });
    dispatch({ type: FETCH_BRANDS_SUCCESS, payload: data.brands });
  } catch (error) {
    dispatch({ type: FETCH_BRANDS_FAIL, payload: error.response.data.message });
  }
};

// Fetch Owned Brands
export const fetchOwnedBrands = () => async (dispatch, getState) => {
  try {
    dispatch({ type: FETCH_OWNED_BRANDS_REQUEST });
    
    const { data } = await axios.get(`${server}/brand/owned-brands`, {
      headers: {
        'current-profile': sessionStorage.getItem('currentProfile') || 'defaultProfile',
      },
      withCredentials: true,
    });
    dispatch({ type: FETCH_OWNED_BRANDS_SUCCESS, payload: data.ownedBrands });
  } catch (error) {
    dispatch({ type: FETCH_OWNED_BRANDS_FAIL, payload: error.response.data.message });
  }
};


// Update Brand Action Creator
export const updateBrand = (brandId, brandData) => async (dispatch) => {
    try {
      dispatch({ type: UPDATE_BRAND_REQUEST });
      // Assume you're using FormData to handle files and text
      const { data } = await axios.put(`${server}/brand/update-brand/${brandId}`, brandData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'current-profile': sessionStorage.getItem('currentProfile') || 'defaultProfile',
        },
        withCredentials: true,
      });
      dispatch({ type: UPDATE_BRAND_SUCCESS, payload: data });
    } catch (error) {
      dispatch({ type: UPDATE_BRAND_FAIL, payload: error.response.data.message });
    }
  };

  // Action to set the selected brand
export const setSelectedBrand = (brand) => ({
    type: SET_SELECTED_BRAND,
    payload: brand,
  });
  
  // Action to clear the selected brand
  export const clearSelectedBrand = () => ({
    type: CLEAR_SELECTED_BRAND,
  });


// Create Brand Catalog Item
export const createBrandCatalogItem = (catalogItemData) => async (dispatch) => {
  try {
    dispatch({ type: CREATE_BRAND_CATALOG_ITEM_REQUEST });
    const { data } = await axios.post(`${server}/brand/create-brand-catalog-item`, catalogItemData, {
        headers: {
            'current-profile': sessionStorage.getItem('currentProfile') || 'defaultProfile',
          },
      withCredentials: true,
    });
    dispatch({ type: CREATE_BRAND_CATALOG_ITEM_SUCCESS, payload: data.catalogItem });
  } catch (error) {
    dispatch({ type: CREATE_BRAND_CATALOG_ITEM_FAIL, payload: error.response.data.message });
  }
};

// Fetch Brand Catalog Items Action Creator
export const fetchBrandCatalogItems = (brandId) => async (dispatch) => {
    try {
      dispatch({ type: FETCH_BRAND_CATALOG_ITEMS_REQUEST });
      const { data } = await axios.get(`${server}/brand/catalog-items/${brandId}`, {
        withCredentials: true,
      });
      dispatch({ type: FETCH_BRAND_CATALOG_ITEMS_SUCCESS, payload: data.catalogItems });
    } catch (error) {
      dispatch({ type: FETCH_BRAND_CATALOG_ITEMS_FAIL, payload: error.response.data.message });
    }
  };