import React, { useState, useEffect } from 'react';
import { XMarkIcon } from '@heroicons/react/20/solid';
import AdFormatIconRow from './AdFormatIconRow'; 

const CampaignReview = ({ campaignAds, onEdit }) => {
    const [selectedFormat, setSelectedFormat] = useState('Top Banner Ad'); 

    const formats = [
        { name: 'Top Banner Ad', label: 'Top Banner', icon: 'https://res.cloudinary.com/dzlopmfj8/image/upload/v1724324560/top_banner_ad_gmvlxo.svg' },
        { name: 'Banner Ad', label: 'Bottom Banner', icon: 'https://res.cloudinary.com/dzlopmfj8/image/upload/v1724324989/bottom_banner_ad_yuxoze.svg' },
        { name: 'Sponsored Products', label: 'Sidebar Ad', icon: 'https://res.cloudinary.com/dzlopmfj8/image/upload/v1724325662/sidebar-ad_jdcpmo.png' },
        { name: 'Dual Product Carousel', label: 'Dual Carousel', icon: 'https://res.cloudinary.com/dzlopmfj8/image/upload/v1724327059/dual_carousel_ad_kviftt.svg' },
        { name: 'Quad Product Carousel', label: 'Quad Carousel', icon: 'https://res.cloudinary.com/dzlopmfj8/image/upload/v1724327188/quad_carousel_ad_uig288.svg' },
        { name: 'Sponsored Product Carousel', label: 'Single Carousel', icon: 'https://res.cloudinary.com/dzlopmfj8/image/upload/v1724327318/single_carousel_ad_mnubsr.svg' },
    ];

    useEffect(() => {
        console.log('campaignAds:', campaignAds);
    }, [campaignAds]);

    return (
        <div className="ad-campaign-ad-preview-container">
            <h2 className="ad-campaign-ad-preview-title">Review Your Campaign</h2>

            <AdFormatIconRow 
                formats={formats} 
                selectedFormat={selectedFormat}
                onFormatSelect={setSelectedFormat} 
            />

            {Object.keys(campaignAds).length === 0 ? (
                <p className="ad-campaign-ad-preview-empty-message">No ads have been added to your campaign yet.</p>
            ) : (
                <div className="ad-campaign-ad-preview-list">
                    {Object.keys(campaignAds).map((adType) =>
                        campaignAds[adType].map((ad, index) => (
                            <div key={`${adType}-${index}`} className="ad-campaign-ad-preview-item">
                                <h3 className="ad-campaign-ad-preview-item-title">
                                    {adType === "product" ? "Sponsored Product" :
                                        adType === "shop" ? "Sponsored Shop" :
                                        adType === "brand" ? "Sponsored Brand" :
                                        "Custom Ad"}
                                </h3>

                                <div className="ad-campaign-ad-preview-details">
                                    {adType === "product" && (
                                        <>
                                            <p><strong>Product Name:</strong> {ad.details.productName}</p>
                                            <p><strong>Ad Spend:</strong> ${ad.details.adSpend}</p>
                                            <p><strong>Duration:</strong> {ad.details.adDuration} days</p>
                                        </>
                                    )}
                                    {adType === "shop" && (
                                        <>
                                            <p><strong>Shop Name:</strong> {ad.details.shopName}</p>
                                            <p><strong>Ad Spend:</strong> ${ad.details.adSpend}</p>
                                            <p><strong>Duration:</strong> {ad.details.adDuration} days</p>
                                        </>
                                    )}
                                    {adType === "brand" && (
                                        <>
                                            <p><strong>Brand Name:</strong> {ad.details.brandName}</p>
                                            <p><strong>Ad Spend:</strong> ${ad.details.adSpend}</p>
                                            <p><strong>Duration:</strong> {ad.details.adDuration} days</p>
                                        </>
                                    )}
                                    {adType === "custom" && (
                                        <>
                                            <p><strong>Ad Text:</strong> {ad.details.text}</p>
                                            <p><strong>Link:</strong> {ad.details.link}</p>
                                            <p><strong>Ad Spend:</strong> ${ad.details.adSpend}</p>
                                            <p><strong>Duration:</strong> {ad.details.adDuration} days</p>
                                        </>
                                    )}

                                    <div className="ad-campaign-ad-preview-images">
                                        <p><strong>Ad Preview:</strong></p>
                                        {ad.details && ad.adSet.images && ad.adSet.images[selectedFormat] ? (
                                            <div className={`ad-preview-${selectedFormat.toLowerCase().replace(/\s+/g, '-')} `}>
                                                {selectedFormat === 'Top Banner Ad' && (
                                                    <div className="ad-preview-top-banner-container">
                                                        <div className="ad-preview-top-banner-content">
                                                            <div className="ad-preview-top-banner-image-container">
                                                                <img
                                                                    src={ad.adSet.images[selectedFormat]}
                                                                    alt={`${selectedFormat} preview`}
                                                                    className="ad-preview-top-banner-image"
                                                                />
                                                            </div>
                                                            <div className="ad-preview-top-banner-text">
                                                                <strong className="ad-preview-top-banner-ad-link">{ad.details.productName}</strong>
                                                                <span className="ad-preview-top-banner-ad-link"> – Explore Now &rarr;</span>
                                                            </div>
                                                            <button className="ad-preview-top-banner-ad-dismiss">
                                                                <XMarkIcon className="ad-preview-top-banner-ad-icon" aria-hidden="true" />
                                                                <span className="sr-only">Dismiss</span>
                                                            </button>
                                                        </div>
                                                    </div>
                                                )}
                                                {selectedFormat === 'Banner Ad' && (
                                                    <div className="ad-preview-banner-ad-container">
                                                        <div className="ad-preview-banner-ad-image-container">
                                                            <img
                                                                src={ad.adSet.images[selectedFormat]}
                                                                alt={`${selectedFormat} preview`}
                                                                className="ad-preview-banner-ad-image"
                                                            />
                                                        </div>
                                                        <div className="ad-preview-banner-ad-content">
                                                            <strong className="ad-preview-banner-ad-link">{ad.details.productName}</strong>
                                                            <span className="ad-preview-banner-ad-link"> – Explore Now &rarr;</span>
                                                            <button className="ad-preview-banner-ad-dismiss">
                                                                <XMarkIcon className="ad-preview-banner-ad-icon" aria-hidden="true" />
                                                                <span className="sr-only">Dismiss</span>
                                                            </button>
                                                        </div>
                                                    </div>
                                                )}
                                                {selectedFormat === 'Sponsored Products' && (
                                                    <div className="ad-preview-sponsored-products-container">
                                                        <div className="ad-preview-sponsored-products-list">
                                                            <div className="ad-preview-sponsored-products-item">
                                                                <div className="ad-preview-sponsored-products-image-container">
                                                                    <img
                                                                        src={ad.adSet.images[selectedFormat]}
                                                                        alt={`${selectedFormat} preview`}
                                                                        className="ad-preview-sponsored-products-image"
                                                                    />
                                                                </div>
                                                                <div className="ad-preview-sponsored-products-info">
                                                                    <div>
                                                                        <h3 className="ad-preview-sponsored-products-product-name">{ad.details.productName}</h3>
                                                                        <p className="ad-preview-sponsored-products-product-price">$100</p>
                                                                    </div>
                                                                    <span className="ad-preview-sponsored-products-visit-store">Visit Store</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                                {selectedFormat === 'Dual Product Carousel' && (
                                                    <div className="ad-preview-dual-product-carousel-container">
                                                        <div className="ad-preview-dual-product-carousel-wrapper">
                                                        <div className="ad-preview-dual-product-carousel-item empty-box"></div>
                                                            <div className="ad-preview-dual-product-carousel-item">
                                                                <div className="ad-preview-dual-product-carousel-image-container">
                                                                    <img
                                                                        src={ad.adSet.images[selectedFormat]}
                                                                        alt={`${selectedFormat} preview`}
                                                                        className="ad-preview-dual-product-carousel-image"
                                                                    />
                                                                </div>
                                                                <div className="ad-preview-dual-product-carousel-content">
                                                                    <h2 className="ad-preview-dual-product-carousel-title">{ad.details.productName}</h2>
                                                                    <p className="ad-preview-dual-product-carousel-price">$100</p>
                                                                    <span className="ad-preview-dual-product-carousel-button">Buy now</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                                {selectedFormat === 'Quad Product Carousel' && (
                                                    <div className="ad-preview-quad-product-carousel-container">
                                                        <div className="ad-preview-quad-product-carousel-wrapper">
                                                        <div className="ad-preview-quad-product-carousel-item empty-box"></div>
                                            <div className="ad-preview-quad-product-carousel-item empty-box"></div>
                                            <div className="ad-preview-quad-product-carousel-item empty-box"></div>
                                                            <div className="ad-preview-quad-product-carousel-item">
                                                                <div className="ad-preview-quad-product-carousel-image-container">
                                                                    <img
                                                                        src={ad.adSet.images[selectedFormat]}
                                                                        alt={`${selectedFormat} preview`}
                                                                        className="ad-preview-quad-product-carousel-image"
                                                                    />
                                                                </div>
                                                                <div className="ad-preview-quad-product-carousel-content">
                                                                    <h2 className="ad-preview-quad-product-carousel-title">{ad.details.productName}</h2>
                                                                    <p className="ad-preview-quad-product-carousel-price">$100</p>
                                                                    <span className="ad-preview-quad-product-carousel-button">Buy now</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                                {selectedFormat === 'Sponsored Product Carousel' && (
                                                    <div className="ad-preview-sponsored-product-carousel">
                                                        <div className="ad-preview-sponsored-product-carousel-image-container">
                                                            <img
                                                                src={ad.adSet.images[selectedFormat]}
                                                                alt={`${selectedFormat} preview`}
                                                                className="ad-preview-sponsored-product-carousel-image"
                                                            />
                                                        </div>
                                                        <div className="ad-preview-sponsored-product-carousel-content">
                                                            <h2 className="ad-preview-sponsored-product-carousel-title">{ad.details.productName}</h2>
                                                            <p className="ad-preview-sponsored-product-carousel-price">$100</p>
                                                            <span className="ad-preview-sponsored-product-carousel-button">Buy now</span>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        ) : (
                                            <p>No ad preview available for {selectedFormat}.</p>
                                        )}
                                    </div>

                                    <button
                                        className="ad-campaign-ad-preview-edit-button"
                                        onClick={() => onEdit(adType, index)}
                                    >
                                        Edit
                                    </button>
                                </div>
                            </div>
                        ))
                    )}
                </div>
            )}
        </div>
    );
};

export default CampaignReview;



