import React, { useState, useEffect } from "react";
import {
  AiOutlineArrowRight,
  AiOutlineCamera,
  AiOutlineDelete,
} from "react-icons/ai";
import { MdTrackChanges } from "react-icons/md";
import { RxCross1 } from "react-icons/rx";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Country, State } from "country-state-city";
import { toast } from "react-toastify";
import axios from "axios";
import { server } from "../../server";
import {
  deleteUserAddress,
  loadUser,
  updatUserAddress,
  updateUserInformation,
} from "../../redux/actions/user";
import { getAllOrdersOfUser } from "../../redux/actions/order";
import UserSubscriptions from "../Subscription/UserSubscriptions";


const ProfileContent = ({ active }) => {
  const { user, error, successMessage } = useSelector((state) => state.user);
  const [name, setName] = useState(user?.name || "");
  const [email, setEmail] = useState(user?.email || "");
  const [phoneNumber, setPhoneNumber] = useState(user?.phoneNumber || "");
  const [password, setPassword] = useState("");
  const [avatar, setAvatar] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (error) {
      toast.error(error);
      dispatch({ type: "clearErrors" });
    }
    if (successMessage) {
      toast.success(successMessage);
      dispatch({ type: "clearMessages" });
    }
  }, [error, successMessage]);

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(updateUserInformation(name, email, phoneNumber, password));
  };

  const handleImage = async (e) => {
    const reader = new FileReader();

    reader.onload = () => {
      if (reader.readyState === 2) {
        setAvatar(reader.result);
        axios
          .put(
            `${server}/user/update-avatar`,
            { avatar: reader.result },
            {
              withCredentials: true,
            }
          )
          .then(() => {
            dispatch(loadUser());
            toast.success("Avatar updated successfully!");
          })
          .catch((error) => {
            toast.error(error.message);
          });
      }
    };

    reader.readAsDataURL(e.target.files[0]);
  };

  return (
    <div className="profile-content-component-container">
      {active === 1 && (
        <>
          <div className="profile-content-component-avatar-container">
            <div className="profile-content-component-avatar-wrapper">
              <img
                src={user?.avatar?.url}
                className="profile-content-component-avatar"
                alt="User Avatar"
              />
              <div className="profile-content-component-avatar-overlay">
                <input
                  type="file"
                  id="image"
                  className="profile-content-component-avatar-input"
                  onChange={handleImage}
                />
                <label htmlFor="image" className="profile-content-component-avatar-label">
                  <AiOutlineCamera />
                </label>
              </div>
            </div>
          </div>
          <div className="profile-content-component-form-container">
            <form onSubmit={handleSubmit}>
              <div className="profile-content-component-form-row">
                <div className="profile-content-component-form-group">
                  <label className="profile-content-component-form-label">Full Name</label>
                  <input
                    type="text"
                    className="profile-content-component-form-input"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                  />
                </div>
                <div className="profile-content-component-form-group">
                  <label className="profile-content-component-form-label">Email Address</label>
                  <input
                    type="email"
                    className="profile-content-component-form-input"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </div>
              </div>
              <div className="profile-content-component-form-row">
                <div className="profile-content-component-form-group">
                  <label className="profile-content-component-form-label">Phone Number</label>
                  <input
                    type="text"
                    className="profile-content-component-form-input"
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                    required
                  />
                </div>
                <div className="profile-content-component-form-group">
                  <label className="profile-content-component-form-label">Enter your password</label>
                  <input
                    type="password"
                    className="profile-content-component-form-input"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                </div>
              </div>
              <button type="submit" className="profile-content-component-submit-btn">
                Update
              </button>
            </form>
          </div>
        </>
      )}

      {active === 2 && <AllOrders />}
      {active === 3 && <AllRefundOrders />}
      {active === 5 && <TrackOrder />}
      {active === 6 && <ChangePassword />}
      {active === 7 && <Address />}
      {active === 9 && <UserSubscriptions />}
    </div>
  );
};

const AllOrders = () => {
  const { user } = useSelector((state) => state.user);
  const { orders } = useSelector((state) => state.order);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllOrdersOfUser(user._id));
  }, [dispatch, user._id]);

  return (
    <div className="profile-content-component-orders">
      <table className="profile-content-component-table">
        <thead>
          <tr>
            <th>Order ID</th>
            <th>Status</th>
            <th>Items Qty</th>
            <th>Total</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {orders &&
            orders.map((order) => (
              <tr key={order._id}>
                <td>{order._id}</td>
                <td className={order.status === "Delivered" ? "greenColor" : "redColor"}>
                  {order.status}
                </td>
                <td>{order.cart.length}</td>
                <td>{`US$ ${order.totalPrice}`}</td>
                <td>
                  <Link to={`/user/order/${order._id}`}>
                    <button className="profile-content-component-view-btn">
                      <AiOutlineArrowRight />
                    </button>
                  </Link>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

const AllRefundOrders = () => {
  const { user } = useSelector((state) => state.user);
  const { orders } = useSelector((state) => state.order);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllOrdersOfUser(user._id));
  }, [dispatch, user._id]);

  const eligibleOrders = orders?.filter((order) => order.status === "Processing refund");

  return (
    <div className="profile-content-component-orders">
      <table className="profile-content-component-table">
        <thead>
          <tr>
            <th>Order ID</th>
            <th>Status</th>
            <th>Items Qty</th>
            <th>Total</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {eligibleOrders &&
            eligibleOrders.map((order) => (
              <tr key={order._id}>
                <td>{order._id}</td>
                <td className={order.status === "Delivered" ? "greenColor" : "redColor"}>
                  {order.status}
                </td>
                <td>{order.cart.length}</td>
                <td>{`US$ ${order.totalPrice}`}</td>
                <td>
                  <Link to={`/user/order/${order._id}`}>
                    <button className="profile-content-component-view-btn">
                      <AiOutlineArrowRight />
                    </button>
                  </Link>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

const TrackOrder = () => {
  const { user } = useSelector((state) => state.user);
  const { orders } = useSelector((state) => state.order);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllOrdersOfUser(user._id));
  }, [dispatch, user._id]);

  return (
    <div className="profile-content-component-orders">
      <table className="profile-content-component-table">
        <thead>
          <tr>
            <th>Order ID</th>
            <th>Status</th>
            <th>Items Qty</th>
            <th>Total</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {orders &&
            orders.map((order) => (
              <tr key={order._id}>
                <td>{order._id}</td>
                <td className={order.status === "Delivered" ? "greenColor" : "redColor"}>
                  {order.status}
                </td>
                <td>{order.cart.length}</td>
                <td>{`US$ ${order.totalPrice}`}</td>
                <td>
                  <Link to={`/user/track/order/${order._id}`}>
                    <button className="profile-content-component-view-btn">
                      <MdTrackChanges />
                    </button>
                  </Link>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

const ChangePassword = () => {
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const passwordChangeHandler = async (e) => {
    e.preventDefault();

    await axios
      .put(
        `${server}/user/update-user-password`,
        { oldPassword, newPassword, confirmPassword },
        { withCredentials: true }
      )
      .then((res) => {
        toast.success(res.data.success);
        setOldPassword("");
        setNewPassword("");
        setConfirmPassword("");
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };

  return (
    <div className="profile-content-component-password-change">
      <h1 className="profile-content-component-title">Change Password</h1>
      <form onSubmit={passwordChangeHandler} className="profile-content-component-form">
        <div className="profile-content-component-form-group">
          <label className="profile-content-component-form-label">Enter your old password</label>
          <input
            type="password"
            className="profile-content-component-form-input"
            value={oldPassword}
            onChange={(e) => setOldPassword(e.target.value)}
            required
          />
        </div>
        <div className="profile-content-component-form-group">
          <label className="profile-content-component-form-label">Enter your new password</label>
          <input
            type="password"
            className="profile-content-component-form-input"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            required
          />
        </div>
        <div className="profile-content-component-form-group">
          <label className="profile-content-component-form-label">Enter your confirm password</label>
          <input
            type="password"
            className="profile-content-component-form-input"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
          />
        </div>
        <button type="submit" className="profile-content-component-submit-btn">
          Update
        </button>
      </form>
    </div>
  );
};

const Address = () => {
  const [open, setOpen] = useState(false);
  const [country, setCountry] = useState("");
  const [city, setCity] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [addressType, setAddressType] = useState("");
  const { user } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const addressTypeData = [
    { name: "Default" },
    { name: "Home" },
    { name: "Office" },
  ];

  const handleSubmit = (e) => {
    e.preventDefault();

    if (addressType === "" || country === "" || city === "") {
      toast.error("Please fill all the fields!");
    } else {
      dispatch(updatUserAddress(country, city, address1, address2, zipCode, addressType));
      setOpen(false);
      setCountry("");
      setCity("");
      setAddress1("");
      setAddress2("");
      setZipCode("");
      setAddressType("");
    }
  };

  const handleDelete = (item) => {
    dispatch(deleteUserAddress(item._id));
  };

  return (
    <div className="profile-content-component-address">
      {open && (
        <div className="profile-content-component-modal">
          <div className="profile-content-component-modal-content">
            <div className="profile-content-component-modal-close">
              <RxCross1 size={30} onClick={() => setOpen(false)} />
            </div>
            <h1 className="profile-content-component-modal-title">Add New Address</h1>
            <form onSubmit={handleSubmit}>
              <div className="profile-content-component-modal-form-group">
                <label className="profile-content-component-form-label">Country</label>
                <select
                  value={country}
                  onChange={(e) => setCountry(e.target.value)}
                  className="profile-content-component-form-input"
                >
                  <option value="">Choose Your Country</option>
                  {Country.getAllCountries().map((item) => (
                    <option key={item.isoCode} value={item.isoCode}>
                      {item.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="profile-content-component-modal-form-group">
                <label className="profile-content-component-form-label">City</label>
                <select
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                  className="profile-content-component-form-input"
                >
                  <option value="">Choose Your City</option>
                  {State.getStatesOfCountry(country).map((item) => (
                    <option key={item.isoCode} value={item.isoCode}>
                      {item.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="profile-content-component-modal-form-group">
                <label className="profile-content-component-form-label">Address 1</label>
                <input
                  type="text"
                  value={address1}
                  onChange={(e) => setAddress1(e.target.value)}
                  className="profile-content-component-form-input"
                  required
                />
              </div>
              <div className="profile-content-component-modal-form-group">
                <label className="profile-content-component-form-label">Address 2</label>
                <input
                  type="text"
                  value={address2}
                  onChange={(e) => setAddress2(e.target.value)}
                  className="profile-content-component-form-input"
                />
              </div>
              <div className="profile-content-component-modal-form-group">
                <label className="profile-content-component-form-label">Zip Code</label>
                <input
                  type="text"
                  value={zipCode}
                  onChange={(e) => setZipCode(e.target.value)}
                  className="profile-content-component-form-input"
                  required
                />
              </div>
              <div className="profile-content-component-modal-form-group">
                <label className="profile-content-component-form-label">Address Type</label>
                <select
                  value={addressType}
                  onChange={(e) => setAddressType(e.target.value)}
                  className="profile-content-component-form-input"
                >
                  <option value="">Choose Your Address Type</option>
                  {addressTypeData.map((item) => (
                    <option key={item.name} value={item.name}>
                      {item.name}
                    </option>
                  ))}
                </select>
              </div>
              <button type="submit" className="profile-content-component-submit-btn">
                Save
              </button>
            </form>
          </div>
        </div>
      )}
      <div className="profile-content-component-address-header">
        <h1 className="profile-content-component-title">My Addresses</h1>
        <button className="profile-content-component-add-btn" onClick={() => setOpen(true)}>
          Add New
        </button>
      </div>
      {user?.addresses.length > 0 ? (
        user.addresses.map((item, index) => (
          <div className="profile-content-component-address-item" key={index}>
            <div>
              <h5>{item.addressType}</h5>
              <p>
                {item.address1}, {item.address2}, {item.city}, {item.zipCode}
              </p>
            </div>
            <AiOutlineDelete size={25} onClick={() => handleDelete(item)} />
          </div>
        ))
      ) : (
        <p className="profile-content-component-no-address">There are no saved addresses.</p>
      )}
    </div>
  );
};

export default ProfileContent;

