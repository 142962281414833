import { createReducer } from "@reduxjs/toolkit";
import {
  CREATE_POST_REQUEST,
  CREATE_POST_SUCCESS,
  CREATE_POST_FAILURE,
  UPLOAD_IMAGE_REQUEST,
  UPLOAD_IMAGE_SUCCESS,
  UPLOAD_IMAGE_FAILURE,
  UPLOAD_VIDEO_REQUEST,
  UPLOAD_VIDEO_SUCCESS,
  UPLOAD_VIDEO_FAILURE,
  LIKE_POST_SUCCESS,
  POST_COMMENT_SUCCESS,
  EDIT_POST_SUCCESS,
  EDIT_COMMENT_SUCCESS,
  DELETE_POST_SUCCESS,
  DELETE_COMMENT_SUCCESS,
  FETCH_COMMENTS_SUCCESS,
  OPERATION_FAIL,
  OPERATION_REQUEST,
  OPERATION_COMPLETE,
  CLEAR_ERRORS,
  FETCH_POSTS_REQUEST, 
  FETCH_POSTS_SUCCESS, 
  FETCH_POSTS_FAILURE,
  RESHARE_POST_REQUEST,
  RESHARE_POST_SUCCESS,
  RESHARE_POST_FAILURE,
  FETCH_LIKES_SUCCESS,
  REPORT_POST_REQUEST,
  REPORT_POST_SUCCESS,
  REPORT_POST_FAILURE,
  FETCH_REPORTED_POSTS_REQUEST,
  FETCH_REPORTED_POSTS_SUCCESS,
  FETCH_REPORTED_POSTS_FAILURE,
  FETCH_SINGLE_POST_REQUEST,
  FETCH_SINGLE_POST_SUCCESS,
  FETCH_SINGLE_POST_FAILURE,
  FETCH_SINGLE_POST_COMMENTS_REQUEST,
  FETCH_SINGLE_POST_COMMENTS_SUCCESS, 
  FETCH_SINGLE_POST_COMMENTS_FAIL,
  FETCH_SINGLE_POST_LIKES_REQUEST,
  FETCH_SINGLE_POST_LIKES_SUCCESS, 
  FETCH_SINGLE_POST_LIKES_FAIL, 
  CLEAR_POSTS,
} from '../actions/post'; 

const initialState = {
  posts: [],
  singlePost: null,
  singlePostComments: [],
  singlePostLikes: {},
  hashtagposts: [],
  comments: {},
  likes: {},
  hashtags: [],
  reportedPosts: [],
  loading: false,
  error: null,
};

export const postsReducer = createReducer(initialState, {

    [CREATE_POST_REQUEST]: (state) => {
        state.loading = true;
        state.error = null;
      },
      [CREATE_POST_SUCCESS]: (state, action) => {
        state.loading = false;
        state.posts.unshift(action.payload); 
        state.error = null;
      },
      [CREATE_POST_FAILURE]: (state, action) => {
        state.loading = false;
        state.error = action.payload;
      },
      [UPLOAD_IMAGE_REQUEST]: (state) => {
        state.loading = true;
    },
    [UPLOAD_IMAGE_SUCCESS]: (state, action) => {
        state.loading = false;
        state.imageUploadUrl = action.payload; 
    },
    [UPLOAD_IMAGE_FAILURE]: (state, action) => {
        state.loading = false;
        state.error = action.payload;
    },
    [UPLOAD_VIDEO_REQUEST]: (state) => {
        state.loading = true;
    },
    [UPLOAD_VIDEO_SUCCESS]: (state, action) => {
        state.loading = false;
        state.videoUploadUrl = action.payload; 
    },
    [UPLOAD_VIDEO_FAILURE]: (state, action) => {
        state.loading = false;
        state.error = action.payload;
    },
    [RESHARE_POST_REQUEST]: (state) => {
        state.loading = true;
        state.error = null;
    },
    [RESHARE_POST_SUCCESS]: (state, action) => {
        state.loading = false;
        const resharedPost = {
            ...action.payload,
            likes: {}, 
            comments: [] 
        };
        state.posts.unshift(resharedPost);
        state.error = null;
    },
    
    [RESHARE_POST_FAILURE]: (state, action) => {
        state.loading = false;
        state.error = action.payload;
    },

  // Handle fetch posts request
  [FETCH_POSTS_REQUEST]: (state) => {
    state.loading = true;
    state.error = null;
  },

[FETCH_POSTS_SUCCESS]: (state, action) => {
  state.loading = false;
  const newPosts = action.payload.posts.filter(post => !state.posts.some(p => p._id === post._id));
  state.posts = [...state.posts, ...newPosts];
  state.page = action.payload.page;
  state.totalPages = action.payload.totalPages;
  state.error = null;
},


  // Handle fetch posts failure
  [FETCH_POSTS_FAILURE]: (state, action) => {
    state.loading = false;
    state.error = action.payload; 
  },

  [FETCH_SINGLE_POST_REQUEST]: (state) => {
    state.loading = true;
    state.error = null;
    state.singlePost = null; 
  },
  [FETCH_SINGLE_POST_SUCCESS]: (state, action) => {
    state.loading = false;
    state.singlePost = action.payload; 
    state.error = null;
  },
  [FETCH_SINGLE_POST_FAILURE]: (state, action) => {
    state.loading = false;
    state.error = action.payload;
  },
  [FETCH_SINGLE_POST_COMMENTS_REQUEST]: (state) => {
    state.loading = true;
    state.error = null;
},
  [FETCH_SINGLE_POST_COMMENTS_SUCCESS]: (state, action) => {
    const { comments } = action.payload;
    state.singlePostComments = comments;
    state.loading = false;
  },
  [FETCH_SINGLE_POST_COMMENTS_FAIL]: (state, action) => {
    state.error = action.payload;
    state.loading = false;
  },
  [FETCH_SINGLE_POST_LIKES_REQUEST]: (state) => {
    state.loading = true;
    state.error = null;
},
  [FETCH_SINGLE_POST_LIKES_SUCCESS]: (state, action) => {
    const { postId, likes } = action.payload;
    state.singlePostLikes[postId] = likes;
    state.loading = false;
  },
  [FETCH_SINGLE_POST_LIKES_FAIL]: (state, action) => {
    state.error = action.payload;
    state.loading = false;
  },

  [CLEAR_POSTS]: (state) => {
    state.posts = [];
  },

  [LIKE_POST_SUCCESS]: (state, action) => {
    const { postId, likes } = action.payload;
    const index = state.posts.findIndex(post => post._id === postId);
    if (index !== -1) {
      state.posts[index] = { ...state.posts[index], likes };
      state.likes[postId] = likes; 
    }
  },
  [FETCH_LIKES_SUCCESS]: (state, action) => {
    const { postId, likes } = action.payload;
    state.likes[postId] = likes;
  },
  
  [POST_COMMENT_SUCCESS]: (state, action) => {
    const { postId, comment } = action.payload;
    if (!state.comments[postId]) {
      state.comments[postId] = [comment];
    } else {
      state.comments[postId].push(comment);
    }
  },

  [EDIT_POST_SUCCESS]: (state, action) => {
    const index = state.posts.findIndex(post => post._id === action.payload._id);
    if (index !== -1) {
      const updatedPosts = [...state.posts];
      updatedPosts[index] = { ...updatedPosts[index], ...action.payload };
      return {
        ...state,
        posts: updatedPosts,
      };
    }
    return state; 
  },
  [EDIT_COMMENT_SUCCESS]: (state, action) => {
    const { postId, commentId, updatedComment } = action.payload;
    if (state.comments[postId]) {
      const index = state.comments[postId].findIndex(comment => comment._id === commentId);
      if (index !== -1) {
        state.comments[postId][index] = updatedComment; 
      }
    }
  },
  [DELETE_POST_SUCCESS]: (state, action) => {
    const postId = action.payload;
    state.posts = state.posts.filter(post => post._id !== postId); 
    delete state.comments[postId]; 
  },
  [DELETE_COMMENT_SUCCESS]: (state, action) => {
    const { postId, commentId } = action.payload;
    if (state.comments[postId]) {
      state.comments[postId] = state.comments[postId].filter(comment => comment._id !== commentId);
    }
  },
  [FETCH_COMMENTS_SUCCESS]: (state, action) => {
    const { postId, comments } = action.payload;
    state.comments[postId] = comments; 
  },
  [REPORT_POST_REQUEST]: (state) => {
    state.loading = true;
  },
  [REPORT_POST_SUCCESS]: (state) => {
    state.loading = false;
    state.error = null;
  },
  [REPORT_POST_FAILURE]: (state, action) => {
    state.loading = false;
    state.error = action.payload;
  },
  [FETCH_REPORTED_POSTS_REQUEST]: (state) => {
    state.loading = true;
    state.error = null;
  },
  [FETCH_REPORTED_POSTS_SUCCESS]: (state, action) => {
    state.loading = false;
    state.reportedPosts = action.payload;
    state.error = null;
  },
  [FETCH_REPORTED_POSTS_FAILURE]: (state, action) => {
    state.loading = false;
    state.error = action.payload;
  },
  [OPERATION_FAIL]: (state, action) => {
    state.error = action.payload; 
  },
  [OPERATION_REQUEST]: (state) => {
    state.loading = true; 
  },
  [OPERATION_COMPLETE]: (state) => {
    state.loading = false;
    state.error = null; 
  },
  [CLEAR_ERRORS]: (state) => {
    state.error = null; 
  },
});



