import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { server } from '../../server'; 

const RoomCreationForm = () => {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [bannerImage, setBannerImage] = useState(null);
  const [bannerPreview, setBannerPreview] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const currentProfile = useSelector(state => state.user.currentProfile);

  const handleBannerChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setBannerImage(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setBannerPreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');

    const formData = new FormData();
    formData.append('name', name);
    formData.append('description', description);
    if (bannerImage) {
      formData.append('bannerImage', bannerImage);
    }

    try {
      const response = await axios.post(`${server}/room/rooms/create`, formData, {
        withCredentials: true,
        headers: {
          'Content-Type': 'multipart/form-data',
          'current-profile': currentProfile
        }
      });

      if (response.status === 201) {
        alert('Room created successfully!');
        navigate('/rooms'); 
      }
    } catch (err) {
      setError(err.response?.data?.message || 'An error occurred while creating the room.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="room-creation-form-container">
      <h2>Create a New Room</h2>
      <form onSubmit={handleSubmit} className="room-creation-form">
        <div className="room-creation-form-group">
          <label htmlFor="name">Room Name</label>
          <input
            type="text"
            id="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
            className="room-creation-form-input"
          />
        </div>

        <div className="room-creation-form-group">
          <label htmlFor="description">Room Description</label>
          <textarea
            id="description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            rows="4"
            maxLength="500"
            placeholder="Enter a brief description of the room (max 500 characters)"
            className="room-creation-form-input"
          />
        </div>

        <div className="room-creation-form-group">
          <label htmlFor="banner">Room Banner</label>
          <input
            type="file"
            id="banner"
            accept="image/*"
            onChange={handleBannerChange}
            className="room-creation-form-input"
          />
          {bannerPreview && (
            <div className="room-creation-form-banner-preview">
              <img src={bannerPreview} alt="Banner Preview" />
            </div>
          )}
        </div>

        {error && <p className="room-creation-form-error">{error}</p>}
        
        <button type="submit" className="room-creation-form-button" disabled={loading}>
          {loading ? 'Creating...' : 'Create Room'}
        </button>
      </form>
    </div>
  );
};

export default RoomCreationForm;


