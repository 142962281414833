import React, { useState, useEffect } from 'react';
import Header from '../components/Layout/Header';
import Sidebar from '../components/Layout/Sidebar';
import AdvertWidget from '../widgets/AdvertWidget';
import { useSelector } from "react-redux";
import MyPostWidgetRedux from '../widgets/MyPostWidgetRedux';
import SponsoredProducts from '../components/Sponsored/SponsoredProducts';
import NotificationPopup from '../components/Notifications/notificationspopup';
import MainFeedWidgetRedux from '../widgets/MainFeedWidgetRedux';
import SponsoredShops from '../components/Sponsored/SponsoredShops';
import SponsoredBrands from '../components/Sponsored/SponsoredBrands';
import MyEvents from '../components/Events/MyEvents';
import MixedSponsoredAds from '../components/Sponsored/MixedSponsoredAds';


const LandingPageTest = () => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const picturePath = user?.picturePath;
  const currentProfile = useSelector((state) => state.user.currentProfile);
  const { seller } = useSelector((state) => state.seller);
  const userId = user?._id;
  const sellerId = seller?._id;
  const id = currentProfile === 'User' ? userId : sellerId;

  return (
    <div className="main-feed-page">
      <Header />
      <Sidebar />
      <NotificationPopup />
      <div className="mx-auto flex w-full flex-col lg:flex-row items-start gap-x-8 px-4 py-10 sm:px-6 lg:px-24">
        
        {/* Main content area, always visible */}
        <main className="main-feed-page-main-content p-2 w-full lg:order-2 main-feed-page-scrollable">
          <MyPostWidgetRedux picturePath={picturePath} />
          <MainFeedWidgetRedux ownerId={id} profileType={currentProfile} isProfile={false} />
        </main>

        {/* Sponsored Products, visible on large screens */}
        <aside className="main-feed-page-sticky-sidebar hidden lg:block w-full lg:w-1/4 shrink-0 bg-[#f5f5f5] p-4 rounded-lg lg:order-3 main-feed-page-scrollable">
          <MixedSponsoredAds />
        </aside>
        
        {/* Advert Widget, initially hidden on large screens but visible on mobile */}
        {/* This aside will show the AdvertWidget at the bottom for mobile screens and keep it on the side for large screens */}
        <aside className="w-full lg:hidden p-2 rounded-lg bg-[#f5f5f5] lg:order-1">
          <SponsoredBrands />
          
        </aside>
        
        {/* For large screens, re-render AdvertWidget in its original position */}
        <aside className="main-feed-page-sticky-sidebar hidden lg:block w-full lg:w-1/4 shrink-0 bg-[#f5f5f5] p-2 rounded-lg lg:order-1 main-feed-page-scrollable">
          <MyEvents />
          
          
        </aside>
      </div>
    </div>
  );
};


export default LandingPageTest;
