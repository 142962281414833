import React from 'react';
import { useSelector } from 'react-redux';

export default function TrendingWholesaleCarousel() {
  const { allProducts, isLoading } = useSelector((state) => state.products);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  // Filter to include only wholesale products
  const wholesaleProducts = allProducts.filter(product => product.productType.includes('wholesale'));

  return (
    <div className="trending-products-carousel-wholesale-bg">
      <div className="trending-products-carousel-wholesale-container">
        <div className="trending-products-carousel-wholesale-header">
          <h2 className="trending-products-carousel-wholesale-title">Trending Wholesale Products</h2>
          <a href="/wholesale-products" className="trending-products-carousel-wholesale-link-hidden">
            See more <span aria-hidden="true"> &rarr;</span>
          </a>
        </div>

        <div className="trending-products-carousel-wholesale-wrapper">
          <div className="trending-products-carousel-wholesale-scroll">
            <ul
              role="list"
              className="trending-products-carousel-wholesale-list"
            >
              {wholesaleProducts.map((product) => (
                <li key={product._id} className="trending-products-carousel-wholesale-item">
                  <div className="trending-products-carousel-wholesale-card">
                    <div className="trending-products-carousel-wholesale-image-container">
                      <a href={`/product/${product._id}`}>
                        <img
                          src={product.images[0]?.url}
                          alt={product.name}
                          className="trending-products-carousel-wholesale-image"
                        />
                        <div className="trending-products-carousel-wholesale-info">
                          <p className="trending-products-carousel-wholesale-category">{product.category}</p>
                          <h3 className="trending-products-carousel-wholesale-product-name">
                            {product.name}
                          </h3>
                          <p className="trending-products-carousel-wholesale-price">{product.discountPrice ? `$${product.discountPrice}` : 'Price not available'}</p>
                        </div>
                      </a>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>

        <div className="trending-products-carousel-wholesale-footer">
          <a href="/wholesale/products" className="trending-products-carousel-wholesale-link">
            See more <span aria-hidden="true"> &rarr;</span>
          </a>
        </div>
      </div>
    </div>
  );
}
