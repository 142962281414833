import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { server } from "../../server";

const QuadProductAdCarousel = () => {
    const [productQuads, setProductQuads] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [currentIndex, setCurrentIndex] = useState(0);
    const adRefs = useRef(new Map()); // To store ad refs

    // Function to log impressions
    const logImpression = async (adId) => {
        try {
            await axios.post(`${server}/sponsored/impression/${adId}`, {}, {
                withCredentials: true,
                headers: {
                    'current-profile': sessionStorage.getItem('currentProfile') || 'defaultProfile',
                }
            });
            console.log(`Impression logged for product: ${adId}`);
        } catch (error) {
            console.error(`Error logging impression for product: ${adId}`, error);
        }
    };

    // Function to log viewed impressions
    const logViewedImpression = async (adId) => {
        try {
            await axios.post(`${server}/sponsored/viewed-impression/${adId}`, {}, {
                withCredentials: true,
                headers: {
                    'current-profile': sessionStorage.getItem('currentProfile') || 'defaultProfile',
                }
            });
            console.log(`Viewed impression logged for product: ${adId}`);
        } catch (error) {
            console.error(`Error logging viewed impression for product: ${adId}`, error);
        }
    };

    useEffect(() => {
        const fetchProducts = async () => {
            try {
                const response = await axios.get(`${server}/sponsored/sponsored-products`, { withCredentials: true });
                const activeProducts = response.data.filter(ad => ad.isActive && ad.product);

                const quads = [];
                for (let i = 0; i < activeProducts.length; i += 4) {
                    const quad = activeProducts.slice(i, i + 4);

                    // Only add the quad if there are exactly 4 ads
                    if (quad.length === 4) {
                        quads.push(quad.map(ad => ({
                            ...ad,
                            product_id: ad.product._id,
                            name: ad.product.name,
                            adSet: ad.adSet,
                            discountPrice: ad.product.discountPrice,
                            originalPrice: ad.product.originalPrice
                        })));
                    }
                }
                
                setProductQuads(quads);
                setIsLoading(false);
            } catch (error) {
                console.error('Error fetching products:', error);
                setIsLoading(false);
            }
        };

        fetchProducts();
    }, []);

    useEffect(() => {
        productQuads.forEach(quad => {
            quad.forEach(ad => {
                if (ad.product_id) {
                    logImpression(ad._id);
                }
            });
        });
    }, [productQuads]);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        const adId = entry.target.dataset.adId;

                        // Start a timer for 1 second to log viewed impression
                        const timer = setTimeout(() => {
                            logViewedImpression(adId);
                        }, 1000);

                        // Store the timer in the DOM element
                        entry.target._timer = timer;
                    } else {
                        // If the ad goes out of view before 1 second, clear the timer
                        clearTimeout(entry.target._timer);
                    }
                });
            },
            { threshold: 0.5 } // 50% of the ad needs to be visible
        );

        // Observe each ad in the productQuads
        productQuads.forEach((quad) => {
            quad.forEach((ad) => {
                const adElement = adRefs.current.get(ad._id);
                if (adElement) {
                    observer.observe(adElement);
                }
            });
        });

        return () => {
            observer.disconnect();
        };
    }, [productQuads]);

    const logAdClick = async (ad_id) => {
        try {
            await axios.post(`${server}/sponsored/click/${ad_id}`, {
                withCredentials: true,
                headers: {
                    'current-profile': sessionStorage.getItem('currentProfile') || 'defaultProfile',
                }
            });
            console.log('Click logged for product:', ad_id);
        } catch (error) {
            console.error('Error logging click:', error);
        }
    };

    const nextSlide = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % productQuads.length);
    };

    const prevSlide = () => {
        setCurrentIndex((prevIndex) => (prevIndex - 1 + productQuads.length) % productQuads.length);
    };

    return (
        <div className="quad-product-ad-carousel-container">
            {isLoading ? (
                <div className="quad-product-ad-carousel-loading">Loading...</div>
            ) : (
                <>
                    {/* Quad wrapper with translateX transition */}
                    <div
                        className="quad-product-ad-carousel-wrapper"
                        style={{
                            display: 'flex',
                            width: '100%',
                            transform: `translateX(-${currentIndex * 100}%)`,
                            transition: 'transform 0.5s ease-in-out',
                        }}
                    >
                        {productQuads.map((quad, index) => (
                            <div key={index} className="quad-product-ad-carousel-quad" style={{ flex: '1 0 100%', display: 'flex' }}>
                                {quad.map((ad, idx) => (
                                    ad.product_id ? (
                                        <Item key={ad._id} ad={ad} logAdClick={() => logAdClick(ad._id)} adRefs={adRefs} />
                                    ) : (
                                        <div key={idx} className="quad-product-ad-carousel-error">Error: Missing product data.</div>
                                    )
                                ))}
                            </div>
                        ))}
                    </div>
                    {productQuads.length > 1 && (
                        <>
                            <button className="sponsored-carousel-nav-button sponsored-carousel-prev" onClick={prevSlide}>‹</button>
                            <button className="sponsored-carousel-nav-button sponsored-carousel-next" onClick={nextSlide}>›</button>
                        </>
                    )}
                </>
            )}
        </div>
    );
};

const Item = ({ ad, logAdClick, adRefs }) => {
    const { images, positions } = ad.adSet;

    const imageUrl = images["Quad Product Carousel"];
    const position = positions["Quad Product Carousel"] || { x: 0, y: 0 };

    return (
        <div
            className="quad-product-ad-carousel-item"
            data-ad-id={ad._id} // To track the ad in IntersectionObserver
            ref={(el) => el && adRefs.current.set(ad._id, el)} // Save the ref
        >
            <div className="quad-product-ad-carousel-image-wrapper">
                <img
                    src={imageUrl}
                    alt={ad.name}
                    className="quad-product-ad-carousel-image"
                    style={{
                        transform: `translate(${position.x}px, ${position.y}px)`,
                    }}
                />
            </div>
            <div className="quad-product-ad-carousel-content">
                <h2 className="quad-product-ad-carousel-title">{ad.name}</h2>
                <p className="quad-product-ad-carousel-price">{`$${ad.discountPrice || ad.originalPrice}`}</p>
                <Link
                    to={`/product/${ad.product_id}`}
                    onClick={logAdClick}
                    className="quad-product-ad-carousel-button"
                >
                    Buy now
                </Link>
            </div>
        </div>
    );
};

export default QuadProductAdCarousel;






