import React, { useRef, useState } from 'react';
import SignatureCanvas from 'react-signature-canvas';

const WaiverOfLiability = ({ nextStep, prevStep, handleChange, formData }) => {
    const [error, setError] = useState('');
    const [isAgreed, setIsAgreed] = useState(false);
    const sigCanvas = useRef({});

    const handleNext = () => {
        if (!isAgreed) {
            setError('You must agree to the terms before proceeding.');
        } else if (sigCanvas.current.isEmpty()) {
            setError('Please provide your digital signature.');
        } else {
            setError('');
            handleChange('signature1')(sigCanvas.current.getTrimmedCanvas().toDataURL('image/png'));
            nextStep();
        }
    };

    const clearSignature = () => {
        sigCanvas.current.clear();
        handleChange('signature3')('');
    };

    return (
        <div className="membership-agreement-container">
            <div className="membership-agreement-content">
                <h2 className="membership-agreement-title">Waiver of Liability</h2>
                <div className="membership-agreement-text">
                    <h3>BUZZVIBE STUDIOS WAIVER OF LIABILITY</h3>
                    <p>
                        Please read and review this Waiver of Liability (“Agreement”) between you (“You”) and BuzzVibe Studios LLC (“Studio”). By signing below, You are accepting the terms of this Agreement, including a waiver and release of liability in favor of Studio and its affiliates, employees, officers, agents, shareholders, successors, and assigns (collectively, “BuzzVibe”).
                    </p>

                    <h4>ACKNOWLEDGEMENT OF RISK</h4>
                    <p>
                        You acknowledge that your visitation to, use of, and/or participation at, events at BuzzVibe facilities or otherwise sponsored by BuzzVibe (the “Activity”) may involve certain risks. Possible risks include physical injury, death, and the damage or destruction of property. You assume all risks associated with the Activity, whether known or unknown.
                    </p>

                    <h4>RELEASE OF LIABILITY</h4>
                    <p>
                        In consideration of BuzzVibe permitting You to engage in the Activity, You agree to hold harmless, release, waive, and discharge BuzzVibe from and against any and all claims, demands, causes of action, losses, damages, liabilities, demands, and injuries (including death) (collectively, “Liabilities”) relating in any way to the Activity (the “Release”).
                    </p>

                    <h4>INDEMNITY</h4>
                    <p>
                        You agree to indemnify, defend, and hold BuzzVibe harmless from and against any Losses, including reasonable attorneys’ fees and costs, to the extent that such Losses are not caused directly by BuzzVibe’s gross negligence or willful misconduct.
                    </p>

                    <h4>COVENANT NOT TO SUE</h4>
                    <p>
                        You agree not to commence any legal action against BuzzVibe concerning your participation in the Activity or matters that are subject to the Release and indemnity set forth in this Agreement, provided however, that this covenant shall not apply in instances of BuzzVibe’s gross negligence or willful misconduct.
                    </p>

                    <h4>BUZZVIBE CODE OF CONDUCT</h4>
                    <p>
                        You agree at all times to abide by BuzzVibe’s Code of Conduct (“House Rules”), which are attached hereto as Exhibit A. BuzzVibe reserves the right to amend these House Rules at any time. Any updates to the House Rules will be binding on you and will be communicated via email and/or posted at the facilities.
                    </p>

                    <h4>MISCELLANEOUS PROVISIONS</h4>
                    <ul>
                        <li>
                            <strong>Severability:</strong> If any provision or term of this agreement is held to be unenforceable, this Agreement will be deemed amended to the extent necessary to render the otherwise unenforceable provision, and the remainder of the Agreement, valid and enforceable.
                        </li>
                        <li>
                            <strong>Governing Law; Disputes:</strong> This Agreement shall be governed in all respects by the laws of the State of California without regard to its choice of law principles. You agree to submit any and all claims, or any dispute related to this Agreement to non-binding arbitration before JAMS. The arbitration shall be held in accordance with the JAMS then-current Streamlined Arbitration Rules & Procedures, which currently are available at: <a href="https://www.jamsadr.com/rules-streamlined-arbitration" target="_blank" rel="noopener noreferrer">https://www.jamsadr.com/rules-streamlined-arbitration</a>. You and BuzzVibe shall work in good faith to choose a mutually agreeable arbitrator, who shall be either a retired judge, or an attorney who is experienced in commercial contracts and licensed to practice law in California, selected pursuant to the JAMS rules. You expressly agree that any arbitration shall be conducted in Los Angeles County. You understand and agree that by signing this Agreement, you are waiving the right to a jury, unless there is a dispute as to the outcome of arbitration.
                        </li>
                        <li>
                            <strong>No Waiver:</strong> None of the terms of this Agreement shall be deemed to have been waived by any act or acquiescence of BuzzVibe. Only a written agreement can constitute waiver of any of the terms of this Agreement between the parties. No waiver of any term or provision of this Agreement shall constitute a waiver of any other term or provision or of the same provision on a future date. Failure of BuzzVibe to enforce any term of this Agreement shall not constitute waiver of such term or any other term.
                        </li>
                        <li>
                            <strong>Successors/Assigns:</strong> This Agreement shall be binding on all successors and assigns of You and BuzzVibe.
                        </li>
                    </ul>

                    <p>
                        By signing below, You acknowledge that you have read, understand, and agree to all terms and conditions in this Agreement.
                    </p>
                </div>
                <div className="membership-agreement-checkbox">
                    <label>
                        <input 
                            type="checkbox" 
                            checked={isAgreed} 
                            onChange={(e) => setIsAgreed(e.target.checked)} 
                        />
                        I agree to the waiver of liability 
                    </label>
                </div>
                <div className="membership-agreement-signature">
                    <label className="membership-agreement-label">Signature</label>
                    <SignatureCanvas
                        ref={sigCanvas}
                        penColor="black"
                        canvasProps={{ className: 'signature-canvas' }}
                    />
                    <button onClick={clearSignature} className="membership-agreement-clear-button">Clear</button>
                    {error && <p className="membership-agreement-error">{error}</p>}
                </div>
                <div className="membership-agreement-buttons">
                    <button onClick={prevStep} className="membership-agreement-button membership-agreement-button-back">Back</button>
                    <button onClick={handleNext} className="membership-agreement-button membership-agreement-button-next">Next</button>
                </div>
            </div>
        </div>
    );
};

export default WaiverOfLiability;
