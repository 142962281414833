import React, { useEffect, useState } from "react";
import PostWidget from "./PostWidget";
import axios from 'axios';
import { useSelector } from 'react-redux';
import Cookie from "js-cookie";
import { server } from "../server";
import SponsoredFeedProducts from '../components/Sponsored/SponsorFeed';

const MainFeedWidget = ({ ownerId, profileType, isProfile = false }) => {
  const [posts, setPosts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [sponsoredProducts, setSponsoredProducts] = useState([]);
  const token = Cookie.get('token');
  const friends = useSelector(state => state.friends.friends);

  useEffect(() => {
    const fetchSponsoredProducts = async () => {
        try {
            const response = await axios.get(`${server}/sponsored/sponsored-products`, { withCredentials: true });
            // Filter out inactive ads before setting the state
            const activeSponsoredProducts = response.data.filter(ad => ad.isActive === true);
            setSponsoredProducts(activeSponsoredProducts); // Set state with only active ads
        } catch (error) {
            console.error('Error fetching sponsored products:', error);
        }
    };
    fetchSponsoredProducts();
}, []);


  const sortPosts = (postsArray) => {
    const friendPosts = postsArray.filter(post => friends.some(friend => friend.id === post.ownerId));
    const nonFriendPosts = postsArray.filter(post => !friends.some(friend => friend.id === post.ownerId));
    const sortFunc = (a, b) => new Date(b.createdAt) - new Date(a.createdAt);
    return [...friendPosts.sort(sortFunc), ...nonFriendPosts.sort(sortFunc)];
  };

  const fetchPosts = async (page) => {
    const endpoint = isProfile ? `${server}/posts/get-user-posts/${profileType}/${ownerId}/posts` : `${server}/posts/get-feed-posts?page=${page}&limit=10`;
    try {
      const response = await axios.get(endpoint, {
        withCredentials: true,
        headers: { Authorization: `Bearer ${token}` }
      });

      if (isProfile) {
        setPosts(sortPosts(response.data));
      } else {
        setPosts(prevPosts => [...prevPosts, ...sortPosts(response.data.posts)]);
        setTotalPages(response.data.totalPages);
      }
    } catch (error) {
      console.error("Error fetching the posts:", error);
    }
  };

  useEffect(() => {
    setPosts([]); // Clear posts when profile changes
    setCurrentPage(1); // Reset to first page
    fetchPosts(1);
  }, [isProfile, ownerId, profileType]);

  const loadMorePosts = () => {
    const nextPage = currentPage + 1;
    if (nextPage <= totalPages) {
      fetchPosts(nextPage);
      setCurrentPage(nextPage);
    }
  };

  const renderFeed = () => {
    const feedItems = [];
    let sponsoredProductIndex = 0;

    posts.forEach((post, index) => {
      feedItems.push(
        <PostWidget
          key={post._id}
          postId={post._id}
          {...post}
          onShareSuccess={() => fetchPosts(currentPage)}
        />
      );

      if ((index + 1) % 5 === 0 && sponsoredProductIndex < sponsoredProducts.length) {
        const sponsoredProduct = sponsoredProducts[sponsoredProductIndex];
        feedItems.push(
          <SponsoredFeedProducts
            key={`sponsored-${sponsoredProduct._id}`} 
            adId={sponsoredProduct._id} 
            product={sponsoredProduct.product}
          />
        );
        sponsoredProductIndex++;
      }
    });

    return feedItems;
  };

  

  return (
    <>
      {renderFeed()}
      {!isProfile && currentPage < totalPages && (
        <button className="load-more-btn" onClick={loadMorePosts}>Load More</button>
      )}
    </>
  );
};

export default MainFeedWidget;

