import React, { useState, useEffect } from "react";
import { Country, State } from "country-state-city";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from "axios";
import { server } from "../../server";
import { toast } from "react-toastify";
import { loadStripe } from '@stripe/stripe-js';
import { EmbeddedCheckoutProvider, EmbeddedCheckout } from '@stripe/react-stripe-js';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const Checkout = () => {
  const { user } = useSelector((state) => state.user);
  const seller  = useSelector((state) => state.seller.activeSeller);
  const getCurrentProfile = () => sessionStorage.getItem('currentProfile') || 'User';
  const profileType = getCurrentProfile();
  const userType = profileType  === 'User' ? 'User' : 'Shop';
  const cart = useSelector((state) => state.cart.carts[profileType] || []);
  const [clientSecret, setClientSecret] = useState('');
  const [shippingRates, setShippingRates] = useState([]);
  const [country, setCountry] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState(null);
  const [userInfo, setUserInfo] = useState(false);
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [zipCode, setZipCode] = useState(null);
  const [couponCode, setCouponCode] = useState("");
  const [couponCodeData, setCouponCodeData] = useState(null);
  const [discountPrice, setDiscountPrice] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const subTotalPrice = cart.reduce(
    (acc, item) => acc + item.qty * item.discountPrice,
    0
  );

  const userId = profileType  === 'User' ? user?._id : seller?._id;
  const userEmail = userType  === 'User' ? user?.email : seller?.email;

  const paymentSubmit = async () => {
    if (address1 === "" || zipCode === null || country === "" || city === "" || state === null) {
      toast.error("Please complete your shipping address!");
    } else {
      const shippingCost = determineShippingRate(cart, zipCode, state);
      const totalCost = subTotalPrice + shippingCost - (discountPrice || 0);
      const shippingAddress = { address1, address2, zipCode, country, city, state };
  
      const orderData = {
        cartItems: cart.map(item => ({
          shopId: item.shop._id,
          productName: item.name,
          description: item.description,
          images: [item.images[0]?.url],
          quantity: item.qty,
          unitAmount: item.discountPrice * 100,
          connectedAccountId: item.shop.stripeAccountId,
          taxCode: item.taxCode
        })),
        currency: 'usd',
        returnUrl: 'https://gamechip.ai/checkout-success',
        userId: userId, 
        userEmail: userEmail,
        shippingAddress: shippingAddress,
        totalPrice: totalCost,
        userType: userType,
        currentProfile: profileType,
        couponCode: couponCodeData ? couponCodeData.name : null
      };

  
      try {
        const response = await axios.post(`${server}/payment/create-checkout-session`, orderData, {  withCredentials: true,
          headers: {
            Authorization: `Bearer ${user.token}`,
            'current-profile': sessionStorage.getItem('currentProfile') || 'defaultProfile',
          }
        });
  
        if (response.data.clientSecret) {
          setClientSecret(response.data.clientSecret);
        } else {
          throw new Error('Client secret not provided.');
        }
      } catch (error) {
        console.error('Error during payment submission:', error);
        toast.error("Failed to initiate payment process.");
      }
    }
  };

  const fetchShippingRates = async (shopId) => {
    try {
      const response = await axios.get(`${server}/shippingrule/checkout/shipping-rules`, {
        params: { shopId },
        withCredentials: true,
        headers: {
          'current-profile': sessionStorage.getItem('currentProfile') || 'defaultProfile',
        },
      });
      return response.data;
    } catch (error) {
      console.error('Failed to fetch shipping rates:', error);
      toast.error("Failed to load shipping rates.");
      return [];
    }
  };

  useEffect(() => {
    const fetchAllShippingRates = async () => {
      const allRates = [];
      for (const item of cart) {
        const rates = await fetchShippingRates(item.shop._id);
        allRates.push(...rates);
      }
      setShippingRates(allRates);
    };

    fetchAllShippingRates();
  }, [cart]);

  const determineShippingRate = (cartItems, zipCode, state) => {
    console.log("State received in determineShippingRate:", state);
    // 1. Filter shipping rates by region based on ZIP code and state
    const applicableRatesByRegion = shippingRates.filter(rate => {
      const matchesZipCode = rate.region.postalCodes.includes(zipCode);
      const matchesState = rate.region.states.includes(state);
      return matchesZipCode || matchesState;
    });
  
    // 2. For each cart item, find the shipping rate that matches the weight and dimensions
    const shippingCosts = cartItems.map(item => {
      const applicableRates = applicableRatesByRegion.filter(rate => {
        const weightInRange = item?.weight >= rate?.weightRange?.min &&
                              (rate?.weightRange.max === null || item?.weight <= rate?.weightRange.max);
        const dimensionsInRange = item?.dimensions?.length <= rate?.dimensions?.length &&
                                  item?.dimensions?.width <= rate?.dimensions?.width &&
                                  item?.dimensions?.height <= rate?.dimensions?.height;
  
        return weightInRange && dimensionsInRange;
      });
  
      // 3. Choose the lowest price among the applicable rates
      if (applicableRates.length > 0) {
        applicableRates.sort((a, b) => a.price - b.price);
        return applicableRates[0].price;
      }
  
      return 0; // Default if no rate applies
    });
  
    // 4. Sum up the shipping costs for all items
    return shippingCosts.reduce((acc, cost) => acc + cost, 0);
  };
  

  const handleSubmit = async (e) => {
    e.preventDefault();
    const name = couponCode.trim();

    await axios.get(`${server}/coupon/get-coupon-value/${name}`).then((res) => {
        const coupon = res.data.couponCode;
        const shopId = coupon?.shopId;
        const couponCodeValue = coupon?.value;

        if (coupon !== null) {
            const isCouponValidForShop = cart.some((item) => item.shopId === shopId);
            const isCouponValidForProducts = coupon.shopWide || coupon.selectedProducts.length === 0 || cart.some((item) => coupon.selectedProducts.includes(item._id));

            if (!isCouponValidForShop) {
                toast.error("Coupon code is not valid for this shop");
                setCouponCode("");
            } else if (!isCouponValidForProducts) {
                toast.error("Coupon code is not valid for the products in your cart");
                setCouponCode("");
            } else {
                const eligibleItems = cart.filter((item) => coupon.shopWide || coupon.selectedProducts.includes(item._id));
                const eligiblePrice = eligibleItems.reduce((acc, item) => acc + item.qty * item.discountPrice, 0);
                const discountPrice = (eligiblePrice * couponCodeValue) / 100;
                setDiscountPrice(discountPrice);
                setCouponCodeData(coupon);
                setCouponCode("");
            }
        } else {
            toast.error("Coupon code doesn't exist!");
            setCouponCode("");
        }
    });
};

  return (
    <div className="checkout-component-wrapper">
      <div className="checkout-component-content">
        <div className="checkout-component-left">
          <ShippingInfo
            user={user}
            country={country}
            setCountry={setCountry}
            city={city}
            setCity={setCity}
            state={state}
            setState={setState}
            userInfo={userInfo}
            setUserInfo={setUserInfo}
            address1={address1}
            setAddress1={setAddress1}
            address2={address2}
            setAddress2={setAddress2}
            zipCode={zipCode}
            setZipCode={setZipCode}
          />
        </div>
        <div className="checkout-component-right">
          <CartData
            handleSubmit={handleSubmit}
            cartItems={cart}
            shippingRates={shippingRates}
            zipCode={zipCode} 
            state={state}
            couponCode={couponCode}
            setCouponCode={setCouponCode}
            discountPrice={discountPrice}
            couponCodeData={couponCodeData}
          />
        </div>
      </div>
      <div className="checkout-component-button" onClick={paymentSubmit}>
        <h5 className="checkout-component-button-text">Go to Payment</h5>
      </div>
      {clientSecret && (
        <EmbeddedCheckoutProvider stripe={stripePromise} options={{ clientSecret }}>
          <EmbeddedCheckout />
        </EmbeddedCheckoutProvider>
      )}
    </div>
  );
};

const ShippingInfo = ({
  user,
  country,
  setCountry,
  city,
  setCity,
  state,
  setState,
  userInfo,
  setUserInfo,
  address1,
  setAddress1,
  address2,
  setAddress2,
  zipCode,
  setZipCode,
}) => {
  return (
    <div className="checkout-component-shipping-info">
      <h5 className="checkout-component-section-title">Shipping Address</h5>
      <br />
      <form>
        <div className="checkout-component-form-row">
          <div className="checkout-component-form-group">
            <label className="checkout-component-label">Full Name</label>
            <input
              type="text"
              value={user && user.name}
              required
              className="checkout-component-input"
            />
          </div>
          <div className="checkout-component-form-group">
            <label className="checkout-component-label">Email Address</label>
            <input
              type="email"
              value={user && user.email}
              required
              className="checkout-component-input"
            />
          </div>
        </div>

        <div className="checkout-component-form-row">
          <div className="checkout-component-form-group">
            <label className="checkout-component-label">Phone Number</label>
            <input
              type="number"
              required
              value={user && user.phoneNumber}
              className="checkout-component-input"
            />
          </div>
          <div className="checkout-component-form-group">
            <label className="checkout-component-label">Zip Code</label>
            <input
              type="number"
              value={zipCode}
              onChange={(e) => setZipCode(e.target.value)}
              required
              className="checkout-component-input"
            />
          </div>
        </div>

        <div className="checkout-component-form-row">
          <div className="checkout-component-form-group">
            <label className="checkout-component-label">Country</label>
            <select
              className="checkout-component-select"
              value={country}
              onChange={(e) => setCountry(e.target.value)}
            >
              <option value="">Choose your country</option>
              {Country &&
                Country.getAllCountries().map((item) => (
                  <option key={item.isoCode} value={item.isoCode}>
                    {item.name}
                  </option>
                ))}
            </select>
          </div>
          <div className="checkout-component-form-group">
            <label className="checkout-component-label">State</label>
            <select
              className="checkout-component-select"
              value={state}
              onChange={(e) => setState(e.target.value)}
            >
              <option value="">Choose your state</option>
              {State &&
                State.getStatesOfCountry(country).map((item) => (
                  <option key={item.name} value={item.name}>
                    {item.name}
                  </option>
                ))}
            </select>
          </div>
        </div>
        <div className="checkout-component-form-row">
          <div className="checkout-component-form-group">
            <label className="checkout-component-label">City</label>
            <input
              type="text"
              className="checkout-component-input"
              value={city}
              onChange={(e) => setCity(e.target.value)}
              required
            />
          </div>
        </div>

        <div className="checkout-component-form-row">
          <div className="checkout-component-form-group">
            <label className="checkout-component-label">Address1</label>
            <input
              type="address"
              required
              value={address1}
              onChange={(e) => setAddress1(e.target.value)}
              className="checkout-component-input"
            />
          </div>
          <div className="checkout-component-form-group">
            <label className="checkout-component-label">Address2</label>
            <input
              type="address"
              value={address2}
              onChange={(e) => setAddress2(e.target.value)}
              className="checkout-component-input"
            />
          </div>
        </div>
      </form>
      <h5
        className="checkout-component-saved-address-title"
        onClick={() => setUserInfo(!userInfo)}
      >
        Choose From saved address
      </h5>
      {userInfo && (
        <div>
          {user &&
            user.addresses.map((item, index) => (
              <div key={index} className="checkout-component-saved-address-item">
                <input
                  type="checkbox"
                  className="checkout-component-checkbox"
                  value={item.addressType}
                  onClick={() =>
                    setAddress1(item.address1) ||
                    setAddress2(item.address2) ||
                    setZipCode(item.zipCode) ||
                    setCountry(item.country) ||
                    setCity(item.city) ||
                    setState(item.state)
                  }
                />
                <h2 className="checkout-component-saved-address-type">{item.addressType}</h2>
              </div>
            ))}
        </div>
      )}
    </div>
  );
};

const CartData = ({
  handleSubmit,
  cartItems, 
  shippingRates,
  zipCode,
  state,   
  couponCode,
  setCouponCode,
  discountPrice,
  couponCodeData,
}) => {
  

  const determineShippingRate = (cartItems, zipCode, state) => {
    // 1. Filter shipping rates by region based on ZIP code and state
    const applicableRatesByRegion = shippingRates.filter(rate => {
      const matchesZipCode = rate.region.postalCodes.includes(zipCode);
      const matchesState = rate.region.states.includes(state);
      return matchesZipCode || matchesState;
    });
  
    // 2. For each cart item, find the shipping rate that matches the weight and dimensions
    const shippingCosts = cartItems.map(item => {
      const applicableRates = applicableRatesByRegion.filter(rate => {
        const weightInRange = item?.weight >= rate?.weightRange.min &&
                              (rate?.weightRange.max === null || item?.weight <= rate?.weightRange.max);
        const dimensionsInRange = item?.dimensions?.length <= rate?.dimensions?.length &&
                                  item?.dimensions?.width <= rate?.dimensions?.width &&
                                  item?.dimensions?.height <= rate?.dimensions?.height;
  
        return weightInRange && dimensionsInRange;
      });
  
      // 3. Choose the lowest price among the applicable rates
      if (applicableRates.length > 0) {
        applicableRates.sort((a, b) => a.price - b.price);
        return applicableRates[0].price;
      }
  
      return 0; // Default if no rate applies
    });
  
    // 4. Sum up the shipping costs for all items
    return shippingCosts.reduce((acc, cost) => acc + cost, 0);
  };
  
  const canCalculateShipping = zipCode || state;

  const shipping = canCalculateShipping
    ? determineShippingRate(cartItems, zipCode, state)
    : null;

  const subTotalPrice = cartItems.reduce(
    (acc, item) => acc + item.qty * item.discountPrice,
    0
  );

  const totalPrice = discountPrice
    ? (subTotalPrice + shipping - discountPrice).toFixed(2)
    : (subTotalPrice + shipping).toFixed(2);

  return (
    <div className="checkout-component-cart-data">
      <div className="checkout-component-cart-item">
        <h3 className="checkout-component-cart-label">Subtotal:</h3>
        <h4 className="checkout-component-cart-value">${subTotalPrice.toFixed(2)}</h4>
      </div>
      <br />
      <div className="checkout-component-cart-item">
        <h3 className="checkout-component-cart-label">Shipping:</h3>
        <h4 className="checkout-component-cart-value">
          {canCalculateShipping 
            ? `$${shipping.toFixed(2)}`
            : "Please enter your address to determine shipping price"}
        </h4>
      </div>
      <br />
      <div className="checkout-component-cart-item checkout-component-cart-item-border">
        <h3 className="checkout-component-cart-label">Discount:</h3>
        <h4 className="checkout-component-cart-value">
          - {discountPrice ? "$" + discountPrice.toFixed(2) : null}
        </h4>
      </div>
      {couponCodeData && (
        <div className="checkout-component-cart-item checkout-component-cart-item-border">
          <h3 className="checkout-component-cart-label">Active Coupon:</h3>
          <h4 className="checkout-component-cart-value">{couponCodeData.name}</h4>
        </div>
      )}
      <h4 className="checkout-component-total-price">${totalPrice}</h4>
      <br />
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          className="checkout-component-input"
          placeholder="Coupon code"
          value={couponCode}
          onChange={(e) => setCouponCode(e.target.value)}
          required
        />
        <input
          className="checkout-component-apply-code-button"
          required
          value="Apply code"
          type="submit"
        />
      </form>
    </div>
  );
};

export default Checkout;

