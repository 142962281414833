import React, { useState } from 'react';
import axios from 'axios';
import { server } from "../../server";

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const { data } = await axios.post(`${server}/user/forgot-password`, { email });
      setMessage(data.message);
      setError(''); 
    } catch (err) {
      setError(err.response?.data?.message || 'An error occurred. Please try again.');
      setMessage(''); 
    }
  };

  return (
    <div className="forgot-password-container">
      <h2 className="forgot-password-title">Reset Your Password</h2>
      <form onSubmit={handleSubmit}>
        <div className="forgot-password-form-group">
          <label htmlFor="email" className="forgot-password-label">Email Address</label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="forgot-password-input"
            required
          />
        </div>
        <div className="forgot-password-button-container">
          <button
            type="submit"
            className="forgot-password-button"
          >
            Send Reset Link
          </button>
        </div>
        {message && <div className="forgot-password-message success">{message}</div>}
        {error && <div className="forgot-password-message error">{error}</div>}
      </form>
    </div>
  );
};

export default ForgotPassword;

