import React from 'react';
import RoomDetails from '../components/Rooms/RoomDetails';
import Header from '../components/Layout/Header';
import Sidebar from '../components/Layout/Sidebar';


const RoomDetailsPage = () => {
  return (
    <div className="room-details-page-container">
        <Header />
        <Sidebar />
      <RoomDetails />
    </div>
  );
};

export default RoomDetailsPage;
