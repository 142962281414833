import React, { useEffect, useState, useRef, useCallback } from "react";
import { useDispatch, useSelector } from 'react-redux';
import SponsoredFeedProducts from '../components/Sponsored/SponsorFeed';
import PostWidgetRedux from "./PostWidgetRedux";
import { fetchPosts } from '../redux/actions/post';
import axios from 'axios';
import { server } from "../server";

const MainFeedWidgetRedux = ({ ownerId, profileType, isProfile = false }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = useSelector(state => state.posts.totalPages);
  const [sponsoredProducts, setSponsoredProducts] = useState([]);
  const dispatch = useDispatch();
  const { posts, loading, error } = useSelector(state => state.posts);

  const observer = useRef();
  const lastPostElementRef = useCallback(node => {
    if (loading) return;
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && currentPage < totalPages) {
        const nextPage = currentPage + 1;
        setCurrentPage(nextPage);
        dispatch(fetchPosts(profileType, ownerId, isProfile, nextPage));
      }
    }, { rootMargin: '100px' });

    if (node) observer.current.observe(node);
  }, [loading, currentPage, totalPages, dispatch, profileType, ownerId, isProfile]);

  useEffect(() => {
    dispatch(fetchPosts(profileType, ownerId, isProfile));
  }, [dispatch, ownerId, profileType, isProfile]);

  useEffect(() => {
    const fetchSponsoredProducts = async () => {
      try {
        const response = await axios.get(`${server}/sponsored/sponsored-products`, { withCredentials: true });
        const activeSponsoredProducts = response.data.filter(ad => ad.isActive);
        setSponsoredProducts(activeSponsoredProducts);
      } catch (error) {
        console.error('Error fetching sponsored products:', error);
      }
    };
    fetchSponsoredProducts();
  }, []);

  useEffect(() => {
    if (posts && posts.length > 0) {
      console.log("Fetched posts:", posts); // Console log for posts
    }
  }, [posts]);

  const renderFeed = useCallback(() => {
    const feedItems = [];
    let sponsoredProductIndex = 0;

    posts.forEach((post, index) => {
      const isLastPost = index === posts.length - 1;

      feedItems.push(
        <div ref={isLastPost ? lastPostElementRef : null} key={post._id}>
          <PostWidgetRedux postId={post._id} {...post} />
        </div>
      );

      if ((index + 1) % 5 === 0 && sponsoredProductIndex < sponsoredProducts.length) {
        const sponsoredProduct = sponsoredProducts[sponsoredProductIndex];
        feedItems.push(
          <SponsoredFeedProducts
            key={`sponsored-${sponsoredProduct._id}`}
            adId={sponsoredProduct._id}
            adSet={sponsoredProduct.adSet}
            product={sponsoredProduct.product}
          />
        );
        sponsoredProductIndex++;
      }
    });

    return feedItems;
  }, [posts, lastPostElementRef, sponsoredProducts]);

  if (error) return <div>Error fetching posts: {error}</div>;
  if (loading && currentPage === 1) return <div>Loading posts...</div>;

  return (
    <>
      {renderFeed()}
    </>
  );
};

export default MainFeedWidgetRedux;

