import React from 'react';

const AdFormatIconRow = ({ formats, selectedFormat, onFormatSelect }) => {
    return (
        <div className="ad-format-icon-row">
            {formats.map(format => (
                <button 
                    key={format.name} 
                    className={`ad-format-icon-item ${selectedFormat === format.name ? 'active' : ''}`} 
                    onClick={() => onFormatSelect(format.name)}
                >
                    <img src={format.icon} alt={format.label} className="ad-format-icon" />
                    <span className="ad-format-label">{format.label}</span> 
                </button>
            ))}
        </div>
    );
};

export default AdFormatIconRow;



