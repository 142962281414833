import React, { useState, useEffect } from 'react';
import socketIOClient from 'socket.io-client';
import { Transition } from '@headlessui/react';
import { CheckCircleIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { useSelector } from 'react-redux';
import { backend_url } from '../../server'

const ENDPOINT = backend_url;
const socket = socketIOClient(ENDPOINT, { withCredentials: true });

export default function NotificationPopup() {
const [show, setShow] = useState(false);
const [notification, setNotification] = useState(null);

const currentProfile = useSelector((state) => state.user.currentProfile);
    const user = useSelector((state) => state.user.user);
    const seller = useSelector((state) => state.seller.seller);

const currentProfileId = currentProfile === 'User' ? user?._id : (seller?._id || null);

useEffect(() => {
    // Listen to socket.io for notifications
    socket.on('notification', (newNotification) => {
        // Check if the notification is for the current user/shop
        if (newNotification.recipientId === currentProfileId) {
            setNotification(newNotification);
            setShow(true);
        }
    });

    return () => {
        socket.off('notification');
    };
}, [currentProfileId]);

// Function to close the notification popup
const handleClose = () => {
    setShow(false);
};

// Function to render notification content
const renderNotificationContent = () => {
    if (!notification) return null;
    return (
        <div className="flex items-start">
            <CheckCircleIcon className="h-6 w-6 text-green-400" aria-hidden="true" />
            <div className="ml-3 w-0 flex-1 pt-0.5">
                <p className="text-sm font-medium text-gray-900">{notification.title}</p>
                <p className="mt-1 text-sm text-gray-500">{notification.message}</p>
            </div>
            <div className="ml-4 flex flex-shrink-0">
                <button
                    type="button"
                    className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    onClick={handleClose}
                >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                </button>
            </div>
        </div>
    );
};

return (
    <div
        aria-live="assertive"
        className="pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6 mt-24"
    >
        <Transition
            show={show}
            as={React.Fragment}
            enter="transform ease-out duration-300 transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            >
            <div className="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
            <div className="p-4">
            {renderNotificationContent()}
            </div>
            </div>
            </Transition>
            </div>
            );
            }
