import React from 'react';
import { useNavigate } from 'react-router-dom';
import AgeVerification from '../components/AgeVerification/ageVerification';

const AgeVerificationPage = () => {
    const navigate = useNavigate();

    const handleVerificationSuccess = () => {
        navigate('/'); // Redirect to the homepage on successful verification
    };

    return <AgeVerification onVerified={handleVerificationSuccess} />;
};

export default AgeVerificationPage;


