import React from "react";

const HeroAllProducts = () => {
  return (
    <div
      className="Hero-all-products-container"
      style={{
        backgroundImage:
          "url(https://res.cloudinary.com/dzlopmfj8/image/upload/v1716384099/buzzvibe2_zxqrw9.jpg)",
        backgroundAttachment: 'fixed',
      }}
    >
      <div className="Hero-all-products-section">
        <h1 className="Hero-all-products-title">
          Shop
        </h1>
      </div>
    </div>
  );
};

export default HeroAllProducts;