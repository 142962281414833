import React, { useRef, useState } from 'react';
import SignatureCanvas from 'react-signature-canvas';

const MembershipTOS = ({ nextStep, prevStep, handleChange, formData }) => {
    const [error, setError] = useState('');
    const [isAgreed, setIsAgreed] = useState(false);
    const sigCanvas = useRef({});

    const handleNext = () => {
        if (!isAgreed) {
            setError('You must agree to the terms before proceeding.');
        } else if (sigCanvas.current.isEmpty()) {
            setError('Please provide your digital signature.');
        } else {
            setError('');
            handleChange('signature1')(sigCanvas.current.getTrimmedCanvas().toDataURL('image/png'));
            nextStep();
        }
    };

    const clearSignature = () => {
        sigCanvas.current.clear();
        handleChange('signature2')('');
    };

    return (
        <div className="membership-tos-container">
            <div className="membership-tos-content">
                <h2 className="membership-tos-title">Terms of Service & Community Guidelines</h2>
                <div className="membership-tos-text">
                    <h3>BUZZVIBE CODE OF CONDUCT / HOUSE RULES</h3>
                    <p>Effective Date: April 1, 2024</p>
                    <p>
                        As a condition of your Membership Agreement and/or access and use of facilities (“Facilities”) or other services offered by BuzzVibe Studios LLC and/or its affiliates (collectively, “BuzzVibe”), you agree to abide by this Code of Conduct (“House Rules”). You are responsible for knowing and abiding by the House Rules at all times.
                    </p>
                    <p>
                        The House Rules apply both online and offline and are subject to change from time to time. All changes will be binding and will be communicated to you electronically (e.g., via email).
                    </p>

                    <h4>OVERVIEW</h4>
                    <p>
                        Members, contributors, staff, service providers, and guests of the BuzzVibe community (“Participants”) pledge to create a mutually supportive, respectful, and harassment-free experience for everyone, regardless of race, age, disability, ethnicity, sex, gender identity and expression, level of experience, education, socio-economic status, nationality, personal appearance, religion, or sexual identity and orientation. All Participants are expected to act and interact in ways that contribute to an open, kind, welcoming, diverse, inclusive, and healthy community.
                    </p>

                    <h4>ZERO TOLERANCE</h4>
                    <p>
                        BuzzVibe is committed to creating a safe experience for everyone. There is no tolerance for acts of hate or harassment in any form. The definition of harassment according to BuzzVibe includes, but is not limited to:
                    </p>
                    <ul>
                        <li>Offensive comments related to gender, gender identity and expression, sexual orientation, disability, mental illness, neuro(a)typicality, physical appearance, body size, age, race, or religion.</li>
                        <li>Unwelcome comments regarding a person’s lifestyle choices and practices, including those related to food, health, parenting, drugs, and employment.</li>
                        <li>Deliberate misgendering or use of rejected names.</li>
                        <li>Gratuitous or off-topic sexual images or inappropriate behavior.</li>
                        <li>Physical contact and simulated physical contact without consent or after a request to stop.</li>
                        <li>Threats of violence.</li>
                        <li>Incitement of violence towards any individual, including encouraging a person to commit to engage in self-harm.</li>
                        <li>Deliberate intimidation.</li>
                        <li>Stalking or following, whether digitally or in person.</li>
                        <li>Harassing photography or recording, including logging online activity for harassment purposes.</li>
                        <li>Sustained disruption of discussion.</li>
                        <li>Unwelcome sexual attention.</li>
                        <li>Patterns of inappropriate social contact, such as requesting/assuming inappropriate levels of intimacy with others.</li>
                        <li>Continued one-on-one communication after requests to cease.</li>
                        <li>Deliberate “outing” of any aspect of a person’s identity without their consent, except as necessary to protect vulnerable persons from intentional abuse.</li>
                        <li>Re-publication of harassing or offensive private communication or content.</li>
                    </ul>

                    <h4>RESPECT</h4>
                    <p>BuzzVibe values empathy and kindness. All Participants are expected to:</p>
                    <ul>
                        <li>Be respectful of differing opinions, viewpoints, and experiences.</li>
                        <li>Use welcoming and inclusive language.</li>
                        <li>Give and gracefully accept constructive feedback.</li>
                        <li>Focus on what’s best not just for Participants as individuals, but for the overall community.</li>
                    </ul>

                    <h4>HONESTY & TRANSPARENCY</h4>
                    <p>
                        As a Participant, we ask that you do not participate anonymously or with a pseudonym. This is a community where we encourage individuals to represent themselves authentically because we seek to foster open, honest, and supportive discussions. We rely on our Participants to notify us of any behavior that is inconsistent with these House Rules.
                    </p>

                    <h4>OTHER FACILITIES RULES</h4>
                    <ul>
                        <li>No pets or animals, other than service animals, are permitted in the Facilities.</li>
                        <li>No alcohol, tobacco, or controlled substances are permitted in the Facilities.</li>
                        <li>No firearms or weapons of any kind are permitted in the Facilities.</li>
                        <li>No gambling is permitted at the Facilities.</li>
                        <li>Smoking and vaping are not permitted in the Facilities. If evidence of smoking or vaping is found during or after your use of the Facilities, (i) you are responsible for all damage including, but not limited to, odors, stains, burns and removal of debris, (ii) you are in breach of these House Rules, and (iii) you (and any guests) may be required to immediately vacate, or be removed from, the Facilities.</li>
                        <li>Food and non-alcoholic beverages are permitted in designated areas only. You are responsible for cleaning up and properly disposing of all food items, containers, etc.</li>
                        <li>Parking is not provided. You agree to be responsible for parking and for all related fees associated with parking. Please be aware of posted signs in area surrounding the Facilities and please be respectful of BuzzVibe’s neighbors.</li>
                        <li>BuzzVibe is not responsible for any of your lost, stolen or missing property or property you leave behind at the Facilities. It is your responsibility to ensure that personal items are secure and remain with you at all times.</li>
                    </ul>

                    <h4>REPORTING</h4>
                    <p>
                        If you are being harassed by a Participant or any other person at or around the Facilities, notice that someone else is being harassed, or have any other concerns regarding misconduct, please report the incident to the BuzzVibe team. If the person harassing you is on the team, they will recuse themselves from handling your incident. BuzzVibe will respond as promptly as possible under the circumstances.
                    </p>
                    <p>
                        These House Rules apply to all community spaces, but if you are being harassed by a Participant outside our spaces, BuzzVibe still wants to know about it. BuzzVibe will take all good-faith reports of harassment by Participants seriously, including harassment outside BuzzVibe’s Facilities. BuzzVibe reserves the right to exclude people from the community based on their past behavior, including behavior outside of the Facilities and behavior towards people who are not Participants.
                    </p>
                    <p>
                        BuzzVibe reserves the right to reject any report it believes has been made in bad faith. Reports intended to silence legitimate criticism may be deleted without response.
                    </p>
                    <p>
                        BuzzVibe will reasonably respect confidentiality requests for the purpose of protecting victims of abuse, subject to applicable law.
                    </p>

                    <h4>ENFORCEMENT</h4>
                    <p>
                        BuzzVibe reserves the right to terminate the membership of a participant (if applicable) and/or participation in the community/at the Facilities if their actions violate these House Rules. Participants asked to stop harassing, or other negative behaviors are expected to comply immediately. If a Participant engages in misconduct, BuzzVibe may take any action deemed appropriate, including expulsion from the Facilities and/or reporting to law enforcement.
                    </p>
                </div>
                <div className="membership-agreement-checkbox">
                    <label>
                        <input 
                            type="checkbox" 
                            checked={isAgreed} 
                            onChange={(e) => setIsAgreed(e.target.checked)} 
                        />
                        I agree to the code of conduct & house rules
                    </label>
                </div>
                <div className="membership-tos-signature">
                    <label className="membership-tos-label">Signature</label>
                    <SignatureCanvas
                        ref={sigCanvas}
                        penColor="black"
                        canvasProps={{ className: 'signature-canvas' }}
                    />
                    <button onClick={clearSignature} className="membership-tos-clear-button">Clear</button>
                    {error && <p className="membership-tos-error">{error}</p>}
                </div>
                <div className="membership-tos-buttons">
                    <button onClick={prevStep} className="membership-tos-button membership-tos-button-back">Back</button>
                    <button onClick={handleNext} className="membership-tos-button membership-tos-button-next">Next</button>
                </div>
            </div>
        </div>
    );
};

export default MembershipTOS;


