import React, { useState, useEffect } from 'react';
import axios from 'axios';
import DashboardHeader from '../../components/Shop/Layout/DashboardHeader';
import DashboardSideBar from '../../components/Shop/Layout/DashboardSideBar';
import SponsorProductCampaign from '../../components/Sponsored/SponsorProductCampaign';
import SponsorShopCampaign from '../../components/Sponsored/SponsorShopCampaign';
import SponsorBrandCampaign from '../../components/Sponsored/SponsorBrandCampaign';
import CreateCustomAdCampaign from '../../components/Sponsored/CreateCustomAdCampaign';
import DualSponsoredDashboard from '../../components/Sponsored/DualSponsoredDashboard';
import CreateAdSteps from '../../components/Sponsored/CreateAdSteps';
import AdTypeDistributionChart from '../../components/Sponsored/AdTypeDistributionChart';
import TopPerformingAds from '../../components/Sponsored/TopPerformingAds';
import AdSetModal from '../../components/Sponsored/AdSetModal';
import CampaignReview from '../../components/Sponsored/CampaignReview';
import CampaignTotals from '../../components/Sponsored/CampaignTotals';
import { server } from '../../server';
import { useSelector } from "react-redux";
import { toast } from 'react-toastify';
import CampaignCreator from '../../components/Sponsored/CampaignCreator';
import CampaignCreateModal from '../../components/Sponsored/CampaignCreatorModal';

const ShopCreateAdvertisement = () => {
    const [isCampaignStarted, setIsCampaignStarted] = useState(false);
    const [activeStep, setActiveStep] = useState(1);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [sponsoredAds, setSponsoredAds] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isAdSetModalOpen, setIsAdSetModalOpen] = useState(false);
    const [campaignAds, setCampaignAds] = useState({});
    const [adSet, setAdSet] = useState({});
    const [openAdComponents, setOpenAdComponents] = useState({});
    const [products, setProducts] = useState([]);
    const [selectedProductId, setSelectedProductId] = useState('');
    const { seller } = useSelector((state) => state.seller);
    const [savedAdConfigurations, setSavedAdConfigurations] = useState({});
    const [editingAd, setEditingAd] = useState(null);
    const [isEditMode, setIsEditMode] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const adOptions = [
        { label: "Sponsor Product", component: <SponsorProductCampaign />, type: "product" },
        { label: "Sponsor Your Shop", component: <SponsorShopCampaign />, type: "shop" },
        { label: "Sponsor Your Brand", component: <SponsorBrandCampaign />, type: "brand" },
        { label: "Create Custom Ad", component: <CreateCustomAdCampaign />, type: "custom" },
    ];

    useEffect(() => {
        const fetchSponsoredAds = async () => {
            setIsLoading(true);
            try {
                const token = localStorage.getItem('token');
                const response = await axios.get(`${server}/sponsored/sponsored-ads`, {
                    withCredentials: true,
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'current-profile': sessionStorage.getItem('currentProfile') || 'defaultProfile',
                    },
                });
                const sortedAds = response.data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
                setSponsoredAds(sortedAds);
            } catch (error) {
                console.error('Error fetching sponsored ads:', error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchSponsoredAds();
    }, []);

    useEffect(() => {
        if (selectedOptions.includes("Sponsor Product")) {
            const fetchProducts = async () => {
                try {
                    const { data } = await axios.get(`${server}/product/get-all-products-shop/${seller._id}`, {
                        withCredentials: true,
                    });
                    setProducts(data.products);
                } catch (error) {
                    console.error('Error fetching products:', error);
                }
            };

            fetchProducts();
        }
    }, [selectedOptions]);

    const toggleOption = (option) => {
        setSelectedOptions((prev) =>
            prev.includes(option)
                ? prev.filter((item) => item !== option)
                : [...prev, option]
        );
    };

    const proceedToNextStep = () => {
        if (activeStep === 1 && selectedOptions.length === 0) {
            alert("Please select at least one ad type.");
            return;
        }
        setActiveStep((prevStep) => Math.min(prevStep + 1, 5));

        // Initialize open ad components for step 2
        if (activeStep === 1) {
            const initialOpenComponents = {};
            selectedOptions.forEach(option => {
                const adType = adOptions.find(ad => ad.label === option).type;
                initialOpenComponents[adType] = 1; // One component open by default
            });
            setOpenAdComponents(initialOpenComponents);
        }
    };

    const goToPreviousStep = () => {
        setActiveStep((prevStep) => Math.max(prevStep - 1, 1)); 
    };

    const handleStartCampaign = () => {
        setIsCampaignStarted(true);
        setActiveStep(1); 
    };

    const handleAdSetSave = (newAdSet, details, adType) => {
        setCampaignAds((prev) => {
            const existingAds = prev[adType] || [];
            const adDetails = {
                type: adType,
                details,
                adSet: {
                    images: newAdSet.images, 
                    positions: newAdSet.positions, 
                },
            };
    
            if (editingAd && editingAd.adType === adType) {
                // Update the existing ad
                const updatedAds = [...existingAds];
                updatedAds[editingAd.index] = adDetails;
                return {
                    ...prev,
                    [adType]: updatedAds,
                };
            } else {
                // Add a new ad
                return {
                    ...prev,
                    [adType]: [...existingAds, adDetails],
                };
            }
        });
    
        setSavedAdConfigurations((prev) => ({
            ...prev,
            [adType]: [...(prev[adType] || []), { adSet: newAdSet, details }],
        }));
    
        setIsAdSetModalOpen(false);
        setEditingAd(null); // Reset the editing state
        setIsEditMode(false);
    };
    

    const handleEditClick = (adType, index) => {
        const adToEdit = campaignAds[adType][index];
        
        setAdSet(adToEdit.adSet || {}); // Load the ad set into the state for editing
        
        // Pass the ad details when editing
        setEditingAd({
            adType,
            index,
            details: adToEdit.details || {}, // Pass details for pre-filling the form
        });
        setIsEditMode(true);
        
        // Expand the ad component in the UI
        setOpenAdComponents((prev) => ({
            ...prev,
            [adType]: 1, 
        }));
    };
    
    

    const handleSubmitCampaign = async () => {
        try {
            // Flatten the campaignAds object into an array
            const adsArray = Object.values(campaignAds).flat();
    
            // Create payload
            const payload = {
                ads: adsArray,
            };
    
            const response = await axios.post(`${server}/sponsored/create-campaign`, payload, {
                withCredentials: true,
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                    'current-profile': sessionStorage.getItem('currentProfile') || 'defaultProfile',
                },
            });
    
            toast.success('Campaign created successfully!');
    
            // Reset state after successful submission
            setCampaignAds([]);
            setSelectedOptions([]);
            setActiveStep(1);
            setIsCampaignStarted(false);
        } catch (error) {
            console.error('Error creating campaign:', error);
            toast.error('Error creating campaign');
        }
    };

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };
    
    

    const totalAds = Object.values(campaignAds).flat().length;
    const totalDuration = Object.values(campaignAds).flat().reduce((acc, ad) => acc + ad.details.adDuration, 0);
    const totalAdSpend = Object.values(campaignAds).flat().reduce((acc, ad) => acc + ad.details.adSpend, 0);

    return (
        <div className="shop-create-advertisement-campaign-container">
            <DashboardHeader />
            <div className="ad-dashboard-layout">
                <DashboardSideBar active={7} />
                <div className="shop-create-advertisement-campaign-content">
                    <div className="shop-create-advertisement-campaign-sidebar">
                    <div className="shop-create-advertisement-campaign-box">
                            <button className="create-ad-button" onClick={openModal}>
                                Create Campaign
                            </button>

                            {/* Modal component */}
                            <CampaignCreateModal isOpen={isModalOpen} onClose={closeModal} />
                                
                            </div>
                        {!isLoading && (
                            <>
                                <AdTypeDistributionChart ads={sponsoredAds} />
                                <TopPerformingAds ads={sponsoredAds} />
                            </>
                        )}
                    </div>
                    <div className="shop-create-advertisement-campaign-main">
                        
                        {!isLoading && <DualSponsoredDashboard />}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ShopCreateAdvertisement;
