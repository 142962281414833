import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { server } from "../../server";
import { useNavigate } from 'react-router-dom';
import MemberDetails from './MemberDetails';

const StudioAdminDashboard = () => {
    const [members, setMembers] = useState([]);
    const [filteredMembers, setFilteredMembers] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedMember, setSelectedMember] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchMembers = async () => {
            try {
                const response = await axios.get(`${server}/membership/members`); 
                setMembers(response.data);
                setFilteredMembers(response.data)
            } catch (error) {
                console.error('Error fetching members:', error);
                toast.error('Failed to fetch members.');
            }
        };

        fetchMembers();
    }, []);

    const handleSearch = (event) => {
        const value = event.target.value.toLowerCase();
        setSearchTerm(value);
        const filtered = members.filter(member => 
            member.firstName.toLowerCase().includes(value) || 
            member.lastName.toLowerCase().includes(value)
        );
        setFilteredMembers(filtered);
    };

    const handleMemberClick = (member) => {
        setSelectedMember(member);
    };

    const handleCloseDetails = () => {
        setSelectedMember(null);
    };

    return (
        <div className="membership-admin-dashboard">
            <img
                className="membership-admin-logo"
                src="https://res.cloudinary.com/dzlopmfj8/image/upload/v1706549678/Asset-1_yjnmot.png"
                alt="BuzzVibe"
            />
            <h1>Admin Dashboard</h1>
            <button 
                className="membership-qr-scanner-button"
                onClick={() => navigate('/admin-studio-qr-scanner')}
            >
                Open QR Scanner
            </button>
            <input 
                type="text" 
                placeholder="Search by name..." 
                value={searchTerm} 
                onChange={handleSearch} 
                className="membership-search-bar"
            />
            <table className="membership-members-table">
                <thead>
                    <tr>
                        <th>First Name</th>
                        <th>Last Name</th>
                        <th>Email</th>
                        <th>Date of Birth</th>
                        <th>Membership Status</th>
                        <th>Signed In Status</th>
                        <th>Photo</th>
                    </tr>
                </thead>
                <tbody>
                    {filteredMembers.map(member => (
                        <tr 
                            key={member._id} 
                            className={`${member.isSignedIn ? 'membership-member-signed-in' : ''} ${member.status === 'canceled' ? 'membership-member-canceled' : ''}`}
                            onClick={() => handleMemberClick(member)}
                        >
                            <td>{member.firstName}</td>
                            <td>{member.lastName}</td>
                            <td>{member.email}</td>
                            <td>{new Date(member.dob).toLocaleDateString()}</td>
                            <td>{member.status}</td>
                            <td>{member.isSignedIn ? 'Signed In' : 'Signed Out'}</td>
                            <td><img src={member.photo} alt={`${member.firstName} ${member.lastName}`} className="membership-member-photo" /></td>
                        </tr>
                    ))}
                </tbody>
            </table>
            {selectedMember && (
                <MemberDetails member={selectedMember} onClose={handleCloseDetails} />
            )}
        </div>
    );
};

export default StudioAdminDashboard;

