import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ProductCard from "../Route/ProductCard/ProductCard";
import { server } from "../../server";
import { Disclosure } from '@headlessui/react';
import { ChevronDownIcon, FunnelIcon } from '@heroicons/react/20/solid';
import { useSelector } from "react-redux";

const IndividualShopProducts = ({ shopId }) => {
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [priceFilter, setPriceFilter] = useState('');
  const [sortByNewest, setSortByNewest] = useState(false);
  const currentProfile = useSelector((state) => state.user.currentProfile);

  useEffect(() => {
    axios.get(`${server}/product/get-all-products-shop/${shopId}`, { withCredentials: true })
      .then((response) => {
        const filteredByCurrentProfile = response.data.products.filter(product => 
          currentProfile === 'User' ? product.productType.includes('retail') : product.productType.includes('wholesale'));
        setProducts(filteredByCurrentProfile);
        setFilteredProducts(filteredByCurrentProfile);
      })
      .catch((error) => console.error('Error fetching products:', error));
  }, [shopId, currentProfile]);

  useEffect(() => {
    applyFilters();
  }, [priceFilter, sortByNewest, products]);

  const applyFilters = () => {
    let filtered = [...products];

    // Price Filter
    if (priceFilter) {
      const [min, max] = priceFilter.split('-').map(Number);
      filtered = filtered.filter(product => {
        const price = product.discountPrice || product.originalPrice;
        return price >= min && (max ? price <= max : true);
      });
    }

    // Sort by Newest
    if (sortByNewest) {
      filtered.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
    }

    setFilteredProducts(filtered);
  };

  return (
    <div className="individual-shop-products-container">
      {/* Filters */}
      <Disclosure as="section" aria-labelledby="filter-heading">
        {({ open }) => (
          <>
            <div className="individual-shop-products-filter-container">
              <div className="individual-shop-products-filter-content">
                <Disclosure.Button className="individual-shop-products-filter-button">
                  <FunnelIcon className="individual-shop-products-filter-icon" aria-hidden="true" />
                  Filters
                  <ChevronDownIcon className={`${open ? 'rotate-180' : ''} individual-shop-products-chevron-icon`} />
                </Disclosure.Button>
              </div>
            </div>
            <Disclosure.Panel className="individual-shop-products-disclosure-panel">
              <div className="individual-shop-products-disclosure-content">
                <div className="individual-shop-products-disclosure-inner">
                  {/* Price Filter */}
                  <div>
                    <label htmlFor="price-filter" className="individual-shop-products-label">Price Range</label>
                    <select
                      id="price-filter"
                      className="individual-shop-products-select"
                      value={priceFilter}
                      onChange={e => setPriceFilter(e.target.value)}
                    >
                      <option value="">All Prices</option>
                      <option value="0-25">$0 - $25</option>
                      <option value="25-50">$25 - $50</option>
                      <option value="50-75">$50 - $75</option>
                      <option value="75-">$75+</option>
                    </select>
                  </div>
                  {/* Sort by Newest */}
                  <div>
                    <label htmlFor="newest-filter" className="individual-shop-products-label">Sort by Newest</label>
                    <input
                      id="newest-filter"
                      type="checkbox"
                      className="individual-shop-products-checkbox"
                      checked={sortByNewest}
                      onChange={e => setSortByNewest(e.target.checked)}
                    />
                  </div>
                </div>
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>

      {/* Product Grid */}
      <div className="individual-shop-products-grid">
        {filteredProducts.map((product, index) => (
          <ProductCard data={product} key={index} />
        ))}
      </div>
    </div>
  );
};

export default IndividualShopProducts;



