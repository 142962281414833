// Add to cart action modified to handle profile names
export const addTocart = (data) => (dispatch, getState) => {
  const profileType = sessionStorage.getItem('currentProfile') || 'User';
  const currentCart = getState().cart.carts[profileType] || [];
  const itemIndex = currentCart.findIndex(item => item._id === data._id);

  let updatedCart = [];
  if (itemIndex !== -1) {
    updatedCart = currentCart.map(item =>
      item._id === data._id ? { ...item, qty: data.qty } : item
    );
  } else {
    updatedCart = [...currentCart, { ...data, qty: data.qty || 1 }];
  }

  dispatch({
    type: "addTocart",
    profileType: profileType,
    payload: updatedCart
  });

  localStorage.setItem("cartItems_" + profileType, JSON.stringify(updatedCart));
};

// Remove from cart action modified to handle profile names
export const removeFromCart = (data) => async (dispatch, getState) => {
  const profileType = sessionStorage.getItem('currentProfile') || 'User';
  dispatch({
    type: "removeFromCart",
    payload: data._id,
    profileType: profileType
  });

  localStorage.setItem("cartItems_" + profileType, JSON.stringify(getState().cart.carts[profileType] || []));
};

export const clearCart = (profileType) => (dispatch) => {
  dispatch({
    type: "clearCart",
    profileType: profileType
  });

  localStorage.setItem("cartItems_" + profileType, JSON.stringify([]));
};