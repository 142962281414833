import React, { useState, useEffect, Fragment, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
    likeOrUnlikePost,
    fetchSinglePostComments,
    postComment,
    editPost,
    deletePost,
    deleteComment,
    editComment,
    resharePost,
    fetchSinglePostLikes,
    reportPost 
} from '../redux/actions/post';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { format } from 'date-fns';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { FixedSizeList as List } from 'react-window';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRocket } from '@fortawesome/free-solid-svg-icons';
import { Box, IconButton, Typography, Modal, Backdrop, Fade, TextField, Button, MenuItem } from "@mui/material";
import {
  ChatBubbleOutlineOutlined,
  ChatBubble,
} from "@mui/icons-material";
import { Popover, Transition } from '@headlessui/react';
import {
  EllipsisHorizontalIcon,
} from '@heroicons/react/24/outline';

import FlexBetween from "../components/FlexBetween";
import Friend from "../components/Friend";
import WidgetWrapper from "../components/WidgetWrapper";
import Avatar from "../components/Layout/Avatar";

const SinglePostWidgetRedux = ({
  postId,
  postUserId,
  owner,
  name,
  description,
  profileType,
  location,
  images = [],
  videos = [],
  avatar,
  likes,
  comments,
  createdAt,
  resharedBy,
  reshareDescription,
  reshareAvatar,
  link
}) => {
  const [isComments, setIsComments] = useState(false);
  const [showReportModal, setShowReportModal] = useState(false); 
  const [reportReason, setReportReason] = useState(''); 
  const [reportDescription, setReportDescription] = useState(''); 
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const seller = useSelector((state) => state.seller.activeSeller);
  const loggedInUserId = user?._id;
  const loggedInSellerId = seller?._id;
  const [scrollPosition, setScrollPosition] = useState(0); 
  const [showReshareInput, setShowReshareInput] = useState(false);
  const [reshareInput, setReshareInput] = useState('');
  const [isReshare, setIsReshare] = useState(!!resharedBy);
  const [isEditMode, setIsEditMode] = useState(false);
  const [editedDescription, setEditedDescription] = useState(description);
  const [editedReshareDescription, setEditedReshareDescription] = useState(reshareDescription);
  const [commentUpdateTrigger, setCommentUpdateTrigger] = useState(0);
  const formattedDate = format(new Date(createdAt), "PPP");
  const [currentPage, setCurrentPage] = useState(1);
  const currentProfileType = useSelector(state => state.user.currentProfile); 
  const [currentProfileId, setCurrentProfileId] = useState(currentProfileType === 'User' ? user?._id : seller?._id);
  const [commentText, setCommentText] = useState('');
  const [editingCommentId, setEditingCommentId] = useState(null);
  const [newCommentText, setNewCommentText] = useState('');
  const currentProfile = useSelector(state => state.user.currentProfile);
  
  // Use single post-specific comments
  const commentsData = useSelector((state) => state.posts.singlePostComments || []);
  
  // Use single post-specific likes
  const likesData = useSelector((state) => state.posts.singlePostLikes[postId] || {});
  
  // Use single post data
  const postData = useSelector((state) => state.posts.singlePost);

  const [isLiked, setIsLiked] = useState(false);
  const [likeCount, setLikeCount] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(fetchSinglePostLikes(postId));
  }, [dispatch, postId]);

  useEffect(() => {
    const relevantPostData = postData;
    if (relevantPostData && likesData) {
      const isLikedStatus = likesData[currentProfileId] === true;
      const likeCountValue = Object.values(likesData).filter(value => value === true).length;
  
      setIsLiked(isLikedStatus);
      setLikeCount(likeCountValue);
    }
  }, [postData, currentProfileId, likesData]);

  useEffect(() => {
    setCurrentProfileId(currentProfileType === 'User' ? user?._id : seller?._id);
  }, [currentProfileType, user, seller]);

  const toggleEditMode = useCallback((e) => {
    if (e) e.preventDefault();
    if (!isEditMode) {
      setScrollPosition(window.scrollY);
    } else {
      window.scrollTo(0, scrollPosition);
    }
    setIsEditMode(!isEditMode);
    if (isEditMode) {
      setEditedDescription(description);
    }
  }, [description, isEditMode, scrollPosition]);

  const handleCancelReshare = () => {
    setShowReshareInput(false); 
    setReshareInput(''); 
  };
  const handleShowReshareInput = () => {
    setReshareInput('');
    setShowReshareInput(true);
  };

  const handleCancelEdit = () => {
    setEditedDescription(description); 
    setEditedReshareDescription(reshareDescription); 
    setIsEditMode(false); 
  };

  useEffect(() => {
    dispatch(fetchSinglePostComments(postId, currentPage)).catch((error) => {
      console.error('Error fetching comments:', error);
      toast.error('Failed to fetch comments');
    });
  }, [postId, dispatch, currentPage, commentUpdateTrigger]); 

  const loadMoreComments = useCallback(() => {
    const nextPage = currentPage + 1;
    dispatch(fetchSinglePostComments(postId, nextPage)).catch((error) => {
      console.error('Error fetching more comments:', error);
      toast.error('Failed to load more comments');
    });
    setCurrentPage(nextPage);
  }, [currentPage, dispatch, postId]);

  const handleLikeOrUnlikePost = () => {
    const updatedLikes = { ...postData.likes };
    if (updatedLikes[currentProfileId]) {
      delete updatedLikes[currentProfileId];
    } else {
      updatedLikes[currentProfileId] = true;
    }

    setIsLiked(!isLiked);
    setLikeCount(isLiked ? likeCount - 1 : likeCount + 1);

    dispatch(likeOrUnlikePost(postId, currentProfile))
      .catch((error) => {
        setIsLiked(isLiked);
        setLikeCount(isLiked ? likeCount + 1 : likeCount - 1);
        toast.error('Failed to update like status');
      });
  };

  const handleCommentSubmit = (e) => {
    e.preventDefault();
    dispatch(postComment(postId, commentText, currentProfile))
      .then(() => {
        setCommentText('');
        setCommentUpdateTrigger(prev => prev + 1); 
      })
      .catch((error) => {
        toast.error('Failed to post comment');
      });
  };

  const handleEditSubmit = (e) => {
    e.preventDefault();
    dispatch(editPost(postId, editedDescription, reshareDescription))
      .catch((error) => {
        toast.error('Failed to edit post');
      });
    setIsEditMode(false);
  };

  const handleDeletePost = (e) => {
    e.preventDefault();
    dispatch(deletePost(postId)).catch((error) => {
      toast.error('Failed to delete post');
    });
  };

  const handleDeleteComment = (commentId) => {
    dispatch(deleteComment(postId, commentId))
      .catch((error) => {
        toast.error('Failed to delete comment');
      });
    setCommentUpdateTrigger(prev => prev + 1);
  };

  const handleEditComment = (commentId, newText) => {
    dispatch(editComment(postId, commentId, newText))
      .catch((error) => {
        toast.error('Failed to edit comment');
      });
    setCommentUpdateTrigger(prev => prev + 1);
  };

  const handleSharePost = () => {
    const reshareData = {
      profileType: currentProfile,
      reshareDescription: reshareInput, 
    };
    dispatch(resharePost(postId, reshareData))
      .then(() => {
        setShowReshareInput(false);
        setReshareInput(''); 
        toast.success('Post reshared successfully');
      })
      .catch((error) => {
        toast.error('Failed to reshare post');
      });
  };

  const handleReportPost = () => {
    if (!reportReason || !reportDescription) {
      toast.error('Please provide both a reason and a description.');
      return;
    }

    const reportData = {
      postId,
      reason: reportReason,
      description: reportDescription,
    };

    dispatch(reportPost(reportData))
      .then(() => {
        setShowReportModal(false);
        setReportReason('');
        setReportDescription('');
        toast.success('Post reported successfully.');
      })
      .catch((error) => {
        toast.error('Failed to report post');
      });
  };

  const renderEditForm = () => (
    <form onSubmit={handleEditSubmit} style={{ width: '100%' }}>
      <textarea
        autoFocus
        className="edit-description-textarea"
        value={isReshare ? editedReshareDescription : editedDescription}
        onChange={(e) => isReshare ? setEditedReshareDescription(e.target.value) : setEditedDescription(e.target.value)}
        rows={3}
        style={{
            width: '100%',
            background: 'none',
            border: 'none',
            fontSize: 'inherit', 
            color: 'inherit', 
            lineHeight: 'inherit', 
            resize: 'none', 
            outline: 'none', 
          }}
      />
      <div className="flex justify-end space-x-2 mt-3">
        <button type="submit" className="inline-flex items-center justify-center rounded-md bg-green-600 px-4 py-2 text-sm font-semibold text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 transition duration-200 ease-in-out">
          Save Changes
        </button>
        <button type="button" onClick={handleCancelEdit} className="inline-flex items-center justify-center rounded-md bg-gray-300 px-4 py-2 text-sm font-semibold text-gray-700 hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 transition duration-200 ease-in-out">
          Cancel
        </button>
      </div>
    </form>
  );

  const parseHashtags = useCallback((text) => {
    const words = text.split(' ');
    return words.map((word, index) => {
      if (word.startsWith('#')) {
        const hashtag = word.substring(1);
        return (
          <span
            key={index}
            style={{ color: 'green', cursor: 'pointer' }}
            onClick={() => navigate(`/hashtag/${hashtag}`)}
          >
            {word}
          </span>
        );
      }
      return `${word} `;
    });
  }, [navigate]);

  const renderLinkPreview = (link) => {
    if (!link || !link.url) return null;

    return (
      <a href={link.url} target="_blank" rel="noopener noreferrer" className="link-post-preview-link">
        <Box className="link-post-preview-container">
          {link.image && (
            <img src={link.image} alt={link.title} className="link-post-preview-image" />
          )}
          <Box className="link-post-preview-content">
            <Typography variant="h6" component="div" className="link-post-preview-title">
              {link.title}
            </Typography>
            <Typography variant="body2" color="textSecondary" className="link-post-preview-description">
              {link.description}
            </Typography>
            <Typography variant="body2" color="primary" className="link-post-preview-url">
              {link.url}
            </Typography>
          </Box>
        </Box>
      </a>
    );
  };

  return (
    <>
      <ToastContainer />
      <WidgetWrapper m="2rem 0" className="post-widget-wrapper">
        {(loggedInUserId === owner || loggedInSellerId === owner) && (
          <Popover className="post-widget-popover">
            <Popover.Button className="post-widget-popover-button">
              <EllipsisHorizontalIcon className="post-widget-icon" aria-hidden="true" />
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="post-widget-transition-enter"
              enterFrom="post-widget-transition-enter-from"
              enterTo="post-widget-transition-enter-to"
              leave="post-widget-transition-leave"
              leaveFrom="post-widget-transition-leave-from"
              leaveTo="post-widget-transition-leave-to"
            >
              <Popover.Panel className="post-widget-popover-panel">
                <div className="post-widget-popover-content">
                  <a
                    href="#"
                    onClick={(e) => toggleEditMode(e)} 
                    className="post-widget-popover-item"
                  >
                    Edit
                  </a>
                  <a
                    href="#"
                    onClick={(e) => handleDeletePost(e)} 
                    className="post-widget-popover-item"
                  >
                    Delete
                  </a>
                </div>
              </Popover.Panel>
            </Transition>
          </Popover>
        )}
  
        {(loggedInUserId !== owner && loggedInSellerId !== owner) && (
          <Popover className="post-widget-popover">
            <Popover.Button className="post-widget-popover-button">
              <EllipsisHorizontalIcon className="post-widget-icon" aria-hidden="true" />
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="post-widget-transition-enter"
              enterFrom="post-widget-transition-enter-from"
              enterTo="post-widget-transition-enter-to"
              leave="post-widget-transition-leave"
              leaveFrom="post-widget-transition-leave-from"
              leaveTo="post-widget-transition-leave-to"
            >
              <Popover.Panel className="post-widget-popover-panel">
                <div className="post-widget-popover-content">
                  <a
                    href="#"
                    onClick={() => setShowReportModal(true)} 
                    className="post-widget-popover-item"
                  >
                    Report
                  </a>
                </div>
              </Popover.Panel>
            </Transition>
          </Popover>
        )}
  
        {resharedBy && (
          <div className="post-widget-reshare">
            <div className="post-widget-reshare-header">
              <img 
                src={reshareAvatar || 'default-avatar-url'} 
                alt={resharedBy}
                className="post-widget-reshare-avatar" 
              />
              <span className="post-widget-reshare-by">Reshared by {resharedBy}</span>
            </div>
            {reshareDescription && (
              <div className="post-widget-reshare-description">
                {isEditMode && isReshare ? renderEditForm() : reshareDescription}
              </div>
            )}
          </div>
        )}
  
        {showReshareInput && (
          <div className="post-widget-reshare-container">
            <textarea
              className="post-widget-reshare-textarea"
              value={reshareInput}
              onChange={(e) => setReshareInput(e.target.value)}
              placeholder="Add a description to your reshare..."
            />
            <div className="post-widget-reshare-buttons-container">
            <button className="post-widget-reshare-button post-widget-cancel-button" onClick={handleCancelReshare}>
              Cancel
            </button>
            <button className="post-widget-reshare-button" onClick={handleSharePost}>
              Share
            </button>
            </div>
          </div>
        )}
        
        <Friend
          friendId={isReshare ? postUserId : owner} 
          friendType={profileType}
          name={name}
          subtitle={location}
          avatar={avatar}
        />
        <div className="post-widget-date">
          {formattedDate}
        </div>
        <div>
          {isEditMode && !isReshare ? renderEditForm() : (
            <Typography color="#1f9c29" sx={{ mt: "1rem" }}>
              {parseHashtags(description)}
            </Typography>
          )}
        </div>
        
        {link && renderLinkPreview(link)}
  
        <div className="post-widget-media-container">
          {images.length > 0 && (
            <div className="post-widget-image-gallery">
              {images.map((image, index) => (
                <LazyLoadImage
                  key={index}
                  width="100%"
                  height="auto"
                  alt={`post image ${index + 1}`}
                  className="post-widget-image"
                  src={image.url}
                  placeholderSrc="path/to/placeholder/image.jpg"
                />
              ))}
            </div>
          )}
          {videos.length > 0 && (
            <div className="post-widget-video-gallery">
              {videos.map((video, index) => (
                <video key={index} width="100%" controls className="post-widget-video">
                  <source src={video.url} type="video/mp4" /> 
                  Your browser does not support the video tag.
                </video>
              ))}
            </div>
          )}
        </div>
  
        <FlexBetween mt="0.25rem">
          <FlexBetween gap="1rem">
            <FlexBetween gap="0.3rem">
              <IconButton onClick={handleLikeOrUnlikePost}>
                {isLiked ? (
                  <img src="https://res.cloudinary.com/dzlopmfj8/image/upload/v1724845531/icon-BuzzBolt-02_ernlpk.png" alt="Liked" className="post-widget-like-icon" />
                ) : (
                  <img src="https://res.cloudinary.com/dzlopmfj8/image/upload/v1724845557/icon-BuzzBolt-01_qhtx6n.png" alt="Not Liked" className="post-widget-like-icon" />
                )}
              </IconButton>
              <Typography>{likeCount}</Typography>
            </FlexBetween>
  
            <FlexBetween gap="0.3rem">
              <IconButton onClick={() => setIsComments(!isComments)}>
                {commentsData.length > 0 ? (
                  <ChatBubble />
                ) : (
                  <ChatBubbleOutlineOutlined /> 
                )}
              </IconButton>
              <Typography>{commentsData.length}</Typography> 
            </FlexBetween>
          </FlexBetween>
  
          <IconButton onClick={handleShowReshareInput} title="Share Post">
      <FontAwesomeIcon icon={faRocket} />
    </IconButton>
        </FlexBetween>
  
        {isComments && (
          <List
            height={400}
            itemCount={commentsData.length}
            itemSize={70}
            width="100%"
          >
            {({ index, style }) => (
              <div key={commentsData[index]?._id} style={style} className="post-widget-comment-container">
                <div className="post-widget-comment-avatar-container">
                  <img
                    className="post-widget-comment-avatar"
                    src={commentsData[index]?.profileType === 'Shop'
                      ? commentsData[index].shopId?.avatar?.url
                      : commentsData[index].userId?.avatar?.url}
                    alt={commentsData[index]?.profileType === 'Shop'
                      ? commentsData[index].shopId?.name || 'Shop Name'
                      : commentsData[index].userId?.name || 'User Name'}
                  />
                </div>
                <div className="post-widget-comment-content">
                  <span className="post-widget-comment-author">
                    {commentsData[index]?.profileType === 'Shop' ? commentsData[index]?.shopId.name : commentsData[index]?.userId?.name}
                  </span>
                  <div className="post-widget-comment-text-box">
                    {editingCommentId === commentsData[index]?._id ? (
                      <input
                        type="text"
                        className="post-widget-comment-edit-input"
                        value={newCommentText}
                        onChange={(e) => setNewCommentText(e.target.value)}
                      />
                    ) : (
                      <span className="post-widget-comment-text">{commentsData[index]?.commentText}</span>
                    )}
                  </div>
  
                  {((commentsData[index]?.profileType === 'User' && commentsData[index].userId && loggedInUserId === commentsData[index].userId._id) ||
                  (commentsData[index]?.profileType === 'Shop' && commentsData[index].shopId && loggedInSellerId === commentsData[index].shopId._id)) && (
                    <Popover className="post-widget-comment-popover">
                      <Popover.Button>
                        <EllipsisHorizontalIcon className="post-widget-comment-options-icon" aria-hidden="true" />
                      </Popover.Button>
                      <Transition
                        as={Fragment}
                        enter="post-widget-transition-enter"
                        enterFrom="post-widget-transition-enter-from"
                        enterTo="post-widget-transition-enter-to"
                        leave="post-widget-transition-leave"
                        leaveFrom="post-widget-transition-leave-from"
                        leaveTo="post-widget-transition-leave-to"
                      >
                        <Popover.Panel className="post-widget-comment-options-panel">
                          <div className="post-widget-comment-options-container">
                            <button
                              onClick={() => {
                                setEditingCommentId(commentsData[index]?._id);
                                setNewCommentText(commentsData[index]?.commentText);
                              }}
                              className="post-widget-comment-option-button"
                            >
                              Edit
                            </button>
                            <button
                              onClick={() => handleDeleteComment(commentsData[index]?._id)}
                              className="post-widget-comment-option-button"
                            >
                              Delete
                            </button>
                          </div>
                        </Popover.Panel>
                      </Transition>
                    </Popover>
                  )}
  
                  {editingCommentId === commentsData[index]?._id && (
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        handleEditComment(editingCommentId, newCommentText);
                        setEditingCommentId(null);
                        setNewCommentText('');
                      }}
                      className="post-widget-comment-save-button"
                    >
                      Save
                    </button>
                  )}
                </div>
              </div>
            )}
          </List>
        )}
  
        <div className="post-widget-comment-input-container">
          <div className="post-widget-comment-input-flex">
            <Avatar />
            <div className="post-widget-comment-input-flex-1">
              <form className="post-widget-comment-input-form" onSubmit={handleCommentSubmit}>
                <textarea
                  value={commentText}
                  onChange={(e) => setCommentText(e.target.value)}
                  rows={3}
                  className="post-widget-comment-input"
                  placeholder="Add your comment..."
                />
                <div className="post-widget-comment-submit-container">
                  <button
                    type="submit"
                    className="post-widget-comment-submit-button"
                  >
                    POST
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </WidgetWrapper>
  
      <Modal
        open={showReportModal}
        onClose={() => setShowReportModal(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={showReportModal}>
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 400,
              bgcolor: '#333',
              border: '2px solid #000',
              boxShadow: 24,
              p: 4,
            }}
          >
            <Typography variant="h6" component="h2">
              Report Post
            </Typography>
            <TextField
              select
              label="Reason"
              value={reportReason}
              onChange={(e) => setReportReason(e.target.value)}
              fullWidth
              margin="normal"
            >
              <MenuItem value="Spam">Spam</MenuItem>
              <MenuItem value="Harassment">Harassment</MenuItem>
              <MenuItem value="Inappropriate Content">Inappropriate Content</MenuItem>
              <MenuItem value="Other">Other</MenuItem>
            </TextField>
            <TextField
              label="Description"
              value={reportDescription}
              onChange={(e) => setReportDescription(e.target.value)}
              fullWidth
              multiline
              rows={4}
              margin="normal"
            />
            <Button
              variant="contained"
              color="primary"
              onClick={handleReportPost}
              fullWidth
            >
              Submit Report
            </Button>
          </Box>
        </Fade>
      </Modal>
    </>
  );}

export default React.memo(SinglePostWidgetRedux);