import React from 'react';

const OutlookCalendarButton = ({ event }) => {
    const formatDate = (date) => {
        try {
            const d = new Date(date);
            if (isNaN(d.getTime())) {
                throw new Error('Invalid date');
            }
            return d.toISOString().replace(/\.\d{3}/, '');
        } catch (error) {
            console.error('Date formatting error:', error);
            return ''; 
        }
    };

    const outlookCalendarUrl = () => {
        const startTime = formatDate(event.date);
        const endTime = formatDate(new Date(new Date(event.date).getTime() + 2 * 60 * 60 * 1000)); // Assuming a 2-hour event
        return `https://outlook.live.com/calendar/0/deeplink/compose?path=/calendar/action/compose&startdt=${startTime}&enddt=${endTime}&subject=${encodeURIComponent(event.title)}&body=${encodeURIComponent(event.description)}&location=${encodeURIComponent(event.location.address)}`;
    };

    return (
        <a href={outlookCalendarUrl()} target="_blank" rel="noopener noreferrer" className="outlook-calendar-button-link">
            <img 
                src="https://res.cloudinary.com/dzlopmfj8/image/upload/v1723804924/outlook_calendar_l07opg.png" 
                alt="Add to Outlook Calendar" 
                className="outlook-calendar-icon"
            />
        </a>
    );
};

export default OutlookCalendarButton;

