import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { server } from "../../server";
import axios from 'axios';

const SponsoredFeedProducts = ({ adId, adSet, product }) => {
    const productRef = useRef(null); 

    const logAdClick = async (adId) => {
        try {
            await axios.post(`${server}/sponsored/click/${adId}`, { withCredentials: true, 
                headers: {
                    'current-profile': sessionStorage.getItem('currentProfile') || 'defaultProfile',
                }});
            console.log('Click logged for ad:', adId);
        } catch (error) {
            console.error('Error logging click:', error);
        }
    };

    const logImpression = async (adId) => {
        try {
            await axios.post(`${server}/sponsored/impression/${adId}`, {}, { withCredentials: true, 
                headers: {
                    'current-profile': sessionStorage.getItem('currentProfile') || 'defaultProfile',
                }});
            console.log('Impression logged for ad:', adId);
        } catch (error) {
            console.error('Error logging impression:', error);
        }
    };

    const logViewedImpression = async (adId) => {
        try {
            await axios.post(`${server}/sponsored/viewed-impression/${adId}`, {}, { withCredentials: true, 
                headers: {
                    'current-profile': sessionStorage.getItem('currentProfile') || 'defaultProfile',
                }});
            console.log('Viewed impression logged for ad:', adId);
        } catch (error) {
            console.error('Error logging viewed impression:', error);
        }
    };

    useEffect(() => {
        if (adId) {
            logImpression(adId);
        }
    }, [adId]);

    useEffect(() => {
        if (productRef.current) {
            const observer = new IntersectionObserver(
                (entries) => {
                    entries.forEach((entry) => {
                        if (entry.isIntersecting) {
                            const timer = setTimeout(() => {
                                logViewedImpression(adId);
                            }, 1000);

                            // Store the timer in the DOM element
                            entry.target._timer = timer;
                        } else {
                            // Clear the timer if the product goes out of view
                            clearTimeout(entry.target._timer);
                        }
                    });
                },
                { threshold: 0.5 } // 50% of the product must be visible
            );

            observer.observe(productRef.current);

            return () => {
                if (productRef.current) {
                    observer.disconnect();
                }
            };
        }
    }, [adId]);

    if (!product || !adSet || !adSet.images || !adSet.images["Sponsored Feed Products"]) {
        return null;
    }

    const imageUrl = adSet.images["Sponsored Feed Products"];
    const position = adSet.positions["Sponsored Feed Products"] || { x: 0, y: 0 };

    return (
        <div className="sponsored-feed-products-container" ref={productRef}>
            <h2 className="sponsored-feed-products-title">Sponsored Product</h2>
            <div className="sponsored-feed-products-content">
                <div key={product._id} className="sponsored-feed-products-card">
                    <Link to={`/product/${product._id}`} onClick={() => logAdClick(adId)} className="sponsored-feed-products-link">
                        {imageUrl && (
                            <img
                                src={imageUrl}
                                alt={product.name}
                                className="sponsored-feed-products-image"
                                style={{
                                    transform: `translate(${position.x}px, ${position.y}px)`,
                                }}
                            />
                        )}
                        <div className="sponsored-feed-products-info">
                            <div>
                                <h3 className="sponsored-feed-products-name">{product.name}</h3>
                                <p className="sponsored-feed-products-price">${product.discountPrice}</p>
                            </div>
                            <Link to={`/shop/${product.handle}`} className="sponsored-feed-products-store-link">Visit Store</Link>
                        </div>
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default SponsoredFeedProducts;


