import React, { useState } from 'react';
import axios from 'axios';
import { server } from '../../server'; 

const PackingSlipForm = ({ onClose }) => {
    const [orderDetails, setOrderDetails] = useState({
        number: '',
        date: '',
        customer: {
            name: '',
            address: ''
        },
        items: []
    });
    const [itemInput, setItemInput] = useState({
        name: '',
        quantity: ''
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        if (name === "itemName" || name === "itemQuantity") {
            setItemInput({
                ...itemInput,
                [name === "itemName" ? "name" : "quantity"]: value
            });
        } else {
            if (name === "customerName" || name === "customerAddress") {
                setOrderDetails({
                    ...orderDetails,
                    customer: {
                        ...orderDetails.customer,
                        [name === "customerName" ? "name" : "address"]: value
                    }
                });
            } else {
                setOrderDetails({
                    ...orderDetails,
                    [name]: value
                });
            }
        }
    };

    const addItemToList = () => {
        if (itemInput.name && itemInput.quantity) {
            setOrderDetails({
                ...orderDetails,
                items: [...orderDetails.items, { ...itemInput, quantity: parseInt(itemInput.quantity, 10) }]
            });
            setItemInput({ name: '', quantity: '' });
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(`${server}/shippingrule/packing-slip`, orderDetails);
            alert('Packing slip generated! File: ' + response.data.filePath);
        } catch (error) {
            console.error('Error generating packing slip:', error);
            alert('Failed to generate packing slip.');
        }
    };

    return (
        <div className="packing-slip-form-modal-content">
            <div className="packing-slip-form-header">
                <h1>Generate Packing Slip</h1>
                <button className="packing-slip-form-close-btn" onClick={onClose}>Close</button>
            </div>
            <form className="packing-slip-form" onSubmit={handleSubmit}>
                <div className="packing-slip-form-group">
                    <label>Order Number:</label>
                    <input type="text" name="number" value={orderDetails.number} onChange={handleInputChange} required />
                </div>
                <div className="packing-slip-form-group">
                    <label>Order Date:</label>
                    <input type="date" name="date" value={orderDetails.date} onChange={handleInputChange} required />
                </div>
                <div className="packing-slip-form-group">
                    <label>Customer Name:</label>
                    <input type="text" name="customerName" value={orderDetails.customer.name} onChange={handleInputChange} required />
                </div>
                <div className="packing-slip-form-group">
                    <label>Customer Address:</label>
                    <input type="text" name="customerAddress" value={orderDetails.customer.address} onChange={handleInputChange} required />
                </div>
                <h3>Add Items</h3>
                <div className="packing-slip-form-group">
                    <label>Item Name:</label>
                    <input type="text" name="itemName" value={itemInput.name} onChange={handleInputChange} />
                </div>
                <div className="packing-slip-form-group">
                    <label>Item Quantity:</label>
                    <input type="number" name="itemQuantity" value={itemInput.quantity} onChange={handleInputChange} />
                </div>
                <button type="button" className="packing-slip-form-add-item-btn" onClick={addItemToList}>Add Item</button>
                <ul className="packing-slip-form-item-list">
                    {orderDetails.items.map((item, index) => (
                        <li key={index}>{item.name} - Quantity: {item.quantity}</li>
                    ))}
                </ul>
                <button type="submit" className="packing-slip-form-submit-btn">Generate Packing Slip</button>
            </form>
        </div>
    );
};

export default PackingSlipForm;
