import React, { useState } from "react";
import { RxCross1 } from "react-icons/rx";
import { IoBagHandleOutline } from "react-icons/io5";
import { HiOutlineMinus, HiPlus } from "react-icons/hi";
import styles from "../../styles/styles";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { addTocart, removeFromCart } from "../../redux/actions/cart";
import { toast } from "react-toastify";
import { setCartOpen } from "../../state/uiSlice"; 

const Cart = ({ setOpenCart }) => {
  const profileType = sessionStorage.getItem('currentProfile') || 'User'; 
  const cartState = useSelector(state => state.cart.carts); 
  const cart = cartState[profileType] || [];
  const isCartOpen = useSelector(state => state.ui.isCartOpen);
  console.log("Initial cart state from local storage:", cart);


  const dispatch = useDispatch();

  const removeFromCartHandler = (data) => {
    dispatch(removeFromCart(data));
  };

  const totalPrice = cart.reduce(
    (acc, item) => acc + item.qty * item.discountPrice,
    0
  );
  

  const quantityChangeHandler = (data) => {
    dispatch(addTocart(data));
  };

  if (!isCartOpen) return null;

  return (
    <div className="cart-sidebar-overlay">
      <div className="cart-sidebar-popup">
        <div>
          <div className="cart-sidebar-close-btn-container">
            <RxCross1
              size={25}
              className="cart-sidebar-close-btn"
              onClick={() => dispatch(setCartOpen(false))}
            />
          </div>
          <div className="cart-sidebar-header">
            <IoBagHandleOutline size={25} />
            <h5 className="cart-sidebar-item-count">
              {cart && cart.length} items
            </h5>
          </div>
          <div className="cart-sidebar-items">
            {cart.length === 0 ? (
              <div className="cart-sidebar-empty">
                <h5>Cart is Empty.</h5>
              </div>
            ) : (
              <>
                {cart.map((i, index) => (
                  <CartSingle
                    key={index}
                    data={i}
                    quantityChangeHandler={quantityChangeHandler}
                    removeFromCartHandler={removeFromCartHandler}
                  />
                ))}
              </>
            )}
          </div>
          <div className="cart-sidebar-checkout">
            {cart.length === 0 ? (
              <div className="cart-sidebar-checkout-btn-disabled">
                <h1>Checkout Now (USD ${totalPrice})</h1>
              </div>
            ) : (
              <Link to="/checkout">
                <div className="cart-sidebar-checkout-btn">
                  <h1>Checkout Now (USD ${totalPrice})</h1>
                </div>
              </Link>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const CartSingle = ({ data, quantityChangeHandler, removeFromCartHandler }) => {
  const [value, setValue] = useState(data.qty);

  const totalPrice = data.discountPrice * value;

  const increment = () => {
    if (data.stock <= value) {
      toast.error("Product stock limited!");
    } else {
      const newQty = value + 1;
      setValue(newQty);
      quantityChangeHandler({ ...data, qty: newQty });
    }
  };

  const decrement = () => {
    if (value > 1) {
      const newQty = value - 1;
      setValue(newQty);
      quantityChangeHandler({ ...data, qty: newQty });
    }
  };

  return (
    <div className="cart-sidebar-item">
      <div className="cart-sidebar-item-content">
        <div className="cart-sidebar-item-controls">
          <div className="cart-sidebar-item-control" onClick={increment}>
            <HiPlus size={16} color="#46d300" />
          </div>
          <span className="cart-sidebar-item-quantity">{value}</span>
          <div className="cart-sidebar-item-control" onClick={decrement}>
            <HiOutlineMinus size={16} color="#46d300" />
          </div>
        </div>
        <img
          src={`${data?.images[0]?.url}`}
          alt=""
          className="cart-sidebar-item-image"
        />
        <div className="cart-sidebar-item-details">
          <h1>{data.name}</h1>
          <h4>${data.discountPrice} * {value}</h4>
          <h4 className="cart-sidebar-item-total-price">US ${totalPrice}</h4>
        </div>
        <RxCross1
          className="cart-sidebar-remove-item"
          onClick={() => removeFromCartHandler(data)}
        />
      </div>
    </div>
  );
};

export default Cart;
