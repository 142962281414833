import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { createProduct } from "../../redux/actions/product";
import { categoriesData } from "../../static/data";
import { toast } from "react-toastify";
import { Switch } from '@headlessui/react';
import { PhotoIcon } from '@heroicons/react/24/solid';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { fetchBrands, fetchBrandCatalogItems } from "../../redux/actions/brand";
import TagSelector from "../Tags/TagSelector";
import TaxCodeSelector from "./TaxCodeSelector";

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const CreateProduct = () => {
  const { seller } = useSelector((state) => state.seller);
  const { success, error } = useSelector((state) => state.products);
  const { brands } = useSelector(state => state.brand);
  const catalogItems = useSelector(state => state.brand.catalogItems);
  const [brandTypeSelection, setBrandTypeSelection] = useState('custom'); 
  const [selectedBrand, setSelectedBrand] = useState('');
  const [selectedCatalogItem, setSelectedCatalogItem] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [productType, setProductType] = useState(""); 
  const [images, setImages] = useState([]);
  const [imagePreviews, setImagePreviews] = useState([]); 
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [category, setCategory] = useState("");
  const [tags, setTags] = useState([]);
  const [originalPrice, setOriginalPrice] = useState();
  const [discountPrice, setDiscountPrice] = useState();
  const [stock, setStock] = useState();
  const [availableForPickup, setAvailableForPickup] = useState(false);
  const [availableForDelivery, setAvailableForDelivery] = useState(false);
  const [brand, setBrand] = useState("");
  const [SKU, setSKU] = useState("");
  const [lowInventoryThreshold, setLowInventoryThreshold] = useState();
  const [unitOfMeasurement, setUnitOfMeasurement] = useState("");
  const [barcode, setBarcode] = useState([]);
  const [barcodePreviews, setBarcodePreviews] = useState([]);
  const [testResults, setTestResults] = useState([]);
  const [testResultPreviews, setTestResultPreviews] = useState([]);
  const [nutritionInfoImage, setNutritionInfoImage] = useState([]);
  const [nutritionInfoImagePreviews, setNutritionInfoImagePreviews] = useState([]);
  const [isEdible, setIsEdible] = useState(false);
  const [isDragOver, setIsDragOver] = useState(false);
  const [videos, setVideos] = useState([]);
  const [videoPreviews, setVideoPreviews] = useState([]);
  const [barcodeString, setBarcodeString] = useState('');
  const [catalogImages, setCatalogImages] = useState([]);
  const [catalogVideos, setCatalogVideos] = useState([]);
  const [catalogBarcodes, setCatalogBarcodes] = useState([]);
  const [catalogTestResults, setCatalogTestResults] = useState([]);
  const [catalogNutritionInfoImages, setCatalogNutritionInfoImages] = useState([]);
  const [isOnSale, setIsOnSale] = useState(false);
  const [taxCode, setTaxCode] = useState("");
  const [weight, setWeight] = useState(0);
  const [length, setLength] = useState(0);
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  useEffect(() => {
    if (error) {
      toast.error(error);
    }
    if (success) {
      toast.success("Product created successfully!");
      navigate(`/dashboard/${seller.handle}`);
      window.location.reload();
    }
  }, [dispatch, error, success]);

  useEffect(() => {
    if (brandTypeSelection === 'branded') {
      dispatch(fetchBrands());
    }
  }, [brandTypeSelection, dispatch]);

  useEffect(() => {
    if (selectedBrand) {
      dispatch(fetchBrandCatalogItems(selectedBrand));
    }
  }, [selectedBrand, dispatch]);

  useEffect(() => {
    if (selectedCatalogItem) {
      setName(selectedCatalogItem.name);
      setDescription(selectedCatalogItem.description);
      setCategory(selectedCatalogItem.category);
      setSKU(selectedCatalogItem.SKU);
      setTags(selectedCatalogItem.tags);
      setProductType(selectedCatalogItem.productType);
      setUnitOfMeasurement(selectedCatalogItem.unitOfMeasurement);
      setIsEdible(selectedCatalogItem.isEdible);
      setBrand(selectedCatalogItem.brand.name);
      setDiscountPrice(selectedCatalogItem.priceFloor);
      setTaxCode(selectedCatalogItem.taxCode)
  
      const imageUrls = selectedCatalogItem?.images.map(image => image.url);
      setImagePreviews(imageUrls);
      setCatalogImages(selectedCatalogItem.images);
      const videoUrls = selectedCatalogItem?.videos.map(video => video.url);
      setVideoPreviews(videoUrls);
      setCatalogVideos(selectedCatalogItem?.videos);
  
      const testResultUrls = selectedCatalogItem?.testResults.map(result => result.url);
      setTestResultPreviews(testResultUrls);
      setCatalogTestResults(selectedCatalogItem?.testResults);

      const nutritionInfoImageUrls = selectedCatalogItem?.nutritionInfoImage.map(image => image.url);
      setNutritionInfoImagePreviews(nutritionInfoImageUrls);
      setCatalogNutritionInfoImages(selectedCatalogItem?.nutritionInfoImage);

      const barcodeUrls = selectedCatalogItem?.barcodes.map(result => result.url);
      setBarcodePreviews(barcodeUrls);
      setCatalogBarcodes(selectedCatalogItem?.barcodes);
    }
  }, [selectedCatalogItem]);
  
const handleDiscountPriceChange = (newPrice) => {
  if (selectedCatalogItem) {
    const adjustedPrice = Math.max(newPrice, selectedCatalogItem.priceFloor);
    setDiscountPrice(adjustedPrice);
  } else {
    setDiscountPrice(newPrice);
  }
};

const handleImageChange = (e, fieldName) => {
  const newFiles = Array.from(e.target.files);
  let updatedFiles = [];
  let updatedPreviews = [];

  switch(fieldName) {
    case "images":
      updatedFiles = [...images, ...newFiles];
      updatedPreviews = updatedFiles.map(file => URL.createObjectURL(file));
      setImages(updatedFiles);
      setImagePreviews(updatedPreviews);
      break;
    case "barcode":
      updatedFiles = [...barcode, ...newFiles];
      updatedPreviews = updatedFiles.map(file => URL.createObjectURL(file));
      setBarcode(updatedFiles);
      setBarcodePreviews(updatedPreviews);
      break;
    case "testResults":
      updatedFiles = [...testResults, ...newFiles];
      updatedPreviews = updatedFiles.map(file => URL.createObjectURL(file));
      setTestResults(updatedFiles);
      setTestResultPreviews(updatedPreviews);
      break;
    case "nutritionInfoImage":
      updatedFiles = [...nutritionInfoImage, ...newFiles];
      updatedPreviews = updatedFiles.map(file => URL.createObjectURL(file));
      setNutritionInfoImage(updatedFiles);
      setNutritionInfoImagePreviews(updatedPreviews);
      break;
    default:
  }
};

const handleVideoChange = (e) => {
  const newFiles = Array.from(e.target.files);
  const updatedFiles = [...videos, ...newFiles];
  const updatedPreviews = updatedFiles.map(file => URL.createObjectURL(file));
  setVideos(updatedFiles);
  setVideoPreviews(updatedPreviews);
};

const handleVideoDrop = (e) => {
  e.preventDefault();
  e.stopPropagation();
  setIsDragOver(false);

  const newFiles = Array.from(e.dataTransfer.files).filter(
    file => file.type.startsWith('video/')
  );
  let updatedFiles = [...videos, ...newFiles];
  let updatedPreviews = updatedFiles.map(file => URL.createObjectURL(file));
  setVideos(updatedFiles);
  setVideoPreviews(updatedPreviews);
};

const handleRemoveVideo = (index) => {
  const newVideos = videos.filter((_, i) => i !== index);
  const newPreviews = newVideos.map(file => URL.createObjectURL(file));
  setVideos(newVideos);
  setVideoPreviews(newPreviews);
};

const handleProductTypeChange = (e) => {
  const value = e.target.value;
  setProductType((prevTypes) => {
    if (prevTypes.includes(value)) {
      return prevTypes.filter((type) => type !== value);
    } else {
      return [...prevTypes, value];
    }
  });
};

const handleRemoveImage = (index, files, setFiles, setPreviews) => {
  const newFiles = files.filter((_, i) => i !== index);
  const newPreviews = newFiles.map(file => URL.createObjectURL(file));
  setFiles(newFiles);
  setPreviews(newPreviews);
};

const onDragEnd = (result) => {
  if (!result.destination) {
    return;
  }
  
  const newImages = Array.from(images);
  const newPreviews = Array.from(imagePreviews);
  
  const [removedImage] = newImages.splice(result.source.index, 1);
  const [removedPreview] = newPreviews.splice(result.source.index, 1);
  
  newImages.splice(result.destination.index, 0, removedImage);
  newPreviews.splice(result.destination.index, 0, removedPreview);
  
  setImages(newImages);
  setImagePreviews(newPreviews);
};
  
const handleImageDrop = (e, fieldName) => {
  e.preventDefault();
  e.stopPropagation();
  setIsDragOver(false);

  const newFiles = Array.from(e.dataTransfer.files);
  let updatedFiles = [];
  let updatedPreviews = [];

  switch(fieldName) {
    case "images":
      updatedFiles = [...images, ...newFiles];
      updatedPreviews = updatedFiles.map(file => URL.createObjectURL(file));
      setImages(updatedFiles);
      setImagePreviews(updatedPreviews);
      break;
    case "barcode":
      updatedFiles = [...barcode, ...newFiles];
      updatedPreviews = updatedFiles.map(file => URL.createObjectURL(file));
      setBarcode(updatedFiles);
      setBarcodePreviews(updatedPreviews);
      break;
    case "testResults":
      updatedFiles = [...testResults, ...newFiles];
      updatedPreviews = updatedFiles.map(file => URL.createObjectURL(file));
      setTestResults(updatedFiles);
      setTestResultPreviews(updatedPreviews);
      break;
    case "nutritionInfoImage":
      updatedFiles = [...nutritionInfoImage, ...newFiles];
      updatedPreviews = updatedFiles.map(file => URL.createObjectURL(file));
      setNutritionInfoImage(updatedFiles);
      setNutritionInfoImagePreviews(updatedPreviews);
      break;
    default:
  }
};

const newForm = new FormData();

const handleSubmit = (e) => {
  e.preventDefault();

  images.forEach(images => newForm.append('images', images));
  newForm.append('catalogImages', JSON.stringify(catalogImages));

  barcode.forEach(barcode => newForm.append('barcode', barcode));
  newForm.append('catalogBarcodes', JSON.stringify(catalogBarcodes));
  
  testResults.forEach(testResults => newForm.append('testResults', testResults));
  newForm.append('catalogTestResults', JSON.stringify(catalogTestResults));

  nutritionInfoImage.forEach(nutritionInfoImage => newForm.append('nutritionInfoImage', nutritionInfoImage));
  newForm.append('catalogNutritionInfoImages', JSON.stringify(catalogNutritionInfoImages));

  videos.forEach(videos => newForm.append('videos', videos));
  newForm.append('catalogVideos', JSON.stringify(catalogVideos));

  newForm.append("name", name);
  newForm.append("brandTypeSelection", brandTypeSelection);
  newForm.append("description", description);
  newForm.append("category", category);
  newForm.append('tags', JSON.stringify(tags));
  newForm.append("originalPrice", originalPrice);
  newForm.append("discountPrice", discountPrice);
  newForm.append("stock", stock);
  newForm.append("brand", brand);
  newForm.append("shopId", seller._id);
  newForm.append("handle", seller.handle);
  newForm.append("productType", JSON.stringify(productType));
  newForm.append("availableForPickup", availableForPickup);
  newForm.append("availableForDelivery", availableForDelivery);
  newForm.append("SKU", SKU);
  newForm.append("lowInventoryThreshold", lowInventoryThreshold);
  newForm.append("unitOfMeasurement", unitOfMeasurement);
  newForm.append("isEdible", isEdible);
  newForm.append("barcodeString", barcodeString);
  newForm.append('isOnSale', isOnSale);
  newForm.append('taxCode', JSON.stringify(taxCode));
  newForm.append("weight", weight);
  newForm.append("dimensions", JSON.stringify({ length, width, height }));

  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
      'current-profile': seller.name || 'defaultProfile', 
    },
    withCredentials: true,
  };

  dispatch(createProduct(newForm, config));
};

const preventScrollChange = (event) => {
  event.target.blur();
};

const updateTags = (newTags) => {
  setTags(newTags);
};
  
const updateTaxCode = (newTaxCode) => {
  console.log("Updating tax code to:", newTaxCode);  
  setTaxCode(newTaxCode);
};

return (
  <div className="create-product-component-wrapper">
    <h5 className="create-product-component-title">Create A Product</h5>
    <div className="create-product-component-section">
      <fieldset>
        <legend className="sr-only">Product Type</legend>
        <div className="create-product-component-subtitle">Product Type</div>
        <p className="create-product-component-description">Choose a product type:</p>
        <div className="create-product-component-radio-group">
          <div className="create-product-component-radio-item">
            <input
              type="radio"
              name="productType"
              value="custom"
              checked={brandTypeSelection === 'custom'}
              onChange={() => setBrandTypeSelection('custom')}
              className="create-product-component-radio-input"
              id="custom"
            />
            <label htmlFor="custom" className="create-product-component-radio-label">
              Custom
            </label>
          </div>
          <div className="create-product-component-radio-item">
            <input
              type="radio"
              name="productType"
              value="branded"
              checked={brandTypeSelection === 'branded'}
              onChange={() => setBrandTypeSelection('branded')}
              className="create-product-component-radio-input"
              id="branded"
            />
            <label htmlFor="branded" className="create-product-component-radio-label">
              Branded
            </label>
          </div>
        </div>
      </fieldset>
    </div>

    {brandTypeSelection === 'branded' && (
      <select
        value={selectedBrand}
        onChange={(e) => setSelectedBrand(e.target.value)}
        className="create-product-component-select"
      >
        <option value="">Select a Brand</option>
        {brands.map((brand) => (
          <option key={brand._id} value={brand._id}>{brand.name}</option>
        ))}
      </select>
    )}

    <div className="create-product-component-catalog-items">
      {selectedBrand && catalogItems.map((item) => (
        <div key={item._id} className="create-product-component-catalog-item" onClick={() => setSelectedCatalogItem(item)}>
          <div className="create-product-component-catalog-item-image-container">
            {item.images.length > 0 && (
              <img src={item.images[0].url} alt={item.name} className="create-product-component-catalog-item-image" />
            )}
          </div>
          <div className="create-product-component-catalog-item-info">
            <h3 className="create-product-component-catalog-item-name">{item.name}</h3>
            <p className="create-product-component-catalog-item-category">{item.category}</p>
            <p className="create-product-component-catalog-item-price">${item.priceFloor}</p>
          </div>
        </div>
      ))}
    </div>

    <form onSubmit={handleSubmit} className="create-product-component-form">
      <div className="create-product-component-form-section">
        <div className="create-product-component-form-group">
          <label htmlFor="name" className="create-product-component-form-label">
            Name <span className="create-product-component-form-required">*</span>
          </label>
          <input
            type="text"
            name="name"
            id="name"
            value={name}
            required
            onChange={(e) => setName(e.target.value)}
            className="create-product-component-form-input"
            placeholder="Enter your product name..."
          />
        </div>
      </div>

      <div className="create-product-component-upload-section">
        <label htmlFor="upload" className="create-product-component-upload-label">
          Upload Product Images
        </label>
        <div 
          className={`create-product-component-dropzone ${isDragOver ? 'create-product-component-dropzone-active' : ''}`}
          onDragEnter={() => setIsDragOver(true)}
          onDragLeave={() => setIsDragOver(false)}
          onDragOver={(e) => { e.preventDefault(); setIsDragOver(true); }}
          onDrop={(e) => handleImageDrop(e, 'images')} 
        >
          <div className="create-product-component-dropzone-content">
            <PhotoIcon className="create-product-component-dropzone-icon" aria-hidden="true" />
            <label htmlFor="upload" className="create-product-component-dropzone-label">
              <span>Drag and drop your images here or click to upload</span>
              <input 
                type="file" 
                id="upload" 
                multiple 
                onChange={(e) => handleImageChange(e, 'images')} 
                className="sr-only" 
              />
            </label>
            <p className="create-product-component-dropzone-note">PNG, JPG, GIF up to 10MB</p>
          </div>
        </div>
      </div>

      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppableImages" direction="horizontal">
          {(provided) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              className="create-product-component-image-previews"
            >
              {imagePreviews.map((imageUrl, index) => (
                <Draggable key={index} draggableId={`image-${index}`} index={index}>
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      className={`create-product-component-image-preview ${snapshot.isDragging ? "create-product-component-image-preview-dragging" : ""}`}
                      style={{ 
                        ...provided.draggableProps.style, 
                        height: index === 0 ? '216px' : '144px',
                        width: index === 0 ? '216px' : '144px',
                      }}
                    >
                      <img 
                        src={imageUrl} 
                        alt={`Uploaded ${index}`} 
                        className="create-product-component-image-preview-img"
                      />
                      <div className="create-product-component-image-preview-badge">
                        {index + 1}
                      </div>
                      <button
                        onClick={() => handleRemoveImage(index, images, setImages, setImagePreviews)}
                        className="create-product-component-image-preview-remove"
                        aria-label="Remove image"
                      >
                        &times;
                      </button>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>

      <div className="create-product-component-upload-section">
        <label htmlFor="video-upload" className="create-product-component-upload-label">
          Upload A Product Video
        </label>
        <div 
          className={`create-product-component-dropzone ${isDragOver ? 'create-product-component-dropzone-active' : ''}`}
          onDragEnter={() => setIsDragOver(true)}
          onDragLeave={() => setIsDragOver(false)}
          onDragOver={(e) => { e.preventDefault(); setIsDragOver(true); }}
          onDrop={(e) => handleVideoDrop(e)} 
        >
          <div className="create-product-component-dropzone-content">
            <PhotoIcon className="create-product-component-dropzone-icon" aria-hidden="true" />
            <label htmlFor="video-upload" className="create-product-component-dropzone-label">
              <span>Drag and drop your videos here or click to upload</span>
              <input 
                type="file" 
                id="video-upload" 
                multiple 
                onChange={handleVideoChange} 
                className="sr-only" 
              />
            </label>
            <p className="create-product-component-dropzone-note">MP4 up to 50MB</p>
          </div>
        </div>
        <div className="create-product-component-video-previews">
          {videoPreviews.map((videoUrl, index) => (
            <div key={index} className="create-product-component-video-preview">
              <video src={videoUrl} alt="Video Preview" className="create-product-component-video-preview-video" controls/>
              <button 
                onClick={() => handleRemoveVideo(index)} 
                className="create-product-component-video-preview-remove"
                aria-label="Remove video"
              >
                &times;
              </button>
            </div>
          ))}
        </div>
      </div>

      <div className="create-product-component-form-section">
        <label htmlFor="description" className="create-product-component-form-label">
          Description <span className="create-product-component-form-required">*</span>
        </label>
        <textarea
          id="description"
          name="description"
          rows={4}
          required
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          className="create-product-component-form-textarea"
          placeholder="Enter your product description..."
        />
      </div>

      <div className="create-product-component-form-section">
        <label className="create-product-component-form-label">
          Product Type <span className="create-product-component-form-required">*</span>
        </label>
        <div className="create-product-component-checkbox-group">
          <label className="create-product-component-checkbox-item">
            <input
              type="checkbox"
              value="retail"
              checked={productType.includes("retail")}
              onChange={handleProductTypeChange}
              className="create-product-component-checkbox-input"
            />
            <span className="create-product-component-checkbox-label">Retail</span>
          </label>
          <label className="create-product-component-checkbox-item">
            <input
              type="checkbox"
              value="wholesale"
              checked={productType.includes("wholesale")}
              onChange={handleProductTypeChange}
              className="create-product-component-checkbox-input"
            />
            <span className="create-product-component-checkbox-label">Wholesale</span>
          </label>
        </div>
      </div>

      <div className="create-product-component-form-section create-product-component-form-group-row">
        <div className="create-product-component-form-group">
          <label htmlFor="sku" className="create-product-component-form-label">
            SKU
          </label>
          <input
            type="text"
            name="sku"
            id="sku"
            value={SKU}
            onChange={(e) => setSKU(e.target.value)}
            className="create-product-component-form-input"
            placeholder="Enter SKU..."
          />
        </div>
        <div className="create-product-component-form-group">
          <label htmlFor="brand" className="create-product-component-form-label">
            Brand <span className="create-product-component-form-required">*</span>
          </label>
          <input
            type="text"
            name="brand"
            id="brand"
            value={brand}
            required
            onChange={(e) => setBrand(e.target.value)}
            className="create-product-component-form-input"
            placeholder="Enter your product brand..."
          />
        </div>
      </div>

      <div className="create-product-component-form-section">
        <label htmlFor="category" className="create-product-component-form-label">
          Category <span className="create-product-component-form-required">*</span>
        </label>
        <select
          id="category"
          name="category"
          value={category}
          required
          onChange={(e) => setCategory(e.target.value)}
          className="create-product-component-select"
        >
          <option value="">Choose a category</option>
          {categoriesData.map((cat, index) => (
            <option key={index} value={cat.title}>{cat.title}</option>
          ))}
        </select>
      </div>

      <div className="create-product-component-form-section">
        <Switch.Group as="div" className="create-product-component-switch-group">
          <span className="create-product-component-switch-label">On Sale</span>
          <Switch
            checked={isOnSale}
            onChange={setIsOnSale}
            className={`${isOnSale ? 'create-product-component-switch-active' : 'create-product-component-switch-inactive'} create-product-component-switch`}
          >
            <span
              className={`${isOnSale ? 'create-product-component-switch-toggle-active' : 'create-product-component-switch-toggle-inactive'} create-product-component-switch-toggle`}
            />
          </Switch>
        </Switch.Group>
      </div>

      <div className="create-product-component-form-section create-product-component-form-group-row">
        <div className="create-product-component-form-group">
          <label htmlFor="original-price" className="create-product-component-form-label">
            Original Price <span className="create-product-component-form-required">*</span>
          </label>
          <input
            type="number"
            name="original-price"
            id="original-price"
            value={originalPrice}
            onChange={(e) => {
              setOriginalPrice(e.target.value);
              if (!isOnSale) {
                setDiscountPrice(e.target.value);
              }
            }}
            onWheel={preventScrollChange}
            className="create-product-component-form-input"
            placeholder="Enter the original price..."
          />
        </div>
        {isOnSale && (
          <div className="create-product-component-form-group">
            <label htmlFor="discount-price" className="create-product-component-form-label">
              Discount Price
            </label>
            <input
              type="number"
              name="discount-price"
              id="discount-price"
              value={discountPrice}
              onChange={(e) => handleDiscountPriceChange(e.target.value)}
              onWheel={preventScrollChange}
              className="create-product-component-form-input"
              placeholder="Enter the discounted price..."
            />
          </div>
        )}
      </div>

      <div className="create-product-component-form-section create-product-component-form-group-row">
        <div className="create-product-component-form-group">
          <label htmlFor="stock" className="create-product-component-form-label">
            Stock <span className="create-product-component-form-required">*</span>
          </label>
          <input
            type="number"
            name="stock"
            id="stock"
            value={stock}
            onChange={(e) => setStock(e.target.value)}
            onWheel={preventScrollChange}
            className="create-product-component-form-input"
            placeholder="Enter the stock amount..."
          />
        </div>
        <div className="create-product-component-form-group">
          <label htmlFor="low-inventory-threshold" className="create-product-component-form-label">
            Low Inventory Threshold
          </label>
          <input
            type="number"
            name="low-inventory-threshold"
            id="low-inventory-threshold"
            value={lowInventoryThreshold}
            onChange={(e) => setLowInventoryThreshold(e.target.value)}
            onWheel={preventScrollChange}
            className="create-product-component-form-input"
            placeholder="Set the low inventory threshold..."
          />
        </div>
      </div>

      <div className="create-product-component-form-section">
        <TaxCodeSelector selectedTaxCode={taxCode} updateTaxCode={updateTaxCode} />
      </div>

      <div className="create-product-component-form-section">
        <label htmlFor="unit-of-measurement" className="create-product-component-form-label">
          Unit of Measurement
        </label>
        <select
          id="unit-of-measurement"
          name="unit-of-measurement"
          value={unitOfMeasurement}
          onChange={(e) => setUnitOfMeasurement(e.target.value)}
          className="create-product-component-select"
        >
          <option value="">Select Unit</option>
          <option value="g">Grams (g)</option>
          <option value="lbs">Pounds (lbs)</option>
          <option value="kg">Kilograms (kg)</option>
        </select>
      </div>

      <div className="create-product-component-form-section">
        <TagSelector selectedTags={tags} updateTags={updateTags} />
      </div>

      <div className="create-product-component-form-section create-product-component-form-group-row">
        <div className="create-product-component-switch-group">
          <Switch
            checked={availableForPickup}
            onChange={setAvailableForPickup}
            className={`${availableForPickup ? 'create-product-component-switch-active' : 'create-product-component-switch-inactive'} create-product-component-switch`}
          >
            <span className="create-product-component-switch-label">Available for Pickup</span>
            <span
              className={`${availableForPickup ? 'create-product-component-switch-toggle-active' : 'create-product-component-switch-toggle-inactive'} create-product-component-switch-toggle`}
            />
          </Switch>
        </div>
        <div className="create-product-component-switch-group">
          <Switch
            checked={availableForDelivery}
            onChange={setAvailableForDelivery}
            className={`${availableForDelivery ? 'create-product-component-switch-active' : 'create-product-component-switch-inactive'} create-product-component-switch`}
          >
            <span className="create-product-component-switch-label">Available for Delivery</span>
            <span
              className={`${availableForDelivery ? 'create-product-component-switch-toggle-active' : 'create-product-component-switch-toggle-inactive'} create-product-component-switch-toggle`}
            />
          </Switch>
        </div>
      </div>

      <div className="create-product-component-form-section">
          <label htmlFor="weight" className="create-product-component-form-label">
            Weight <span className="create-product-component-form-required">*</span>
          </label>
          <input
            type="number"
            name="weight"
            id="weight"
            value={weight}
            onChange={(e) => setWeight(e.target.value)}
            className="create-product-component-form-input"
            placeholder="Enter the product weight..."
            required
          />
        </div>
        <div className="create-product-component-form-section">
          <label htmlFor="length" className="create-product-component-form-label">
            Length <span className="create-product-component-form-required">*</span>
          </label>
          <input
            type="number"
            name="length"
            id="length"
            value={length}
            onChange={(e) => setLength(e.target.value)}
            className="create-product-component-form-input"
            placeholder="Enter the product length..."
            required
          />
        </div>
        <div className="create-product-component-form-section">
          <label htmlFor="width" className="create-product-component-form-label">
            Width <span className="create-product-component-form-required">*</span>
          </label>
          <input
            type="number"
            name="width"
            id="width"
            value={width}
            onChange={(e) => setWidth(e.target.value)}
            className="create-product-component-form-input"
            placeholder="Enter the product width..."
            required
          />
        </div>
        <div className="create-product-component-form-section">
          <label htmlFor="height" className="create-product-component-form-label">
            Height <span className="create-product-component-form-required">*</span>
          </label>
          <input
            type="number"
            name="height"
            id="height"
            value={height}
            onChange={(e) => setHeight(e.target.value)}
            className="create-product-component-form-input"
            placeholder="Enter the product height..."
            required
          />
        </div>

      <div className="create-product-component-form-section">
        <label htmlFor="barcode-string" className="create-product-component-form-label">
          Barcode
        </label>
        <input
          type="text"
          id="barcode-string"
          value={barcodeString}
          onChange={(e) => setBarcodeString(e.target.value)}
          className="create-product-component-form-input"
          placeholder="Enter barcode..."
        />
      </div>

      <div className="create-product-component-upload-section">
        <label htmlFor="barcode-upload" className="create-product-component-upload-label">
          Upload Barcode
        </label>
        <div 
          className={`create-product-component-dropzone ${isDragOver ? 'create-product-component-dropzone-active' : ''}`}
          onDragEnter={() => setIsDragOver(true)}
          onDragLeave={() => setIsDragOver(false)}
          onDragOver={(e) => { e.preventDefault(); setIsDragOver(true); }}
          onDrop={(e) => handleImageDrop(e, 'barcode')} 
        >
          <div className="create-product-component-dropzone-content">
            <PhotoIcon className="create-product-component-dropzone-icon" aria-hidden="true" />
            <label htmlFor="barcode-upload" className="create-product-component-dropzone-label">
              <span>Drag and drop your barcodes here or click to upload</span>
              <input 
                type="file" 
                id="barcode-upload" 
                multiple 
                onChange={(e) => handleImageChange(e, 'barcode')} 
                className="sr-only" 
              />
            </label>
            <p className="create-product-component-dropzone-note">PNG, JPG, GIF up to 10MB</p>
          </div>
        </div>
        <div className="create-product-component-barcode-previews">
          {barcodePreviews.map((barcodeUrl, index) => (
            <div key={index} className="create-product-component-barcode-preview">
              <img src={barcodeUrl} alt="Barcode" className="create-product-component-barcode-preview-img" />
              <button  
                onClick={() => handleRemoveImage(index, barcode, setBarcode, setBarcodePreviews)} 
                className="create-product-component-barcode-preview-remove"
                aria-label="Remove barcode"
              >
                &times;
              </button>
            </div>
          ))}
        </div>
      </div>

      <div className="create-product-component-upload-section">
        <label htmlFor="test-results-upload" className="create-product-component-upload-label">
          Upload Test Results
        </label>
        <div 
          className={`create-product-component-dropzone ${isDragOver ? 'create-product-component-dropzone-active' : ''}`}
          onDragEnter={() => setIsDragOver(true)}
          onDragLeave={() => setIsDragOver(false)}
          onDragOver={(e) => { e.preventDefault(); setIsDragOver(true); }}
          onDrop={(e) => handleImageDrop(e, 'testResults')} 
        >
          <div className="create-product-component-dropzone-content">
            <PhotoIcon className="create-product-component-dropzone-icon" aria-hidden="true" />
            <label htmlFor="test-results-upload" className="create-product-component-dropzone-label">
              <span>Drag and drop test results here or click to upload</span>
              <input 
                type="file" 
                id="test-results-upload" 
                multiple 
                onChange={(e) => handleImageChange(e, 'testResults')} 
                className="sr-only" 
              />
            </label>
            <p className="create-product-component-dropzone-note">PNG, JPG, GIF up to 10MB</p>
          </div>
        </div>
        <div className="create-product-component-test-result-previews">
          {testResultPreviews.map((testResultUrl, index) => (
            <div key={index} className="create-product-component-test-result-preview">
              <img src={testResultUrl} alt="Test Result" className="create-product-component-test-result-preview-img" />
              <button 
                onClick={() => handleRemoveImage(index, testResults, setTestResults, setTestResultPreviews)} 
                className="create-product-component-test-result-preview-remove"
                aria-label="Remove test result"
              >
                &times;
              </button>
            </div>
          ))}
        </div>
      </div>

      <div className="create-product-component-switch-group">
        <Switch
          checked={isEdible}
          onChange={setIsEdible}
          className={`${isEdible ? 'create-product-component-switch-active' : 'create-product-component-switch-inactive'} create-product-component-switch`}
        >
          <span className="create-product-component-switch-label">Is Edible</span>
          <span
            className={`${isEdible ? 'create-product-component-switch-toggle-active' : 'create-product-component-switch-toggle-inactive'} create-product-component-switch-toggle`}
          />
        </Switch>
      </div>

      {isEdible && (
        <div className="create-product-component-upload-section">
          <label htmlFor="nutrition-info-upload" className="create-product-component-upload-label">
            Upload Nutrition Info
          </label>
          <div 
            className={`create-product-component-dropzone ${isDragOver ? 'create-product-component-dropzone-active' : ''}`}
            onDragEnter={() => setIsDragOver(true)}
            onDragLeave={() => setIsDragOver(false)}
            onDragOver={(e) => { e.preventDefault(); setIsDragOver(true); }}
            onDrop={(e) => handleImageDrop(e, 'nutritionInfoImage')} 
          >
            <div className="create-product-component-dropzone-content">
              <PhotoIcon className="create-product-component-dropzone-icon" aria-hidden="true" />
              <label htmlFor="nutrition-info-upload" className="create-product-component-dropzone-label">
                <span>Drag and drop nutrition info here or click to upload</span>
                <input 
                  type="file" 
                  id="nutrition-info-upload" 
                  multiple 
                  onChange={(e) => handleImageChange(e, 'nutritionInfoImage')} 
                  className="sr-only" 
                />
              </label>
              <p className="create-product-component-dropzone-note">PNG, JPG, GIF up to 10MB</p>
            </div>
          </div>
          <div className="create-product-component-nutrition-info-previews">
            {nutritionInfoImagePreviews.map((nutritionInfoUrl, index) => (
              <div key={index} className="create-product-component-nutrition-info-preview">
                <img src={nutritionInfoUrl} alt="Nutrition Info" className="create-product-component-nutrition-info-preview-img" />
                <button 
                  onClick={() => handleRemoveImage(index, nutritionInfoImage, setNutritionInfoImage, setNutritionInfoImagePreviews)} 
                  className="create-product-component-nutrition-info-preview-remove"
                  aria-label="Remove nutrition info"
                >
                  &times;
                </button>
              </div>
            ))}
          </div>
        </div>
      )}

      <div className="create-product-component-submit">
        <button
          type="submit"
          className="create-product-component-submit-button"
        >
          Create Product
        </button>
      </div>
    </form>
  </div>
);
};

export default CreateProduct;
