import { Typography, useTheme } from "@mui/material";
import FlexBetween from "../components/FlexBetween";
import WidgetWrapper from "../components/WidgetWrapper";

const AdvertWidget = () => {

  return (
    <WidgetWrapper>
      <FlexBetween>
        <Typography color="#124216" variant="h5" fontWeight="500">
          Sponsored
        </Typography>
        <Typography color="#26a631">Create Ad</Typography>
      </FlexBetween>
      <img
        width="320px"
        height="auto"
        alt="advert"
        src="https://res.cloudinary.com/dzlopmfj8/image/upload/v1698336314/fbbzpannrveiiusop06x.jpg"
        style={{ borderRadius: "0.75rem", margin: "0.75rem 0" }}
      />
      <FlexBetween>
        <Typography color="#9fb3a1">Mika CBD</Typography>
        <Typography color="#26a631">Visit Store</Typography>
      </FlexBetween>
      <Typography color="#26a631" m="0.5rem 0">
       CBD Tinctures 
      </Typography>
    </WidgetWrapper>
  );
};

export default AdvertWidget;
