import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { server } from "../../server";
import { toast } from 'react-toastify'; 
import ShopChangeSubscription from './ShopChangeSubscription';
import { useNavigate } from 'react-router-dom';

const ShopSubscriptions = () => {
    const [subscriptions, setSubscriptions] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [selectedSubscription, setSelectedSubscription] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        fetchSubscriptions();
    }, []);

    const fetchSubscriptions = async () => {
        try {
            const response = await axios.get(`${server}/subscriptions/shop-subscriptions/my-subscriptions`, {
                withCredentials: true,
                headers: {
                    "Content-Type": "application/json",
                    'current-profile': sessionStorage.getItem('currentProfile') || 'defaultProfile',
                }
            });
            setSubscriptions(response.data);
            setLoading(false);
        } catch (error) {
            setError('Failed to fetch subscriptions');
            setLoading(false);
            console.error(error);
        }
    };

    const confirmCancelSubscription = (subscriptionId) => {
        setShowModal(true);
        setSelectedSubscription(subscriptionId);
    };

    const cancelSubscription = async () => {
        try {
            await axios.post(`${server}/subscriptions/shop-subscriptions/cancel-subscription`, { subscriptionId: selectedSubscription }, {
                withCredentials: true,
                headers: {
                    "Content-Type": "application/json",
                    'current-profile': sessionStorage.getItem('currentProfile') || 'defaultProfile',
                }
            });
            fetchSubscriptions(); 
            setShowModal(false);
            toast.success('Subscription cancelled successfully!');
        } catch (error) {
            console.error('Failed to cancel subscription', error);
            toast.error('Failed to cancel subscription');
            setShowModal(false);
        }
    };

    if (loading) return <div className="shop-subscriptions-overview-loading">Loading...</div>;
    if (error) return <div className="shop-subscriptions-overview-error-message">Error: {error}</div>;

    return (
        <div className="shop-subscriptions-overview-container">
            <h2>My Shop Subscriptions</h2>
            {showModal && (
                <div className="shop-subscriptions-overview-modal-overlay">
                    <div className="shop-subscriptions-overview-modal-content">
                        <h4>Are you sure you want to cancel this subscription?</h4>
                        <button onClick={cancelSubscription} className="shop-subscriptions-overview-modal-confirm-btn">Yes</button>
                        <button onClick={() => setShowModal(false)} className="shop-subscriptions-overview-modal-cancel-btn">No</button>
                    </div>
                </div>
            )}

            {subscriptions.length > 0 ? (
                <ul className="shop-subscriptions-overview-list">
                    {subscriptions.map(subscription => (
                        <li key={subscription._id} className={`shop-subscriptions-overview-item ${subscription.status === 'active' ? 'shop-subscriptions-overview-item-active' : ''}`}>
                            <p className="shop-subscriptions-overview-detail">Tier: {subscription.tier}</p>
                            <p className="shop-subscriptions-overview-detail">Status: {subscription.status}</p>
                            <p className="shop-subscriptions-overview-detail">Next Billing Date: {new Date(subscription.currentPeriodEnd).toLocaleDateString()}</p>
                            {subscription.status === 'active' && (
                                <div className="shop-subscriptions-overview-controls">
                                    <ShopChangeSubscription currentSubscriptionId={subscription._id} />
                                    <button onClick={() => confirmCancelSubscription(subscription._id)} className="shop-subscriptions-overview-cancel-btn">
                                        Cancel Subscription
                                    </button>
                                </div>
                            )}
                        </li>
                    ))}
                </ul>
            ) : (
                <div className="shop-subscriptions-overview-empty">
                    <p>No subscriptions found.</p>
                    <button onClick={() => navigate('/shop-subscriptions')} className="shop-subscriptions-overview-view-plans-btn">
                        View Subscription Plans
                    </button>
                </div>
            )}

        </div>
    );
};

export default ShopSubscriptions;

