import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { server } from '../server'; 
import UserProfileData from '../components/UserProfileData';
import Header from '../components/Layout/Header';
import Sidebar from '../components/Layout/Sidebar';
import AdvertWidget from '../widgets/AdvertWidget';
import UserBanner from '../components/Shop/UserBanner';
import FriendListTileWidget from '../widgets/FriendListTileWidget';
import { AiOutlineCamera } from "react-icons/ai";
import FriendsAvatarDisplay from '../components/FriendsAvatarDisplay';
import SponsoredProducts from '../components/Sponsored/SponsoredProducts';
import SponsoredBrands from '../components/Sponsored/SponsoredBrands';
import MyImages from '../components/Content/MyImages';
import MyEvents from '../components/Events/MyEvents';
import MyContentCarousel from '../components/Profile/MyContentCarousel';
import FollowersAvatarDisplay from '../components/Followers/FollowersAvatarDisplay';
import RoomsProfile from '../components/Rooms/RoomsProfile';
import MyFollowersDisplay from '../components/Connections/MyFollowersDisplay';
import FollowersDisplay from '../components/Connections/FollowersDisplay';


const PrivateProfilePage = () => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [selectedFile, setSelectedFile] = useState(null);
  const { id } = useParams(); 
  const picturePath = user?.picturePath;

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get(`${server}/user/user-info/${id}`);
        setUser(response.data.user);
      } catch (error) {
        console.error('Error fetching user data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, [id]);

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleSubmitAvatar = async () => {
    if (!selectedFile) {
      alert('Please select a file to upload');
      return;
    }

    const formData = new FormData();
    formData.append('avatar', selectedFile);

    try {
      const response = await axios.put(`${server}/user/update-avatar`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        withCredentials: true,
      });

      if (response.data.success) {
        setUser({ ...user, avatar: response.data.user.avatar });
      }
    } catch (error) {
      console.error('Error updating avatar:', error);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!user) {
    return <div>User data not available</div>;
  }

  return (
    <div className="profile-page">
      <Header />
      <Sidebar />
      <UserBanner isUser={true} />
      <div className="profile-header"></div>
      <div className="profile-main">
      <div className="profile-extra-container">
        <RoomsProfile />
  </div>
  <div className="profile-info-container">
    <div className="profile-avatar-wrapper">
      <img
        src={user.avatar?.url || 'default-avatar.png'}
        alt={user.name}
        className="profile-avatar"
      />
      {id === user._id && (
              <div className="profile-upload-container">
                <input type="file" id="avatar" onChange={handleFileChange} className="hidden" />
                <label htmlFor="avatar">
                  <AiOutlineCamera />
                </label>
                <button onClick={handleSubmitAvatar}></button>
              </div>
            )}
      <div className="profile-info">
        <div className="profile-name-wrapper">
          <h3 className="profile-name">{user.name}</h3>
          <h3 className="profile-handle">@{user.handle}</h3>
          <div className="profile-friends">
            
            <FollowersDisplay userId={id} profileType="User" />
          
            </div>
            
        </div>
      </div>
    </div>
    
  </div>
  
  
  <div className="profile-mycontent-carousel">
    <MyContentCarousel />
  </div>
  </div>
  
  
      <div className="profile-main-content">
        <div className="profile-sidebar-left">
          <MyImages userId={id} />
          <MyEvents />
        </div>
        <main className="profile-main-area">
          <UserProfileData />
        </main>
        <aside className="profile-sidebar-right">
          <SponsoredProducts />
          <SponsoredBrands />
        </aside>
      </div>
    </div>
  );
  };

export default PrivateProfilePage;


