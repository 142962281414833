import React, { useState } from 'react';
import MemeGallery from '../Meme/MemeGallery';
import MemeCreator from '../Meme/MemeCreator';

const MyContentCarousel = () => {
  const items = [
    {
      type: 'video',
      videoSrc: "https://player.vimeo.com/video/1003216849?autoplay=0&loop=1&title=0&byline=0&portrait=0"
    },
    {
      type: 'meme',
    },
    {
      type: 'image',
      name: "Collectibles",
      description: "The New Collection",
      backgroundImage: "https://res.cloudinary.com/dzlopmfj8/image/upload/v1716393583/collectibles-category-buzzvibe_xeeuv2.jpg",
      buttonLink: "http://link_to_some_destination.com",
      buttonText: "Discover"
    },
    {
      type: 'image',
      name: "What's the Buzz?",
      description: "Our Favourites",
      backgroundImage: "https://res.cloudinary.com/dzlopmfj8/image/upload/v1716393233/art-category-buzzvibe_cfepbq.jpg",
      buttonLink: "http://link_to_some_destination.com",
      buttonText: "Explore"
    },
  ];

  const [currentIndex, setCurrentIndex] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? items.length - 1 : prevIndex - 1));
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex === items.length - 1 ? 0 : prevIndex + 1));
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="my-content-carousel-container">
      <button onClick={handlePrev} className="my-content-carousel-control left">
        &#10094;
      </button>
      <div className="my-content-carousel-content" style={{ transform: `translateX(-${currentIndex * 100}%)` }}>
        {items.map((item, index) => (
          <div
            key={index}
            className={`my-content-carousel-item ${index === currentIndex ? 'active' : ''}`}
          >
            {item.type === 'video' ? (
              <div className="my-content-carousel-video-container">
                <iframe 
                  src={item.videoSrc}
                  width="100%"
                  height="100%"
                  frameBorder="0"
                  allow="autoplay; fullscreen; picture-in-picture"
                  allowFullScreen
                  title="Video"
                ></iframe>
              </div>
            ) : item.type === 'meme' ? (
              <div className="meme-slide">
                
                <MemeGallery />
                <div className="meme-slide-button-container">
                  <button onClick={handleOpenModal} className="meme-create-button">
                    Create Meme
                  </button>
                </div>
              </div>
            ) : (
              <div 
                className="my-content-carousel-image-container"
                style={{
                  backgroundImage: `url(${item.backgroundImage})`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  width: '100%',
                  height: '100%',
                }}
              >
                <div className="my-content-carousel-overlay">
                  <h2 className="my-content-carousel-title">{item.name}</h2>
                  <p className="my-content-carousel-description">{item.description}</p>
                  <a href={item.buttonLink} className="my-content-carousel-button">
                    {item.buttonText}
                  </a>
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
      <button onClick={handleNext} className="my-content-carousel-control right">
        &#10095;
      </button>

      {isModalOpen && (
        <div className="meme-modal">
          <div className="meme-modal-content">
            <span className="meme-close" onClick={handleCloseModal}>
              &times;
            </span>
            <MemeCreator />
          </div>
        </div>
      )}
    </div>
  );
};

export default MyContentCarousel;





