import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DataGrid } from "@mui/x-data-grid";
import { AiOutlineDelete, AiOutlineEdit, AiOutlineEye } from "react-icons/ai";
import { Button } from "@mui/material";
import styles from "../../styles/styles";
import { RxCross1 } from "react-icons/rx";
import axios from "axios";
import { server } from "../../server";
import { toast } from "react-toastify";
import { getAllSellers } from "../../redux/actions/sellers";
import { Switch } from '@headlessui/react';
import { Link } from "react-router-dom";
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}


const AllSellers = () => {
  const dispatch = useDispatch();
  const { sellers } = useSelector((state) => state.seller);
  const [editOpen, setEditOpen] = useState(false);
  const [currentSeller, setCurrentSeller] = useState({});
  const [open, setOpen] = useState(false);
  const [userId, setUserId] = useState("");
  const [avatarFile, setAvatarFile] = useState(null);
  const [bannerFile, setBannerFile] = useState(null);
  const [timeFrame, setTimeFrame] = useState('daily');
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [
      {
        label: 'Seller Sign-ups',
        data: [],
        borderColor: 'rgb(75, 192, 192)',
        backgroundColor: 'rgba(75, 192, 192, 0.5)',
      }
    ]
  });

  useEffect(() => {
    dispatch(getAllSellers());
  }, [dispatch]);

  useEffect(() => {
    if (sellers && sellers.length > 0) {
      processChartData(sellers, timeFrame); // Adjusted to include timeFrame in processing
    }
  }, [sellers, timeFrame]); // Dependency array includes timeFrame

  const processChartData = (sellers, timeFrame) => {
    const sortedSellers = [...sellers].sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
    const aggregationMap = {};

    sortedSellers.forEach(seller => {
      const date = new Date(seller.createdAt);
      let key;
      if (timeFrame === 'daily') {
        key = date.toLocaleDateString();
      } else { // 'monthly'
        key = `${date.getMonth() + 1}-${date.getFullYear()}`;
      }

      if (!aggregationMap[key]) {
        aggregationMap[key] = 0;
      }
      aggregationMap[key]++;
    });

    const labels = Object.keys(aggregationMap);
    const data = Object.values(aggregationMap);

    setChartData({
      labels: labels,
      datasets: [{
        label: 'Seller Sign-ups',
        data: data,
        borderColor: 'rgb(75, 192, 192)',
        backgroundColor: 'rgba(75, 192, 192, 0.5)',
      }]
    });
  };

  const handleEditClick = (seller) => {
    setCurrentSeller(seller);
    setEditOpen(true);
  };
  
  const handleEditSubmit = async (e) => {
    e.preventDefault();
  
    const formData = new FormData();
    // Append all text fields as before
    Object.keys(currentSeller).forEach(key => {
      if (key !== 'avatar' && key !== 'banner') {
        formData.append(key, currentSeller[key]);
      }
    });
  
    // Append the files for avatar and banner if they are selected
    if (avatarFile) formData.append('avatar', avatarFile);
    if (bannerFile) formData.append('banner', bannerFile);
  
    try {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        withCredentials: true,
      };
      const response = await axios.patch(`${server}/shop/edit-seller/${currentSeller.id}`, formData, config);
      toast.success(response.data.message);
      setEditOpen(false);
      dispatch(getAllSellers());
    } catch (error) {
      console.error("Error updating seller:", error);
      toast.error("Failed to update seller.");
    }
  };
  

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Seller Sign-ups Over Time',
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: 'Date',
        },
      },
      y: {
        title: {
          display: true,
          text: 'Count',
        },
        beginAtZero: true,
      },
    },
    elements: {
      line: {
        tension: 0.4,
      },
      point: {
        hoverRadius: 7,
        radius: 5,
      },
    },
  };

  const handleDelete = async (id) => {
    await axios
    .delete(`${server}/shop/delete-seller/${id}`, { withCredentials: true })
    .then((res) => {
      toast.success(res.data.message);
    });

  dispatch(getAllSellers());
  };

  

  const columns = [
    { field: "id", headerName: "Seller ID", minWidth: 150, flex: 0.7 },

    { 
      field: "avatar", 
      headerName: "Avatar", 
      renderCell: (params) => (
        <img src={params.value} alt="avatar" style={{ width: 30, height: 30, borderRadius: '50%' }} />
      ), 
      minWidth: 70, 
      flex: 0.5, 
      sortable: false, 
      align: 'center',
      headerAlign: 'center',
    },

    {
      field: "name",
      headerName: "name",
      minWidth: 130,
      flex: 0.7,
    },
    {
      field: "email",
      headerName: "Email",
      type: "text",
      minWidth: 130,
      flex: 0.7,
    },
    {
      field: "address",
      headerName: "Seller Address",
      type: "text",
      minWidth: 130,
      flex: 0.7,
    },

    {
      field: "joinedAt",
      headerName: "joinedAt",
      type: "text",
      minWidth: 130,
      flex: 0.8,
    },
    {
        field: "  ",
        flex: 1,
        minWidth: 150,
        headerName: "Preview Shop",
        type: "number",
        sortable: false,
        renderCell: (params) => {
          return (
            <>
            <Link to={`/shop/${params.id}`}>
            <Button>
                <AiOutlineEye size={20} />
              </Button>
            </Link>
            </>
          );
        },
      },
      {
        field: "edit",
        headerName: "Edit",
        sortable: false,
        renderCell: (params) => (
          <>
            <Button onClick={() => handleEditClick(params.row)}>
              <AiOutlineEdit size={20} />
            </Button>
          </>
        ),
        flex: 1,
        minWidth: 150,
      },      
    {
      field: " ",
      flex: 1,
      minWidth: 150,
      headerName: "Delete Seller",
      type: "number",
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            <Button onClick={() => setUserId(params.id) || setOpen(true)}>
              <AiOutlineDelete size={20} />
            </Button>
          </>
        );
      },
    },
  ];

  const row = [];
  sellers &&
  sellers.forEach((item) => {
      row.push({
        id: item._id,
        name: item?.name,
        email: item?.email,
        joinedAt: item.createdAt.slice(0, 10),
        address: item.address,
        avatar: item.avatar.url,
      });
    });

    const scrollableStyle = {
      maxHeight: '80vh',
      overflowY: 'auto',
    };
    

  return (
      <div className="w-full flex flex-col justify-center pt-5 items-center">
        {/* Graph Section */}
        <div className="w-full p-4 mb-5">
          <h3 className="text-[22px] font-Poppins pb-2">Seller Sign-ups Chart</h3>
          <div>
  <button
    onClick={() => setTimeFrame('daily')}
    style={{
      backgroundColor: '#4CAF50',
      color: 'white',
      padding: '10px 24px',
      border: 'none',
      borderRadius: '4px',
      cursor: 'pointer',
      marginRight: '8px',
    }}
  >
    Daily
  </button>
  <button
    onClick={() => setTimeFrame('monthly')}
    style={{
      backgroundColor: '#008CBA',
      color: 'white',
      padding: '10px 24px',
      border: 'none',
      borderRadius: '4px',
      cursor: 'pointer',
    }}
  >
    Monthly
  </button>
</div>


          <div className="w-full bg-white rounded p-5 shadow">
            <Line data={chartData} options={{
              responsive: true,
              plugins: {
                legend: {
                  position: 'top',
                },
                title: {
                  display: true,
                  text: 'Seller Sign-ups Over Time',
                },
              },
            }} />
          </div>
        </div>
      <div>
      <div className="w-full p-4">
      <h3 className="text-[22px] font-Poppins pb-2">All Sellers</h3>
  <div className="w-full min-h-[45vh] bg-white rounded shadow" style={{ marginTop: '32px' }}> {/* Add shadow and margin-top */}
    <DataGrid
      rows={row}
      columns={columns}
      pageSize={10}
      disableSelectionOnClick
      autoHeight
      rowHeight={120}  // Specify row height
      sx={{
        boxShadow: 'none', // Remove box shadow
        border: '1px solid #2D3748', // Add border
        '.MuiDataGrid-columnHeaders': {
          backgroundColor: '#146e10', // Set column headers background color
          color: '#F7FAFC', // Set column headers text color
          justifyContent: 'center', // Center column headers content
        },
        '.MuiDataGrid-cell': {
          borderBottom: '1px solid #2D3748', // Set cell bottom border
          padding: '4px', // Set cell padding
          color: '#F7FAFC', // Set cell text color
          justifyContent: 'center', // Center cell content horizontally
          display: 'flex',
          alignItems: 'center', // Center cell content vertically
        },
        '.MuiDataGrid-cell:focus': {
          outline: 'none', // Remove cell focus outline
        },
      }}
    />
    </div>
  </div>
        {open && (
          <div className="w-full fixed top-0 left-0 z-[999] bg-[#00000039] flex items-center justify-center h-screen">
            <div className="w-[95%] 800px:w-[40%] min-h-[20vh] bg-white rounded shadow p-5">
              <div className="w-full flex justify-end cursor-pointer">
                <RxCross1 size={25} onClick={() => setOpen(false)} />
              </div>
              <h3 className="text-[25px] text-center py-5 font-Poppins text-[#000000cb]">
                Are you sure you wanna delete this user?
              </h3>
              <div className="w-full flex items-center justify-center">
                <div
                  className={`${styles.button} text-white text-[18px] !h-[42px] mr-4`}
                  onClick={() => setOpen(false)}
                >
                  cancel
                </div>
                <div
                  className={`${styles.button} text-white text-[18px] !h-[42px] ml-4`}
                  onClick={() =>  setOpen(false) || handleDelete(userId)}
                >
                  confirm
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <div>
      {editOpen && (
  <div className="w-full fixed top-0 left-0 z-[999] bg-[#00000039] flex items-center justify-center h-screen">
    <div className="bg-white rounded p-5" style={scrollableStyle}>
      <div onClick={() => setEditOpen(false)}>X</div>
      <form onSubmit={handleEditSubmit} style={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: '20px' }}>
        <div>
          <label htmlFor="name">Name:</label>
          <input
            type="text"
            id="name"
            className="mt-1 block w-full bg-black p-2 rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring focus:ring-green-500 focus:ring-opacity-50"
            value={currentSeller.name || ''}
            onChange={(e) => setCurrentSeller({ ...currentSeller, name: e.target.value })}
          />
        </div>
        <div>
          <label htmlFor="handle">Handle:</label>
          <input
            type="text"
            id="handle"
            className="mt-1 block w-full bg-black p-2 rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring focus:ring-green-500 focus:ring-opacity-50"
            value={currentSeller.handle || ''}
            onChange={(e) => setCurrentSeller({ ...currentSeller, handle: e.target.value })}
          />
        </div>
        <div>
          <label htmlFor="isActive" className="flex items-center">
            Is Active:
          </label>
          <Switch
            checked={currentSeller.isActive || false}
            onChange={(newValue) => setCurrentSeller({ ...currentSeller, isActive: newValue })}
            className={classNames(
              currentSeller.isActive ? 'bg-indigo-600' : 'bg-gray-200',
              'relative inline-flex items-center h-6 rounded-full w-11 focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2 transition-colors duration-200 ease-in-out'
            )}
          >
            <span className="sr-only">Activate seller</span>
            <span
              aria-hidden="true"
              className={classNames(
                currentSeller.isActive ? 'translate-x-5' : 'translate-x-0',
                'inline-block w-5 h-5 transform bg-white rounded-full shadow ring-0 transition duration-200 ease-in-out'
              )}
            />
          </Switch>
        </div>
        <div>
          <label htmlFor="email">Email:</label>
          <input
            type="email"
            id="email"
            className="mt-1 block w-full bg-black p-2 rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring focus:ring-green-500 focus:ring-opacity-50"
            value={currentSeller.email || ''}
            onChange={(e) => setCurrentSeller({ ...currentSeller, email: e.target.value })}
          />
        </div>
        <div>
          <label htmlFor="description">Description:</label>
          <textarea
            id="description"
            className="mt-1 block w-full bg-black p-2 rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring focus:ring-green-500 focus:ring-opacity-50"
            value={currentSeller.description || ''}
            onChange={(e) => setCurrentSeller({ ...currentSeller, description: e.target.value })}
          />
        </div>
        
        <div>
  <label htmlFor="avatar">Avatar:</label>
  <input
    type="file"
    id="avatar"
    onChange={(e) => setAvatarFile(e.target.files[0])} // Assuming setAvatarFile updates the avatar file state
  />
</div>
<div>
  <label htmlFor="banner">Banner:</label>
  <input
    type="file"
    id="banner"
    onChange={(e) => setBannerFile(e.target.files[0])} // Assuming setBannerFile updates the banner file state
  />
</div>
        <div>
          <label htmlFor="address">Address:</label>
          <input
            type="text"
            id="address"
            className="mt-1 block w-full bg-black p-2 rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring focus:ring-green-500 focus:ring-opacity-50"
            value={currentSeller.address || ''}
            onChange={(e) => setCurrentSeller({ ...currentSeller, address: e.target.value })}
          />
        </div>
        <div>
          <label htmlFor="phoneNumber">Phone Number:</label>
          <input
            type="text"
            id="phoneNumber"
            className="mt-1 block w-full bg-black p-2 rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring focus:ring-green-500 focus:ring-opacity-50"
            value={currentSeller.phoneNumber || ''}
            onChange={(e) => setCurrentSeller({ ...currentSeller, phoneNumber: e.target.value })}
          />
        </div>
        <div>
          <label htmlFor="zipCode">Zip Code:</label>
          <input
            type="text"
            id="zipCode"
            className="mt-1 block w-full bg-black p-2 rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring focus:ring-green-500 focus:ring-opacity-50"
            value={currentSeller.zipCode || ''}
            onChange={(e) => setCurrentSeller({ ...currentSeller, zipCode: e.target.value })}
          />
        </div>
        {/* For latitude and longitude, assuming direct text input for simplicity */}
        <div>
          <label htmlFor="latitude">Latitude:</label>
          <input
            type="text"
            id="latitude"
            className="mt-1 block w-full bg-black p-2 rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring focus:ring-green-500 focus:ring-opacity-50"
            value={currentSeller.latitude || ''}
            onChange={(e) => setCurrentSeller({ ...currentSeller, latitude: e.target.value })}
          />
        </div>
        <div>
          <label htmlFor="longitude">Longitude:</label>
          <input
            type="text"
            id="longitude"
            className="mt-1 block w-full bg-black p-2 rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring focus:ring-green-500 focus:ring-opacity-50"
            value={currentSeller.longitude || ''}
            onChange={(e) => setCurrentSeller({ ...currentSeller, longitude: e.target.value })}
          />
        </div>
        <div>
          <label htmlFor="day">Opening Day:</label>
          <select
            id="day"
            className="mt-1 block w-full bg-black p-2 rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring focus:ring-green-500 focus:ring-opacity-50"
            value={currentSeller.openingHours?.day || ''}
            onChange={(e) => setCurrentSeller({ ...currentSeller, openingHours: { ...currentSeller.openingHours, day: e.target.value }})}
          >
            <option value="">Select a Day</option>
            {['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'].map(day => (
              <option key={day} value={day}>{day}</option>
            ))}
          </select>
        </div>
        <div>
          <label htmlFor="openTime">Open Time:</label>
          <input
            type="time"
            id="openTime"
            className="mt-1 block w-full bg-black p-2 rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring focus:ring-green-500 focus:ring-opacity-50"
            value={currentSeller.openingHours?.openTime || ''}
            onChange={(e) => setCurrentSeller({ ...currentSeller, openingHours: { ...currentSeller.openingHours, openTime: e.target.value }})}
          />
        </div>
        <div>
          <label htmlFor="closeTime">Close Time:</label>
          <input
            type="time"
            id="closeTime"
            className="mt-1 block w-full bg-black p-2 rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring focus:ring-green-500 focus:ring-opacity-50"
            value={currentSeller.openingHours?.closeTime || ''}
            onChange={(e) => setCurrentSeller({ ...currentSeller, openingHours: { ...currentSeller.openingHours, closeTime: e.target.value }})}
          />
        </div>
        <div>
          <label htmlFor="averageRating">Average Rating:</label>
          <input
            type="number"
            id="averageRating"
            className="mt-1 block w-full bg-black p-2 rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring focus:ring-green-500 focus:ring-opacity-50"
            value={currentSeller.averageRating || 0}
            readOnly
          />
        </div>
        <button
  type="submit"
  className="inline-flex items-center justify-center px-4 py-2 border border-transparent text-base font-medium rounded-md text-white bg-green-600 shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
>
  Save Changes
</button>

      </form>
    </div>
  </div>
)}

      </div>
    </div>
  );
};

export default AllSellers;
