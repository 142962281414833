import axios from "axios";
import { server } from "../../server";

export const FETCH_FRIENDS_REQUEST = "FetchFriendsRequest";
export const FETCH_FRIENDS_SUCCESS = "FetchFriendsSuccess";
export const FETCH_FRIENDS_FAIL = "FetchFriendsFail";
export const FETCH_IS_FRIEND_REQUEST = "FetchIsFriendRequest";
export const FETCH_IS_FRIEND_SUCCESS = "FetchIsFriendSuccess";
export const FETCH_IS_FRIEND_FAIL = "FetchIsFriendFail";

export const fetchFriends = (userId, profileType) => async (dispatch) => {
    dispatch({ type: FETCH_FRIENDS_REQUEST });
    try {
        const response = await axios.get(`${server}/friends/get-friends/${userId}/${profileType}`, {
            withCredentials: true,
        });
        dispatch({
            type: FETCH_FRIENDS_SUCCESS,
            payload: response.data,
        });
    } catch (error) {
        dispatch({
            type: FETCH_FRIENDS_FAIL,
            payload: error.response ? error.response.data.message : "There was a problem fetching friends. Please try again.",
        });
    }
};


export const fetchIsFriend = (currentProfileId, friendId, profileType, friendProfileType) => async (dispatch) => {
    dispatch({ type: FETCH_IS_FRIEND_REQUEST });
    try {
        const response = await axios.get(`${server}/friends/get-is-friend/${currentProfileId}/${friendId}/${profileType}/${friendProfileType}`, {
            withCredentials: true,
        });
        dispatch({
            type: FETCH_IS_FRIEND_SUCCESS,
            payload: response.data.isFriend,
        });
    } catch (error) {
        dispatch({
            type: FETCH_IS_FRIEND_FAIL,
            payload: error.response ? error.response.data.message : "There was a problem checking friendship status. Please try again.",
        });
    }
};



  

  
