import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from "react-router-dom";

const ShopAbout = ({ shopData, isOwner }) => {
  const { products } = useSelector((state) => state.products);
  const totalReviewsLength = products && products.reduce((acc, product) => acc + product.reviews.length, 0);
  const totalRatings = products && products.reduce((acc, product) => acc + product.reviews.reduce((sum, review) => sum + review.rating, 0), 0);
  const averageRating = parseFloat((totalRatings / totalReviewsLength).toFixed(1)) || 0;

  return (
    <div className="shop-about-container">
      <h2 className="shop-about-title">About {shopData.name}</h2>
      <p className="shop-about-description">{shopData.description}</p>
      <div className="shop-about-details">
        <div className="shop-about-detail">
          <h5 className="shop-about-label">Address</h5>
          <p className="shop-about-value">{shopData.address}</p>
        </div>
        <div className="shop-about-detail">
          <h5 className="shop-about-label">Phone Number</h5>
          <p className="shop-about-value">{shopData.phoneNumber}</p>
        </div>
        <div className="shop-about-detail">
          <h5 className="shop-about-label">Total Products</h5>
          <p className="shop-about-value">{products && products.length}</p>
        </div>
        <div className="shop-about-detail">
          <h5 className="shop-about-label">Shop Ratings</h5>
          <p className="shop-about-value">{averageRating}/5</p>
        </div>
        <div className="shop-about-detail">
          <h5 className="shop-about-label">Joined On</h5>
          <p className="shop-about-value">{shopData.createdAt?.slice(0, 10)}</p>
        </div>
      </div>
      {isOwner && (
        <div className="shop-about-actions">
          <Link to="/settings" className="shop-about-action-button">
            Edit Shop
          </Link>
        </div>
      )}
    </div>
  );
};

export default ShopAbout;
