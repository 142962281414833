import React, { useState, useEffect } from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import { XMarkIcon } from '@heroicons/react/20/solid';
import AdFormatIconRow from './AdFormatIconRow';
import DraggableImage from './DraggableImage';
import { FaUpload } from 'react-icons/fa';

const AdSetModal = ({ isOpen, onClose, onSave, adFormats, productImage, productName }) => {
    const [adImages, setAdImages] = useState({});
    const [adPositions, setAdPositions] = useState({});
    const [editMode, setEditMode] = useState({});
    const [selectedFormat, setSelectedFormat] = useState(adFormats[0]);
    const [adImageNames, setAdImageNames] = useState({});
    const [isUploaded, setIsUploaded] = useState({});

    useEffect(() => {
        if (isOpen) {
            const initialAdImages = {};
            const initialAdPositions = {};
            const initialAdImageNames = {};
            const initialIsUploaded = {};
            adFormats.forEach((format) => {
                initialAdImages[format] = productImage;
                initialAdPositions[format] = { x: 0, y: 0 };
                initialAdImageNames[format] = '';
                initialIsUploaded[format] = false;
            });
            setAdImages(initialAdImages);
            setAdPositions(initialAdPositions);
            setAdImageNames(initialAdImageNames);
            setIsUploaded(initialIsUploaded);
            setEditMode(adFormats.reduce((acc, format) => {
                acc[format] = false;
                return acc;
            }, {}));
            setSelectedFormat(adFormats[0]); 
        }
    }, [isOpen, adFormats, productImage]);

    const handlePositionChange = (format, position) => {
        setAdPositions((prev) => ({ ...prev, [format]: position }));
    };

    const toggleEditMode = (format) => {
        setEditMode((prev) => ({ ...prev, [format]: !prev[format] }));
    };

    const handleImageChange = (format, event) => {
        const file = event.target.files[0];
        if (file) {
            setIsUploaded(true);
            const reader = new FileReader();
            reader.onloadend = () => {
                setAdImages((prev) => ({
                    ...prev,
                    [format]: reader.result,
                }));
                setAdImageNames((prev) => ({
                    ...prev,
                    [format]: file.name, 
                }));
                setIsUploaded((prev) => ({
                    ...prev,
                    [format]: true, 
                }));
                
            };
            reader.readAsDataURL(file);
        }
    };

    const handleSave = () => {
        onSave({ images: adImages, positions: adPositions });
        onClose();
    };

    if (!isOpen) return null;

    const formatDetails = {
        'Top Banner Ad': {
            label: 'Top Banner',
            icon: 'https://res.cloudinary.com/dzlopmfj8/image/upload/v1724324560/top_banner_ad_gmvlxo.svg',
        },
        'Banner Ad': {
            label: 'Bottom Banner',
            icon: 'https://res.cloudinary.com/dzlopmfj8/image/upload/v1724324989/bottom_banner_ad_yuxoze.svg',
        },
        'Sponsored Products': {
            label: 'Sidebar Ad',
            icon: 'https://res.cloudinary.com/dzlopmfj8/image/upload/v1724325662/sidebar-ad_jdcpmo.png',
        },
        'Dual Product Carousel': {
            label: 'Dual Carousel',
            icon: 'https://res.cloudinary.com/dzlopmfj8/image/upload/v1724327059/dual_carousel_ad_kviftt.svg',
        },
        'Quad Product Carousel': {
            label: 'Quad Carousel',
            icon: 'https://res.cloudinary.com/dzlopmfj8/image/upload/v1724327188/quad_carousel_ad_uig288.svg',
        },
        'Sponsored Product Carousel': {
            label: 'Single Carousel',
            icon: 'https://res.cloudinary.com/dzlopmfj8/image/upload/v1724327318/single_carousel_ad_mnubsr.svg',
        },
        'Map Product Ad Slider': {
            label: 'Map Slider',
            icon: 'https://res.cloudinary.com/dzlopmfj8/image/upload/v1724328456/map_product_ad_slider_rvqlsi.png',
        },
        'Sponsored Feed Products': {
        label: 'Feed Products',
        icon: 'https://res.cloudinary.com/dzlopmfj8/image/upload/v1724329200/sponsored_feed_products_ad_vc9lrw.svg', // Replace with actual icon URL
    },
    };

    return (
        <div className="ad-set-modal-overlay">
            <div className="ad-set-modal">
                <button onClick={onClose} className="close-modal-button">
                    <AiOutlineClose />
                </button>
                <h2>Customize Ad Set</h2>

                {/* Ad Format Icon Row */}
                <AdFormatIconRow
                    formats={adFormats.map((format) => ({
                        name: format,
                        label: formatDetails[format]?.label || format,
                        icon: formatDetails[format]?.icon || 'default_icon_url',
                    }))}
                    selectedFormat={selectedFormat}
                    onFormatSelect={setSelectedFormat}
                />

                {/* Content based on selected format */}
                <div className="ad-format-section">
                    <h3>{selectedFormat}</h3>
                    <div className="ad-format-controls">
                        <button onClick={() => toggleEditMode(selectedFormat)} className="ad-set-modal-edit-button">
                            {editMode[selectedFormat] ? 'Done' : 'Edit'}
                        </button>
                    </div>
                    <div className="ad-format-preview">
                        <div className="ad-format-content">
                        <div className="ad-preview-upload-icon-wrapper" onClick={() => document.getElementById('fileInput').click()}>
                        <FaUpload
                                    className={`ad-preview-upload-icon ${
                                        isUploaded[selectedFormat] ? 'uploaded' : ''
                                    }`}
                                />
                            <p className="ad-preview-upload-text">Uploaded Image: {adImageNames[selectedFormat]}</p>
                            <input
                                id="fileInput"
                                type="file"
                                accept="image/*"
                                onChange={(event) => handleImageChange(selectedFormat, event)}
                                className="ad-preview-upload-input"
                            />
                        </div>

                            {/* Display content based on the selected format */}
                            {selectedFormat === 'Top Banner Ad' && (
                                <div className="ad-preview-top-banner-container">
                                    <div className="ad-preview-top-banner-content">
                                        <div className="ad-preview-top-banner-image-container">
                                            {editMode[selectedFormat] ? (
                                                <DraggableImage
                                                    src={adImages[selectedFormat]}
                                                    initialPosition={adPositions[selectedFormat]}
                                                    onPositionChange={(position) => handlePositionChange(selectedFormat, position)}
                                                />
                                            ) : (
                                                <img
                                                    src={adImages[selectedFormat]}
                                                    alt="Product"
                                                    className="ad-preview-top-banner-image"
                                                    style={{
                                                        left: `${adPositions[selectedFormat]?.x || 0}px`,
                                                        top: `${adPositions[selectedFormat]?.y || 0}px`,
                                                    }}
                                                />
                                            )}
                                        </div>
                                        <div className="ad-preview-top-banner-text">
                                            <strong className="ad-preview-top-banner-ad-link">{productName}</strong>
                                            <span className="ad-preview-top-banner-ad-link"> – Explore Now &rarr;</span>
                                        </div>
                                        <button className="ad-preview-top-banner-ad-dismiss">
                                            <XMarkIcon className="ad-preview-top-banner-ad-icon" aria-hidden="true" />
                                            <span className="sr-only">Dismiss</span>
                                        </button>
                                    </div>
                                </div>
                            )}

                            {selectedFormat === 'Banner Ad' && (
                                <div className="ad-preview-banner-ad-container">
                                    <div className="ad-preview-banner-ad-image-container">
                                        {editMode[selectedFormat] ? (
                                            <DraggableImage
                                                src={adImages[selectedFormat]}
                                                initialPosition={adPositions[selectedFormat]}
                                                onPositionChange={(position) => handlePositionChange(selectedFormat, position)}
                                            />
                                        ) : (
                                            <img
                                                src={adImages[selectedFormat]}
                                                alt="Product"
                                                className="ad-preview-banner-ad-image"
                                                style={{
                                                    left: `${adPositions[selectedFormat]?.x || 0}px`,
                                                    top: `${adPositions[selectedFormat]?.y || 0}px`,
                                                }}
                                            />
                                        )}
                                    </div>
                                    <div className="ad-preview-banner-ad-content">
                                        <strong className="ad-preview-banner-ad-link">{productName}</strong>
                                        <span className="ad-preview-banner-ad-link"> – Explore Now &rarr;</span>
                                        <button className="ad-preview-banner-ad-dismiss">
                                            <XMarkIcon className="ad-preview-banner-ad-icon" aria-hidden="true" />
                                            <span className="sr-only">Dismiss</span>
                                        </button>
                                    </div>
                                </div>
                            )}

                            {selectedFormat === 'Sponsored Products' && (
                                <div className="ad-preview-sponsored-products-container">
                                    <div className="ad-preview-sponsored-products-list">
                                        <div className="ad-preview-sponsored-products-item">
                                            <div className="ad-preview-sponsored-products-image-container">
                                                {editMode[selectedFormat] ? (
                                                    <DraggableImage
                                                        src={adImages[selectedFormat]}
                                                        initialPosition={adPositions[selectedFormat]}
                                                        onPositionChange={(position) => handlePositionChange(selectedFormat, position)}
                                                    />
                                                ) : (
                                                    <img
                                                        src={adImages[selectedFormat]}
                                                        alt="Product"
                                                        className="ad-preview-sponsored-products-image"
                                                        style={{
                                                            left: `${adPositions[selectedFormat]?.x || 0}px`,
                                                            top: `${adPositions[selectedFormat]?.y || 0}px`,
                                                        }}
                                                    />
                                                )}
                                            </div>
                                            <div className="ad-preview-sponsored-products-info">
                                                <div>
                                                    <h3 className="ad-preview-sponsored-products-product-name">{productName}</h3>
                                                    <p className="ad-preview-sponsored-products-product-price">$100</p>
                                                </div>
                                                <span className="ad-preview-sponsored-products-visit-store">Visit Store</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}

                            {selectedFormat === 'Dual Product Carousel' && (
                                <div className="ad-preview-dual-product-carousel-container">
                                    <div className="ad-preview-dual-product-carousel-wrapper">
                                    <div className="ad-preview-dual-product-carousel-item empty-box"></div>
                                        <div className="ad-preview-dual-product-carousel-item">
                                            <div className="ad-preview-dual-product-carousel-image-container">
                                                {editMode[selectedFormat] ? (
                                                    <DraggableImage
                                                        src={adImages[selectedFormat]}
                                                        initialPosition={adPositions[selectedFormat]}
                                                        onPositionChange={(position) => handlePositionChange(selectedFormat, position)}
                                                    />
                                                ) : (
                                                    <img
                                                        src={adImages[selectedFormat]}
                                                        alt="Product"
                                                        className="ad-preview-dual-product-carousel-image"
                                                        style={{
                                                            left: `${adPositions[selectedFormat]?.x || 0}px`,
                                                            top: `${adPositions[selectedFormat]?.y || 0}px`,
                                                            objectFit: 'cover',
                                                        }}
                                                    />
                                                )}
                                            </div>
                                            <div className="ad-preview-dual-product-carousel-content">
                                                <h2 className="ad-preview-dual-product-carousel-title">{productName}</h2>
                                                <p className="ad-preview-dual-product-carousel-price">$100</p>
                                                <span className="ad-preview-dual-product-carousel-button">Buy now</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}

                            {selectedFormat === 'Quad Product Carousel' && (
                                <div className="ad-preview-quad-product-carousel-container">
                                    <div className="ad-preview-quad-product-carousel-wrapper">
                                    <div className="ad-preview-quad-product-carousel-item empty-box"></div>
                                            <div className="ad-preview-quad-product-carousel-item empty-box"></div>
                                            <div className="ad-preview-quad-product-carousel-item empty-box"></div>
                                        <div className="ad-preview-quad-product-carousel-item">
                                            <div className="ad-preview-quad-product-carousel-image-container">
                                                {editMode[selectedFormat] ? (
                                                    <DraggableImage
                                                        src={adImages[selectedFormat]}
                                                        initialPosition={adPositions[selectedFormat]}
                                                        onPositionChange={(position) => handlePositionChange(selectedFormat, position)}
                                                    />
                                                ) : (
                                                    <img
                                                        src={adImages[selectedFormat]}
                                                        alt="Product"
                                                        className="ad-preview-quad-product-carousel-image"
                                                        style={{
                                                            left: `${adPositions[selectedFormat]?.x || 0}px`,
                                                            top: `${adPositions[selectedFormat]?.y || 0}px`,
                                                            objectFit: 'cover',
                                                        }}
                                                    />
                                                )}
                                            </div>
                                            <div className="ad-preview-quad-product-carousel-content">
                                                <h2 className="ad-preview-quad-product-carousel-title">{productName}</h2>
                                                <p className="ad-preview-quad-product-carousel-price">$100</p>
                                                <span className="ad-preview-quad-product-carousel-button">Buy now</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}

                            {selectedFormat === 'Sponsored Product Carousel' && (
                                <div className="ad-preview-sponsored-product-carousel">
                                    <div className="ad-preview-sponsored-product-carousel-image-container">
                                        {editMode[selectedFormat] ? (
                                            <DraggableImage
                                                src={adImages[selectedFormat]}
                                                initialPosition={adPositions[selectedFormat]}
                                                onPositionChange={(position) => handlePositionChange(selectedFormat, position)}
                                            />
                                        ) : (
                                            <img
                                                src={adImages[selectedFormat]}
                                                alt="Product"
                                                className="ad-preview-sponsored-product-carousel-image"
                                                style={{
                                                    left: `${adPositions[selectedFormat]?.x || 0}px`,
                                                    top: `${adPositions[selectedFormat]?.y || 0}px`,
                                                }}
                                            />
                                        )}
                                    </div>
                                    <div className="ad-preview-sponsored-product-carousel-content">
                                        <h2 className="ad-preview-sponsored-product-carousel-title">{productName}</h2>
                                        <p className="ad-preview-sponsored-product-carousel-price">$100</p>
                                        <span className="ad-preview-sponsored-product-carousel-button">Buy now</span>
                                    </div>
                                </div>
                            )}

                            {selectedFormat === 'Map Product Ad Slider' && (
                                <div className="ad-preview-map-product-slider-container">
                                    <div className="ad-preview-map-product-slider-wrapper">
                                        <div className="ad-preview-map-product-slider-item">
                                            <div className="ad-preview-map-product-slider-image-container">
                                                {editMode[selectedFormat] ? (
                                                    <DraggableImage
                                                        src={adImages[selectedFormat]}
                                                        initialPosition={adPositions[selectedFormat]}
                                                        onPositionChange={(position) => handlePositionChange(selectedFormat, position)}
                                                    />
                                                ) : (
                                                    <img
                                                        src={adImages[selectedFormat]}
                                                        alt="Product"
                                                        className="ad-preview-map-product-slider-image"
                                                        style={{
                                                            left: `${adPositions[selectedFormat]?.x || 0}px`,
                                                            top: `${adPositions[selectedFormat]?.y || 0}px`,
                                                            objectFit: 'cover',
                                                        }}
                                                    />
                                                )}
                                            </div>
                                            <div className="ad-preview-map-product-slider-content">
                                                <h2 className="ad-preview-map-product-slider-title">{productName}</h2>
                                                <p className="ad-preview-map-product-slider-price">$100</p>
                                                <span className="ad-preview-map-product-slider-button">Buy now</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {selectedFormat === 'Sponsored Feed Products' && (
                                <div className="ad-preview-sponsored-feed-products-container">
                                    <div className="ad-preview-sponsored-feed-products-wrapper">
                                        <div className="ad-preview-sponsored-feed-products-item">
                                            <div className="ad-preview-sponsored-feed-products-image-container">
                                                {editMode[selectedFormat] ? (
                                                    <DraggableImage
                                                        src={adImages[selectedFormat]}
                                                        initialPosition={adPositions[selectedFormat]}
                                                        onPositionChange={(position) => handlePositionChange(selectedFormat, position)}
                                                    />
                                                ) : (
                                                    <img
                                                        src={adImages[selectedFormat]}
                                                        alt="Product"
                                                        className="ad-preview-sponsored-feed-products-image"
                                                        style={{
                                                            left: `${adPositions[selectedFormat]?.x || 0}px`,
                                                            top: `${adPositions[selectedFormat]?.y || 0}px`,
                                                            objectFit: 'cover',
                                                        }}
                                                    />
                                                )}
                                            </div>
                                            <div className="ad-preview-sponsored-feed-products-content">
                                                <h2 className="ad-preview-sponsored-feed-products-title">{productName}</h2>
                                                <p className="ad-preview-sponsored-feed-products-price">$100</p>
                                                <span className="ad-preview-sponsored-feed-products-store-link">Visit Store</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {selectedFormat !== 'Top Banner Ad' &&
                                selectedFormat !== 'Banner Ad' &&
                                selectedFormat !== 'Sponsored Products' &&
                                selectedFormat !== 'Dual Product Carousel' &&
                                selectedFormat !== 'Quad Product Carousel' &&
                                selectedFormat !== 'Sponsored Product Carousel' &&
                                selectedFormat !== 'Map Product Ad Slider' && 
                                selectedFormat !== 'Sponsored Feed Products' && (
                                    <>
                                        <img src={adImages[selectedFormat]} alt={`${selectedFormat} preview`} />
                                        <p>Position: {adPositions[selectedFormat]?.x}, {adPositions[selectedFormat]?.y}</p>
                                    </>
                                )}
                            {editMode[selectedFormat] && (
                                <DraggableImage
                                    src={adImages[selectedFormat]}
                                    initialPosition={adPositions[selectedFormat]}
                                    onPositionChange={(position) => handlePositionChange(selectedFormat, position)}
                                />
                            )}
                        </div>
                    </div>
                </div>
                <button onClick={handleSave} className="save-ad-set-button">
                    Save
                </button>
            </div>
        </div>
    );
};

export default AdSetModal;





