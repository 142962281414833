import React from 'react';

const CreateAdSteps = ({ activeStep }) => {
  return (
    <div className="create-ad-steps-wrapper">
      <div className="create-ad-steps-content">
        <div className={`create-ad-step ${activeStep > 1 ? 'past' : activeStep === 1 ? 'active' : ''}`}>
          <div className="create-ad-flex">
            <div className="create-ad-step-button">
              <span className={`create-ad-step-text ${activeStep > 1 ? 'past-text' : activeStep === 1 ? '' : 'inactive-text'}`}>1. Choose Type</span>
            </div>
            <div className={`create-ad-line ${activeStep > 1 ? 'past' : ''}`} />
          </div>
        </div>
        <div className={`create-ad-step ${activeStep > 2 ? 'past' : activeStep === 2 ? 'active' : ''}`}>
          <div className="create-ad-flex">
            <div className="create-ad-step-button">
              <span className={`create-ad-step-text ${activeStep > 2 ? 'past-text' : activeStep === 2 ? '' : 'inactive-text'}`}>2. Set Up</span>
            </div>
            <div className={`create-ad-line ${activeStep > 2 ? 'past' : ''}`} />
          </div>
        </div>
        <div className={`create-ad-step ${activeStep > 3 ? 'past' : activeStep === 3 ? 'active' : ''}`}>
          <div className="create-ad-flex">
            <div className="create-ad-step-button">
              <span className={`create-ad-step-text ${activeStep > 3 ? 'past-text' : activeStep === 3 ? '' : 'inactive-text'}`}>3. Review</span>
            </div>
            <div className={`create-ad-line ${activeStep > 3 ? 'past' : ''}`} />
          </div>
        </div>
        <div className={`create-ad-step ${activeStep > 4 ? 'past' : activeStep === 4 ? 'active' : ''}`}>
          <div className="create-ad-flex">
            <div className="create-ad-step-button">
              <span className={`create-ad-step-text ${activeStep > 4 ? 'past-text' : activeStep === 4 ? '' : 'inactive-text'}`}>4. Checkout</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateAdSteps;





