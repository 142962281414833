import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { server } from '../../server';

const ManageSubscriptionCoupons = () => {
  const [coupons, setCoupons] = useState([]);
  const [loading, setLoading] = useState(true);
  const [editingCoupon, setEditingCoupon] = useState(null); // For editing

  useEffect(() => {
    // Fetch all subscription coupon codes
    const fetchCoupons = async () => {
      try {
        const { data } = await axios.get(`${server}/subscriptioncouponcode/all-subscription-coupons`, {
          withCredentials: true,
        });
        if (data.success) {
          setCoupons(data.coupons);
        } else {
          toast.error('Failed to load coupon codes');
        }
      } catch (error) {
        toast.error(error.response?.data?.message || 'Error fetching coupons');
      } finally {
        setLoading(false);
      }
    };

    fetchCoupons();
  }, []);

  const handleDeleteCoupon = async (id) => {
    try {
      const { data } = await axios.delete(`${server}/subscriptioncouponcode/delete-subscription-coupon/${id}`, {
        withCredentials: true,
      });
      toast.success(data.message);
      setCoupons(coupons.filter((coupon) => coupon._id !== id));
    } catch (error) {
      toast.error(error.response?.data?.message || 'Error deleting coupon');
    }
  };

  const handleEditCoupon = async (coupon) => {
    setEditingCoupon(coupon); // Load coupon data into the form for editing
  };

  const handleSaveEdit = async () => {
    try {
      const { data } = await axios.put(
        `${server}/subscriptioncouponcode/edit-subscription-coupon/${editingCoupon._id}`,
        editingCoupon,
        {
          withCredentials: true,
        }
      );
      toast.success(data.message);
      setCoupons((prevCoupons) =>
        prevCoupons.map((coupon) => (coupon._id === editingCoupon._id ? editingCoupon : coupon))
      );
      setEditingCoupon(null); // Clear the form after editing
    } catch (error) {
      toast.error(error.response?.data?.message || 'Error updating coupon');
    }
  };

  return (
    <div className="subscription-couponcode-list-container">
      <h2>Manage Subscription Coupons</h2>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <div>
          {editingCoupon ? (
            <div className="subscription-couponcode-edit-container">
              <h3>Edit Coupon</h3>
              <form>
                <label>Name</label>
                <input
                  type="text"
                  value={editingCoupon.name}
                  onChange={(e) => setEditingCoupon({ ...editingCoupon, name: e.target.value })}
                />
                <label>Discount Amount</label>
                <input
                  type="number"
                  value={editingCoupon.discountAmount}
                  onChange={(e) =>
                    setEditingCoupon({ ...editingCoupon, discountAmount: e.target.value })
                  }
                />
                <label>Discount Type</label>
                <select
                  value={editingCoupon.discountType}
                  onChange={(e) =>
                    setEditingCoupon({ ...editingCoupon, discountType: e.target.value })
                  }
                >
                  <option value="percentage">Percentage</option>
                  <option value="fixed">Fixed</option>
                </select>
                <label>Valid For</label>
                <select
                  value={editingCoupon.validFor}
                  onChange={(e) => setEditingCoupon({ ...editingCoupon, validFor: e.target.value })}
                >
                  <option value="monthly">Monthly</option>
                  <option value="annually">Annually</option>
                  <option value="both">Both</option>
                </select>
                <button type="button" onClick={handleSaveEdit}>
                  Save Changes
                </button>
                <button type="button" onClick={() => setEditingCoupon(null)}>
                  Cancel
                </button>
              </form>
            </div>
          ) : (
            <table className="subscription-couponcode-table">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Discount Amount</th>
                  <th>Valid For</th>
                  <th>Max Redemptions</th>
                  <th>Status</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {coupons.map((coupon) => (
                  <tr key={coupon._id}>
                    <td>{coupon.name}</td>
                    <td>{coupon.discountAmount}</td>
                    <td>{coupon.validFor}</td>
                    <td>{coupon.maxRedemptions}</td>
                    <td>{coupon.status}</td>
                    <td>
                      <button onClick={() => handleEditCoupon(coupon)}>Edit</button>
                      <button onClick={() => handleDeleteCoupon(coupon._id)}>Delete</button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      )}
    </div>
  );
};

export default ManageSubscriptionCoupons;

