import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { useSelector, useDispatch } from "react-redux";
import { fetchOwnedBrands } from "../../redux/actions/brand";
import Loader from "../Layout/Loader";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AiOutlineClose } from 'react-icons/ai';
import { server } from "../../server";
import AdSetModalForBrands from './AdSetModalForBrands';

const SponsorBrand = ({ onSave, adType, details }) => {
    const [selectedBrandId, setSelectedBrandId] = useState(details?.brandId || '');
    const [adSpendPerDay, setAdSpendPerDay] = useState(details?.adSpend || 50);
    const [adDuration, setAdDuration] = useState(details?.adDuration || 7);
    const [totalAdSpend, setTotalAdSpend] = useState(adSpendPerDay * adDuration);
    const [predictedClicks, setPredictedClicks] = useState({ min: 0, max: 0 });
    const [predictedImpressions, setPredictedImpressions] = useState({ min: 0, max: 0 });
    const [displayedPredictedClicks, setDisplayedPredictedClicks] = useState({ min: 0, max: 0 });
    const [displayedPredictedImpressions, setDisplayedPredictedImpressions] = useState({ min: 0, max: 0 });
    const { ownedBrands } = useSelector(state => state.brand);
    const [isLoading, setIsLoading] = useState(true);
    const dispatch = useDispatch();
    const debounceRef = useRef(null);
    const [isAdSetModalOpen, setIsAdSetModalOpen] = useState(false);
    const [adSet, setAdSet] = useState(details?.adSet || {}); 
    const [isAdSetSaved, setIsAdSetSaved] = useState(!!details?.adSet); 

    useEffect(() => {
        dispatch(fetchOwnedBrands());
    }, [dispatch]);

    useEffect(() => {
        setIsLoading(false);
        setTotalAdSpend(adSpendPerDay * adDuration);
        fetchPredictions(adSpendPerDay * adDuration);
    }, [adSpendPerDay, adDuration]);

    const handleDeselectBrand = (event) => {
        event.stopPropagation();
        setSelectedBrandId('');
    };

    const fetchPredictions = async (spend) => {
        if (debounceRef.current) clearTimeout(debounceRef.current);
        debounceRef.current = setTimeout(async () => {
            try {
                const { data } = await axios.post(`${server}/sponsored/predict`, { adSpend: spend }, { withCredentials: true });
                if (data) {
                    setPredictedClicks(data.predictedClicks);
                    setPredictedImpressions(data.predictedImpressions);
                    updateDisplayValues('total');
                }
            } catch (error) {
                console.error('Error fetching predictions:', error);
                toast.error('Failed to fetch predictions');
            }
        }, 500);
    };

    const updateDisplayValues = (mode) => {
        const factor = mode === 'daily' ? 1 / adDuration : 1;
        setDisplayedPredictedClicks({
            min: Math.round(predictedClicks.min * factor),
            max: Math.round(predictedClicks.max * factor)
        });
        setDisplayedPredictedImpressions({
            min: Math.round(predictedImpressions.min * factor),
            max: Math.round(predictedImpressions.max * factor)
        });
    };

    const openAdSetModal = () => {
        setIsAdSetModalOpen(true);
    };

    const handleAdSetSave = (newAdSet) => {
        setAdSet(newAdSet);
        setIsAdSetSaved(true);
        setIsAdSetModalOpen(false);
    };

    const handleSaveAd = () => {
        if (!selectedBrandId) {
            toast.error("Please select a brand before saving.");
            return;
        }

        const selectedBrand = ownedBrands.find(brand => brand._id === selectedBrandId);

        const details = {
            brandId: selectedBrandId,
            brandName: selectedBrand?.name,
            brandImage: selectedBrand?.avatarImage?.url,
            adSpend: totalAdSpend,
            adDuration,
            adSet,
        };

        onSave(adSet, details, adType);
        toast.success("Brand ad configuration saved.");
    };

    // Determine if the Save button should be enabled
    const isSaveEnabled = isAdSetSaved && selectedBrandId;

    return (
        <>
            {isLoading ? <Loader /> : (
                <div className="sponsor-brand-component-container">
                    <ToastContainer />
                    <form>
                        <h2 className="sponsor-brand-component-title">Sponsor Your Brand</h2>

                        <div className="sponsor-brand-component-product-selection">
                            <label>Select a Brand:</label>
                            <div className="sponsor-brand-component-product-list">
                                {ownedBrands.map(brand => (
                                    <div key={brand._id} 
                                         onClick={() => setSelectedBrandId(brand._id)} 
                                         className={`sponsor-brand-component-product-item ${selectedBrandId === brand._id ? 'selected' : ''}`}>
                                        <img src={brand.avatarImage.url} alt={brand.name} />
                                        <span>{brand.name}</span>
                                        {selectedBrandId === brand._id && (
                                            <AiOutlineClose 
                                                onClick={handleDeselectBrand}
                                                className="sponsor-brand-component-close-icon"
                                            />
                                        )}
                                    </div>
                                ))}
                            </div>
                        </div>

                        {selectedBrandId && (
                            <>
                                <div className="customize-ad-set-button-container">
                                    <button type="button" onClick={openAdSetModal} className="customize-ad-set-button">
                                        Customize Ad Set
                                    </button>
                                </div>
                            </>
                        )}

                        <div className="sponsor-brand-component-slider">
                            <label>Set Ad Spend per Day ($):</label>
                            <input 
                                type="text"
                                value={adSpendPerDay}
                                onChange={(e) => {
                                    const newSpend = Number(e.target.value);
                                    setAdSpendPerDay(newSpend);
                                    fetchPredictions(newSpend * adDuration); 
                                }}
                                className="sponsor-brand-component-input"
                            />
                            <input 
                                type="range" 
                                min="1" 
                                max="1000"
                                value={adSpendPerDay}
                                onChange={(e) => {
                                    const newSpend = Number(e.target.value);
                                    setAdSpendPerDay(newSpend);
                                    fetchPredictions(newSpend * adDuration); 
                                }}
                                className="sponsor-brand-component-range-slider"
                            />
                        </div>

                        <div className="sponsor-brand-component-slider">
                            <label>Set Ad Duration (Days):</label>
                            <input 
                                type="text"
                                value={adDuration}
                                onChange={(e) => {
                                    const newDuration = Number(e.target.value);
                                    setAdDuration(newDuration);
                                    fetchPredictions(adSpendPerDay * newDuration); 
                                }}
                                className="sponsor-brand-component-input"
                            />
                            <input 
                                type="range" 
                                min="1" 
                                max="30"
                                value={adDuration}
                                onChange={(e) => {
                                    const newDuration = Number(e.target.value);
                                    setAdDuration(newDuration);
                                    fetchPredictions(adSpendPerDay * newDuration); 
                                }}
                                className="sponsor-brand-component-range-slider"
                            />
                        </div>

                        <div className="sponsor-brand-component-total-spend">
                            <label>Total Ad Spend ($): {totalAdSpend}</label>
                        </div>

                        <div className="sponsor-brand-component-display-mode">
                            <button type="button" onClick={() => updateDisplayValues('daily')}>Daily</button>
                            <button type="button" onClick={() => updateDisplayValues('total')}>Total</button>
                        </div>

                        <div className="sponsor-brand-component-predictions">
                            {displayedPredictedClicks.min !== 0 || displayedPredictedClicks.max !== 0 ? (
                                <>
                                    <div className="sponsor-brand-component-prediction-item">
                                        <label>Predicted Clicks Range:</label>
                                        <span>{displayedPredictedClicks.min} to {displayedPredictedClicks.max}</span>
                                    </div>
                                    <div className="sponsor-brand-component-prediction-item">
                                        <label>Predicted Impressions Range:</label>
                                        <span>{displayedPredictedImpressions.min} to {displayedPredictedImpressions.max}</span>
                                    </div>
                                </>
                            ) : (
                                <div className="sponsor-brand-component-null-message">
                                    Please choose your ad spend/time duration
                                </div>
                            )}
                        </div>

                        <button 
                            type="button" 
                            className={`sponsor-brand-component-submit-button ${isSaveEnabled ? 'enabled' : 'disabled'}`} 
                            onClick={isSaveEnabled ? handleSaveAd : null} 
                            disabled={!isSaveEnabled}
                        >
                            Save Ad Configuration
                        </button>
                    </form>

                    <AdSetModalForBrands
                        isOpen={isAdSetModalOpen}
                        onClose={() => setIsAdSetModalOpen(false)}
                        onSave={handleAdSetSave}
                        adFormats={['Sponsored Brands', 'Sponsored Brands Carousel']}
                        brandImage={ownedBrands.find(brand => brand._id === selectedBrandId)?.avatarImage.url}
                        brandName={ownedBrands.find(brand => brand._id === selectedBrandId)?.name}
                    />
                </div>
            )}
        </>
    );
};

export default SponsorBrand;


