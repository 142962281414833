import React from 'react'
import Header from "../components/Layout/Header"
import Sidebar from '../components/Layout/Sidebar';
import SubscriptionPricing from '../components/Subscription/SubscriptionPricing';

const SubscriptionPage = () => {
  
  return (
    <div>
        <Header />
        <Sidebar />
        <SubscriptionPricing />
    </div>
  )
}

export default SubscriptionPage;