import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { loadConnectAndInitialize } from '@stripe/connect-js';
import { ConnectPayments, ConnectBalances, ConnectPaymentDetails, ConnectDocuments,  ConnectNotificationBanner, ConnectComponentsProvider } from "@stripe/react-connect-js";
import { server } from "../../server";
import DashboardSideBar from '../../components/Shop/Layout/DashboardSideBar';
import DashboardHeader from '../Shop/Layout/DashboardHeader';
import { useDispatch } from "react-redux";
import { loadSeller } from "../../redux/actions/user";

export default function StripePaymentDashboard() {
    const [stripeConnectInstance, setStripeConnectInstance] = useState(null);
    const [error, setError] = useState('');
    const currentProfile = sessionStorage.getItem('currentProfile');
    const [visible, setVisible] = React.useState(false);
    const onOpen = () => {
        setVisible(true);
    };
    const onClose = () => {
        setVisible(false);
    };
    const dispatch = useDispatch();

    useEffect(() => {
      dispatch(loadSeller());
    }, [dispatch]);

    useEffect(() => {
        const fetchClientSecret = async () => {
            try {
                // Fetch the AccountSession client secret using Axios
                const { data } = await axios.post(`${server}/shop/account-session`, {}, { withCredentials: true, headers: {
                    'Current-Profile': currentProfile, 
                  } });

                if (data.client_secret) {
                    const connectInstance = await loadConnectAndInitialize({
                        publishableKey: process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY,
                        clientSecret: data.client_secret,
                    });
                    setStripeConnectInstance(connectInstance);
                    setError('');
                } else {
                    throw new Error('Client secret not available');
                }
            } catch (error) {
                console.error('An error occurred: ', error);
                setError(error.message || 'An error occurred');
            }
        };

        fetchClientSecret();
    }, []);

    return (
        <div>
        <DashboardHeader />
        <div className="flex justify-between w-full">
        <div className="w-[80px] md:w-[330px]">
          <DashboardSideBar active={17} />
        </div>
        <div className="container">
            {error && (
                <div id="error" style={{ color: 'red' }}>
                    {error}
                </div>
            )}
            {stripeConnectInstance ? (
                <ConnectComponentsProvider connectInstance={stripeConnectInstance}>
                    <ConnectNotificationBanner />
                    <ConnectBalances />
                    <ConnectPayments />
                    {visible && <ConnectPaymentDetails
          payment="{{PAYMENT_OR_PAYMENT_INTENT_ID}}"
          onClose={onClose}
        />}
                    <ConnectDocuments />
                </ConnectComponentsProvider>
            ) : (
                <p>Loading...</p>
            )}
        </div>
        </div>
        </div>
    );
}
