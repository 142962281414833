import React, { useEffect } from 'react';
import { AiOutlineArrowRight, AiOutlineMoneyCollect } from 'react-icons/ai';
import { MdBorderClear } from 'react-icons/md';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getAllOrdersOfShop } from '../../redux/actions/order';
import { getAllProductsShop } from '../../redux/actions/product';
import Loader from '../Layout/Loader';

const DashboardHero = () => {
  const dispatch = useDispatch();
  const { orders, isLoading } = useSelector((state) => state.order);
  const { seller } = useSelector((state) => state.seller);
  const { products } = useSelector((state) => state.products);

  useEffect(() => {
    if (seller && seller._id) {
      dispatch(getAllOrdersOfShop(seller._id));
      dispatch(getAllProductsShop(seller._id));
    }
  }, [dispatch, seller?._id]);

  const availableBalance = seller?.availableBalance?.toFixed(2);

  const columns = [
    { field: 'id', headerName: 'Order ID', minWidth: 150, flex: 0.7 },
    {
      field: 'status',
      headerName: 'Status',
      minWidth: 130,
      flex: 0.7,
      cellClassName: (params) =>
        params.row.status === 'Delivered' ? 'greenColor' : 'redColor',
    },
    {
      field: 'itemsQty',
      headerName: 'Items Qty',
      type: 'number',
      minWidth: 130,
      flex: 0.7,
    },
    {
      field: 'total',
      headerName: 'Total',
      type: 'number',
      minWidth: 130,
      flex: 0.8,
    },
    {
      field: 'paymentStatus',
      headerName: 'Payment Status',
      minWidth: 130,
      flex: 0.8,
      cellClassName: (params) =>
        params.row.paymentStatus === 'Pending'
          ? 'yellowColor'
          : 'greenColor',
    },
    {
      field: 'date',
      headerName: 'Date',
      minWidth: 160,
      flex: 0.8,
      valueFormatter: ({ value }) =>
        new Date(value).toLocaleDateString('en-US'),
    },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 1,
      minWidth: 150,
      sortable: false,
      renderCell: (params) => (
        <Link to={`/order/${params.id}`}>
          <button className="dashboard-hero-button">
            <AiOutlineArrowRight size={20} />
          </button>
        </Link>
      ),
    },
  ];

  const rows = orders
    ? orders.map((item) => {
        const total = item.cart.reduce(
          (acc, curr) =>
            acc + (curr.unitAmount / 100) * curr.quantity,
          0
        );
        return {
          id: item._id,
          itemsQty: item.cart.length,
          total: `US$ ${total.toFixed(2)}`,
          status: item.status,
          paymentStatus: item.paymentInfo.status,
          date: item.createdAt,
        };
      })
    : [];

  const getRowClassName = (params) => {
    if (params.row.paymentStatus === 'Pending') {
      return 'shop-dashboard-hero-all-orders-pending-row';
    }
    return '';
  };

  return (
    <div className="shop-dashboard-hero-header-container">
      <h3 className="shop-dashboard-hero-header-title">Overview</h3>
      <div className="shop-dashboard-hero-header-overview">
        <div className="shop-dashboard-hero-header-card">
          <div className="shop-dashboard-hero-header-card-content">
            <AiOutlineMoneyCollect
              size={30}
              className="mr-2"
              fill="#00000085"
            />
            <h3 className="shop-dashboard-hero-header-card-title">
              Account Balance{' '}
              <span className="shop-dashboard-hero-header-card-subtitle">
                (with 10% service charge)
              </span>
            </h3>
          </div>
          <h5 className="shop-dashboard-hero-header-card-value">
            ${availableBalance}
          </h5>
          <Link to="/dashboard-withdraw-money">
            <h5 className="shop-dashboard-hero-header-card-link">
              Withdraw Money
            </h5>
          </Link>
        </div>

        <div className="shop-dashboard-hero-header-card">
          <div className="shop-dashboard-hero-header-card-content">
            <MdBorderClear size={30} className="mr-2" fill="#00000085" />
            <h3 className="shop-dashboard-hero-header-card-title">All Orders</h3>
          </div>
          <h5 className="shop-dashboard-hero-header-card-value">
            {orders?.length}
          </h5>
          <Link to="/dashboard-orders">
            <h5 className="shop-dashboard-hero-header-card-link">
              View Orders
            </h5>
          </Link>
        </div>

        <div className="shop-dashboard-hero-header-card">
          <div className="shop-dashboard-hero-header-card-content">
            <AiOutlineMoneyCollect
              size={30}
              className="mr-2"
              fill="#00000085"
            />
            <h3 className="shop-dashboard-hero-header-card-title">
              All Products
            </h3>
          </div>
          <h5 className="shop-dashboard-hero-header-card-value">
            {products?.length}
          </h5>
          <Link to="/dashboard-products">
            <h5 className="shop-dashboard-hero-header-card-link">
              View Products
            </h5>
          </Link>
        </div>
      </div>
      <br />
      <h3 className="shop-dashboard-hero-header-latest-orders">
        Latest Orders
      </h3>
      <div className="shop-dashboard-hero-header-orders-container shop-dashboard-hero-all-orders-container">
        {isLoading ? (
          <Loader />
        ) : (
          <div className="shop-dashboard-hero-header-orders-table shop-dashboard-hero-all-orders-table">
            <table className="shop-dashboard-hero-header-data-grid shop-dashboard-hero-all-orders-data-grid">
              <thead>
                <tr>
                  {columns.map((column) => (
                    <th
                      key={column.field}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.headerName}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {rows.map((row) => (
                  <tr
                    key={row.id}
                    className={getRowClassName({ row })}
                  >
                    <td>{row.id}</td>
                    <td>{row.status}</td>
                    <td>{row.itemsQty}</td>
                    <td>{row.total}</td>
                    <td>{row.paymentStatus}</td>
                    <td>
                      {new Date(row.date).toLocaleDateString('en-US')}
                    </td>
                    <td>
                      <Link to={`/order/${row.id}`}>
                        <button className="dashboard-hero-button">
                          <AiOutlineArrowRight size={20} />
                        </button>
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
};

export default DashboardHero;
