import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { server } from '../../server'; 
import RegionForm from './RegionForm';

const RegionsList = () => {
    const [regions, setRegions] = useState([]);
    const [currentRegion, setCurrentRegion] = useState(null);

    useEffect(() => {
        fetchRegions();
    }, []);

    const fetchRegions = async () => {
        try {
            const { data } = await axios.get(`${server}/region/regions`);
            setRegions(data);
        } catch (error) {
            console.error('Error fetching regions:', error);
        }
    };

    const handleDelete = async (id) => {
        try {
            await axios.delete(`${server}/region/regions/${id}`);
            fetchRegions();
        } catch (error) {
            console.error('Error deleting region:', error);
        }
    };

    return (
        <div className="regions-container">
            {currentRegion ? (
                <RegionForm currentRegion={currentRegion} setCurrentRegion={setCurrentRegion} fetchRegions={fetchRegions} />
            ) : (
                <button className="regions-button regions-button-add" onClick={() => setCurrentRegion({name: '', states: [], boundaries: {}})}>Add New Region</button>
            )}
            <ul className="region-list">
                {regions.map(region => (
                    <li key={region._id} className="region-item">
                        {region.name}
                        <div className="region-actions">
                            <button className="regions-button regions-button-edit" onClick={() => setCurrentRegion(region)}>Edit</button>
                            <button className="regions-button regions-button-delete" onClick={() => handleDelete(region._id)}>Delete</button>
                        </div>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default RegionsList;
