import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Carousel from 'react-material-ui-carousel';
import { Link } from 'react-router-dom';
import { server } from "../../server";
import EventCard from './EventCard';

const EventCarousel = () => {
    const [events, setEvents] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchEvents = async () => {
            try {
                const response = await axios.get(`${server}/event/events`);
                setEvents(response.data);
                setIsLoading(false);
            } catch (error) {
                console.error('Error fetching events:', error);
                setIsLoading(false);
            }
        };

        fetchEvents();
    }, []);

    // Group events into pairs
    const groupEvents = () => {
        const grouped = [];
        for (let i = 0; i < events.length; i += 2) {
            grouped.push(events.slice(i, i + 2));
        }
        return grouped;
    };

    const groupedEvents = groupEvents();

    return (
        <div>
            {isLoading ? (
                <div>Loading...</div>
            ) : (
                <Carousel>
                    {groupedEvents.map((eventPair, index) => (
                        <div key={index} style={{ display: 'flex', justifyContent: 'center' }}>
                            {eventPair.map((event) => (
                                <Link
                                    key={event._id}
                                    to={`/events/${event._id}`}
                                    style={{ textDecoration: 'none', margin: '0 10px' }}
                                >
                                    <EventCard event={event} />
                                </Link>
                            ))}
                        </div>
                    ))}
                </Carousel>
            )}
        </div>
    );
};

export default EventCarousel;


