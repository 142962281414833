import React from 'react';

const GoogleCalendarButton = ({ event }) => {
    const formatDate = (date) => {
        try {
            const d = new Date(date);
            if (isNaN(d.getTime())) {
                throw new Error('Invalid date');
            }
            return d.toISOString().replace(/-|:|\.\d\d\d/g, '');
        } catch (error) {
            console.error('Date formatting error:', error);
            return ''; 
        }
    };
    
    const googleCalendarUrl = () => {
        const startTime = formatDate(event.date);
        const endTime = formatDate(new Date(new Date(event.date).getTime() + 2 * 60 * 60 * 1000)); // Assuming a 2 hour event
        return `https://calendar.google.com/calendar/render?action=TEMPLATE&text=${encodeURIComponent(event.title)}&dates=${startTime}/${endTime}&details=${encodeURIComponent(event.description)}`;
    };

    return (
        <a href={googleCalendarUrl()} target="_blank" rel="noopener noreferrer" className="google-calendar-button-link">
            <img 
                src="https://res.cloudinary.com/dzlopmfj8/image/upload/v1723804924/Google_Calendar_icon_aplhdp.png" 
                alt="Add to Google Calendar" 
                className="google-calendar-icon"
            />
        </a>
    );
};

export default GoogleCalendarButton;

