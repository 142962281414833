import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { server } from '../../server';
import { AiOutlineClose } from "react-icons/ai";

const AdminTagManagement = () => {
    const [tags, setTags] = useState([]);
    const [tagName, setTagName] = useState('');
    const [tagDescription, setTagDescription] = useState('');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        fetchTags();
    }, []);

    const fetchTags = async () => {
        setLoading(true);
        try {
            const response = await axios.get(`${server}/tags/tags`, { withCredentials: true });
            setTags(response.data);
            setLoading(false);
        } catch (error) {
            console.error('Failed to fetch tags:', error);
            setLoading(false);
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const response = await axios.post(`${server}/tags/tag`, { 
                name: tagName, 
                description: tagDescription 
            }, { withCredentials: true });
            setTags([...tags, response.data]);
            setTagName('');
            setTagDescription('');
        } catch (error) {
            console.error('Error adding tag:', error);
        }
    };

    const handleApprove = async (id) => {
        try {
            await axios.patch(`${server}/tags/tags/approve/${id}`, {}, { withCredentials: true });
            fetchTags();  // Refresh the list
        } catch (error) {
            console.error('Error approving tag:', error);
        }
    };

    const handleDelete = async (id) => {
        try {
            await axios.delete(`${server}/tags/delete-tag/${id}`, { withCredentials: true });
            setTags(tags.filter(tag => tag._id !== id));
        } catch (error) {
            console.error('Error deleting tag:', error);
        }
    };

    const approvedTags = tags.filter(tag => tag.approved);
    const unapprovedTags = tags.filter(tag => !tag.approved);

    return (
        <div className="admin-tag-management">
            <h2 className="admin-heading">Manage Tags</h2>
            <form onSubmit={handleSubmit} className="tag-form">
                <input
                    type="text"
                    className="tag-input tag-name-input"
                    placeholder="Tag Name"
                    value={tagName}
                    onChange={(e) => setTagName(e.target.value)}
                />
                <input
                    type="text"
                    className="tag-input tag-description-input"
                    placeholder="Tag Description"
                    value={tagDescription}
                    onChange={(e) => setTagDescription(e.target.value)}
                />
                <button type="submit" className="tagbtn-add-tag">Add Tag</button>
            </form>
            {loading ? <p className="loading-text">Loading tags...</p> : (
                <>
                    <div>
                        <h3>Approved Tags</h3>
                        <ul className="tag-list">
                            {approvedTags.map(tag => (
                                <li key={tag._id} className="tag-item">
                                    <span className="tag-name">{tag.name}</span>
                                    <button
                                        type="button"
                                        onClick={() => handleDelete(tag._id)}
                                        className="tag-delete-button"
                                    >
                                        <AiOutlineClose />
                                    </button>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div>
                        <h3>Tags Waiting for Approval</h3>
                        <ul className="tag-list">
                            {unapprovedTags.map(tag => (
                                <li key={tag._id} className="tag-item">
                                    <span className="tag-name">{tag.name}</span>
                                    <button onClick={() => handleApprove(tag._id)} className="tagbtn-approve">
                                        Approve
                                    </button>
                                    <button
                                        type="button"
                                        onClick={() => handleDelete(tag._id)}
                                        className="tag-delete-button"
                                    >
                                        <AiOutlineClose />
                                    </button>
                                </li>
                            ))}
                        </ul>
                    </div>
                </>
            )}
        </div>
    );
};

export default AdminTagManagement;
