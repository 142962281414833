import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { fetchBrandCatalogItems } from '../../redux/actions/brand';
import { XMarkIcon } from '@heroicons/react/24/outline'
import { server } from '../../server';


const BrandCatalog = () => {
  const { brandId } = useParams();
  const dispatch = useDispatch();
  const { catalogItems, error } = useSelector((state) => state.brand);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [select, setSelect] = useState(0);
  const [isEditMode, setIsEditMode] = useState(false);
  const [editFormData, setEditFormData] = useState({
    name: '',
    description: '',
    priceFloor: '',
    category: '',
    SKU: '',
    productType: '',
    unitOfMeasurement: '',
    isEdible: false,
    newImages: [],
    newVideos: [],
  });


  useEffect(() => {
    dispatch(fetchBrandCatalogItems(brandId));
  }, [dispatch, brandId]);

  useEffect(() => {
    if (isEditMode && selectedItem) {
      setEditFormData({
        name: selectedItem.name || '',
        description: selectedItem.description || '',
        priceFloor: selectedItem.priceFloor || '',
        category: selectedItem.category || '',
        SKU: selectedItem.SKU || '',
        productType: selectedItem.productType?.join(', ') || '',
        unitOfMeasurement: selectedItem.unitOfMeasurement || '',
        isEdible: selectedItem.isEdible || false,
        newImages: [],
        newVideos: [],
      });
    }
  }, [isEditMode, selectedItem]);

  const openModal = (item, mode = 'view') => {
    setSelectedItem(item);
    setIsModalOpen(true);
    setIsEditMode(mode === 'edit');
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedItem(null);
    setIsEditMode(false);
    // Reset form data upon closing the modal to ensure clean state for next edit
    setEditFormData({
      name: '',
      description: '',
      priceFloor: '',
      category: '',
      SKU: '',
      productType: '',
      unitOfMeasurement: '',
      isEdible: false,
      newImages: [],
      newVideos: [],
    });
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setEditFormData({
      ...editFormData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const handleFileChange = (e) => {
    setEditFormData({
      ...editFormData,
      [e.target.name]: [...e.target.files],
    });
  };

  const submitEditForm = async () => {
    const formData = new FormData();
    Object.keys(editFormData).forEach((key) => {
      if (key === 'newImages' || key === 'newVideos') {
        Array.from(editFormData[key]).forEach((file) => {
          formData.append(key, file);
        });
      } else {
        formData.append(key, editFormData[key]);
      }
    });


    try {
      const response = await fetch(`${server}/update-brand-catalog-item/${selectedItem._id}`, {
        method: 'PATCH',
        body: formData,
      });
      const data = await response.json();
      if (data.success) {
        alert('Catalog item updated successfully');
        closeModal();
        dispatch(fetchBrandCatalogItems(brandId)); 
      } else {
        alert('Error updating catalog item');
      }
    } catch (error) {
      console.error('Error updating catalog item:', error);
      alert('Error updating catalog item');
    }
  };

  if (error) return <div>Error: {error}</div>;

  return (
    <div className="brand-catalog-component-container">
      <div className="brand-catalog-component-inner">
        <h2 className="brand-catalog-component-title">Brand Catalog</h2>
        {catalogItems.length > 0 ? (
          <div className="brand-catalog-component-grid">
            {catalogItems.map((item) => (
              <div key={item._id} className="brand-catalog-component-item" onClick={() => openModal(item)}>
                <div className="brand-catalog-component-item-image-container">
                  {item.images.length > 0 && (
                    <img src={item.images[0].url} alt={item.name} className="brand-catalog-component-item-image" />
                  )}
                </div>
                <div className="brand-catalog-component-item-details">
                  <h3 className="brand-catalog-component-item-name">{item.name}</h3>
                  <p className="brand-catalog-component-item-category">{item.category}</p>
                  <p className="brand-catalog-component-item-price">${item.priceFloor}</p>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <p>No catalog items found.</p>
        )}
      </div>

      {isModalOpen && selectedItem && (
        <div className="brand-catalog-component-modal-overlay" onClick={closeModal}>
          <div className="brand-catalog-component-modal" onClick={(e) => e.stopPropagation()}>
            <div className="brand-catalog-component-modal-header">
              <button className="brand-catalog-component-modal-close" onClick={closeModal}>
                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            {isEditMode ? (
              <form onSubmit={(e) => e.preventDefault()} className="brand-catalog-component-edit-form">
               <div>
                  <label htmlFor="newImages" className="brand-catalog-component-edit-label">Current Images</label>
                  <div className="brand-catalog-current-images-container">
                    {selectedItem.images.map((image, index) => (
                      <img key={index} src={image.url} alt="Current" className="brand-catalog-current-image-preview" />
                    ))}
                  </div>
                </div>
                <div>
                  <label htmlFor="newImages" className="brand-catalog-component-edit-label">Upload New Images</label>
                  <input
                    type="file"
                    name="newImages"
                    id="newImages"
                    multiple
                    accept="image/*"
                    onChange={handleFileChange}
                    className="brand-catalog-component-edit-input"
                  />
                </div>
                <div>
                  <label htmlFor="newVideos" className="brand-catalog-component-edit-label">Current Videos</label>
                  <div className="current-videos-container">
                    {selectedItem.videos.map((video, index) => (
                      <video key={index} src={video.url} className="current-video-preview" controls />
                    ))}
                  </div>
                </div>
                <div>
                  <label htmlFor="newVideos" className="brand-catalog-component-edit-label">Upload New Videos</label>
                  <input
                    type="file"
                    name="newVideos"
                    id="newVideos"
                    multiple
                    accept="video/*"
                    onChange={handleFileChange}
                    className="brand-catalog-component-edit-input"
                  />
                </div>

                <div>
                  <label htmlFor="name" className="brand-catalog-component-edit-label">Name</label>
                  <input
                    type="text"
                    name="name"
                    id="name"
                    value={editFormData.name}
                    onChange={handleInputChange}
                    className="brand-catalog-component-edit-input"
                  />
                </div>
                <div>
                  <label htmlFor="description" className="brand-catalog-component-edit-label">Description</label>
                  <textarea
                    name="description"
                    id="description"
                    value={editFormData.description}
                    onChange={handleInputChange}
                    rows={3}
                    className="brand-catalog-component-edit-textarea"
                  ></textarea>
                </div>
                <div>
                  <label htmlFor="priceFloor" className="brand-catalog-component-edit-label">Price Floor</label>
                  <input
                    type="number"
                    name="priceFloor"
                    id="priceFloor"
                    value={editFormData.priceFloor}
                    onChange={handleInputChange}
                    className="brand-catalog-component-edit-input"
                  />
                </div>
                <div>
                  <label className="brand-catalog-component-edit-label">Product Type</label>
                  <input
                    type="text"
                    name="productType"
                    value={editFormData.productType}
                    onChange={handleInputChange}
                    className="brand-catalog-component-edit-input"
                    placeholder="Separate types with commas (e.g., retail,wholesale)"
                  />
                </div>
                <div>
                  <label htmlFor="category" className="brand-catalog-component-edit-label">Category</label>
                  <input
                    type="text"
                    name="category"
                    id="category"
                    value={editFormData.category}
                    onChange={handleInputChange}
                    className="brand-catalog-component-edit-input"
                  />
                </div>
                <div>
                  <label htmlFor="SKU" className="brand-catalog-component-edit-label">SKU</label>
                  <input
                    type="text"
                    name="SKU"
                    id="SKU"
                    value={editFormData.SKU}
                    onChange={handleInputChange}
                    className="brand-catalog-component-edit-input"
                  />
                </div>
                <div className="brand-catalog-component-edit-checkbox-group">
                  <input
                    type="checkbox"
                    name="isEdible"
                    id="isEdible"
                    checked={editFormData.isEdible}
                    onChange={handleInputChange}
                    className="brand-catalog-component-edit-checkbox"
                  />
                  <label htmlFor="isEdible" className="brand-catalog-component-edit-checkbox-label">Is Edible</label>
                </div>
                <div className="brand-catalog-component-modal-actions">
                  <button
                    type="button"
                    onClick={closeModal}
                    className="brand-catalog-component-modal-button close"
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    onClick={submitEditForm}
                    className="brand-catalog-component-modal-button save"
                  >
                    Save Changes
                  </button>
                </div>
              </form>
            ) : (
              <div className="brand-catalog-component-modal-content">
                <div className="brand-catalog-component-modal-images">
                  {select < selectedItem.images.length ? (
                    <img
                      src={selectedItem.images[select]?.url}
                      alt={selectedItem.name}
                      className="brand-catalog-component-modal-main-image"
                    />
                  ) : (
                    <video
                      src={selectedItem.videos[select - selectedItem.images.length]?.url}
                      className="brand-catalog-component-modal-main-image"
                      controls
                    />
                  )}
                  <div className="brand-catalog-component-modal-thumbnails">
                    {selectedItem.images.map((image, index) => (
                      <img
                        key={index}
                        src={image.url}
                        alt="Product"
                        className={`brand-catalog-component-modal-thumbnail ${select === index ? "selected" : ""}`}
                        onClick={() => setSelect(index)}
                      />
                    ))}
                    {selectedItem.videos.map((video, index) => (
                      <video
                        key={index}
                        src={video.url}
                        className={`brand-catalog-component-modal-thumbnail ${select === selectedItem.images.length + index ? "selected" : ""}`}
                        onClick={() => setSelect(selectedItem.images.length + index)}
                        loop
                      />
                    ))}
                  </div>
                </div>
                <div className="brand-catalog-component-modal-info">
                  <h3 className="brand-catalog-component-modal-title">{selectedItem.name}</h3>
                  <p className="brand-catalog-component-modal-description">{selectedItem.description}</p>
                  <p className="brand-catalog-component-modal-price">Price Floor: ${selectedItem.priceFloor}</p>
                  <div className="brand-catalog-component-modal-details">
                    <p className="brand-catalog-component-modal-details-item">SKU: {selectedItem.SKU}</p>
                    <p className="brand-catalog-component-modal-details-item">Category: {selectedItem.category}</p>
                    <p className="brand-catalog-component-modal-details-item">Product Type: {selectedItem.productType.join(', ')}</p>
                    <p className="brand-catalog-component-modal-details-item">Unit of Measurement: {selectedItem.unitOfMeasurement}</p>
                    <p className="brand-catalog-component-modal-details-item">Is Edible: {selectedItem.isEdible ? 'Yes' : 'No'}</p>
                  </div>
                  <div className="brand-catalog-component-modal-gallery">
                    <h4 className="brand-catalog-component-modal-gallery-title">Test Results</h4>
                    <div className="brand-catalog-component-modal-gallery-container">
                      {selectedItem.testResults.map((testResult, index) => (
                        <img key={index} src={testResult.url} alt="Test Result" className="brand-catalog-component-modal-gallery-image" />
                      ))}
                    </div>
                    <h4 className="brand-catalog-component-modal-gallery-title">Nutrition Info Image</h4>
                    <div className="brand-catalog-component-modal-gallery-container">
                      {selectedItem.nutritionInfoImage.map((image, index) => (
                        <img key={index} src={image.url} alt="Nutrition Info" className="brand-catalog-component-modal-gallery-image" />
                      ))}
                    </div>
                  </div>
                </div>
                <div className="brand-catalog-component-modal-actions">
                  <button
                    type="button"
                    className="brand-catalog-component-modal-button close"
                    onClick={closeModal}
                  >
                    Close
                  </button>
                  <button
                    onClick={() => setIsEditMode(true)}
                    className="brand-catalog-component-modal-button edit"
                  >
                    Edit
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default BrandCatalog;




  