import { createReducer } from "@reduxjs/toolkit";
import {
  FOLLOW_UNFOLLOW_REQUEST,
  FOLLOW_UNFOLLOW_SUCCESS,
  FOLLOW_UNFOLLOW_FAIL,
  FETCH_MY_CONNECTIONS_REQUEST,
  FETCH_MY_CONNECTIONS_SUCCESS,
  FETCH_MY_CONNECTIONS_FAIL,
  FETCH_CONNECTIONS_REQUEST,
  FETCH_CONNECTIONS_SUCCESS,
  FETCH_CONNECTIONS_FAIL,
  FETCH_IS_FOLLOWING_REQUEST,
  FETCH_IS_FOLLOWING_SUCCESS,
  FETCH_IS_FOLLOWING_FAIL,
  FETCH_RECOMMENDED_CONNECTIONS_REQUEST,
  FETCH_RECOMMENDED_CONNECTIONS_SUCCESS,
  FETCH_RECOMMENDED_CONNECTIONS_FAIL,
} from "../actions/connections";

const initialState = {
    isLoading: false,
    myConnections: [],
    myFollowers: [],
    myFollowing: [],
    followers: [],
    following: [],
    recommendedConnections: [],
    isFollowingById: {}, // Track following status by connectionId
    error: null,
  };
  
  export const connectionsReducer = createReducer(initialState, {
    // Handle follow/unfollow actions
    [FOLLOW_UNFOLLOW_REQUEST]: (state) => {
      state.isLoading = true;
    },
    [FOLLOW_UNFOLLOW_SUCCESS]: (state) => {
      state.isLoading = false;
    },
    [FOLLOW_UNFOLLOW_FAIL]: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
  
    // Handle fetching current user's connections
    [FETCH_MY_CONNECTIONS_REQUEST]: (state) => {
        state.isLoading = true;
      },
      [FETCH_MY_CONNECTIONS_SUCCESS]: (state, action) => {
        state.isLoading = false;
        if (action.payload.relationship === 'followers') {
          state.myFollowers = action.payload.connections; 
        } else if (action.payload.relationship === 'following') {
          state.myFollowing = action.payload.connections;  
        }
      },
      [FETCH_MY_CONNECTIONS_FAIL]: (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      },
  
    // Handle fetching another user's connections
    [FETCH_CONNECTIONS_REQUEST]: (state) => {
      state.isLoading = true;
    },
    [FETCH_CONNECTIONS_SUCCESS]: (state, action) => {
      const { connections, relationship } = action.payload;
  
      if (relationship === 'followers') {
        state.followers = connections;
      } else if (relationship === 'following') {
        state.following = connections;
      }
  
      state.isLoading = false;
    },
    [FETCH_CONNECTIONS_FAIL]: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
  
    // Handle checking if the current user is following another profile
    [FETCH_IS_FOLLOWING_REQUEST]: (state) => {
      state.isLoading = true;
    },
    [FETCH_IS_FOLLOWING_SUCCESS]: (state, action) => {
      state.isLoading = false;
      state.isFollowingById[action.payload.connectionId] = action.payload.isFollowing; // Track by connectionId
    },
    [FETCH_IS_FOLLOWING_FAIL]: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
  
    // Handle fetching recommended connections
    [FETCH_RECOMMENDED_CONNECTIONS_REQUEST]: (state) => {
      state.isLoading = true;
    },
    [FETCH_RECOMMENDED_CONNECTIONS_SUCCESS]: (state, action) => {
      state.isLoading = false;
      state.recommendedConnections = action.payload;
    },
    [FETCH_RECOMMENDED_CONNECTIONS_FAIL]: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
  
    // Clear errors
    clearErrors: (state) => {
      state.error = null;
    },
  });
  
  export default connectionsReducer;
  


