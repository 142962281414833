import React, { useState } from 'react';

export default function RoomDemo() {
    const [currentSlide, setCurrentSlide] = useState(0);
  
    const slides = [
      {
        image: 'https://res.cloudinary.com/dzlopmfj8/image/upload/v1723499113/crypto_casino_rpvgz6.jpg',
        alt: 'Casino Image 1',
        title: 'Welcome to the Casino!',
        buttonLabel: 'Join Now',
      },
      {
        image: 'https://res.cloudinary.com/dzlopmfj8/image/upload/v1723490211/featured_sports_betting_nfl_akhfxg.webp',
        alt: 'Casino Image 2',
        title: 'NFL Gaming!',
        buttonLabel: 'Play Now',
      },
      {
        image: 'https://res.cloudinary.com/dzlopmfj8/image/upload/v1723499236/Crypto-Casino2_sem2np.jpg',
        alt: 'Casino Image 3',
        title: 'Spin the Slots!',
        buttonLabel: 'Try Your Luck',
      },
    ];
  
    const games = [
      { title: 'Super Slots', image: 'https://res.cloudinary.com/dzlopmfj8/image/upload/v1723488216/DALL_E_2024-08-12_18.51.44_-_A_colorful_and_vibrant_cartoon-style_online_gambling_slot_game_graphic._The_design_should_include_a_slot_machine_in_the_center_with_a_shiny_animated_z860zx.webp' },
      { title: 'MineSweeper', image: 'https://res.cloudinary.com/dzlopmfj8/image/upload/v1723488686/DALL_E_2024-08-12_20.51.08_-_A_colorful_and_vibrant_cartoon-style_online_gambling_game_featuring_a_minesweeper_theme._The_scene_includes_a_grid_with_hidden_mines_flags_and_numbe_t1xgtn.webp' },
      { title: 'Super Dice', image: 'https://res.cloudinary.com/dzlopmfj8/image/upload/v1723489075/DALL_E_2024-08-12_20.57.43_-_A_vibrant_and_colorful_cartoon-style_online_casino_game_graphic_focusing_on_dice._The_image_features_large_stylized_dice_in_motion_with_bright_color_va7gux.webp' },
      { title: 'E-Poker', image: 'https://res.cloudinary.com/dzlopmfj8/image/upload/v1723488863/DALL_E_2024-08-12_20.54.05_-_A_vibrant_and_colorful_cartoon-style_online_casino_game_interface_focused_on_playing_cards._The_design_should_feature_a_playful_engaging_atmosphere_w_uypwmy.webp' },
    ];
  
    const nextSlide = () => {
      setCurrentSlide((prev) => (prev + 1) % slides.length);
    };
  
    const prevSlide = () => {
      setCurrentSlide((prev) => (prev - 1 + slides.length) % slides.length);
    };
  
    return (
      <div>
        
        <div className="gambling-page-casino-landing-page">
          <div className="gambling-page-casino-landing-page-wrapper">
            {/* Hero Slider */}
            <div className="gambling-page-hero-slider">
            <div className="gambling-page-slides-container">
                {slides.map((slide, index) => (
                    <div
                    key={index}
                    className={`gambling-page-slide ${
                        index === currentSlide ? 'gambling-page-active' : ''
                    }`}
                    >
                    <img
                        src={slide.image}
                        alt={slide.alt}
                        className="gambling-page-slider-image"
                    />
                    <div className="gambling-page-slide-content">
                        <h2>{slide.title}</h2>
                        <button className="gambling-page-slide-button">
                        {slide.buttonLabel}
                        </button>
                    </div>
                    </div>
                ))}
                </div>

              <button onClick={prevSlide} className="gambling-page-slider-button gambling-page-prev">
                &#10094;
              </button>
              <button onClick={nextSlide} className="gambling-page-slider-button gambling-page-next">
                &#10095;
              </button>
            </div>
  
            {/* Call-to-Action Boxes */}
            <div className="gambling-page-cta-section">
                <div
                    className="gambling-page-cta-box"
                    style={{ backgroundImage: 'url(https://res.cloudinary.com/dzlopmfj8/image/upload/v1723499320/most_trusted_crypto_casinos_vyzqsk.webp)' }}
                >
                    <h3>Join the Casino</h3>
                    <p>Get started with a welcome bonus!</p>
                    <button className="gambling-page-cta-button">Sign Up</button>
                </div>
                <div
                    className="gambling-page-cta-box"
                    style={{ backgroundImage: 'url(https://res.cloudinary.com/dzlopmfj8/image/upload/v1723499574/cryptocasino_fue8yi.jpg)' }}
                >
                    <h3>Explore the Games</h3>
                    <p>Discover a variety of games to play.</p>
                    <button className="gambling-page-cta-button">Browse Games</button>
                </div>
                </div>

  
            {/* List of Games */}
            <div className="gambling-page-games-list">
              <h2>Popular Games</h2>
              <div className="gambling-page-games-grid">
                {games.map((game, index) => (
                  <div key={index} className="gambling-page-game-card">
                    <img
                      src={game.image}
                      alt={game.title}
                      className="gambling-page-game-image"
                    />
                    <h4>{game.title}</h4>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }