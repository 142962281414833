import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { server } from "../../server";
import GoogleCalendarButton from './GoogleCalendarButton';
import OutlookCalendarButton from './OutlookCalendarButton';
import DownloadICSButton from './DownloadICSButton';
import { CalendarIcon, MapPinIcon } from '@heroicons/react/20/solid';



const EventDetail = () => {
    const { eventId } = useParams();
    const [event, setEvent] = useState(null);
    const currentProfileType = useSelector((state) => state.user.currentProfile);
    const { user } = useSelector((state) => state.user);
    const UserId = user._id
    const [isGoing, setIsGoing] = useState(false);


    useEffect(() => {
        const fetchEvent = async () => {
            try {
                const response = await axios.get(`${server}/event/events/${eventId}`);
                const fetchedEvent = response.data;
                setEvent(fetchedEvent);
                setIsGoing(fetchedEvent.attendees.some(attendee => attendee._id === UserId));
            } catch (error) {
                console.error('Failed to fetch event:', error);
            }
        };

        fetchEvent();
    }, [eventId, UserId]);

    if (!event) {
        return <div>Loading...</div>;
    }

    const handleRSVP = async () => {
        if (isGoing) {
            await handleCancelRSVP();
        } else {
            await handleNewRSVP();
        }
    };

    const handleNewRSVP = async () => {
        try {
            const data = { currentProfile: currentProfileType };
            const response = await axios.post(`${server}/event/rsvp/${eventId}`, data, {
                withCredentials: true
            });
            if (response.data.success) {
                setIsGoing(true);
                
            }
        } catch (error) {
            console.error('Error RSVPing to event:', error);
            alert('Failed to RSVP: ' + (error.response?.data?.message || 'Error occurred'));
        }
    };

    const handleCancelRSVP = async () => {
        try {
            const response = await axios.delete(`${server}/event/rsvp/${eventId}`, { withCredentials: true });
            
            setIsGoing(false);
        } catch (error) {
            console.error('Failed to cancel RSVP:', error);
            alert('Failed to cancel RSVP: ' + (error.response?.data?.message || 'Error occurred'));
        }
    };
    

    const styles = {
        overlayContainer: {
            position: 'relative',
            maxWidth: '800px',
            margin: 'auto',
            borderRadius: '10px',
            overflow: 'hidden',
        },
        image: {
            width: '100%',
            height: 'auto',
            display: 'block',
            borderRadius: '10px',
        },
        titleOverlay: {
            position: 'absolute',
            bottom: '0',
            left: '0',
            width: '100%',
            padding: '10px 20px',
            background: 'linear-gradient(to top, rgba(0,0,0,0.8), transparent)',
            color: '#4CAF50',
            fontSize: '32px',
            textAlign: 'center',
        },
        button: {
            backgroundColor: '#007BFF',
            color: 'white',
            border: 'none',
            padding: '10px 20px',
            marginTop: '10px',
            cursor: 'pointer',
            borderRadius: '5px',
            fontSize: '16px',
            width: '90%',
        },
    };

    return (
        <div style={{ fontFamily: 'Arial, sans-serif', backgroundColor: '#000000', padding: '20px' }}>
            <div style={{
                margin: 'auto',
                maxWidth: '800px',
                backgroundColor: '#323232',
                padding: '20px',
                border: '1px solid #ccc',
                borderRadius: '10px',
                boxShadow: '0 2px 6px rgba(0,0,0,0.1)'
            }}>
                <div style={styles.overlayContainer}>
                    <img src={event.image} alt={event.title} style={styles.image} />
                    <h1 style={styles.titleOverlay}>{event.title}</h1>
                </div>
                <div style={{ textAlign: 'center', marginTop: '10px' }}>
                <p style={{ fontSize: '18px', color: '#fff', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <CalendarIcon className="h-6 w-6 mr-2" style={{ display: 'inline-block', color: 'white' }} />
                    {new Date(event.date).toLocaleString()}
                </p>
                <p style={{ fontSize: '18px', color: '#fff', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <MapPinIcon className="h-6 w-6 mr-2" style={{ display: 'inline-block', color: 'white' }} />
                    <a href={`https://maps.google.com/?q=${encodeURIComponent(event.location.address)}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        title="Open in Google Maps"
                        style={{ color: 'white', textDecoration: 'underline' }}>
                            {event.location.address}
                        </a>

                </p>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
            <button style={styles.button} onClick={handleRSVP} disabled={false}>
                    {isGoing ? 'Going' : 'RSVP'}
                </button>
                </div>

                <div style={{ display: 'flex', justifyContent: 'space-around', margin: '20px 0' }}>
                    <GoogleCalendarButton event={event} />
                    <OutlookCalendarButton event={event} />
                    <DownloadICSButton event={event} />
                </div>
                <div style={{ borderTop: '1px solid #ccc', paddingTop: '10px' }}>
                    <h3 style={{ color: '#4CAF50' }}>Hosted by:</h3>
                    <img src={event.owner.avatar.url} alt="Owner avatar" style={{ width: '50px', height: '50px', borderRadius: '50%' }} />
                    <p>{event.owner.name}</p>
                </div>
                <div style={{ borderTop: '1px solid #ccc', paddingTop: '10px' }}>
                    <h3 style={{ color: '#4CAF50' }}>Who is going?</h3>
                    <div className="isolate flex -space-x-2 overflow-hidden p-4">
                        {event.attendees.slice(0, 5).map((attendee, index) => (
                            <img
                                key={index}
                                className={`relative inline-block h-10 w-10 rounded-full ring-2 ring-white z-${30 - index * 10}`}
                                src={attendee.avatar.url}
                                alt=""
                            />
                        ))}
                    </div>
                    <p style={{ marginTop: '10px', fontSize: '18px', color: '#fff' }}>{event.attendees.length} attendees</p>
                </div>
                <div style={{ borderTop: '1px solid #ccc', paddingTop: '10px', marginTop: '20px' }}>
                <h3 style={{ color: '#4CAF50', marginBottom: '10px' }}>What's the vibe?</h3>
                <p style={{ fontSize: '16px', color: '#fff', padding: '10px 0' }}>{event.description}</p>
            </div>
            </div>
        </div>
    );
    
};

export default EventDetail;

