import { createReducer } from "@reduxjs/toolkit";
import {
  FETCH_FRIENDS_REQUEST,
  FETCH_FRIENDS_SUCCESS,
  FETCH_FRIENDS_FAIL,
  FETCH_IS_FRIEND_REQUEST,
  FETCH_IS_FRIEND_SUCCESS,
  FETCH_IS_FRIEND_FAIL
} from "../actions/friends"; 

const initialState = {
  isLoading: false,
  friends: [],
  isFriend: false,
  error: null
};

export const friendsReducer = createReducer(initialState, {
  [FETCH_FRIENDS_REQUEST]: (state) => {
    state.isLoading = true;
  },
  [FETCH_FRIENDS_SUCCESS]: (state, action) => {
    state.isLoading = false;
    state.friends = action.payload;
  },
  [FETCH_FRIENDS_FAIL]: (state, action) => {
    state.isLoading = false;
    state.error = action.payload;
  },
  [FETCH_IS_FRIEND_REQUEST]: (state) => {
    state.isLoading = true;
  },
  [FETCH_IS_FRIEND_SUCCESS]: (state, action) => {
    state.isLoading = false;
    state.isFriend = action.payload;
  },
  [FETCH_IS_FRIEND_FAIL]: (state, action) => {
    state.isLoading = false;
    state.error = action.payload;
  },
  clearErrors: (state) => {
    state.error = null;
  }
});

export default friendsReducer;
