import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import Footer from "../components/Layout/Footer";
import Header from "../components/Layout/Header";
import Loader from "../components/Layout/Loader";
import ProductCard from "../components/Route/ProductCard/ProductCard";
import styles from "../styles/styles";
import Sidebar from '../components/Layout/Sidebar';
import HeroWholesaleProducts from "../components/Route/Hero/HeroWholesaleProducts";
import ShopBySeller from "../components/Shop/ShopBySeller";
import CategorySection from "../components/Shop/CategorySection";
import TrendingWholesaleCarosel from "../components/Products/TrendingProductsCaroselWholesale";

const WholesaleProductsPage = () => {
  const [searchParams] = useSearchParams();
  const categoryData = searchParams.get("category");
  const { allProducts, isLoading } = useSelector((state) => state.products);
  const [data, setData] = useState([]);

  useEffect(() => {
    let filteredProducts = allProducts;

    // Filter for wholesale products
    if (filteredProducts) {
      filteredProducts = filteredProducts.filter(product => product.productType.includes('wholesale'));
    }

    // Further filter by category if necessary
    if (categoryData) {
      filteredProducts = filteredProducts.filter(product => product.category === categoryData);
    }

    setData(filteredProducts);
  }, [allProducts, categoryData]);

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div>
          <Header activeHeading={3} />
          <Sidebar />
          <HeroWholesaleProducts />
          <br />
          <br />
          <CategorySection />
          <TrendingWholesaleCarosel />
          <div className={`${styles.section}`}>
            <div className="grid grid-cols-1 gap-[20px] md:grid-cols-2 md:gap-[25px] lg:grid-cols-4 lg:gap-[25px] xl:grid-cols-5 xl:gap-[30px] mb-12">
              {data && data.map((product, index) => <ProductCard data={product} key={index} />)}
            </div>
            {data && data.length === 0 ? (
              <h1 className="text-center w-full pb-[100px] text-[20px]">
                No wholesale products found!
              </h1>
            ) : null}
          </div>
          <ShopBySeller />
          <Footer />
        </div>
      )}
    </>
  );
};

export default WholesaleProductsPage;
