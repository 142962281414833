import React, { useState } from 'react';
import Friend from '../../components/Friend';

const OtherFollowing = ({ following, isLoading, error }) => {
  const [searchTerm, setSearchTerm] = useState('');

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value.toLowerCase());
  };

  const filteredFollowing = searchTerm
    ? following.filter((follow) => follow.name.toLowerCase().includes(searchTerm))
    : following;

  return (
    <div className="following-list-widget-wrapper">
      <h5 className="following-list-widget-title">Following</h5>
      <input
        type="text"
        className="following-list-widget-search"
        placeholder="Search following"
        onChange={handleSearchChange}
      />
      <div className="following-list-widget-content">
        {isLoading && <p>Loading...</p>}
        {error && <p>{error}</p>}
        {!isLoading && !error && filteredFollowing.length > 0 ? (
          filteredFollowing.map((follow) => (
            <Friend key={follow._id} friendId={follow._id} {...follow} friendType={follow.type} />
          ))
        ) : (
          <p>{error || 'No following found.'}</p>
        )}
      </div>
    </div>
  );
};

export default OtherFollowing;
