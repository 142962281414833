import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import FlexBetween from "../components/FlexBetween";
import UserImage from "../components/UserImage";
import axios from 'axios';
import Cookie from "js-cookie";
import { server } from "../server";

const token = Cookie.get('token');

const Friend = ({ 
  friendId, 
  name, 
  subtitle, 
  avatar,
  friendType,
  friends   
}) => {
  const navigate = useNavigate();
  const [sellerHandle, setSellerHandle] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (friendType === 'Shop') {
      setIsLoading(true);
      axios.get(`${server}/shop/get-shop-info/${friendId}`)
        .then(res => {
          if (res.data && res.data.shop) {
            setSellerHandle(res.data.shop.handle);
          } else {
            console.error('Shop data is missing in the response');
          }
          setIsLoading(false);
        })
        .catch(error => {
          console.error('Error fetching shop info:', error);
          setIsLoading(false);
        });
    }
  }, [friendId, friendType]);

  const handleProfileClick = () => {
    if (isLoading) {
      return;
    }
    if (friendType === 'User') {
      navigate(`/profile/${friendId}`);
    } else if (sellerHandle) {
      navigate(`/shop/${sellerHandle}`);
    } else {
      console.error('Seller handle is not defined');
    }
  };
  
  return (
    <FlexBetween>
      <FlexBetween gap="1rem">
        <UserImage image={avatar} size="55px" />
        <div onClick={handleProfileClick} style={{ cursor: "pointer" }}>
          <h5 className="friend-name">{name}</h5>
          <p className="friend-subtitle">{subtitle}</p>
        </div>
      </FlexBetween>
      {/* Optionally, display a loading indicator here */}
    </FlexBetween>
  );
};

export default Friend;





