import React, { useRef, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { AiOutlineSearch, AiOutlineClose } from "react-icons/ai";
import { useSelector } from "react-redux";


const SearchBox = ({ searchTerm, setSearchTerm, handleSearchChange, handleFocus }) => {
  const searchBoxRef = useRef(null);
  const { allProducts } = useSelector((state) => state.products);
  const [searchData, setSearchData] = useState(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (searchBoxRef.current && !searchBoxRef.current.contains(event.target)) {
        setSearchData(null); 
      }
    };


    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [searchBoxRef]);

  useEffect(() => {
    if (searchTerm) {
      setSearchData(
        allProducts.filter((product) =>
          product.name.toLowerCase().includes(searchTerm.toLowerCase())
        )
      );
    } else {
      setSearchData(null);
    }
  }, [searchTerm, allProducts]);

  const clearSearch = () => {
    setSearchTerm("");
    setSearchData(null);
  };

  return (
    <div className="search-box-component-container" ref={searchBoxRef}>
      <input
        style={{ backgroundColor: 'rgba(255, 255, 255, 0.2)' }}
        type="text"
        placeholder="Search Products..."
        value={searchTerm}
        onChange={handleSearchChange}
        onFocus={handleFocus}
        className="search-box-component-input"
      />
      <AiOutlineSearch
        size={20}
        className="search-box-component-icon search-box-component-search-icon"
      />
      {searchTerm && (
        <AiOutlineClose
          size={18}
          className="search-box-component-icon search-box-component-close-icon"
          onClick={clearSearch}
        />
      )}
      {searchData && searchData.length !== 0 && (
        <div className="search-box-component-dropdown">
          {searchData.map((i, index) => (
            <Link to={`/product/${i._id}`} key={index}>
              <div className="search-box-component-dropdown-item">
                <img
                  src={`${i.images[0]?.url}`}
                  alt=""
                  className="search-box-component-item-image"
                />
                <h1 className="search-box-component-item-name">{i.name}</h1>
                <div className="search-box-component-item-details">
                  <span className="search-box-component-item-brand">
                    Brand:{i.brand}
                  </span>
                  <span className="search-box-component-item-shop">
                    Shop:{i.shop.name}
                  </span>
                </div>
              </div>
            </Link>
          ))}
        </div>
      )}
    </div>
  );
};

export default SearchBox;

