import axios from "axios";
import { server } from "../../server";

export const FOLLOW_USER_REQUEST = "FollowUserRequest";
export const FOLLOW_USER_SUCCESS = "FollowUserSuccess";
export const FOLLOW_USER_FAIL = "FollowUserFail";

export const UNFOLLOW_USER_REQUEST = "UnfollowUserRequest";
export const UNFOLLOW_USER_SUCCESS = "UnfollowUserSuccess";
export const UNFOLLOW_USER_FAIL = "UnfollowUserFail";

export const GET_CURRENT_USER_FOLLOWERS_REQUEST = "GetCurrentUserFollowersRequest";
export const GET_CURRENT_USER_FOLLOWERS_SUCCESS = "GetCurrentUserFollowersSuccess";
export const GET_CURRENT_USER_FOLLOWERS_FAIL = "GetCurrentUserFollowersFail";

export const GET_OTHER_USER_FOLLOWERS_REQUEST = "GetOtherUserFollowersRequest";
export const GET_OTHER_USER_FOLLOWERS_SUCCESS = "GetOtherUserFollowersSuccess";
export const GET_OTHER_USER_FOLLOWERS_FAIL = "GetOtherUserFollowersFail";

// Fetch current user's followers and following
export const getCurrentUserFollowers = (userId, profileType) => async (dispatch) => {
    dispatch({ type: GET_CURRENT_USER_FOLLOWERS_REQUEST });
    try {
        const response = await axios.get(`${server}/followers/get-following-followers/${userId}/${profileType}`, {
            withCredentials: true,
        });
        dispatch({
            type: GET_CURRENT_USER_FOLLOWERS_SUCCESS,
            payload: response.data,
        });
    } catch (error) {
        dispatch({
            type: GET_CURRENT_USER_FOLLOWERS_FAIL,
            payload: error.response ? error.response.data.message : "Failed to fetch data.",
        });
    }
};

// Fetch another user's followers and following
export const getOtherUserFollowers = (userId, profileType) => async (dispatch) => {
    dispatch({ type: GET_OTHER_USER_FOLLOWERS_REQUEST });
    try {
        const response = await axios.get(`${server}/followers/get-following-followers/${userId}/${profileType}`, {
            withCredentials: true,
        });
        dispatch({
            type: GET_OTHER_USER_FOLLOWERS_SUCCESS,
            payload: response.data,
        });
    } catch (error) {
        dispatch({
            type: GET_OTHER_USER_FOLLOWERS_FAIL,
            payload: error.response ? error.response.data.message : "Failed to fetch data.",
        });
    }
};


// Actions to follow and unfollow a user
export const followUser = (currentProfileId, friendId, profileType, friendProfileType) => async (dispatch) => {
    dispatch({ type: FOLLOW_USER_REQUEST });
    try {
        const response = await axios.put(`${server}/followers/follow-unfollow/${currentProfileId}/${friendId}/${profileType}/${friendProfileType}`);
        dispatch({
            type: FOLLOW_USER_SUCCESS,
            message: response.data.message
        });
    } catch (error) {
        dispatch({
            type: FOLLOW_USER_FAIL,
            payload: error.response ? error.response.data.message : "Failed to update follow status.",
        });
    }
};
