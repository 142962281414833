import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { server } from "../../server";

const SponsoredShopsCarousel = () => {
    const [shops, setShops] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [currentIndex, setCurrentIndex] = useState(0);
    const shopRefs = useRef(new Map()); 

    const logAdClick = async (adId) => {
        try {
            await axios.post(`${server}/sponsored/click/${adId}`, { withCredentials: true, 
                headers: {
                    'current-profile': sessionStorage.getItem('currentProfile') || 'defaultProfile',
                }});
            console.log('Click logged for shop:', adId);
        } catch (error) {
            console.error('Error logging click:', error);
        }
    };

    const logImpression = async (adId) => {
        try {
            await axios.post(`${server}/sponsored/impression/${adId}`, {}, { withCredentials: true, 
                headers: {
                    'current-profile': sessionStorage.getItem('currentProfile') || 'defaultProfile',
                }});
            console.log('Impression logged for shop:', adId);
        } catch (error) {
            console.error('Error logging impression:', error);
        }
    };

    const logViewedImpression = async (adId) => {
        try {
            await axios.post(`${server}/sponsored/viewed-impression/${adId}`, {}, { withCredentials: true, 
                headers: {
                    'current-profile': sessionStorage.getItem('currentProfile') || 'defaultProfile',
                }});
            console.log('Viewed impression logged for shop:', adId);
        } catch (error) {
            console.error('Error logging viewed impression:', error);
        }
    };

    useEffect(() => {
        const fetchShops = async () => {
            try {
                const response = await axios.get(`${server}/sponsored/sponsored-shops`, { withCredentials: true });
                const activeShops = response.data.filter(ad => ad.isActive && ad.shop);
                setShops(activeShops);
                setIsLoading(false);
            } catch (error) {
                console.error('Error fetching shops:', error);
                setIsLoading(false);
            }
        };

        fetchShops();
    }, []);

    useEffect(() => {
        shops.forEach(shop => {
            logImpression(shop._id);
        });
    }, [shops]);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        const adId = entry.target.dataset.adId;

                        // Start a timer for 1 second to log viewed impression
                        const timer = setTimeout(() => {
                            logViewedImpression(adId);
                        }, 1000);

                        // Store the timer in the DOM element
                        entry.target._timer = timer;
                    } else {
                        clearTimeout(entry.target._timer); 
                    }
                });
            },
            { threshold: 0.5 } 
        );

        // Observe the current shop ad in the carousel
        const currentShopElement = shopRefs.current.get(shops[currentIndex]?._id);
        if (currentShopElement) {
            observer.observe(currentShopElement);
        }

        return () => {
            observer.disconnect(); 
        };
    }, [currentIndex, shops]);

    const nextSlide = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % shops.length);
    };

    const prevSlide = () => {
        setCurrentIndex((prevIndex) => (prevIndex - 1 + shops.length) % shops.length);
    };

    return (
        <div className="sponsored-shops-carousel-container">
            {isLoading ? (
                <div className="sponsored-shops-carousel-loading">Loading...</div>
            ) : (
                <>
                    {/* Only show the current shop ad */}
                    <div className="sponsored-shops-carousel-wrapper">
                        {shops[currentIndex] && (
                            <div
                                key={shops[currentIndex]._id}
                                className="sponsored-shops-carousel-paper"
                                style={{ backgroundImage: `url(${shops[currentIndex].shop.banner?.url})` }}
                                data-ad-id={shops[currentIndex]._id} // Track ad ID for IntersectionObserver
                                ref={(el) => el && shopRefs.current.set(shops[currentIndex]._id, el)} // Save ref for IntersectionObserver
                            >
                                <ShopItem shop={shops[currentIndex].shop} logAdClick={() => logAdClick(shops[currentIndex]._id)} />
                            </div>
                        )}
                    </div>
                    {shops.length > 1 && (
                        <>
                            <button className="sponsored-carousel-nav-button sponsored-carousel-prev" onClick={prevSlide}>‹</button>
                            <button className="sponsored-carousel-nav-button sponsored-carousel-next" onClick={nextSlide}>›</button>
                        </>
                    )}
                </>
            )}
        </div>
    );
};

const ShopItem = ({ shop, logAdClick }) => {
    return (
        <div className="sponsored-shops-carousel-content">
            <img src={shop.avatar?.url} alt={shop.name} className="sponsored-shops-carousel-avatar" />
            <h2 className="sponsored-shops-carousel-title">{shop.name}</h2>
            <Link
                to={`/shop/${shop.handle}`}
                onClick={logAdClick}
                className="sponsored-shops-carousel-button"
            >
                Visit Store
            </Link>
        </div>
    );
};

export default SponsoredShopsCarousel;


