import React, { useState, useEffect } from "react";
import {
  AiFillHeart,
  AiOutlineEye,
  AiOutlineHeart,
  AiOutlineShoppingCart,
} from "react-icons/ai";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ProductDetailsCard from "../ProductDetailsCard/ProductDetailsCard";
import {
  addToWishlist,
  removeFromWishlist,
} from "../../../redux/actions/wishlist";
import { addTocart } from "../../../redux/actions/cart";
import { toast } from "react-toastify";
import Ratings from "../../Products/Ratings";

const ProductCard = ({ data, isEvent }) => {
  const { wishlist } = useSelector((state) => state.wishlist);
  const { cart } = useSelector((state) => state.cart);
  const [click, setClick] = useState(false);
  const [open, setOpen] = useState(false);
  const [quantity, setQuantity] = useState(1);
  const dispatch = useDispatch();

  useEffect(() => {
    if (wishlist && wishlist.find((i) => i._id === data._id)) {
      setClick(true);
    } else {
      setClick(false);
    }
  }, [wishlist]);

  const handleQuantityChange = (event, amount) => {
    event.stopPropagation();

    if (amount < 0) {
      setQuantity(Math.max(1, quantity + amount));
    } else {
      setQuantity(Math.min(data.stock, quantity + amount));
    }
  };

  const removeFromWishlistHandler = (data) => {
    setClick(!click);
    dispatch(removeFromWishlist(data));
  };

  const addToWishlistHandler = (data) => {
    setClick(!click);
    dispatch(addToWishlist(data));
  };

  const addToCartHandler = (id) => {
    const isItemExists = cart && cart.find((i) => i._id === id);
    if (isItemExists) {
      toast.error("Item already in cart!");
    } else {
      if (data.stock < 1) {
        toast.error("Product stock limited!");
      } else {
        const cartData = { ...data, qty: quantity };
        dispatch(addTocart(cartData));
        toast.success("Item added to cart successfully!");
      }
    }
  };

  return (
    <>
      <div className="product-card-component-container">
        
        <Link to={`${isEvent === true ? `/product/${data._id}?isEvent=true` : `/product/${data._id}`}`}>
          <img src={`${data.images && data.images[0]?.url}`} alt="" className="product-card-component-image" />
          <h5 className="product-card-component-shop-name">{data.shop.name}</h5>
          <h4 className="product-card-component-product-name">
            {data.name.length > 40 ? data.name.slice(0, 40) + "..." : data.name}
          </h4>

          <div className="product-card-component-ratings">
            <Ratings rating={data?.ratings} />
          </div>

          <div className="product-card-component-price-section">
            <div className="product-card-component-price">
              <h5 className="product-card-component-discount-price">
                $ {data.originalPrice === 0 ? data.originalPrice : data.discountPrice}
              </h5>
              <h4 className="product-card-component-original-price">
                {data.originalPrice ? "$" + data.originalPrice : null}
              </h4>
            </div>
          </div>
        </Link>

        <div className="product-card-component-quantity-selector"> 
          <button 
            onClick={(e) => handleQuantityChange(e, -1)} 
            className="product-card-component-quantity-button">
            -
          </button>
          <span className="product-card-component-quantity">{quantity}</span>
          <button 
            onClick={(e) => handleQuantityChange(e, 1)} 
            className="product-card-component-quantity-button" 
            disabled={quantity >= data.stock}>
            +
          </button>
          <AiOutlineShoppingCart
            size={25}
            className="product-card-component-cart-icon"
            onClick={(e) => {
              e.stopPropagation();
              addToCartHandler(data._id);
            }}
            color="#444"
            title="Add to cart"
          />
        </div>

        <div className="product-card-component-wishlist-icon">
          {click ? (
            <AiFillHeart
              size={22}
              className="cursor-pointer"
              onClick={() => removeFromWishlistHandler(data)}
              color={click ? "red" : "#333"}
              title="Remove from wishlist"
            />
          ) : (
            <AiOutlineHeart
              size={22}
              className="cursor-pointer"
              onClick={() => addToWishlistHandler(data)}
              color={click ? "red" : "#333"}
              title="Add to wishlist"
            />
          )}
        </div>

        <div className="product-card-component-eye-icon">
          <AiOutlineEye
            size={22}
            className="cursor-pointer"
            onClick={() => setOpen(!open)}
            color="#333"
            title="Quick view"
          />
        </div>

        {open && <ProductDetailsCard setOpen={setOpen} data={data} />}

        {data.brandTypeSelection === 'branded' && (
          <div className="product-card-component-brand-badge">
            <img
              src="https://res.cloudinary.com/dzlopmfj8/image/upload/v1710252613/feat_morfbr.png"
              alt="Featured Brand"
              className="product-card-component-brand-image"
            />
          </div>
        )}

      </div>
    </>
  );
};

export default ProductCard;


