import React from 'react';
import DashboardHeader from '../../components/Shop/Layout/DashboardHeader';
import DashboardSideBar from '../../components/Shop/Layout/DashboardSideBar';
import AllCoupons from "../../components/Shop/AllCoupons";

const ShopAllCoupons = () => {
  return (
    <div className="shop-all-coupons-page-container">
        <DashboardHeader />
        <div className="shop-all-coupons-page-content">
            <div className="shop-all-coupons-page-sidebar">
              <DashboardSideBar active={10} />
            </div>
            <div className="shop-all-coupons-page-main">
                <AllCoupons />
            </div>
        </div>
    </div>
  );
};

export default ShopAllCoupons;
