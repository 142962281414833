import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { server } from "../../server";

const ShopBySellerCircle = () => {
  const [sellers, setSellers] = useState([]);

  useEffect(() => {
    const fetchSellers = async () => {
      try {
        const response = await axios.get(`${server}/shop/all-sellers`, { withCredentials: true });
        setSellers(response.data.sellers);
      } catch (error) {
        console.error('Error fetching sellers:', error);
      }
    };
    fetchSellers();
  }, []);

  return (
    <div>
      <div className="circle-shop-list">
        {sellers.map((seller) => (
          <a href={`https://www.gamechip.ai/shop/${seller.handle}`} key={seller._id} className="circle-shop-card">
            <div className="circle-avatar-container">
              <img src={seller.avatar.url} alt={seller.name} className="circle-avatar" />
            </div>
            <h3 className="circle-shop-name">{seller.name}</h3>
          </a>
        ))}
      </div>
    </div>
  );
};

export default ShopBySellerCircle;
