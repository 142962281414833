import React, { useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { server } from '../../server';

const CouponCreationForm = () => {
  const [name, setName] = useState('');
  const [discountAmount, setDiscountAmount] = useState('');
  const [discountType, setDiscountType] = useState('percentage');
  const [validFor, setValidFor] = useState('both');
  const [maxRedemptions, setMaxRedemptions] = useState(1);
  const [startTime, setStartTime] = useState('');
  const [expiresAt, setExpiresAt] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const couponData = {
        name,
        discountAmount: parseFloat(discountAmount),
        discountType,
        validFor,
        maxRedemptions: parseInt(maxRedemptions, 10),
        startTime: startTime || undefined,
        expiresAt: expiresAt || undefined,
      };

      const { data } = await axios.post(
        `${server}/subscriptioncouponcode/create-subscription-coupon`,
        couponData,
        {
          headers: {
            'Content-Type': 'application/json',
          },
          withCredentials: true,
        }
      );

      toast.success(data.message);
      // Reset form fields after successful creation
      setName('');
      setDiscountAmount('');
      setDiscountType('percentage');
      setValidFor('both');
      setMaxRedemptions(1);
      
      setStartTime('');
      setExpiresAt('');
    } catch (error) {
      console.error('Error creating coupon:', error);
      toast.error(
        error.response?.data?.message || 'Failed to create coupon. Please try again.'
      );
    }
  };

  return (
    <div className="subscription-couponcode-creation-form-container">
      <h2>Create Subscription Coupon Code</h2>
      <form onSubmit={handleSubmit} className="subscription-couponcode-creation-form">
        <div className="subscription-couponcode-creation-form-group">
          <label>Coupon Code Name</label>
          <input
            type="text"
            className="subscription-couponcode-creation-input"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
        </div>

        <div className="subscription-couponcode-creation-form-group">
          <label>Discount Amount</label>
          <input
            type="number"
            className="subscription-couponcode-creation-input"
            value={discountAmount}
            onChange={(e) => setDiscountAmount(e.target.value)}
            required
          />
        </div>

        <div className="subscription-couponcode-creation-form-group">
          <label>Discount Type</label>
          <select
            className="subscription-couponcode-creation-input"
            value={discountType}
            onChange={(e) => setDiscountType(e.target.value)}
            required
          >
            <option value="percentage">Percentage</option>
            <option value="fixed">Fixed</option>
          </select>
        </div>

        <div className="subscription-couponcode-creation-form-group">
          <label>Valid For</label>
          <select
            className="subscription-couponcode-creation-input"
            value={validFor}
            onChange={(e) => setValidFor(e.target.value)}
            required
          >
            <option value="monthly">Monthly</option>
            <option value="annually">Annually</option>
            <option value="both">Both</option>
          </select>
        </div>

        <div className="subscription-couponcode-creation-form-group">
          <label>Max Redemptions</label>
          <input
            type="number"
            className="subscription-couponcode-creation-input"
            value={maxRedemptions}
            onChange={(e) => setMaxRedemptions(e.target.value)}
            required
          />
        </div>

        <div className="subscription-couponcode-creation-form-group">
          <label>Start Time (Optional)</label>
          <input
            type="datetime-local"
            className="subscription-couponcode-creation-input"
            value={startTime}
            onChange={(e) => setStartTime(e.target.value)}
          />
        </div>

        <div className="subscription-couponcode-creation-form-group">
          <label>Expiration Time (Optional)</label>
          <input
            type="datetime-local"
            className="subscription-couponcode-creation-input"
            value={expiresAt}
            onChange={(e) => setExpiresAt(e.target.value)}
          />
        </div>

        <button type="submit" className="subscription-couponcode-creation-button">
          Create Coupon
        </button>
      </form>
    </div>
  );
};

export default CouponCreationForm;

