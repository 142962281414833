import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { server } from '../../server';
import axios from 'axios';

const frequencies = [
  { value: 'monthly', label: 'Monthly', priceSuffix: '/month' },
  { value: 'annually', label: 'Annually', priceSuffix: '/year' },
];

const tiers = [
  {
    name: 'No G Shop',
    id: 'tier-basic-shop',
    description: 'Perfect for small shops starting out.',
    features: ['10 product listings', 'Email support', 'Basic analytics', 'Community access'],
    mostPopular: false,
    price: { monthly: 'Free', annually: 'Free' },
    priceId: { monthly: 'price_1Py9XKP4OgHr2xe6d0rugodI', annually: 'price_1Py9XKP4OgHr2xe6d0rugodI' },
  },
  {
    name: 'OG Shop',
    id: 'tier-advanced-shop',
    description: 'Advanced features for growing shops.',
    features: [
      'Up to 100 product listings',
      'Priority email support',
      'Advanced analytics',
      'Marketing tools integration',
      '1 promotional campaign per month',
    ],
    mostPopular: true,
    price: { monthly: '$50', annually: '$500' },
    priceId: { monthly: 'price_1POJS0P4OgHr2xe6is2bUBJA', annually: 'price_1POJSmP4OgHr2xe6EIE34Txp' },
  },
  {
    name: 'Super OG Shop',
    id: 'tier-enterprise-shop',
    description: 'Comprehensive solutions for large operations.',
    features: [
      'Unlimited product listings',
      'Dedicated support',
      'Full analytics suite',
      'Custom API integrations',
      'Unlimited promotional campaigns',
    ],
    mostPopular: false,
    price: { monthly: '$200', annually: '$2000' },
    priceId: { monthly: 'price_1POJUSP4OgHr2xe6WRgDQS7J', annually: 'price_1POJUmP4OgHr2xe68fpxHuAH' },
  },
];

const ShopChangeSubscriptionModal = ({ currentSubscriptionId }) => {
  const [showModal, setShowModal] = useState(false);
  const [currentSubscription, setCurrentSubscription] = useState(null);
  const [frequency, setFrequency] = useState(frequencies[0].value);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [selectedPriceId, setSelectedPriceId] = useState(null);

  useEffect(() => {
    const fetchCurrentSubscription = async () => {
      try {
        const response = await axios.get(`${server}/subscriptions/shop-subscriptions/${currentSubscriptionId}`, {
          withCredentials: true,
          headers: {
            'Content-Type': 'application/json',
            'current-profile': sessionStorage.getItem('currentProfile') || 'defaultProfile',
          },
        });
        setCurrentSubscription(response.data);
      } catch (error) {
        console.error('Error fetching current subscription:', error);
      }
    };
    fetchCurrentSubscription();
  }, [currentSubscriptionId]);

  const confirmSubscriptionChange = (newPriceId) => {
    setSelectedPriceId(newPriceId);
    setShowConfirmationModal(true);
  };

  const handleSubscriptionChange = async () => {
    const newPriceId = selectedPriceId;
    if (newPriceId === currentSubscription.priceId) {
      toast.info("You're already on this plan.");
      return;
    }
    try {
      const response = await axios.post(`${server}/subscriptions/shop-subscriptions/change-tier`, {
        subscriptionId: currentSubscriptionId,
        newPriceId,
      }, {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
          'current-profile': sessionStorage.getItem('currentProfile') || 'defaultProfile',
        },
      });
      toast.success('Subscription updated successfully!');
      setCurrentSubscription(response.data.newSubscription);
      setShowModal(false);
      setShowConfirmationModal(false);
    } catch (error) {
      console.error('Error changing subscription:', error);
      toast.error('Failed to update subscription.');
    }
  };

  const currentFrequencySuffix = frequencies.find(f => f.value === frequency)?.priceSuffix;

  return (
    <div>
      <button className="shop-change-subscription-upgrade-plan-btn" onClick={() => setShowModal(true)}>Upgrade Plan</button>
      {showModal && (
        <div className="shop-change-subscription-modal">
          <div className="shop-change-subscription-modal-content">
            <span className="shop-change-subscription-close-btn" onClick={() => setShowModal(false)}>&times;</span>
            <h2 className="shop-change-subscription-title">Choose your new plan</h2>

            {/* Frequency Selection */}
            <div className="shop-change-subscription-frequency">
              {frequencies.map((option) => (
                <div
                  key={option.value}
                  className={`shop-change-subscription-frequency-option ${frequency === option.value ? 'active' : ''}`}
                  onClick={() => setFrequency(option.value)}
                >
                  {option.label}
                </div>
              ))}
            </div>

            {/* Tier Selection */}
            <div className="shop-change-subscription-tier-group">
              {tiers.map((tier) => (
                <div
                  key={tier.priceId[frequency]}
                  className={`shop-change-subscription-tier-option ${currentSubscription && currentSubscription.priceId === tier.priceId[frequency] ? 'active' : ''}`}
                  onClick={() => confirmSubscriptionChange(tier.priceId[frequency])}
                >
                  <div className="shop-change-subscription-tier-content">
                    <span className="shop-change-subscription-tier-name">{tier.name}</span>
                    <span className="shop-change-subscription-tier-description">{tier.description}</span>
                    <div className="shop-change-subscription-tier-features">
                      {tier.features.map((feature, index) => (
                        <span key={index}>{feature}</span>
                      ))}
                    </div>
                    <span className="shop-change-subscription-tier-price">
                      {tier.price[frequency]}
                      <span className="shop-change-subscription-price-suffix">{currentFrequencySuffix}</span>
                    </span>
                  </div>
                  {currentSubscription && currentSubscription.priceId === tier.priceId[frequency] && (
                    <span className="shop-change-subscription-tier-check-icon">✔</span>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      )}

      {showConfirmationModal && (
        <div className="shop-change-subscription-confirmation-modal">
          <div className="shop-change-subscription-confirmation-content">
            <h2 className="shop-change-subscription-confirmation-title">Confirm Subscription Change</h2>
            <p className="shop-change-subscription-confirmation-text">Are you sure you want to change your subscription?</p>
            <div className="shop-change-subscription-confirmation-actions">
              <button
                className="shop-change-subscription-confirmation-cancel"
                onClick={() => setShowConfirmationModal(false)}
              >
                Cancel
              </button>
              <button
                className="shop-change-subscription-confirmation-confirm"
                onClick={handleSubscriptionChange}
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ShopChangeSubscriptionModal;



