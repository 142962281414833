import React from 'react'
import Header from "../components/Layout/Header"
import Sidebar from '../components/Layout/Sidebar';
import SubscriptionCheckout from '../components/Checkout/SubscriptionCheckout';

const SubscriptionCheckoutPage = () => {
  
  return (
    <div>
        <Header />
        <Sidebar />
        <div className="m-24">
        <SubscriptionCheckout />
        </div>
    </div>
  )
}

export default SubscriptionCheckoutPage ;