import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { server } from "../../server";
import styles from "../../styles/styles";
import Loader from "../Layout/Loader";
import { useDispatch, useSelector } from "react-redux";
import { getAllProductsShop } from "../../redux/actions/product";
import ShareToSocialMedia from './ShareToSocialMedia';
import { AiOutlineCamera } from "react-icons/ai";
import { loadSeller } from "../../redux/actions/user";
import { toast } from "react-toastify";
import AddFriendButton from "../AddFriendButton";
import FriendsAvatarDisplay from "../FriendsAvatarDisplay";
import FollowersAvatarDisplay from "../Followers/FollowersAvatarDisplay";
import FollowButton from "../Connections/FollowButton";
import FollowersDisplay from "../Connections/FollowersDisplay";



const ShopInfo = ({ isOwner }) => {
  const [data, setData] = useState({});
  const {products} = useSelector((state) => state.products);
  const [isLoading,setIsLoading] = useState(false);
  const {handle} = useParams();
  const [avatar, setAvatar] = useState();
  const dispatch = useDispatch();
  const currentProfile = useSelector((state) => state.user.currentProfile);
  const currentProfileName = sessionStorage.getItem('currentProfile');
  

  useEffect(() => {
    setIsLoading(true);
    axios.get(`${server}/shop/handle-to-id/${handle}`)
      .then(res => {
        const id = res.data.id;
        
        dispatch(getAllProductsShop(id));
        return axios.get(`${server}/shop/get-shop-info/${id}`);
      })
      .then(res => {
        setData(res.data.shop);
        
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [handle, dispatch]);
  
  useEffect(() => {
    console.log("Shop Data updated:", data);
    // Place any logic here that needs to run after `data` updates
  }, [data]);
  

  const handleImage = async (e) => {
    const reader = new FileReader();

    reader.onload = () => {
      if (reader.readyState === 2) {
        setAvatar(reader.result);
        axios
          .put(
            `${server}/shop/update-shop-avatar`,
            { avatar: reader.result },
            { withCredentials: true, headers: {
              'Current-Profile': currentProfileName, 
            } }
          )
          .then((res) => {
            dispatch(loadSeller());
            toast.success("Avatar updated successfully!");
          })
          .catch((error) => {
            toast.error(error.response.data.message);
          });
      }
    };

    reader.readAsDataURL(e.target.files[0]);
  };


  

  const logoutHandler = () => {
    axios.post(`${server}/shop/logout`, {}, { withCredentials: true })
      .then((response) => {
        if (response.data.success) {
          // Provide a success message
          toast.success("Successfully logged out");
  
          // Redirect to the login page or home page
          window.location.href = "/shop-login"; // Adjust the route as needed
        }
      })
      .catch((error) => {
        // Handle error case
        console.error('Logout failed:', error);
        toast.error(error.response?.data?.message || "Logout failed");
      });
  };
  

  const totalReviewsLength =
    products &&
    products.reduce((acc, product) => acc + product.reviews.length, 0);

  const totalRatings = products && products.reduce((acc,product) => acc + product.reviews.reduce((sum,review) => sum + review.rating, 0),0);

  const averageRating = parseFloat((totalRatings / totalReviewsLength).toFixed(1)) || 0;

  

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="shop-info-sidebar-container">
          {/* Top Row */}
          <div className="shop-info-sidebar-top-row">
            <div className="shop-info-sidebar-avatar-container">
              <img
                src={`${data.avatar?.url}`}
                alt="Shop Avatar"
                className="shop-info-sidebar-avatar"
              />
              {isOwner && (
                <div className="shop-info-sidebar-avatar-edit">
                  <input type="file" id="avatar" className="shop-info-sidebar-avatar-input" onChange={handleImage} />
                  <label htmlFor="avatar" className="shop-info-sidebar-avatar-label">
                    <AiOutlineCamera />
                  </label>
                </div>
              )}
            </div>
            <h3 className="shop-info-sidebar-name">{data?.name}</h3>
            {data && data._id && currentProfile && (
              <div className="shop-info-sidebar-follow-section">
                
                <FollowButton className="profile-followers-add-button" connectionId={data?._id} connectionType="Shop" />
                
                <FollowersDisplay userId={data?._id} profileType="Shop" />
              </div>
            )}
          </div>

          

          {/* Bottom Row */}
          <div className="shop-info-sidebar-bottom-row">
            <h5>Share Shop</h5>
            <ShareToSocialMedia 
              url={window.location.href} 
              message={`Check out this shop: ${data.name}`}
            />
          </div>

          {isOwner && (
            <div className="shop-info-sidebar-actions">
              <Link to="/settings" className="shop-info-sidebar-action-button">
                Edit Shop
              </Link>
              <div className="shop-info-sidebar-action-button" onClick={logoutHandler}>
                Log Out
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default ShopInfo;
