import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { PersonAddOutlined, PersonRemoveOutlined } from "@mui/icons-material";
import { followUnfollow, fetchIsFollowing, fetchConnections  } from "../../redux/actions/connections";

const FollowButton = ({ connectionId, connectionType }) => {
    const dispatch = useDispatch();

    // Selectors for the current user's profile and connection state
    const currentProfile = useSelector((state) => state.user.currentProfile);
    const { user } = useSelector((state) => state.user);
    const { seller } = useSelector((state) => state.seller);

    // Track `isFollowing` status for each connectionId individually
    const isFollowing = useSelector(state => state.connections.isFollowingById[connectionId]);

    // Determine the ID and type of the current profile
    const currentProfileId = currentProfile === 'User' ? user?._id : seller?._id;
    const currentProfileType = currentProfile === 'User' ? 'User' : 'Shop';

    // Effect to check if the current user is following the viewed profile
    useEffect(() => {
        if (currentProfileId && connectionId) {
            // Check if current profile is following the target profile
            dispatch(fetchIsFollowing(currentProfileId, connectionId, currentProfileType, connectionType));
        }
    }, [dispatch, currentProfileId, connectionId, currentProfileType, connectionType]);

    const handleFollowUnfollow = async () => {
        // Trigger follow/unfollow action
        dispatch(followUnfollow(connectionId, currentProfileType, connectionType)).then(() => {
            // Re-fetch follow status after follow/unfollow operation
            dispatch(fetchIsFollowing(currentProfileId, connectionId, currentProfileType, connectionType));
            dispatch(fetchConnections(connectionId, connectionType, 'followers'));
        });
    };

    return (
        <button 
            onClick={handleFollowUnfollow} 
            className={`add-friend-button-button ${isFollowing ? 'following' : 'follow'}`}
        >
            {isFollowing ? (
                <>
                    <PersonRemoveOutlined className="icon" />
                    Following
                </>
            ) : (
                <>
                    <PersonAddOutlined className="icon" />
                    Follow
                </>
            )}
        </button>
    );
};

export default FollowButton;




