import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { server } from "../../server";

const SponsoredProducts = () => {
    const [sponsoredProducts, setSponsoredProducts] = useState([]);
    const adRefs = useRef(new Map()); // To store ad refs

    const logAdClick = async (adId) => {
        try {
            await axios.post(`${server}/sponsored/click/${adId}`, { withCredentials: true, 
                headers: {
                    'current-profile': sessionStorage.getItem('currentProfile') || 'defaultProfile',
                }});
            console.log('Click logged for ad:', adId);
        } catch (error) {
            console.error('Error logging click:', error);
        }
    };

    const logImpression = async (adId) => {
        try {
            await axios.post(`${server}/sponsored/impression/${adId}`, {}, { withCredentials: true, 
                headers: {
                    'current-profile': sessionStorage.getItem('currentProfile') || 'defaultProfile',
                }});
            console.log('Impression logged for ad:', adId);
        } catch (error) {
            console.error('Error logging impression:', error);
        }
    };

    const logViewedImpression = async (adId) => {
        try {
            await axios.post(`${server}/sponsored/viewed-impression/${adId}`, {}, { withCredentials: true, 
                headers: {
                    'current-profile': sessionStorage.getItem('currentProfile') || 'defaultProfile',
                }});
            console.log('Viewed impression logged for ad:', adId);
        } catch (error) {
            console.error('Error logging viewed impression:', error);
        }
    };

    useEffect(() => {
        const fetchSponsoredProducts = async () => {
            try {
                const response = await axios.get(`${server}/sponsored/sponsored-products`, { withCredentials: true });
                console.log('Fetched sponsored products:', response.data);
                // Filter out inactive ads before setting the state
                const activeSponsoredProducts = response.data.filter(ad => ad.isActive === true && ad.product !== undefined);
                console.log('Filtered active sponsored products:', activeSponsoredProducts);
                setSponsoredProducts(activeSponsoredProducts); // Set state with only active ads
            } catch (error) {
                console.error('Error fetching sponsored products:', error);
            }
        };
        fetchSponsoredProducts();
    }, []);

    useEffect(() => {
        sponsoredProducts.forEach(({ _id: adId }) => {
            logImpression(adId); 
        });
    }, [sponsoredProducts]);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        const adId = entry.target.dataset.adId;

                        // Start a timer for 1 second to log viewed impression
                        const timer = setTimeout(() => {
                            logViewedImpression(adId);
                        }, 1000);

                        // Store the timer in the DOM element
                        entry.target._timer = timer;
                    } else {
                        // Clear the timer if the element goes out of view before 1 second
                        clearTimeout(entry.target._timer);
                    }
                });
            },
            { threshold: 0.5 }
        );

        sponsoredProducts.forEach(({ _id: adId }) => {
            const adElement = adRefs.current.get(adId);
            if (adElement) {
                observer.observe(adElement);
            }
        });

        return () => {
            observer.disconnect();
        };
    }, [sponsoredProducts]);

    return (
        <div className="sponsored-products-component-container">
            <h2 className="sponsored-products-component-title">Sponsored Products</h2>
            <div className="sponsored-products-component-list">
                {sponsoredProducts.map(({ _id: adId, product, adSet }) => (
                    <div
                        key={adId}
                        className="sponsored-products-component-item"
                        data-ad-id={adId}
                        ref={(el) => el && adRefs.current.set(adId, el)} 
                    >
                        <div className="sponsored-products-component-image-wrapper">
                            <img
                                src={adSet.images["Sponsored Products"]}
                                alt={product.name}
                                className="sponsored-products-component-image"
                                style={{
                                    transform: `translate(${adSet.positions["Sponsored Products"]?.x || 0}px, ${adSet.positions["Sponsored Products"]?.y || 0}px)`,
                                }}
                            />
                        </div>
                        <Link to={`/product/${product._id}`} onClick={() => logAdClick(adId)} className="sponsored-products-component-link">
                            <div className="sponsored-products-component-info">
                                <div>
                                    <h3 className="sponsored-products-component-product-name">{product.name}</h3>
                                    <p className="sponsored-products-component-product-price">${product.discountPrice || product.originalPrice}</p>
                                </div>
                                {product.handle && (
                                    <Link to={`/shop/${product.handle}`} className="sponsored-products-component-visit-store">Visit Store</Link>
                                )}
                            </div>
                        </Link>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default SponsoredProducts;






