import React from 'react';
import { Link } from 'react-router-dom';
import FollowButton from '../Connections/FollowButton';

const FriendCard = ({ friend, profileType, friendId }) => {
    return (
        <div className="friend-card">
            <Link to={`/profile/${friend._id}`} className="friend-card-link">
                <div className="friend-card-image">
                    <img src={friend.avatar.url} alt={friend.name} />
                </div>
                <div className="friend-card-content">
                    <h4 className="friend-card-name">{friend.name}</h4>
                </div>
            </Link>
            <div className="friend-card-actions">
                 <FollowButton className="profile-followers-add-button" connectionId={friendId} connectionType={profileType} />
            </div>
        </div>
    );
};

export default FriendCard;

