import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  ConnectAccountOnboarding,
  ConnectComponentsProvider,
} from "@stripe/react-connect-js";
import { useStripeConnect } from "../../useStripeConnect";
import { loadSeller } from "../../redux/actions/user";

export default function ShopPaymentSettingsPage() {
  const [error, setError] = useState(false);
  const { seller } = useSelector(state => state.seller); 
  const connectedAccountId = seller?.stripeAccountId;
  const stripeConnectInstance = useStripeConnect(connectedAccountId);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadSeller());
  }, [dispatch]);


return (
  <div className="shop-onboarding-container">
    <div className="shop-onboarding-banner">
    <h2>{seller?.name || "Seller"}</h2>
    </div>
    <div className="shop-onboarding-content">
      {!stripeConnectInstance && (
        <h2>Add information to start accepting money</h2>
      )}
      {error && (
        <p className="shop-onboarding-error">
          Something went wrong, please try again!
        </p>
      )}
      {stripeConnectInstance && (
        <ConnectComponentsProvider connectInstance={stripeConnectInstance}>
          <div className="shop-onboarding-onboarding-container">
            <ConnectAccountOnboarding
              onExit={() => {
                console.log("Onboarding process has exited.");
              }}
            />
          </div>
        </ConnectComponentsProvider>
      )}
    </div>
  </div>
);
}


