import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { server } from '../server'; 
import Header from '../components/Layout/Header';
import Sidebar from '../components/Layout/Sidebar';
import UserBanner from '../components/Shop/UserBanner';
import AddFriendButton from '../components/AddFriendButton';
import { useParams } from 'react-router-dom';
import { useSelector } from "react-redux";
import UserProfileData from '../components/UserProfileData';
import SponsoredProducts from '../components/Sponsored/SponsoredProducts';
import FollowersAvatarDisplay from '../components/Followers/FollowersAvatarDisplay';
import SponsoredBrands from '../components/Sponsored/SponsoredBrands';
import MyImages from '../components/Content/MyImages';
import MyEvents from '../components/Events/MyEvents';
import MyContentCarousel from '../components/Profile/MyContentCarousel';
import MemeCreator from '../components/Meme/MemeCreator';
import FollowButton from '../components/Connections/FollowButton';
import FollowersDisplay from '../components/Connections/FollowersDisplay';


const PublicProfilePage = () => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const { id } = useParams();
  const picturePath = user?.picturePath;
  const currentProfile = useSelector((state) => state.user.currentProfile);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get(`${server}/user/user-info/${id}`);
        setUser(response.data.user);
      } catch (error) {
        console.error('Error fetching user data:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchUserData();
  }, [id]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!user) {
    return <div>User data not available</div>;
  }

  return (
    <div className="profile-page">
      <Header />
      <Sidebar />
      <UserBanner isUser={false} />
      <div className="profile-header"></div>
      <div className="profile-main">
      <div className="profile-extra-container">
  <img
    src="https://res.cloudinary.com/dzlopmfj8/image/upload/v1723499236/Crypto-Casino2_sem2np.jpg"
    alt="Crypto Casino"
    className="profile-extra-image"
  />
</div>
  <div className="profile-info-container">
    <div className="profile-avatar-wrapper">
      <img
        src={user.avatar?.url || 'default-avatar.png'}
        alt={user.name}
        className="profile-avatar"
      />
      <div className="profile-info">
        <div className="profile-name-wrapper">
          <h3 className="profile-name">{user.name}</h3>
          <h3 className="profile-handle">@{user.handle}</h3>
  
  {/* Log the userId and profileType before rendering FollowersAvatarDisplay */}
  {console.log("Rendering FollowersAvatarDisplay with userId:", id, "and profileType: User")}
  
  
  <FollowersDisplay userId={id} profileType="User" />
  
  {/* Log the friendId before rendering AddFriendButton */}
  {console.log("Rendering AddFriendButton with friendId:", id, "and friendType: User")}
  
  
  <FollowButton className="profile-followers-add-button" connectionId={id} connectionType="User" />
        </div>
      </div>
    </div>
    
  </div>

  

  
  <div className="profile-mycontent-carousel">
    <MyContentCarousel />
  </div>
</div>

  
      <div className="profile-main-content">
        <div className="profile-sidebar-left">
          <MyImages userId={id} />
          <MyEvents />
        </div>
        <main className="profile-main-area">
          <UserProfileData />
        </main>
        <aside className="profile-sidebar-right">
          <SponsoredProducts />
          <SponsoredBrands />
        </aside>
      </div>
    </div>
  );
};

export default PublicProfilePage;
