import React, { Fragment, useState, useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/20/solid';
import { useSelector, useDispatch } from 'react-redux';
import { loadSeller } from "../../redux/actions/user";
import { fetchMyConnections } from "../../redux/actions/connections";
import OtherFollowers from './OtherFollowers';
import OtherFollowing from './OtherFollowing';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function ConnectionsPanel() {
    const [open, setOpen] = useState(true);
    const [activeTab, setActiveTab] = useState('Followers');
    const dispatch = useDispatch();
    const currentProfile = useSelector((state) => state.user.currentProfile);
    const { user } = useSelector((state) => state.user);
    const seller = useSelector((state) => state.seller.activeSeller);
    const profileId = currentProfile === 'User' ? user?._id : seller?._id;
    const profileType = currentProfile === 'User' ? 'User' : 'Shop';

    console.log('Seller State:', seller);
  
    // Select followers and following from the state
    const { myFollowers, myFollowing, isLoading, error } = useSelector((state) => state.connections);
  
    // Fetch followers or following when the tab changes
    useEffect(() => {
      if (profileId && profileType) {
        if (activeTab === 'Followers') {
          dispatch(fetchMyConnections(profileId, profileType, 'followers')); // Fetch my followers
        } else if (activeTab === 'Following') {
          dispatch(fetchMyConnections(profileId, profileType, 'following')); // Fetch my following
        }
      }
    }, [dispatch, profileId, profileType, activeTab, currentProfile]);
  
    const tabs = [
      { name: 'Followers', current: activeTab === 'Followers' },
      { name: 'Following', current: activeTab === 'Following' },
    ];
  
    return (
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="followers-following-panel-container" onClose={setOpen}>
          <div className="followers-following-panel-overlay" />
          <div className="followers-following-panel-wrapper">
            <div className="followers-following-panel-inner">
              <div className="followers-following-panel-content">
                <Transition.Child
                  as={Fragment}
                  enter="followers-following-panel-enter"
                  enterFrom="followers-following-panel-enter-from"
                  enterTo="followers-following-panel-enter-to"
                  leave="followers-following-panel-leave"
                  leaveFrom="followers-following-panel-leave-from"
                  leaveTo="followers-following-panel-leave-to"
                >
                  <Dialog.Panel className="followers-following-panel-panel">
                    <div className="followers-following-panel-body">
                      <div className="followers-following-panel-header">
                        <Dialog.Title className="followers-following-panel-title">Social Connections</Dialog.Title>
                        <div className="followers-following-panel-close-button-wrapper">
                          <button
                            type="button"
                            className="followers-following-panel-close-button"
                            onClick={() => setOpen(false)}
                          >
                            <XMarkIcon className="followers-following-panel-icon" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                      <div className="followers-following-panel-tabs">
                        <nav className="followers-following-panel-tabs-nav" aria-label="Tabs">
                          {tabs.map((tab) => (
                            <a
                              key={tab.name}
                              href="#"
                              className={classNames(
                                tab.current ? 'followers-following-panel-tab-active' : 'followers-following-panel-tab-inactive',
                                'followers-following-panel-tab'
                              )}
                              aria-current={tab.current ? 'page' : undefined}
                              onClick={() => setActiveTab(tab.name)}
                            >
                              {tab.name}
                            </a>
                          ))}
                        </nav>
                      </div>
                    </div>
                    {activeTab === 'Followers' && (
                      <OtherFollowers
                        followers={myFollowers}
                        isLoading={isLoading}
                        error={error}
                      />
                    )}
                    {activeTab === 'Following' && (
                      <OtherFollowing
                        following={myFollowing}
                        isLoading={isLoading}
                        error={error}
                      />
                    )}
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    );
  }
  