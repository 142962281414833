import React, { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

const DropDown = ({ categoriesData, setDropDown }) => {
  const navigate = useNavigate();
  const dropdownRef = useRef();

  const featureProducts = [
    {
      title: 'New Street Collection',
      image_Url: 'https://res.cloudinary.com/dzlopmfj8/image/upload/v1718108735/avatars/i14hrbyt7yfyv2ecdoh8.jpg',
      shopName: 'Dad Grass',
    },
    {
      title: 'Yellow Jumpsuit',
      image_Url: 'https://res.cloudinary.com/dzlopmfj8/image/upload/v1717859858/brand_catalog_items/ss9lwdhmmwkho9buw7gx.jpg',
      shopName: 'Crystal',
    },
  ];

  const featuredShop = {
    name: 'Amsterdam style',
    imageUrl: 'https://res.cloudinary.com/dzlopmfj8/image/upload/v1718108735/banners/hd2iauw5ydeyhpgbsg10.jpg',
  };

  const submitHandle = (i) => {
    navigate(`/products/${i.title}`);
    setDropDown(false);
    window.location.reload();
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropDown(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [setDropDown]);

  return (
    <div ref={dropdownRef} className="categories-dropdown-header-container">
      <div className="categories-dropdown-header-inner">
        <div className="categories-dropdown-header-section categories-dropdown-header-categories">
          <h3 className="categories-dropdown-header-title">Categories</h3>
          {categoriesData.map((category, index) => (
            <div key={index} className="categories-dropdown-header-item" onClick={() => submitHandle(category)}>
              <img src={category.image_Url} alt="" className="categories-dropdown-header-item-image" />
              <span className="categories-dropdown-header-item-text">{category.title}</span>
            </div>
          ))}
        </div>
        <div className="categories-dropdown-header-section categories-dropdown-header-feature-products">
          <h3 className="categories-dropdown-header-title">Featured Products</h3>
          {featureProducts.map((product, index) => (
            <div key={index} className="categories-dropdown-header-product">
              <img src={product.image_Url} alt="" className="categories-dropdown-header-product-image" />
              <div>
                <span className="categories-dropdown-header-product-title">{product.title}</span>
                <div className="categories-dropdown-header-product-shop">{product.shopName}</div>
              </div>
            </div>
          ))}
          <div className="categories-dropdown-header-featured-shop">
            <h3 className="categories-dropdown-header-title">Featured Shop</h3>
            <div className="categories-dropdown-header-featured-shop-inner">
              <img src={featuredShop.imageUrl} alt={featuredShop.name} className="categories-dropdown-header-featured-shop-image" />
              <div className="categories-dropdown-header-featured-shop-overlay">
                <span className="categories-dropdown-header-featured-shop-text">{featuredShop.name}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DropDown;

