import React, { Fragment, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon, PlusIcon, CurrencyDollarIcon, ChevronDownIcon, ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline';
import { useSelector, useDispatch } from 'react-redux';
import { setWalletSidebarOpen } from '../state/uiSlice';

export default function CryptoWalletBuySidebar() {
  const isWalletSidebarOpen = useSelector((state) => state.ui.isWalletSidebarOpen);
  const dispatch = useDispatch();

  const userProfile = useSelector((state) => state.user.user);
  const sellerProfiles = useSelector((state) => state.seller.sellers);
  const currentProfile = useSelector((state) => state.user.currentProfile);
  const userSubscriptions = useSelector(state => state.user.subscriptions || []);
  const sellerSubscriptions = useSelector(state => state.seller.subscriptions || []);

  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [selectedFrom, setSelectedFrom] = useState(''); // State to store selected value

  const profile = currentProfile === 'User' ? userProfile : sellerProfiles.find(seller => seller.name === currentProfile);
  const avatarUrl = profile?.avatar?.url;
  const profileName = profile?.name || currentProfile;

  const getSubscriptionImageUrl = () => {
    let maxTier = 0;

    if (currentProfile === 'User' && userSubscriptions.length) {
      maxTier = userSubscriptions.reduce((max, sub) => {
        return sub.tier === "Super OG" ? 3 : (sub.tier === "OG" ? 2 : max);
      }, 0);
    } else if (sellerSubscriptions.length) {
      maxTier = sellerSubscriptions.reduce((max, sub) => {
        return sub.tier === "Super OG Shop" ? 3 : (sub.tier === "OG Shop" ? 2 : max);
      }, 0);
    }

    switch (maxTier) {
      case 3:
        return 'https://res.cloudinary.com/dzlopmfj8/image/upload/v1715090157/super_og_youavf.svg';
      case 2:
        return 'https://res.cloudinary.com/dzlopmfj8/image/upload/v1715090152/og_ircw65.svg';
      default:
        return null;
    }
  };

  const subscriptionIconUrl = getSubscriptionImageUrl();

  const handleClose = () => {
    dispatch(setWalletSidebarOpen({ isOpen: false }));
  };

  const toggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  const handleSelect = (value) => {
    setSelectedFrom(value);
    setDropdownOpen(false); // Close the dropdown after selection
  };

  return (
    <Transition.Root show={isWalletSidebarOpen} as={Fragment}>
      <Dialog as="div" className="crypto-wallet-sidebar-dialog" onClose={handleClose}>
        <div className="crypto-wallet-sidebar-overlay" />

        <div className="crypto-wallet-sidebar-container">
          <Transition.Child
            as={Fragment}
            enter="crypto-wallet-sidebar-enter"
            enterFrom="crypto-wallet-sidebar-enterFrom"
            enterTo="crypto-wallet-sidebar-enterTo"
            leave="crypto-wallet-sidebar-leave"
            leaveFrom="crypto-wallet-sidebar-leaveFrom"
            leaveTo="crypto-wallet-sidebar-leaveTo"
          >
            <Dialog.Panel className="crypto-wallet-sidebar-panel">
              <div className="crypto-wallet-sidebar-inner">
                {/* Cover Image with Avatar and Titles */}
                <div className="crypto-wallet-cover-container">
                  <img
                    src="https://res.cloudinary.com/dzlopmfj8/image/upload/v1722588106/posts/wjqwxnil1hnh1ofqcfy5.webp"
                    alt="Cover"
                    className="crypto-wallet-cover-image"
                  />
                  <div className="crypto-wallet-avatar-container">
                    <img
                      src={avatarUrl || 'default-avatar-url'}
                      alt={`${currentProfile} Avatar`}
                      className="crypto-wallet-avatar"
                    />
                    {subscriptionIconUrl && (
                      <div className="crypto-wallet-subscription-icon">
                        <img src={subscriptionIconUrl} alt="Subscription Status" className="crypto-wallet-subscription-img" />
                      </div>
                    )}
                  </div>
                  <div className="crypto-wallet-avatar-info">
                    <h2 className="crypto-wallet-title">{profileName}</h2>
                    <h3 className="crypto-wallet-subtitle">User</h3>
                  </div>
                </div>


                {/* Buttons with Titles */}
                <div className="crypto-wallet-buttons-container">
                  <ChevronLeftIcon className="crypto-wallet-chevron-icon" aria-hidden="true" />
                  <div className="crypto-wallet-button-wrapper">
                    <button className="crypto-wallet-button wallet" onClick={() => dispatch(setWalletSidebarOpen({ isOpen: true, type: null }))}>
                      <img
                        src="https://res.cloudinary.com/dzlopmfj8/image/upload/v1723455786/wallet_logog_npmln6.png"
                        alt="Custom Wallet Icon"
                        className="crypto-wallet-button-icon"
                      />
                    </button>
                    <span className="crypto-wallet-button-title">Wallet</span>
                  </div>
                  <div className="crypto-wallet-button-wrapper">
                    <button className="crypto-wallet-button buy crypto-wallet-action-button-active" onClick={() => dispatch(setWalletSidebarOpen({ isOpen: true, type: 'buy' }))}>
                      <img
                        src="https://res.cloudinary.com/dzlopmfj8/image/upload/v1723455786/buy_logo_nlfx4t.png"
                        alt="Custom Wallet Icon"
                        className="crypto-wallet-button-icon"
                      />
                    </button>
                    <span className="crypto-wallet-button-title">Buy</span>
                  </div>
                  <div className="crypto-wallet-button-wrapper">
                    <button className="crypto-wallet-button sell" onClick={() => dispatch(setWalletSidebarOpen({ isOpen: true, type: 'sell' }))}>
                      <img
                        src="https://res.cloudinary.com/dzlopmfj8/image/upload/v1723455786/sell_logo_nb404k.png"
                        alt="Custom Wallet Icon"
                        className="crypto-wallet-button-icon"
                      />
                    </button>
                    <span className="crypto-wallet-button-title">Cash Out</span>
                  </div>
                  <div className="crypto-wallet-button-wrapper">
                    <button className="crypto-wallet-button pay" onClick={() => dispatch(setWalletSidebarOpen({ isOpen: true, type: 'pay' }))}>
                      <img
                        src="https://res.cloudinary.com/dzlopmfj8/image/upload/v1723455786/Pay_logo_a74bco.png"
                        alt="Custom Wallet Icon"
                        className="crypto-wallet-button-icon"
                      />
                    </button>
                    <span className="crypto-wallet-button-title">Pay</span>
                  </div>
                  <div className="crypto-wallet-button-wrapper">
                    <button className="crypto-wallet-button trade">
                      <img
                        src="https://res.cloudinary.com/dzlopmfj8/image/upload/v1723455786/trade_logo_zfh39o.png"
                        alt="Custom Wallet Icon"
                        className="crypto-wallet-button-icon"
                      />
                    </button>
                    <span className="crypto-wallet-button-title">Trade</span>
                  </div>
                  <ChevronRightIcon className="crypto-wallet-chevron-icon" aria-hidden="true" />
                </div>

                {/* Section Title with Icon */}
                <div className="crypto-wallet-section-title">
                  <div className="crypto-wallet-section-icon-buy">
                    <img
                      src="https://res.cloudinary.com/dzlopmfj8/image/upload/v1723455786/buy_logo_nlfx4t.png"
                      alt="Buy"
                      className="crypto-wallet-section-icon-img"
                    />
                  </div>
                  <span className="crypto-wallet-section-text">Buy</span>
                </div>

                {/* Form */}
                <div className="crypto-wallet-form-container">
                  <form className="crypto-wallet-form">
                    <div className="crypto-wallet-input-group crypto-wallet-input-group-amount">
                      <label className="crypto-wallet-label" htmlFor="amount">Amount</label>
                      <div className="crypto-wallet-input-wrapper">
                        <input
                          type="text"
                          id="amount"
                          placeholder=""
                          className="crypto-wallet-input"
                        />
                        <span className="crypto-wallet-currency">USD</span>
                      </div>
                    </div>
                    <div className="crypto-wallet-input-group crypto-wallet-input-group-from">
                      <label className="crypto-wallet-label" htmlFor="from">From</label>
                      <div className="crypto-wallet-input-wrapper">
                        <input
                          type="text"
                          id="from"
                          placeholder=""
                          className="crypto-wallet-input"
                          value={selectedFrom} // Bind the selected value to this field
                          readOnly
                        />
                        <div className="crypto-wallet-chevron-wrapper" onClick={toggleDropdown}>
                          <div className="crypto-wallet-vertical-line"></div>
                          <ChevronDownIcon className="crypto-wallet-chevron-icon" aria-hidden="true" />
                        </div>
                      </div>
                      {isDropdownOpen && (
                        <div className="crypto-wallet-dropdown-menu">
                          <div className="crypto-wallet-dropdown-item" onClick={() => handleSelect('Bank account - ACH')}>
                            <CurrencyDollarIcon className="crypto-wallet-dropdown-icon" />
                            Bank account - ACH
                          </div>
                          <div className="crypto-wallet-dropdown-item" onClick={() => handleSelect('Crypto account - BTC')}>
                            <img src="https://res.cloudinary.com/dzlopmfj8/image/upload/v1723492442/bitcoin_qjlxyb.png" alt="BTC Icon" className="crypto-wallet-dropdown-icon" />
                            Crypto account - BTC
                          </div>
                          <div className="crypto-wallet-dropdown-item" onClick={() => handleSelect('Crypto account - ETH')}>
                            <img src="https://res.cloudinary.com/dzlopmfj8/image/upload/v1723492452/ethereum_spl8t3.png" alt="ETH Icon" className="crypto-wallet-dropdown-icon" />
                            Crypto account - ETH
                          </div>
                          <div className="crypto-wallet-dropdown-item" onClick={() => handleSelect('Add bank account')}>
                            <PlusIcon className="crypto-wallet-dropdown-icon" />
                            Add bank account
                          </div>
                          <div className="crypto-wallet-dropdown-item" onClick={() => handleSelect('Add crypto account')}>
                            <PlusIcon className="crypto-wallet-dropdown-icon" />
                            Add crypto account
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="crypto-wallet-input-group crypto-wallet-input-group-pay-with">
                      <label className="crypto-wallet-label" htmlFor="pay-with">Pay With</label>
                      <div className="crypto-wallet-input-wrapper">
                        <input
                          type="text"
                          id="pay-with"
                          placeholder="Funding Account"
                          className="crypto-wallet-input crypto-wallet-input-placeholder-left"
                        />
                        <div className="crypto-wallet-vertical-line"></div>
                        <span className="crypto-wallet-placeholder-balance">$50,000</span>
                      </div>
                    </div>
                    <div className="crypto-wallet-input-group">
                      <label className="crypto-wallet-label" htmlFor="note">Note</label>
                      <input
                        type="text"
                        id="note"
                        placeholder=""
                        className="crypto-wallet-input crypto-wallet-input-note"
                      />
                    </div>
                  </form>
                </div>

                {/* Text Fields and Divider with Dollar Values */}
                <div className="crypto-wallet-summary">
                  <div className="crypto-wallet-summary-row">
                    <div className="crypto-wallet-summary-item">Price</div>
                    <div className="crypto-wallet-summary-value">$0.00</div>
                  </div>
                  <div className="crypto-wallet-summary-row">
                    <div className="crypto-wallet-summary-item">Fees</div>
                    <div className="crypto-wallet-summary-value">$0.00</div>
                  </div>
                  <hr className="crypto-wallet-divider" />
                  <div className="crypto-wallet-summary-row">
                    <div className="crypto-wallet-summary-item">Total</div>
                    <div className="crypto-wallet-summary-value">$0.00</div>
                  </div>
                </div>

                {/* Action Buttons */}
                <div className="crypto-wallet-actions">
                  <button className="crypto-wallet-action-button cancel">Cancel</button>
                  <button className="crypto-wallet-action-button buy">Buy</button>
                </div>

                {/* Wallet Section at the Bottom */}
                <div className="crypto-wallet-section-title crypto-wallet-bottom-bar">
                  <div className="crypto-wallet-section-icon crypto-wallet-bottom-icon">
                    <img
                      src="https://res.cloudinary.com/dzlopmfj8/image/upload/v1723455786/wallet_logog_npmln6.png"
                      alt="Wallet"
                      className="crypto-wallet-section-icon-img"
                    />
                  </div>
                  <span className="crypto-wallet-section-text">Wallet</span>
                </div>

              </div>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
