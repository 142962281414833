import React from 'react'
import DashboardHeader from '../../components/Shop/Layout/DashboardHeader'
import DashboardSideBar from '../../components/Shop/Layout/DashboardSideBar'
import CreateProduct from "../../components/Shop/CreateProduct";
import BulkProductUpload from "../../components/Shop/BulkProductUpload";


const ShopCreateProduct = () => {
  return (
    <div>
        <DashboardHeader />
        <div className="flex items-start w-full">
            <div className="w-[80px] 800px:w-[330px]">
              <DashboardSideBar active={4} />
            </div>
            <div className="w-full flex flex-col items-center">
              <div className="border-b border-gray-200 p-4 sm:flex sm:items-center sm:justify-between w-full">
                <h3 className="text-lg font-bold leading-7 text-white">Create Products</h3>
                <div className="mt-3 sm:ml-4 sm:mt-0">
                <BulkProductUpload />
                </div>
              </div>
              <CreateProduct />
            </div>
        </div>
    </div>
  );
};

export default ShopCreateProduct