import React, { useState } from 'react';
import BrandCreate from '../components/Brands/CreateBrand';
import DashboardSideBar from '../components/Shop/Layout/DashboardSideBar';
import BrandSelection from '../components/Brands/BrandSelection';
import DashboardHeader from '../components/Shop/Layout/DashboardHeader';


const BrandPage = () => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <DashboardHeader />

      <div className="brand-page-container">
        <div className="brand-page-sidebar">
          <DashboardSideBar active={13} />
        </div>
        <div className="brand-page-main-container">
          <div className="brand-page-grid-container">
            <div>
              <button className="brand-page-button" onClick={handleOpen}>
                Create New Brand
              </button>
              {open && (
                <>
                  <div className="brand-page-modal-overlay" onClick={handleClose}></div>
                  <div
                    className="brand-page-modal"
                    role="dialog"
                    aria-labelledby="create-brand-modal-title"
                    aria-describedby="create-brand-modal-description"
                  >
                    <div className="brand-page-modal-header">
                      <h2 id="create-brand-modal-title" className="brand-page-modal-title">
                        Create a New Brand
                      </h2>
                      <button className="brand-page-modal-close-button" onClick={handleClose}>
                        &times;
                      </button>
                    </div>
                    <BrandCreate />
                  </div>
                </>
              )}
            </div>
            <div>
              <BrandSelection />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BrandPage;
