import React from 'react'
import Header from "../components/Layout/Header";
import Sidebar from '../components/Layout/Sidebar';
import EventDetail from '../components/Events/EventDetail';
import { Link } from 'react-router-dom';


const EventDetailsPage = () => {
  return (
    <div>
        <Header activeHeading={1} />
        <Sidebar />
        <Link to="/events" style={{
            display: 'inline-block',  
            margin: '10px auto',      
            padding: '10px 20px',     
            textAlign: 'center',
            backgroundColor: '#4CAF50',  
            color: 'white',           
            borderRadius: '5px',     
            textDecoration: 'none',   
            boxShadow: '0 2px 4px rgba(0,0,0,0.2)',  
            transition: 'background-color 0.3s', 
            cursor: 'pointer'        
        }}>Back to Events List</Link>

        <EventDetail />
        
    </div>
  )
}

export default EventDetailsPage