import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { server } from "../../server";

const ShopBySeller = () => {
  const [sellers, setSellers] = useState([]);

  useEffect(() => {
    const fetchSellers = async () => {
      try {
        const response = await axios.get(`${server}/shop/all-sellers`, { withCredentials: true,});
        setSellers(response.data.sellers);
      } catch (error) {
        console.error('Error fetching sellers:', error);
      }
    };
    fetchSellers();
  }, []);

  
  return (
    <div>
      <div className="shop-list">
        {sellers.map(seller => (
          <a href={`https://www.gamechip.ai/shop/${seller.handle}`} key={seller._id} className="shop-card">
            <img src={seller.avatar.url} alt={seller.name} />
            <h3>{seller.name}</h3>
            {/* Include other details such as rating, etc. */}
          </a>
        ))}
      </div>
    </div>
  );
};

export default ShopBySeller;
