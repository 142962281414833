import React from 'react';
import styles from '../../styles/styles';
import ShopInfo from "../../components/Shop/ShopInfo";
import ShopProfileData from "../../components/Shop/ShopProfileData";
import ShopBanner from '../../components/Shop/ShopBanner';
import Header from '../../components/Layout/Header';
import { useSelector } from 'react-redux';
import AdvertWidget from '../../widgets/AdvertWidget';
import Sidebar from '../../components/Layout/Sidebar';
import SponsoredProducts from '../../components/Sponsored/SponsoredProducts';

const ShopPreviewPage = () => {

  const { user } = useSelector((state) => state.user);
  const picturePath = user?.picturePath;
  const _id = user?._id;

  return (
    <div>
        <Header />
        <Sidebar />
        <div className="w-full">
        <ShopBanner />
      </div>
    

    {/* Flex container with column direction on small screens and row direction on larger screens */}
    <div className="profile-main-content">

{/* Left column area, shown on top on small screens */}
<aside className="profile-sidebar-left">
  <ShopInfo isOwner={false} />
</aside>

{/* Main area */}
<main className="profile-main-area">
  <ShopProfileData isOwner={false} />
</main>

{/* Right column area, shown below on small screens */}
<aside className="profile-sidebar-right">
  <SponsoredProducts />
</aside>

</div>
</div>
  )
}

export default ShopPreviewPage;
