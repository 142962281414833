import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import Footer from "../components/Layout/Footer";
import Header from "../components/Layout/Header";
import Loader from "../components/Layout/Loader";
import ProductCard from "../components/Route/ProductCard/ProductCard";
import styles from "../styles/styles";
import Sidebar from '../components/Layout/Sidebar';
import HeroAllProducts from "../components/Route/Hero/HeroAllProducts";
import CategorySection from "../components/Shop/CategorySection"
import FeaturedShop from "../components/Shop/FeaturedShop"
import SplitImagePromo from "../components/Shop/SplitImagePromo"
import ThreeTileGrid from "../components/Shop/ThreeTileGrid";
import ShopBySeller from "../components/Shop/ShopBySeller";
import HeroSlider from "../components/Route/Hero/HeroSlider";
import TrendingProductsCarosel from "../components/Products/TrendingProductsCarosel";
import ShopByBrand from "../components/Brands/ShopByBrandCarosel";
import Carousel4Slides from "../components/Carousel4Slides";
import SponsoredProductCarousel from "../components/Sponsored/SponsoredProductsCarousel";
import HeroSliderMarketplace from "../components/Route/Hero/HeroSliderMarketplace";
import RetailCategorySection from "../components/Marketplace/RetailCategorySection";


const ProductsPage = () => {
  const [searchParams] = useSearchParams();
  const categoryData = searchParams.get("category");
  const { allProducts, isLoading } = useSelector((state) => state.products);
  const [data, setData] = useState([]);

  useEffect(() => {
    let filteredProducts = allProducts;

    // Filter for wholesale products
    if (filteredProducts) {
      filteredProducts = filteredProducts.filter(product => product.productType.includes('retail'));
    }
    
    // Further filter by category if necessary
    if (categoryData) {
      filteredProducts = filteredProducts.filter(product => product.category === categoryData);
    }

    setData(filteredProducts);
  }, [allProducts, categoryData]);

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div>
          <Header activeHeading={3} />
          <Sidebar />
          <div className="marketplace-page-content">
          <HeroSliderMarketplace />
          <ShopBySeller />
          <CategorySection />
          <RetailCategorySection />
          <TrendingProductsCarosel />
          <Carousel4Slides />
          <ShopByBrand />
          <br />
          <br />
          <div className={`${styles.section}`}>
            <div className="grid grid-cols-1 gap-[20px] md:grid-cols-2 md:gap-[25px] lg:grid-cols-4 lg:gap-[25px] xl:grid-cols-5 xl:gap-[30px] mb-12">
              {data && data.map((product, index) => <ProductCard data={product} key={index} />)}
            </div>
            {data && data.length === 0 ? (
              <h1 className="text-center w-full pb-[100px] text-[20px]">
                No retail products found!
              </h1>
            ) : null}
          </div>
          <FeaturedShop />
          <ShopBySeller />
          <ThreeTileGrid />
          <SplitImagePromo />
          <Footer />
          </div>
        </div>
      )}
    </>
  );
};

export default ProductsPage;
