import React from 'react';

const ProgressIndicator = ({ currentStep, totalSteps }) => {
    const steps = Array.from({ length: totalSteps }, (_, index) => index + 1);

    return (
        <div className="progress-indicator">
            {steps.map(step => (
                <span
                    key={step}
                    className={`progress-dot ${step === currentStep ? 'active' : ''}`}
                />
            ))}
        </div>
    );
};

export default ProgressIndicator;
