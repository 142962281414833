import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { server } from "../../server";
import Loader from "../Layout/Loader";
import { AiOutlineCamera } from "react-icons/ai";
import { loadSeller } from "../../redux/actions/user";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import DraggableImage from "../../components/Sponsored/DraggableImage";

const ShopBanner = ({ isOwner }) => {
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const { handle } = useParams();
  const [banner, setBanner] = useState(null);
  const [bannerPosition, setBannerPosition] = useState({ x: 0, y: 0 });
  const [showSaveButton, setShowSaveButton] = useState(false);
  const currentProfile = sessionStorage.getItem("currentProfile");

  const dispatch = useDispatch();

  useEffect(() => {
    setIsLoading(true);
    axios
      .get(`${server}/shop/handle-to-id/${handle}`) // Resolve handle to ID
      .then((res) => {
        const id = res.data.id;
        return axios.get(`${server}/shop/get-shop-info/${id}`); // Fetch shop info using ID
      })
      .then((res) => {
        setData(res.data.shop);
        setBannerPosition(res.data.shop.bannerPosition || { x: 0, y: 0 });
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  }, [handle, dispatch]);

  const handleBannerChange = async (e) => {
    const reader = new FileReader();

    reader.onload = () => {
      if (reader.readyState === 2) {
        setBanner(reader.result);
        setShowSaveButton(true); // Show save button after image is uploaded
      }
    };
    reader.readAsDataURL(e.target.files[0]);
  };

  const handleSaveBanner = async () => {
    try {
      await axios.put(
        `${server}/shop/update-shop-banner`,
        { banner, bannerPosition }, // Send both banner image and position
        {
          withCredentials: true,
          headers: { "Current-Profile": currentProfile },
        }
      );
      dispatch(loadSeller());
      toast.success("Banner updated successfully!");
      setShowSaveButton(false); // Hide save button after saving
      window.location.reload();
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className="shop-banner-container">
      {showSaveButton ? (
        <DraggableImage
          src={banner}
          initialPosition={bannerPosition}
          onPositionChange={setBannerPosition} // Update position state when banner is moved
        />
      ) : (
        <img
          src={banner || `${data.banner?.url}`}
          alt="Shop Banner"
          className="shop-banner-image"
          style={{
            transform: `translate(${bannerPosition.x}px, ${bannerPosition.y}px)`,
          }}
        />
      )}
      {isOwner && (
        <div className="shop-banner-controls">
          {!showSaveButton && (
            <div className="shop-banner-edit-icon">
              <input
                type="file"
                id="banner"
                className="shop-banner-file-input"
                onChange={handleBannerChange}
              />
              <label htmlFor="banner">
                <AiOutlineCamera className="shop-banner-camera-icon" />
              </label>
            </div>
          )}
          {showSaveButton && (
            <button
              className="shop-banner-save-button"
              onClick={handleSaveBanner}
            >
              Save
            </button>
          )}
        </div>
      )}
    </div>
  );
};

export default ShopBanner;
