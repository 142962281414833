import React from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { server } from "../../server";

const AgeVerification = ({ onVerified }) => {
    const handleVerification = async () => {
        try {
            await axios.post(
                `${server}/ageverification/setAgeVerified`,
                {}, 
                { withCredentials: true } 
            );
            onVerified(); 
        } catch (error) {
            console.error('Error verifying age:', error);
        }
    };

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100vh', 
            color: 'white',
            backgroundColor: 'black', 
            textAlign: 'center', 
            fontFamily: 'Arial, sans-serif',
        }}>
            <h1 style={{ marginBottom: '2rem' }}>Are you 21 or older?</h1>
            <button onClick={handleVerification} style={{
                padding: '1rem 2rem',
                fontSize: '1rem',
                color: 'white',
                backgroundColor: 'green',
                border: 'none',
                borderRadius: '5px',
                cursor: 'pointer',
                transition: 'background-color 0.3s', // Smooth transition for hover effect
            }}>
                Yes, I am 21 or older
            </button>
        </div>
    );
    
};

AgeVerification.propTypes = {
    onVerified: PropTypes.func.isRequired
};

export default AgeVerification;
