import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { server } from "../../server";

const DualProductAdCarousel = () => {
    const [productPairs, setProductPairs] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [currentIndex, setCurrentIndex] = useState(0);
    const adRefs = useRef(new Map());  // Store refs to each ad element

    // Function to log viewed impressions
    const logViewedImpression = async (adId) => {
        try {
            await axios.post(`${server}/sponsored/viewed-impression/${adId}`, {}, {
                withCredentials: true,
                headers: {
                    'current-profile': sessionStorage.getItem('currentProfile') || 'defaultProfile',
                }
            });
            console.log(`Viewed impression logged for ad: ${adId}`);
        } catch (error) {
            console.error(`Error logging viewed impression for ad: ${adId}`, error);
        }
    };

    // Set up the Intersection Observer to detect viewed impressions
    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    const adId = entry.target.dataset.adId;
                    if (entry.isIntersecting) {
                        const timer = setTimeout(() => {
                            logViewedImpression(adId);
                        }, 1000);
                        entry.target._timer = timer;
                    } else {
                        clearTimeout(entry.target._timer);
                    }
                });
            },
            {
                threshold: 0.5, 
            }
        );

        // Observe all ad elements
        productPairs.forEach((pair) => {
            pair.forEach(ad => {
                const adElement = adRefs.current.get(ad._id);
                if (adElement) {
                    observer.observe(adElement);
                }
            });
        });

        return () => {
            observer.disconnect(); 
        };
    }, [productPairs]);

    // Fetch sponsored product ads
    useEffect(() => {
        const fetchProducts = async () => {
            try {
                const response = await axios.get(`${server}/sponsored/sponsored-products`, { withCredentials: true });
                const activeProducts = response.data.filter(ad => ad.isActive && ad.product);
                const pairs = [];
                for (let i = 0; i < activeProducts.length; i += 2) {
                    pairs.push(activeProducts.slice(i, i + 2).map(ad => ({
                        ...ad,
                        product_id: ad.product._id,
                        name: ad.product.name,
                        adSet: ad.adSet,
                        discountPrice: ad.product.discountPrice,
                        originalPrice: ad.product.originalPrice
                    })));
                }
                setProductPairs(pairs);
                setIsLoading(false);
            } catch (error) {
                console.error('Error fetching products:', error);
                setIsLoading(false);
            }
        };

        fetchProducts();
    }, []);

    // Log impressions when the component renders
    useEffect(() => {
        productPairs.forEach(pair => {
            pair.forEach(ad => {
                if (ad._id) {
                    axios.post(`${server}/sponsored/impression/${ad._id}`, {}, {
                        withCredentials: true,
                        headers: {
                            'current-profile': sessionStorage.getItem('currentProfile') || 'defaultProfile',
                        }
                    })
                    .then(() => console.log(`Impression logged for ad: ${ad._id}`))
                    .catch(error => console.error(`Error logging impression for ad: ${ad._id}`, error));
                }
            });
        });
    }, [productPairs]);

    const logAdClick = async (ad_id) => {
        try {
            await axios.post(`${server}/sponsored/click/${ad_id}`, {}, {
                withCredentials: true,
                headers: {
                    'current-profile': sessionStorage.getItem('currentProfile') || 'defaultProfile',
                }
            });
            console.log('Click logged for ad:', ad_id);
        } catch (error) {
            console.error('Error logging click:', error);
        }
    };

    const nextSlide = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % productPairs.length);
    };

    const prevSlide = () => {
        setCurrentIndex((prevIndex) => (prevIndex - 1 + productPairs.length) % productPairs.length);
    };

    return (
        <div className="dual-product-ad-carousel-container">
          {isLoading ? (
            <div className="dual-product-ad-carousel-loading">Loading...</div>
          ) : (
            <>
              <div className="dual-product-ad-carousel-wrapper">
                {productPairs[currentIndex] &&
                  productPairs[currentIndex].map((ad, idx) => (
                    ad.product_id ? (
                      <Item key={ad._id} ad={ad} logAdClick={() => logAdClick(ad._id)} adRef={el => adRefs.current.set(ad._id, el)} />
                    ) : (
                      <div key={idx} className="dual-product-ad-carousel-error">
                        Error: Missing product data.
                      </div>
                    )
                  ))}
              </div>
              {productPairs.length > 1 && (
                <>
                  <button className="sponsored-carousel-nav-button sponsored-carousel-prev" onClick={prevSlide}>
                    ‹
                  </button>
                  <button className="sponsored-carousel-nav-button sponsored-carousel-next" onClick={nextSlide}>
                    ›
                  </button>
                </>
              )}
            </>
          )}
        </div>
      );
};

const Item = ({ ad, logAdClick, adRef }) => {
    const { images, positions } = ad.adSet;

    const imageUrl = images["Dual Product Carousel"];
    const position = positions["Dual Product Carousel"] || { x: 0, y: 0 };

    return (
        <div className="dual-product-ad-carousel-item" ref={adRef} data-ad-id={ad._id}>
            <div className="dual-product-ad-carousel-image-wrapper">
                <img
                    src={imageUrl}
                    alt={ad.name}
                    className="dual-product-ad-carousel-image"
                    style={{
                        transform: `translate(${position.x}px, ${position.y}px)`,
                    }}
                />
            </div>
            <div className="dual-product-ad-carousel-content">
                <h2 className="dual-product-ad-carousel-title">{ad.name}</h2>
                <p className="dual-product-ad-carousel-price">{`$${ad.discountPrice || ad.originalPrice}`}</p>
                <Link
                    to={`/product/${ad.product_id}`}
                    onClick={logAdClick}
                    className="dual-product-ad-carousel-button"
                >
                    Buy now
                </Link>
            </div>
        </div>
    );
};

export default DualProductAdCarousel;





               



