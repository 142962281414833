import React, { Fragment, useState, useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/20/solid';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { loadSeller } from "../../redux/actions/user";
import { getCurrentUserFollowers } from '../../redux/actions/followers';
import { server } from '../../server';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function RoomAddMemberForm({ roomId, currentMembers, onMemberAdded, onClose }) {
  const [open, setOpen] = useState(true);
  const [activeTab, setActiveTab] = useState('Followers');
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const dispatch = useDispatch();
  const currentProfile = useSelector((state) => state.user.currentProfile);
  const { user } = useSelector((state) => state.user);
  const { seller } = useSelector((state) => state.seller);
  const { currentUserFollowers, currentUserFollowing, loading: followersLoading, error: followersError } = useSelector(state => state.followers);

  const profileId = currentProfile === 'User' ? user?._id : seller?._id;
  const profileType = currentProfile === 'User' ? 'User' : 'Shop';

  useEffect(() => {
    dispatch(loadSeller());
    dispatch(getCurrentUserFollowers(profileId, profileType));
  }, [dispatch, activeTab, profileId, profileType]);

  const tabs = [
    { name: 'Followers', current: activeTab === 'Followers' },
    { name: 'Following', current: activeTab === 'Following' },
  ];

  const handleMemberSelect = (id, type) => {
    setSelectedMembers((prevSelectedMembers) =>
      prevSelectedMembers.some(member => member.id === id && member.type === type)
        ? prevSelectedMembers.filter(member => !(member.id === id && member.type === type))
        : [...prevSelectedMembers, { id, type }]
    );
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value.toLowerCase());
  };

  const filteredMembers = (() => {
    const members = activeTab === 'Followers' ? currentUserFollowers : currentUserFollowing;
    const filtered = members.filter(
      member => 
        member.name.toLowerCase().includes(searchTerm) && 
        !currentMembers.some(currentMember => currentMember._id === member._id)
    );
    return filtered;
  })();

  const handleSubmit = async () => {
    setLoading(true);
    setError('');
    setSuccess('');

    try {
      await axios.post(
        `${server}/room/rooms/${roomId}/add-members`,
        { memberIds: selectedMembers },
        {
          withCredentials: true,
        }
      );
      setSuccess('Members added successfully!');
      setSelectedMembers([]);
      onMemberAdded();
    } catch (err) {
      setError('Failed to add members. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="add-member-form-container" onClose={() => { setOpen(false); onClose(); }}>
        <div className="add-member-form-overlay" />
        <div className="add-member-form-wrapper">
          <div className="add-member-form-inner">
            <div className="add-member-form-content">
              <Transition.Child
                as={Fragment}
                enter="add-member-form-enter"
                enterFrom="add-member-form-enter-from"
                enterTo="add-member-form-enter-to"
                leave="add-member-form-leave"
                leaveFrom="add-member-form-leave-from"
                leaveTo="add-member-form-leave-to"
              >
                <Dialog.Panel className="add-member-form-panel">
                  <div className="add-member-form-body">
                    <div className="add-member-form-header">
                      <Dialog.Title className="add-member-form-title">Invite Members to Room</Dialog.Title>
                      <div className="add-member-form-close-button-wrapper">
                        <button
                          type="button"
                          className="add-member-form-close-button"
                          onClick={() => { setOpen(false); onClose(); }}
                        >
                          <XMarkIcon className="add-member-form-icon" aria-hidden="true" />
                        </button>
                      </div>
                    </div>
                    <div className="add-member-form-tabs">
                      <nav className="add-member-form-tabs-nav" aria-label="Tabs">
                        {tabs.map((tab) => (
                          <a
                            key={tab.name}
                            href="#"
                            className={classNames(
                              tab.current ? 'add-member-form-tab-active' : 'add-member-form-tab-inactive',
                              'add-member-form-tab'
                            )}
                            aria-current={tab.current ? 'page' : undefined}
                            onClick={() => setActiveTab(tab.name)}
                          >
                            {tab.name}
                          </a>
                        ))}
                      </nav>
                    </div>
                  </div>

                  <div className="add-member-form-user-selection">
                    <input
                      type="text"
                      placeholder={`Search ${activeTab.toLowerCase()}`}
                      className="add-member-form-search"
                      onChange={handleSearchChange}
                    />
                    <div className="add-member-form-list">
                      {followersLoading && <p>Loading...</p>}
                      {followersError && <p>{followersError}</p>}
                      {!followersLoading && !followersError && filteredMembers.length > 0 ? (
                        filteredMembers.map((member) => (
                          <div key={member._id} className="add-member-form-user-item">
                            <label>
                              <input
                                type="checkbox"
                                checked={selectedMembers.some(selected => selected.id === member._id && selected.type === member.type)}
                                onChange={() => handleMemberSelect(member._id, member.type)}
                              />
                              <img
                                src={member.avatar || '/default-avatar.png'}
                                alt={member.name}
                                className="add-member-form-user-avatar"
                              />
                              <span>{member.name}</span>
                            </label>
                          </div>
                        ))
                      ) : (
                        <p>{followersError || 'No members found.'}</p>
                      )}
                    </div>
                  </div>

                  <div className="add-member-form-actions">
                    <button
                      type="button"
                      className="add-member-form-submit"
                      onClick={handleSubmit}
                      disabled={loading || selectedMembers.length === 0}
                    >
                      {loading ? 'Inviting...' : 'Invite Selected Members'}
                    </button>
                    {error && <p className="error-message">{error}</p>}
                    {success && <p className="success-message">{success}</p>}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}




