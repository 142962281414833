import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { XMarkIcon } from '@heroicons/react/20/solid';
import { server } from "../../server";

const BannerAd = () => {
    const [ad, setAd] = useState(null);
    const adRef = useRef(null); // Ref for the banner ad

    // Function to log viewed impressions
    const logViewedImpression = async (adId) => {
        try {
            await axios.post(`${server}/sponsored/viewed-impression/${adId}`, {}, { withCredentials: true, 
                headers: {
               
                'current-profile': sessionStorage.getItem('currentProfile') || 'defaultProfile',
              }});
            console.log(`Viewed impression logged for ad: ${adId}`);
        } catch (error) {
            console.error('Error logging viewed impression:', error);
        }
    };

    useEffect(() => {
        const fetchAd = async () => {
            try {
                const response = await axios.get(`${server}/sponsored/sponsored-products`, { withCredentials: true });
                const activeAds = Array.isArray(response.data) ? response.data.filter(ad => ad.isActive && ad.adSet.images && ad.adSet.images["Banner Ad"]) : [];
                if (activeAds.length > 0) {
                    setAd(activeAds[0]);
                    logImpression(activeAds[0]._id);
                } else {
                    console.log("No active ads with Banner Ad images found");
                }
            } catch (error) {
                console.error('Error fetching ad:', error);
            }
        };

        fetchAd();
    }, []);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        const adId = entry.target.dataset.adId;
                        const timer = setTimeout(() => {
                            logViewedImpression(adId);
                        }, 1000);

                        entry.target._timer = timer;
                    } else {
                        clearTimeout(entry.target._timer);
                    }
                });
            },
            { threshold: 0.5 } // Trigger when 50% of the ad is in view
        );

        if (ad && adRef.current) {
            observer.observe(adRef.current);
        }

        return () => {
            observer.disconnect();
        };
    }, [ad]);

    const logAdClick = async (adId) => {
        try {
            await axios.post(`${server}/sponsored/click/${adId}`, {}, { withCredentials: true, 
                headers: {
               
                'current-profile': sessionStorage.getItem('currentProfile') || 'defaultProfile',
              }});
        } catch (error) {
            console.error('Error logging click:', error);
        }
    };

    const logImpression = async (adId) => {
        try {
            await axios.post(`${server}/sponsored/impression/${adId}`, {}, { withCredentials: true, 
                headers: {
               
                'current-profile': sessionStorage.getItem('currentProfile') || 'defaultProfile',
              }});
        } catch (error) {
            console.error('Error logging impression:', error);
        }
    };

    if (!ad) return null;

    const imageUrl = ad.adSet.images["Banner Ad"];
    const position = ad.adSet.positions["Banner Ad"] || { x: 0, y: 0 };

    return (
        <div className="product-banner-ad-container" ref={adRef} data-ad-id={ad._id}>
            <div className="product-banner-ad-content" 
                 style={{
                    backgroundImage: `linear-gradient(to left, rgba(0,0,0,0.1), rgba(0,0,0,0.8)), url(${imageUrl})`,
                    backgroundPosition: `${position.x}px ${position.y}px`,
                    backgroundSize: 'cover',
                }}>
                <Link to={`/product/${ad.product._id}`} 
                      onClick={() => logAdClick(ad._id)} 
                      className="product-banner-ad-link">
                    <strong className="product-banner-ad-strong">{ad.product.name}</strong>
                    <span> – Explore Now &rarr;</span>
                </Link>
                <button onClick={() => setAd(null)} className="product-banner-ad-dismiss">
                    <XMarkIcon className="product-banner-ad-icon" aria-hidden="true" />
                    <span className="sr-only">Dismiss</span>
                </button>
            </div>
        </div>
    );
};

export default BannerAd;



