import React from "react";
import { Link } from "react-router-dom";
import styles from "../../../styles/styles";

const HeroWholesaleProducts = () => {
  return (
    <div
      className= {`relative min-h-[70vh] 800px:min-h-[42vh] w-full bg-no-repeat ${styles.noramlFlex}`}
      style={{
        backgroundImage:
          "url(https://res.cloudinary.com/dzlopmfj8/image/upload/v1700274138/b3ayiztcpbtdepxysmlj.jpg)",
        backgroundAttachment: 'fixed',
      }}
    >
      <div className={`${styles.section} w-[90%] 800px:w-[60%]`}>
        <h1
          className={`text-[35px] leading-[1.2] 800px:text-[60px] text-[#ffffff] font-[600] capitalize`}
        >
          Wholesale Marketplace 
        </h1>
      </div>
    </div>
  );
};

export default HeroWholesaleProducts;