import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import Carousel from 'react-material-ui-carousel';
import { Paper, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import { server } from "../../server";

const MapProductAdSlider = () => {
    const [products, setProducts] = useState([]);
    const productRefs = useRef(new Map()); // To store references to each product ad element

    useEffect(() => {
        const fetchProducts = async () => {
            try {
                const response = await axios.get(`${server}/sponsored/sponsored-products`, { withCredentials: true });
                const activeProducts = response.data.filter(ad => ad.isActive && ad.product && ad.adSet.images["Sponsored Products"]).slice(0, 7);
                setProducts(activeProducts);
    
                // Log impressions for all products
                activeProducts.forEach(product => {
                    logImpression(product._id);
                });
            } catch (error) {
                console.error('Error fetching products:', error);
            }
        };
    
        fetchProducts();
    }, []);
    

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        const adId = entry.target.dataset.adId;
                        // Start a timer for 1 second to log viewed impression
                        const timer = setTimeout(() => {
                            logViewedImpression(adId);
                        }, 1000);

                        // Store the timer in the element to clear it if necessary
                        entry.target._timer = timer;
                    } else {
                        clearTimeout(entry.target._timer); // Clear the timer if ad goes out of view
                    }
                });
            },
            { threshold: 0.5 } // Trigger when 50% of the ad is in view
        );

        products.forEach(product => {
            const productElement = productRefs.current.get(product._id);
            if (productElement) {
                observer.observe(productElement);
            }
        });

        return () => {
            observer.disconnect(); // Clean up the observer on component unmount
        };
    }, [products]);

    const logImpression = async (adId) => {
        try {
            await axios.post(`${server}/sponsored/impression/${adId}`, {}, {
                withCredentials: true,
                headers: {
                    'current-profile': sessionStorage.getItem('currentProfile') || 'defaultProfile',
                }
            });
            console.log('Impression logged for product:', adId);
        } catch (error) {
            console.error('Error logging impression:', error);
        }
    };

    const logViewedImpression = async (adId) => {
        try {
            await axios.post(`${server}/sponsored/viewed-impression/${adId}`, {}, {
                withCredentials: true,
                headers: {
                    'current-profile': sessionStorage.getItem('currentProfile') || 'defaultProfile',
                }
            });
            console.log('Viewed impression logged for product:', adId);
        } catch (error) {
            console.error('Error logging viewed impression:', error);
        }
    };

    const logAdClick = async (adId) => {
        try {
            await axios.post(`${server}/sponsored/click/${adId}`, {}, {
                withCredentials: true,
                headers: {
                    'current-profile': sessionStorage.getItem('currentProfile') || 'defaultProfile',
                }
            });
            console.log('Click logged for ad:', adId);
        } catch (error) {
            console.error('Error logging click:', error);
        }
    };

    return (
        <div className="map-product-ad-slider-container fixed z-50 bottom-0 left-0 px-4 py-4">
            <Carousel autoPlay={true} animation="slide" navButtonsAlwaysVisible={true} interval={7000}>
                {products.map(({ _id, product, adSet }) => {
                    const imageUrl = adSet.images["Sponsored Products"];
                    const position = adSet.positions["Sponsored Products"] || { x: 0, y: 0 };
                    return (
                        <Paper
                            key={_id}
                            ref={(el) => productRefs.current.set(_id, el)} // Store ref to the product element
                            data-ad-id={_id} // Set the ad ID as a data attribute
                            className="map-product-ad-slider-paper"
                            style={{
                                backgroundImage: `url(${imageUrl})`,
                                transform: `translate(${position.x}px, ${position.y}px)`,
                            }}
                        >
                            <div className="map-product-ad-slider-content">
                                <h2 className="map-product-ad-slider-title">{product.name}</h2>
                                <p className="map-product-ad-slider-price">{`$${product.discountPrice || product.originalPrice}`}</p>
                                <Button
                                    component={Link}
                                    to={`/product/${product._id}`}
                                    onClick={() => logAdClick(_id)}
                                    variant="contained"
                                    className="map-product-ad-slider-button"
                                >
                                    Buy now
                                </Button>
                            </div>
                        </Paper>
                    );
                })}
            </Carousel>
        </div>
    );
};

export default MapProductAdSlider;


