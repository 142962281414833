import React from 'react';
import CampaignCreator from './CampaignCreator';


const CampaignCreateModal = ({ isOpen, onClose}) => {
  if (!isOpen) return null;

  return (
    <div className="campaign-creator-modal-overlay">
      <div className="campaign-creator-modal-content">
        <button className="campaign-creator-close-button" onClick={onClose}>
          X
        </button>
        <CampaignCreator />
      </div>
    </div>
  );
};

export default CampaignCreateModal;
