import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { loadSeller, fetchShopAndSubscriptions } from '../redux/actions/user';
import Loader from '../components/Layout/Loader';

const ShopProtectedRoute = ({ children, requiredTiers }) => {
    const dispatch = useDispatch();
    const { isAuthenticated } = useSelector(state => state.user);
    const { isLoading, error, seller, isSeller } = useSelector(state => state.seller);
    const subscriptions = useSelector(state => state.seller.subscriptions);
    const [initialLoading, setInitialLoading] = useState(true);

    useEffect(() => {
        if (!seller || !subscriptions) {
            dispatch(fetchShopAndSubscriptions());
        } else {
            setInitialLoading(false);
        }
    }, [dispatch, seller?.id, subscriptions]);

    if (isLoading || initialLoading) {
        return <Loader />;
    }

    if (error) {
        console.error('Error fetching shop details:', error);
        return <div>Error: {error}</div>;
    }

    if (!isSeller) {
        console.log('No shop data available');
        return <div>No Shop Data</div>;
    }

    const hasRequiredTier = subscriptions.some(sub => requiredTiers.includes(sub.tier) && sub.status === 'active');

    if (!isAuthenticated || !isSeller || !hasRequiredTier || subscriptions.length === 0) {
        console.log('Redirecting due to unmet conditions', { isAuthenticated, isSeller, hasRequiredTier, subscriptions });
        return <Navigate to="/shop-subscriptions" replace />;
    }

    return children;
};

export default ShopProtectedRoute;


