import { useState, useEffect } from "react";
import axios from "axios";
import { loadConnectAndInitialize } from "@stripe/connect-js";
import { server } from "./server";

export const useStripeConnect = (connectedAccountId) => {
  const [stripeConnectInstance, setStripeConnectInstance] = useState();
  const currentProfile = sessionStorage.getItem('currentProfile');

  useEffect(() => {
    if (connectedAccountId) {
      const fetchClientSecret = async () => {
        try {
          // Use axios to post to the backend
          const { data } = await axios.post(`${server}/shop/create-stripe-session`, {
            account: connectedAccountId
          },  { withCredentials: true, headers: {
            'Current-Profile': currentProfile, 
          } });

          if (data.client_secret) {
            return data.client_secret;
          } else {
            throw new Error("No client secret received");
          }
        } catch (error) {
          // Handle any errors that come from the request
          console.error("An error occurred while fetching the client secret:", error.response ? error.response.data.error : error.message);
          throw error;
        }
      };

      setStripeConnectInstance(
        loadConnectAndInitialize({
          publishableKey: process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY,
          fetchClientSecret,
          appearance: {
            overlays: "dialog",
            variables: {
              colorPrimary: "#C12AF8",
              colorBackground: "#333",
            },
          },
        })
      );
    }
  }, [connectedAccountId]);

  return stripeConnectInstance;
};

export default useStripeConnect;
