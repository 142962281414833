import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { server } from '../../server'; 
import FriendCard from './FriendCard'; 
import { useSelector } from 'react-redux';

const RecommendedConnections = () => {
    const [recommendedConnections, setRecommendedConnections] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    // Selectors to get the current profile, user, and seller information
    const currentProfile = useSelector((state) => state.user.currentProfile);
    const { user } = useSelector((state) => state.user);
    const initialSeller = useSelector((state) => state.seller.seller);
    const seller = useSelector((state) => state.seller.activeSeller);

    // Check if currentProfile is User or Shop and get the correct ID
    const sellerId = seller?._id || initialSeller?._id;
    const profileType = currentProfile === 'User' ? 'User' : 'Shop';
    const userId = currentProfile === 'User' ? user?._id : sellerId;

    // Log userId and profileType for debugging
    console.log('user:', user);
    console.log('seller:', seller);
    console.log('initialSeller:', initialSeller);
    console.log('userId:', userId);
    console.log('profileType:', profileType);
    useEffect(() => {
        // Only run the effect if userId is available
        if (userId) {
            const fetchRecommendedConnections = async () => {
                setLoading(true);
                try {
                    const response = await axios.get(`${server}/connections/recommend-connections/${userId}/${profileType}`, {
                        withCredentials: true
                    });
                    console.log('Response Data:', response.data); // Log response data
    
                    // Ensure the response is an array
                    if (Array.isArray(response.data.recommendedConnections)) {
                        setRecommendedConnections(response.data.recommendedConnections);
                    } else {
                        setRecommendedConnections([]); // Fallback to an empty array if the response is not an array
                    }
                } catch (err) {
                    if (err.response) {
                        console.error('Error Response Data:', err.response.data);
                        console.error('Error Response Status:', err.response.status);
                        console.error('Error Response Headers:', err.response.headers);
                        setError(`Error: ${err.response.status} - ${err.response.data}`);
                    } else if (err.request) {
                        console.error('Error Request:', err.request);
                        setError('No response received from the server.');
                    } else {
                        console.error('Error Message:', err.message);
                        setError(`Error: ${err.message}`);
                    }
                } finally {
                    setLoading(false);
                }
            };
    
            fetchRecommendedConnections();
        } else {
            console.log('User ID is not available yet, waiting...');
        }
    }, [userId, profileType]); // Trigger effect only when userId is defined
    
    

    if (loading) return <p>Loading...</p>;
    if (error) return <p>{error}</p>;

    // Prevent the component from rendering until userId is available
    if (!userId) {
        return <p>Loading user information...</p>;
    }

    return (
        <div className="recommended-connections-container">
            <h2 className="recommended-connections-title">Connections Like You!</h2>
            <div className="suggested-connection-list">
                {recommendedConnections.map(connection => (
                    <FriendCard 
                        key={connection._id} 
                        friend={connection} 
                        profileType={profileType} 
                        friendId={connection._id}
                    />
                ))}
            </div>
        </div>
    );
};

export default RecommendedConnections;



