import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchPosts, clearPosts, deletePost as deletePostAction, editPost as editPostAction } from '../redux/actions/post';
import PostWidgetRedux from "./PostWidgetRedux";

const PostsWidgetRedux = ({ ownerId, profileType, isProfile = true }) => {
  const dispatch = useDispatch();
  // Using useSelector to get the state from Redux store
  const { posts, loading, error } = useSelector(state => state.posts);

  useEffect(() => {
    // Clear posts before fetching new ones
    dispatch(clearPosts());
    // Dispatch the fetchPosts action on component mount or when ownerId changes
    dispatch(fetchPosts(profileType, ownerId, isProfile));
  }, [dispatch, ownerId, profileType, isProfile]);

   // Add the console log for posts
   useEffect(() => {
    if (posts && posts.length > 0) {
      console.log("Fetched posts:", posts);
    }
  }, [posts]);


  // Handle post deletion
  const handlePostDelete = (deletedPostId) => {
    // Dispatch deletePost action
    dispatch(deletePostAction(deletedPostId));
  };

  // Handle post edit
  const handlePostEdit = (updatedPost) => {
    // Dispatch editPost action
    dispatch(editPostAction(updatedPost));
  };

  // Optionally, you can handle loading and error states
  if (loading) return <div>Loading posts...</div>;
  if (error) return <div>Error fetching posts: {error}</div>;

  return (
    <>
      {posts && posts.map(post => (
        <PostWidgetRedux
          key={`${post._id}-${ownerId}`}
          postId={post._id}
          owner={post.owner}
          postUserId={post.postUserId}  
          profileType={post.profileType}
          name={post.name}
          description={post.description}
          location={post.location}
          images={post.images}        
          videos={post.videos}       
          avatar={post.avatar}
          likes={post.likes}
          link={post.link}
          comments={post.comments}
          totalComments={post.totalComments}
          createdAt={post.createdAt}
          onShareSuccess={() => {}}
          resharedBy={post.resharedBy}
          reshareDescription={post.reshareDescription}
          reshareAvatar={post.reshareAvatar}
          onPostDelete={handlePostDelete}
          onPostEdit={handlePostEdit}
          isLikedByCurrentUser={post.isLikedByCurrentUser}
        />
      ))}
    </>
  );
};

export default PostsWidgetRedux;

