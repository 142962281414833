import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getAllOrdersOfUser } from "../../redux/actions/order";

const TrackOrder = () => {
  const { orders } = useSelector((state) => state.order);
  const { user } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const { id } = useParams();

  useEffect(() => {
    dispatch(getAllOrdersOfUser(user._id));
  }, [dispatch, user._id]);

  const data = orders && orders.find((item) => item._id === id);

  return (
    <div className="track-order-component-container">
      <>
        {data && data?.status === "Processing" ? (
          <h1 className="track-order-component-status">
            Your Order is processing in the shop.
          </h1>
        ) : data?.status === "Transferred to delivery partner" ? (
          <h1 className="track-order-component-status">
            Your Order is on the way to the delivery partner.
          </h1>
        ) : data?.status === "Shipping" ? (
          <h1 className="track-order-component-status">
            Your Order is on the way with our delivery partner.
          </h1>
        ) : data?.status === "Received" ? (
          <h1 className="track-order-component-status">
            Your Order is in your city. Our Delivery person will deliver it.
          </h1>
        ) : data?.status === "On the way" ? (
          <h1 className="track-order-component-status">
            Our Delivery person is going to deliver your order.
          </h1>
        ) : data?.status === "Delivered" ? (
          <h1 className="track-order-component-status">
            Your order has been delivered!
          </h1>
        ) : data?.status === "Processing refund" ? (
          <h1 className="track-order-component-status">
            Your refund is being processed!
          </h1>
        ) : data?.status === "Refund Success" ? (
          <h1 className="track-order-component-status">
            Your refund was successful!
          </h1>
        ) : null}
      </>
    </div>
  );
};

export default TrackOrder;

