import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { server } from "../../server";

const SponsoredBrandCarousel = () => {
    const [brands, setBrands] = useState([]);
    const brandRefs = useRef(new Map()); // To store brand refs for IntersectionObserver

    useEffect(() => {
        const fetchBrands = async () => {
            try {
                const response = await axios.get(`${server}/sponsored/sponsored-brands`, { withCredentials: true });
                const activeBrands = response.data.filter(ad => ad.isActive && ad.brand);
                setBrands(activeBrands);
            } catch (error) {
                console.error('Error fetching brands:', error);
            }
        };

        fetchBrands();
    }, []);

    const logImpression = async (adId) => {
        try {
            await axios.post(`${server}/sponsored/impression/${adId}`, {}, {
                withCredentials: true,
                headers: {
                    'current-profile': sessionStorage.getItem('currentProfile') || 'defaultProfile',
                }
            });
            console.log('Impression logged for brand:', adId);
        } catch (error) {
            console.error('Error logging impression:', error);
        }
    };

    const logViewedImpression = async (adId) => {
        try {
            await axios.post(`${server}/sponsored/viewed-impression/${adId}`, {}, {
                withCredentials: true,
                headers: {
                    'current-profile': sessionStorage.getItem('currentProfile') || 'defaultProfile',
                }
            });
            console.log('Viewed impression logged for brand:', adId);
        } catch (error) {
            console.error('Error logging viewed impression:', error);
        }
    };

    const logAdClick = async (adId) => {
        try {
            await axios.post(`${server}/sponsored/click/${adId}`, {
                withCredentials: true,
                headers: {
                    'current-profile': sessionStorage.getItem('currentProfile') || 'defaultProfile',
                }
            });
            console.log('Click logged for brand ad:', adId);
        } catch (error) {
            console.error('Error logging click:', error);
        }
    };

    useEffect(() => {
        brands.forEach(({ _id }) => {
            logImpression(_id); 
        });
    }, [brands]);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        const adId = entry.target.dataset.adId;

                        // Start a timer for 1 second to log viewed impression
                        const timer = setTimeout(() => {
                            logViewedImpression(adId);
                        }, 1000);

                        // Store the timer in the DOM element
                        entry.target._timer = timer;
                    } else {
                        clearTimeout(entry.target._timer); 
                    }
                });
            },
            { threshold: 0.5 } 
        );

        // Observe each brand in the carousel
        brands.forEach(({ _id }) => {
            const brandElement = brandRefs.current.get(_id);
            if (brandElement) {
                observer.observe(brandElement);
            }
        });

        return () => {
            observer.disconnect(); 
        };
    }, [brands]);

    return (
        <div className="sponsored-brand-ad-carousel">
            {brands.map(({ _id, brand }) => (
                <div
                    key={_id}
                    className="sponsored-brand-ad-carousel-item"
                    style={{ backgroundImage: `url(${brand.coverImage.url})` }}
                    data-ad-id={_id} 
                    ref={(el) => el && brandRefs.current.set(_id, el)} 
                >
                    <Item brand={brand} logAdClick={() => logAdClick(_id)} />
                </div>
            ))}
        </div>
    );
};

const Item = ({ brand, logAdClick }) => {
    return (
        <div className="sponsored-brand-ad-carousel-content">
            <h2 className="sponsored-brand-ad-carousel-title">{brand.name}</h2>
            <p className="sponsored-brand-ad-carousel-description">{brand.description}</p>
            <Link to={`/brand/${brand.name}`} onClick={logAdClick} className="sponsored-brand-ad-carousel-button">
                Visit Brand
            </Link>
        </div>
    );
};

export default SponsoredBrandCarousel;


