import React, { useEffect } from "react";
import AdminHeader from "../components/Layout/AdminHeader";
import AdminSideBar from "../components/Admin/Layout/AdminSideBar";
import { useDispatch, useSelector } from "react-redux";
import { getAllOrdersOfAdmin } from "../redux/actions/order";


const AdminDashboardOrders = () => {
  const dispatch = useDispatch();

  const { adminOrders, adminOrderLoading } = useSelector(
    (state) => state.order
  );

  useEffect(() => {
    dispatch(getAllOrdersOfAdmin());
  }, [dispatch]);

  const rows = [];
  adminOrders &&
    adminOrders.forEach((item) => {
      rows.push({
        id: item._id,
        itemsQty: item?.cart?.reduce((acc, item) => acc + item.qty, 0),
        total: item?.totalPrice + " $",
        status: item?.status,
        createdAt: item?.createdAt.slice(0,10),
      });
    });

  return (
    <div>
      <AdminHeader />
      <div className="admin-all-orders-container">
        <div className="admin-all-orders-content">
          <div className="admin-all-orders-sidebar">
            <AdminSideBar active={2} />
          </div>
  
          <div className="admin-all-orders-main">
            <div className="admin-all-orders-table-container">
              <table className="admin-all-orders-table">
              <thead className="admin-all-orders-thead">
                  <tr>
                    <th>Order ID</th>
                    <th>Status</th>
                    <th>Items Qty</th>
                    <th>Total</th>
                    <th>Order Date</th>
                  </tr>
                </thead>
                <tbody className="admin-all-orders-tbody">
                  {rows.map((row) => (
                    <tr key={row.id}>
                      <td>{row.id}</td>
                      <td className={row.status === "Delivered" ? "greenColor" : "redColor"}>
                        {row.status}
                      </td>
                      <td>{row.itemsQty}</td>
                      <td>{row.total}</td>
                      <td>{row.createdAt}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminDashboardOrders;

