import React, { useState } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload } from '@fortawesome/free-solid-svg-icons';
import { server } from "../../server";

function MemeCreator() {
  const [image, setImage] = useState(null);
  const [text, setText] = useState('');
  const [previewUrl, setPreviewUrl] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewUrl(reader.result);
      };
      reader.readAsDataURL(file);
      setImage(file);
    }
  };

  const handleTextChange = (event) => {
    setText(event.target.value);
  };

  const handleSubmit = async () => {
    if (!image || !text) {
      setError('Please upload an image and add text.');
      return;
    }

    const formData = new FormData();
    formData.append('image', image);
    formData.append('overlayText', text);

    setLoading(true);
    setError(null);

    try {
      const response = await axios.post(`${server}/meme/create-meme`, formData, {
         withCredentials: true ,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      console.log('Meme created:', response.data);
      setLoading(false);
      // Optionally, redirect or update UI to show the new meme
    } catch (error) {
      console.error('Error creating meme:', error);
      setLoading(false);
      setError('Failed to create meme. Please try again.');
    }
  };

  return (
    <div className="meme-creator">
      <h2>Create Your Meme</h2>
      {error && <div className="meme-error-message">{error}</div>}
      <div className="meme-upload-section">
        <label htmlFor="image-upload" className="meme-upload-label">
          <FontAwesomeIcon icon={faUpload} /> Upload Image
        </label>
        <input
          id="image-upload"
          type="file"
          accept="image/*"
          onChange={handleImageUpload}
          style={{ display: 'none' }}
        />
      </div>
      <input
        type="text"
        placeholder="Enter your text here"
        value={text}
        onChange={handleTextChange}
        className="meme-text-input"
      />
      {previewUrl && (
        <div className="meme-preview">
          <img src={previewUrl} alt="Meme Preview" className="meme-preview-image" />
          <div className="meme-text-overlay">{text}</div>
        </div>
      )}
      <button onClick={handleSubmit} disabled={loading} className="meme-submit-button">
        {loading ? 'Creating Meme...' : 'Create Meme'}
      </button>
    </div>
  );
}

export default MemeCreator;
