import React, { useState } from 'react';
import AdDetailsModal from './AdDetailsModal'; 
import axios from 'axios';
import { toast } from 'react-toastify';
import { server } from "../../server";

const TopPerformingAds = ({ ads }) => {
    const [selectedAd, setSelectedAd] = useState(null); 
    const [isModalOpen, setIsModalOpen] = useState(false);

    if (!ads || ads.length === 0) {
        return <p>No ads available.</p>;
    }

    const sortedAds = [...ads].sort((a, b) => {
        const totalA = a.clicks + a.impressions;
        const totalB = b.clicks + b.impressions;
        return totalB - totalA; 
    });

    const topAds = sortedAds.slice(0, 3);

    const averageClicks = ads.reduce((acc, ad) => acc + ad.clicks, 0) / ads.length;
    const averageImpressions = ads.reduce((acc, ad) => acc + ad.impressions, 0) / ads.length;

    const lowestEngagementAd = sortedAds[sortedAds.length - 1];
    const averageEngagement = { clicks: averageClicks, impressions: averageImpressions };

    const openModal = (ad) => {
        setSelectedAd(ad);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setSelectedAd(null);
        setIsModalOpen(false);
    };

    const handleRerunAd = async (adId) => {
        try {
            await axios.post(
                `${server}/sponsored/rerun-ad`, 
                { adId }, 
                {
                    withCredentials: true, 
                    headers: {
                        'current-profile': sessionStorage.getItem('currentProfile'),
                    }
                }
            );
            toast.success('Ad re-run successfully!');
            closeModal();
        } catch (error) {
            toast.error('Error re-running ad.');
        }
    };
    

    return (
        <div className="top-performing-ads-container">
            <h3 className="top-performing-ads-title">Top Performing Ads</h3>
            <div className="top-performing-ads-list">
                {topAds.map((ad, index) => (
                    <div key={ad._id} className="top-ad-card">
                        <div className="top-ad-rank">#{index + 1}</div>
                        <div className="top-ad-content">
                            <div className="top-ad-image">
                                <img
                                    src={
                                        ad.product?.images[0]?.url ||
                                        ad.shop?.avatar?.url ||
                                        ad.brand?.avatarImage?.url ||
                                        'https://via.placeholder.com/150' 
                                    }
                                    alt={ad.product?.name || ad.shop?.name || ad.brand?.name}
                                />
                            </div>
                            <div className="top-ad-details">
                                <h4 className="top-ad-name">
                                    {ad.product?.name || ad.shop?.name || ad.brand?.name}
                                </h4>
                                <p className="top-ad-description">
                                    {ad.product?.description || ad.shop?.description || ad.brand?.description || 'No description available.'}
                                </p>
                                <div className="top-ad-stats">
                                    <span>Clicks: {ad.clicks}</span>
                                    <span>Impressions: {ad.impressions}</span>
                                </div>
                            </div>
                        </div>
                        <div className="top-ad-ellipsis" onClick={() => openModal(ad)}>
                            &#x2026;
                        </div>
                    </div>
                ))}
            </div>
            {isModalOpen && (
                <AdDetailsModal
                    ad={selectedAd}
                    onClose={closeModal}
                    lowestEngagementAd={lowestEngagementAd}
                    averageEngagement={averageEngagement}
                    onRerunAd={handleRerunAd}
                />
            )}
        </div>
    );
};

export default TopPerformingAds;

