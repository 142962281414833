import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom'; 

export default function ShopByBrand() {
  const { allProducts, isLoading } = useSelector((state) => state.products);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  // Extract unique brands
  const brands = [...new Set(allProducts.map(product => product.brand))];

  return (
    <div className="shop-by-brand-carousel-bg">
      <div className="shop-by-brand-carousel-container">
        <div className="shop-by-brand-carousel-header">
          <h2 className="shop-by-brand-carousel-title">Shop by Brand</h2>
        </div>

        <div className="shop-by-brand-carousel-content">
          <div className="shop-by-brand-carousel-grid">
            {brands.map((brand, index) => (
              <div key={index} className="shop-by-brand-carousel-item">
                <Link to={`/brand/${brand}`} className="shop-by-brand-carousel-link">
                  <div className="shop-by-brand-carousel-brand">{brand}</div>
                  <div className="shop-by-brand-carousel-explore">Explore products</div>
                </Link>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

