import React from 'react';

const MemberSignInModal = ({ member, onClose, action }) => {
    return (
        <div className="member-sign-in-modal-overlay">
            <div className="member-sign-in-modal">
            <h2>{`Member ${action === 'signin' ? 'Signed In' : 'Signed Out'}!`}</h2>
                <img src={member.photo} alt={`${member.firstName} ${member.lastName}`} />
                <p>{`${member.firstName} ${member.lastName}`}</p>
                <button onClick={onClose}>Close</button>
            </div>
        </div>
    );
};

export default MemberSignInModal;
