import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { server } from "../../server";
import Loader from "../Layout/Loader";
import { AiOutlineCamera } from "react-icons/ai";
import { loadUser } from "../../redux/actions/user";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import DraggableImage from "../../components/Sponsored/DraggableImage";

const UserBanner = ({ isUser }) => {
  const [user, setUser] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const { id } = useParams();
  const [userBanner, setUserBanner] = useState(null);
  const [bannerPosition, setBannerPosition] = useState({ x: 0, y: 0 });
  const [showSaveButton, setShowSaveButton] = useState(false);
  const currentProfile = sessionStorage.getItem("currentProfile");
  const dispatch = useDispatch();

  useEffect(() => {
    setIsLoading(true);
    axios
      .get(`${server}/user/user-info/${id}`)
      .then((res) => {
        setUser(res.data.user);
        setUserBanner(res.data.user.userBanner?.url);
        setBannerPosition(res.data.user.bannerPosition || { x: 0, y: 0 });
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  }, [id]);

  const handleBannerChange = async (e) => {
    const reader = new FileReader();

    reader.onload = () => {
      if (reader.readyState === 2) {
        setUserBanner(reader.result);
        setShowSaveButton(true); // Show the save button after image is uploaded
      }
    };
    reader.readAsDataURL(e.target.files[0]);
  };

  const handleSaveBanner = async () => {
    try {
      await axios.put(
        `${server}/user/update-banner`,
        { userBanner, bannerPosition }, // Send both banner image and position
        {
          withCredentials: true,
          headers: { "Current-Profile": currentProfile },
        }
      );
      dispatch(loadUser());
      toast.success("Banner updated successfully!");
      setShowSaveButton(false); // Hide the save button after saving
      window.location.reload();
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className="user-banner-container">
      {showSaveButton ? (
        <DraggableImage
          src={userBanner}
          initialPosition={bannerPosition}
          onPositionChange={setBannerPosition} // Update position state when banner is moved
        />
      ) : (
        <img
          src={userBanner || `${user.userBanner?.url}`}
          alt="User Banner"
          className="user-banner-image"
          style={{
            transform: `translate(${bannerPosition.x}px, ${bannerPosition.y}px)`,
          }}
        />
      )}
      {isUser && (
        <div className="user-banner-controls">
          {!showSaveButton && (
            <div className="user-banner-edit-icon">
              <input
                type="file"
                id="banner"
                className="user-banner-file-input"
                onChange={handleBannerChange}
              />
              <label htmlFor="banner">
                <AiOutlineCamera className="user-banner-camera-icon" />
              </label>
            </div>
          )}
          {showSaveButton && (
            <button
              className="user-banner-save-button"
              onClick={handleSaveBanner}
            >
              Save
            </button>
          )}
        </div>
      )}
    </div>
  );
};

export default UserBanner;

