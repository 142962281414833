import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { AiOutlineEye } from "react-icons/ai";
import { server } from "../../server";


const AllEvents = () => {
  const [events, setEvents] = useState([]);

  useEffect(() => {
    axios
      .get(`${server}/salesevent/admin-all-events`, { withCredentials: true })
      .then((res) => {
        setEvents(res.data.events);
      });
  }, []);

  return (
    <div className="admin-all-events-component-container">
      <table className="admin-all-events-component-table">
        <thead>
          <tr>
            <th>Product Id</th>
            <th>Name</th>
            <th>Price</th>
            <th>Stock</th>
            <th>Sold out</th>
            <th>Preview</th>
          </tr>
        </thead>
        <tbody>
          {events.map((item) => (
            <tr key={item._id}>
              <td>{item._id}</td>
              <td>{item.name}</td>
              <td>US$ {item.discountPrice}</td>
              <td>{item.stock}</td>
              <td>{item.sold_out}</td>
              <td>
                <Link to={`/product/${item._id}?isEvent=true`}>
                  <button className="admin-all-events-component-preview-btn">
                    <AiOutlineEye size={20} />
                  </button>
                </Link>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default AllEvents;

