import React, { useState, useRef, useEffect } from 'react';

const DraggableImage = ({ src, initialPosition, onPositionChange }) => {
    const [position, setPosition] = useState(initialPosition);
    const [dragging, setDragging] = useState(false);
    const imgRef = useRef(null);
    const containerRef = useRef(null);
    const offset = useRef({ x: 0, y: 0 });

    const handleMouseDown = (e) => {
        e.preventDefault();
        setDragging(true);

        const rect = imgRef.current.getBoundingClientRect();
        offset.current = {
            x: e.clientX - rect.left,
            y: e.clientY - rect.top,
        };
    };

    const handleMouseMove = (e) => {
        if (dragging) {
            const containerRect = containerRef.current.getBoundingClientRect();
            const imgRect = imgRef.current.getBoundingClientRect();

            // Calculate new positions
            const newX = e.clientX - containerRect.left - offset.current.x;
            const newY = e.clientY - containerRect.top - offset.current.y;

            // Allow free movement within and beyond the container's boundaries
            const constrainedX = Math.min(Math.max(newX, containerRect.width - imgRect.width), 0);
            const constrainedY = Math.min(Math.max(newY, containerRect.height - imgRect.height), 0);

            setPosition({ x: constrainedX, y: constrainedY });
        }
    };

    const handleMouseUp = () => {
        if (dragging) {
            setDragging(false);
            onPositionChange(position);
        }
    };

    useEffect(() => {
        const handleMouseUpGlobal = () => {
            if (dragging) {
                setDragging(false);
                onPositionChange(position);
            }
        };
        window.addEventListener('mouseup', handleMouseUpGlobal);
        window.addEventListener('mousemove', handleMouseMove);
        return () => {
            window.removeEventListener('mouseup', handleMouseUpGlobal);
            window.removeEventListener('mousemove', handleMouseMove);
        };
    }, [dragging, position, onPositionChange]);

    return (
        <div
            ref={containerRef}
            onMouseDown={handleMouseDown}
            style={{
                position: 'relative',
                width: '100%',
                height: '100%',
                overflow: 'hidden',
            }}
        >
            <img
                ref={imgRef}
                src={src}
                alt="Draggable"
                style={{
                    position: 'absolute',
                    left: `${position.x}px`,
                    top: `${position.y}px`,
                    cursor: dragging ? 'grabbing' : 'grab',
                    userSelect: 'none',
                    width: '120%',
                }}
            />
        </div>
    );
};

export default DraggableImage;













