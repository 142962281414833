import React, { useState } from 'react';
import Rooms from './Rooms';
import RoomCreationForm from './RoomCreationForm';


const RoomsProfile = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="rooms-profile-container">
      
      

      <Rooms />
      <button className="rooms-profile-create-room-button" onClick={openModal}>
        Create Room
      </button>

      {isModalOpen && (
        <div className="rooms-profile-modal-overlay" onClick={closeModal}>
          <div className="rooms-profile-modal-content" onClick={(e) => e.stopPropagation()}>
            <button className="rooms-profile-close-modal-button" onClick={closeModal}>
              &times;
            </button>
            <RoomCreationForm />
          </div>
        </div>
      )}
    </div>
  );
};

export default RoomsProfile;
