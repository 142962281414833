import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import { loadStripe } from '@stripe/stripe-js';
import { EmbeddedCheckoutProvider, EmbeddedCheckout } from '@stripe/react-stripe-js';
import { server } from "../../server";
import styles from "../../styles/styles";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const MembershipCheckout = ({ prevStep, formData }) => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [priceDetails, setPriceDetails] = useState(null);
    const [clientSecret, setClientSecret] = useState('');
    const [sessionId, setSessionId] = useState('');

    useEffect(() => {
        const fetchPriceDetails = async () => {
            try {
                const response = await axios.get(`${server}/membership/memberships/price-details`);
                setPriceDetails(response.data);
            } catch (error) {
                console.error('Failed to fetch price details', error);
                toast.error('Failed to load pricing information.');
            }
        };

        fetchPriceDetails();
    }, []);

    const handlePaymentSubmission = async (event) => {
        event.preventDefault();
        setIsLoading(true);

        try {
            const { data } = await axios.post(`${server}/membership/create-membership-checkout-session`, {
                firstName: formData.firstName,
                lastName: formData.lastName,
                email: formData.email,
                dob: formData.dob,
                photo: formData.photo,
                priceId: priceDetails.id
            }, {
                headers: {
                    "Content-Type": "application/json",
                }
            });

            setClientSecret(data.clientSecret);
            setSessionId(data.sessionId);
        } catch (error) {
            console.error('Checkout session creation failed:', error);
            toast.error('Failed to create checkout session. Please try again.');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="membership-checkout-container mx-auto p-4">
            <div className="grid grid-cols-1 gap-4 max-w-4xl mx-auto p-6 rounded-lg shadow-lg">
                <div className="order-2 lg:order-1 p-6 rounded-lg shadow-inner">
                    {priceDetails && (
                        <div className="membership-details mb-6">
                            <h2 className="text-xl font-semibold mb-2">{`Level up with your new: ${priceDetails.product.name}`}</h2>
                            <p className="mb-4">{priceDetails.product.description}</p>
                            <p className="font-semibold">Price: ${(priceDetails.unit_amount / 100).toFixed(2)} {priceDetails.currency.toUpperCase()}</p>
                        </div>
                    )}
                    <div className="membership-summary bg-gray-100 p-4 rounded-lg shadow-inner">
                        <h3 className="text-lg font-semibold mb-4">Review Your Information</h3>
                        <p><strong>First Name:</strong> {formData.firstName}</p>
                        <p><strong>Last Name:</strong> {formData.lastName}</p>
                        <p><strong>Email:</strong> {formData.email}</p>
                        <p><strong>Date of Birth:</strong> {formData.dob}</p>
                        {formData.photo && <p><strong>Photo:</strong> <img src={formData.photo} alt="Member" className="membership-photo-preview" /></p>}
                        {formData.signature1 && <p><strong>Signature 1:</strong> <img src={formData.signature1} alt="Signature 1" className="membership-signature-preview" /></p>}
                        {formData.signature2 && <p><strong>Signature 2:</strong> <img src={formData.signature2} alt="Signature 2" className="membership-signature-preview" /></p>}
                        {formData.signature3 && <p><strong>Signature 3:</strong> <img src={formData.signature3} alt="Signature 3" className="membership-signature-preview" /></p>}
                    </div>
                    <form onSubmit={handlePaymentSubmission} className={styles.form}>
                        <div className="mb-4">
                            <label className="block pb-2 text-gray-700">Name On Card</label>
                            <input type="text" className={styles.input} placeholder="Full Name" value={`${formData.firstName} ${formData.lastName}`} readOnly />
                        </div>
                        <div className="mb-4">
                            <label className="block pb-2 text-gray-700">Email Address</label>
                            <input type="email" className={styles.input} placeholder="Email Address" value={formData.email} readOnly />
                        </div>
                        <button type="submit" className={styles.button} disabled={isLoading}>
                            {isLoading ? 'Processing...' : 'Confirm Membership'}
                        </button>
                    </form>
                    <button onClick={prevStep} className="btn btn-secondary mt-4 lg:mt-0">Back</button>
                </div>
                {clientSecret && (
                    <div className="order-3 lg:order-2 p-6 rounded-lg shadow-inner">
                        <EmbeddedCheckoutProvider stripe={stripePromise} options={{ clientSecret }}>
                            <EmbeddedCheckout sessionId={sessionId} />
                        </EmbeddedCheckoutProvider>
                    </div>
                )}
            </div>
        </div>
    );
};

export default MembershipCheckout;
