import React from 'react';

const FlexBetween = ({ children, gap, className = '', style, ...props }) => {
  return (
    <div 
      className={`flex-between ${className}`} 
      style={{ gap, ...style }} 
      {...props}
    >
      {children}
    </div>
  );
};

export default FlexBetween;

