import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { server } from "../../server";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from '../Layout/Loader';

const CreateCustomAdCampaign = ({ onSave, adType, details }) => {
    const { seller } = useSelector(state => state.seller);
    const [adText, setAdText] = useState(details?.adText || '');
    const [adLink, setAdLink] = useState(details?.adLink || '');
    const [adImage, setAdImage] = useState(null);
    const [adSpendPerDay, setAdSpendPerDay] = useState(details?.adSpend || 50);
    const [adDuration, setAdDuration] = useState(details?.adDuration || 7);
    const [totalAdSpend, setTotalAdSpend] = useState(adSpendPerDay * adDuration);
    const [isLoading, setIsLoading] = useState(false);
    const fileInputRef = useRef(null);

    const id = seller?._id;
    const currentProfileName = sessionStorage.getItem('currentProfile');

    useEffect(() => {
        setTotalAdSpend(adSpendPerDay * adDuration);
    }, [adSpendPerDay, adDuration]);

    const handleSaveAd = async (e) => {
        e.preventDefault();

        if (!adText || !adLink || !adImage || !adSpendPerDay || !adDuration) {
            toast.error('All fields are required.');
            return;
        }

        // Construct ad details for saving
        const formData = new FormData();
        formData.append('text', adText);
        formData.append('link', adLink);
        formData.append('totalAdSpend', totalAdSpend);
        formData.append('adDuration', adDuration);
        formData.append('image', adImage);

        const details = {
            adText,
            adLink,
            adImage: URL.createObjectURL(adImage), // Preview URL, replace with server URL later
            adSpend: totalAdSpend,
            adDuration,
        };

        // Pass the ad set and details back to the parent component
        onSave(formData, details, adType);

        toast.success('Custom ad configuration saved.');
    };

    const handleImageChange = (e) => {
        setAdImage(e.target.files[0]);
    };

    return (
        <div className="create-custom-ad-container">
            <h2>Create a Custom Ad</h2>

            {isLoading ? (
                <Loader />
            ) : (
                <form onSubmit={handleSaveAd}>

                    <div className="form-group">
                        <label>Ad Text</label>
                        <input
                            type="text"
                            value={adText}
                            onChange={(e) => setAdText(e.target.value)}
                            placeholder="Enter ad text"
                        />
                    </div>

                    <div className="form-group">
                        <label>Ad Link</label>
                        <input
                            type="text"
                            value={adLink}
                            onChange={(e) => setAdLink(e.target.value)}
                            placeholder="Enter ad link"
                        />
                    </div>

                    <div className="form-group">
                        <label>Upload Ad Image</label>
                        <input
                            type="file"
                            ref={fileInputRef}
                            onChange={handleImageChange}
                            accept="image/*"
                        />
                    </div>

                    <div className="form-group sponsor-product-component-slider">
                        <label>Set Ad Spend per Day ($)</label>
                        <input
                            type="text"
                            value={adSpendPerDay}
                            onChange={(e) => setAdSpendPerDay(Number(e.target.value))}
                        />
                        <input
                            type="range"
                            min="1"
                            max="1000"
                            value={adSpendPerDay}
                            onChange={(e) => setAdSpendPerDay(Number(e.target.value))}
                            className="sponsor-product-component-range-slider"
                        />
                    </div>

                    <div className="form-group sponsor-product-component-slider">
                        <label>Set Ad Duration (Days)</label>
                        <input
                            type="text"
                            value={adDuration}
                            onChange={(e) => setAdDuration(Number(e.target.value))}
                        />
                        <input
                            type="range"
                            min="1"
                            max="30"
                            value={adDuration}
                            onChange={(e) => setAdDuration(Number(e.target.value))}
                            className="sponsor-product-component-range-slider"
                        />
                    </div>

                    <div className="form-group">
                        <label>Total Ad Spend: ${totalAdSpend}</label>
                    </div>

                    <button type="submit" className="create-ad-button">
                        Save Ad Configuration
                    </button>
                </form>
            )}
        </div>
    );
};

export default CreateCustomAdCampaign;
